import { useState, useEffect } from "react";
import { useParams } from "react-router-dom";
import { trackPromise } from "react-promise-tracker";
import { BookSlotServices } from "../../services";
import AgoraUIKit from "agora-react-uikit";
import "./Appointment.call.css";
import { formatDate, formatTime, yearDateFormat } from "../../utilities/functions";
import { useSelector } from "react-redux";
import RatingPopUp from "./RatingPopUp";
import * as Yup from "yup";
import coachRatingService from "../../services/coachRating.service";
import { toast } from "react-toastify";
import { Modal, Button } from "react-bootstrap";

import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
import StreamVideoComponent from "./StreamVideo";
// AgoraRTC.createCameraVideoTrack({
//   encoderConfig: '360p_1',
// }).then(localVideoTrack => {/** ... **/ });

// const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });


const styles = {
  container: { width: "100vw", height: "100vh", display: "flex" },
};

function AghoraVideo() {
  const { authState } = useSelector((state) => state);

  const params = useParams();
  const [pageError, set_pageError] = useState("");
  const [slotDetails, set_slotDetails] = useState([]);
  /* Video calling */
  const [rtcConfig, set_rtcConfig] = useState({});
  const [videocall, set_videocall] = useState(false);
  const [isOpen, setIsopen] = useState(false);
  const [bookingRating, set_bookingRating] = useState({});
  const [showModal, setShowModal] = useState(false);

  const role = authState?.user?.role?.roleCode;

  const slotId = params?.slotId;
  // const callbacks = {
  //   EndCall: () => {
  //     console.log("end");
  //     set_videocall(false);
  //     if (role === "coach") {
  //       window.close();
  //     }
  //     setIsopen(true);
  //   },
  // };
  const callbacks = {
    EndCall: () => {
      // set_videocall(true);
      setShowModal(true);
    },
  };
  const handleConfirmEndCall = () => {
    set_videocall(false);
    if (role === "coach") {
      window.close();
    }
    setShowModal(false);
    setIsopen(true);
  };

  useEffect(() => {
    async function fetchAppointmentDetail() {
      try {
        const resp = await trackPromise(BookSlotServices.getSlotAppointmentDetail(slotId));
        const { data } = resp;
        if (data?.success) {
          set_slotDetails(data?.data);
        } else {
          set_pageError(data.message || "Error in fetching ");
        }
      } catch (err) {
        console.error("Fetch Coaches catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchAppointmentDetail();
  }, [slotId]);

  useEffect(() => {
    getRtcConfiguration();
  }, []);

  useEffect(() => {
    (async () => {
      console.log("rtcConfig ==========> ", rtcConfig);
      console.log("videocall ==========>", videocall);
    })();
  }, [rtcConfig]);
  // customRtcClient:{
  //   localTracks:
  // }

  useEffect(() => {
    if (slotDetails.length !== 0 && role === "student") {
      fetchBookingRating();
    }
  }, [role, slotDetails]);

  async function getRtcConfiguration() {
    try {
      const resp = await trackPromise(BookSlotServices.getRtcCofiguration(params?.slotId));
      const { data } = resp;
      set_videocall(data.success);
      if (data.success) {
        const rtconfg = data.data;
        // delete rtconfg.user;
        console.log(rtcConfig,".........................................................");
        set_rtcConfig({...rtconfg});
      } else {
        set_pageError(data.message || "Error in fetching RTC configuration.");
      }
    } catch (err) {
      console.error("Fetch RTC configuration catch => ", err);
      if (err?.code !== "ERR_NETWORK") {
        const date = err?.response?.data?.message?.msgData;
        set_pageError(
          `${err?.response?.data?.message?.textMsg}: ${date ? `${formatDate(date)} ${formatTime(date)}.` : ""}`
        );
      } else {
        set_pageError(err?.message);
      }
    }
  }

  function formObject() {
    return {
      rating: 0,
    };
  }

  const FormSchema = Yup.object().shape({
    rating: Yup.number().required("Rating is Required"),
    // review: Yup.string().required("Detail is Required"),
  });

  async function fetchBookingRating() {
    try {
      const resp = await trackPromise(coachRatingService.getCoachRatingByBooking(slotDetails?.booking));
      const { data } = resp;

      if (data.success) {
        if (data.data) {
          set_bookingRating(data.data);
        }
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const onSubmitRating = async (values) => {
    const ratingData = {
      coach: slotDetails?.coach?.id,
      topic: slotDetails?.topic?.id,
      booking: slotDetails?.booking,
      rating: values?.rating,
    };
    try {
      const resp = bookingRating.id
        ? await trackPromise(coachRatingService.updateCoachRating(bookingRating.id, ratingData))
        : await trackPromise(coachRatingService.createCoachRating(ratingData));
      const { data } = resp;
      if (data.success) {
        fetchBookingRating();
        setIsopen(false);
      } else {
        set_pageError(data.message || "Registeration failed");
      }
    } catch (err) {
      console.error("Register error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err?.response?.data?.message || err?.message || "Register error");
    }
    setIsopen(false);
    window.close();
  };

  const onCancleRating = () => {
    setIsopen(false);
    window.close();
  };

  return (
    <>
      <div className="main-content w-100 d-flex">
        {pageError ? <div className="error-msg">{pageError}</div> : <></>}
        <div className="video-container">
          <div style={styles.container}>
            {videocall ?
            //  <AgoraUIKit rtcProps={{...rtcConfig}} callbacks={callbacks}  /> 
            <StreamVideoComponent  channel={rtcConfig.channel}  handleCallEnd={()=>{setShowModal(true)}} />
             : null}
          </div>
          {role === "student" && (
            <RatingPopUp
              isOpen={isOpen}
              pageError={pageError}
              set_pageError={set_pageError}
              handleFormSubmit={onSubmitRating}
              setIsopen={setIsopen}
              FormSchema={FormSchema}
              formObject={formObject}
              onCancleRating={onCancleRating}
              slotDetails={slotDetails}
            />
          )}
        </div>
      </div>
      <>
        <Modal show={showModal} onHide={() => setShowModal(false)}>
          <Modal.Header closeButton>
            <Modal.Title>Confirmation</Modal.Title>
          </Modal.Header>
          <Modal.Body>Do you want to end the call?</Modal.Body>
          <Modal.Footer>
            <Button variant="secondary" onClick={() => setShowModal(false)}>
              Cancel
            </Button>
            <Button variant="primary" onClick={handleConfirmEndCall}>
              Confirm
            </Button>
          </Modal.Footer>
        </Modal>
      </>
    </>
  );
}

export default AghoraVideo;

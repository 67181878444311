import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";

import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { MoodleCoursesService } from "../../services";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import ReactProgressBar from "../CustomComponent/ReactProgressBar";

function ViewMaterial({ pageTitle }) {
  const dispatch = useDispatch();
  // const [allStudent, set_allStudent] = useState([]);
  const [courseList, set_CourseList] = useState([]);
  const [moodleLink, set_MoodleLink] = useState();
  const [pageError, set_pageError] = useState("");
  const [selectedStudent, set_selectedStudent] = useState(undefined);

  const [courseIds, set_courseIds] = useState([]);

  const gridRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));

    fetchMoodleCourses();
    fetchMoodleResources();
  }, []);

  async function fetchMoodleCourses() {
    try {
      const resp = await trackPromise(MoodleCoursesService.getUserEnrolments());
      const { data } = resp;
      if (data.success) {
        set_CourseList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topics");
      }
    } catch (err) {
      console.error("Fetch topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchMoodleResources() {
    try {
      const resp = await trackPromise(MoodleCoursesService.getMoodleResource());
      const { data } = resp;
      if (data.success) {
        set_MoodleLink(data.data.weblink);
      } else {
        set_pageError(data.message || "Error in fetching topics");
      }
    } catch (err) {
      console.error("Fetch topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function CreateStudentMoodleCourses() {
    try {
      const resp = await trackPromise(
        MoodleCoursesService.createCourseEnrolment({ student: selectedStudent, courseIds })
      );
      const { data } = resp;
      if (data.success) {
        // set_CourseList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topics");
      }
    } catch (err) {
      console.error("Fetch topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  const handleAddNewClick = () => {
    if (moodleLink) {
      window.open(moodleLink, "_blank");
    } else {
      console.error("Moodle link not available");
    }
  };

  const columnDefs = [
    {
      headerName: "Display Name",
      field: "displayname",
      suppressMovable: true,
      showDisabledCheckboxes: true,
    },

    { headerName: "Full Name", field: "fullname", suppressMovable: true },
    { headerName: "Short Name", field: "shortname", suppressMovable: true },
    {
      headerName: "Summary",
      field: "summary",
      suppressMovable: true,
      tooltipField: "summary",
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Resource Progress",
      field: "courseProgress",
      suppressMovable: true,
      cellRenderer: function (progress) {
        return <ReactProgressBar courseProgress={progress?.value ? progress?.value : 0} />;
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    sortable: true,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    //flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">View Coaching Resources</h4>
        <Button variant="clr-primary" className="me-2" onClick={handleAddNewClick}>
          Access Resources
        </Button>
      </div>
      <Row className="mb-4">
        <Col>
          <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={courseList}
              pagination={true}
              paginationPageSize={10}
              onFirstDataRendered={onFirstDataRendered}
              onPageSizeChanged={onPageSizeChanged}
              ref={gridRef}
            />
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ViewMaterial;

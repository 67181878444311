import httpClient from "../httpClient";
const baseSlug = "/organisation";

class httpService {
  getAll() {
    return httpClient.get(`${baseSlug}`);
  }

  getList() {
    return httpClient.get(`${baseSlug}/list`);
  }

  getById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  create(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  update(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  delete(id, data) {
    return httpClient.put(`${baseSlug}/delete/${id}`, data);
  }
}

export default new httpService();

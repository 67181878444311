export const THEME = [
  {
    theme_code: "default",
    color_theme: "defaultTheme",
    CLR_PRIMARY: "#B18C93",
    CLR_PRIMARY_LIGHT: "#FEF9F5",
    CLR_PRIMARY_LIGHT_02: "#D6C5C8",
    // CLR_PRIMARY_LIGHT_02: "#E8DCDF",
    CLR_PRIMARY_DARK: "#423A38",
    CLR_SECONDARY: "#E8DCDF",
    CLR_TRANSPARENT: "transparent",
    CLR_WHITE_01: "#FFFFFF",
    CLR_BLACK_01: "#000000",
    CLR_GREY_01: "#433B39",
    CLR_GREY_02: "#8E8988",
    CLR_GREY_03: "#CACACA",
    TOP_NAV_HEIGHT: "100px",
    ASIDE_WIDTH: "20%",
    MAIN_WIDTH: "80%",
    CLR_MENU: "#624B50",
    CLR_CHART1: "#D6C5C8",
    CLR_CHART2: "#B18C93",
  },
  {
    theme_code: "themeCode01",
    themeName: "Blue",
    color_theme: "blueTheme",
    CLR_PRIMARY: "#8D8DB2",
    CLR_PRIMARY_LIGHT: "#B6B6D6",
    CLR_PRIMARY_LIGHT_02: "#D4D4F4",
    CLR_PRIMARY_DARK: "#383842",
    CLR_SECONDARY: "#F4F4FF",
    CLR_TRANSPARENT: "transparent",
    TOP_NAV_HEIGHT: "100px",
    ASIDE_WIDTH: "20%",
    MAIN_WIDTH: "80%",
    CLR_MENU: "#7cc6e8",
    CLR_CHART1: "#8D8DB2",
    CLR_CHART2: "#B6B6D6",
  },
  {
    theme_code: "themeCode02",
    themeName: "Green",
    color_theme: "greenTheme",
    CLR_PRIMARY: "#8DB2AB",
    CLR_PRIMARY_LIGHT: "#B6D6D1",
    CLR_PRIMARY_LIGHT_02: "#D4F1F4",
    CLR_PRIMARY_DARK: "#384042",
    CLR_SECONDARY: "#F4FAFF",
    CLR_TRANSPARENT: "transparent",
    TOP_NAV_HEIGHT: "100px",
    ASIDE_WIDTH: "20%",
    MAIN_WIDTH: "80%",
    CLR_MENU: "#7cc6e8",
    CLR_CHART1: "#8DB2AB",
    CLR_CHART2: "#B6D6D1",
  },
  {
    theme_code: "themeCode03",
    themeName: "yellow",
    color_theme: "yellowTheme",
    CLR_PRIMARY: "#B2B08D",
    CLR_PRIMARY_LIGHT: "#D6D5B6",
    CLR_PRIMARY_LIGHT_02: "#EDF4D4",
    CLR_PRIMARY_DARK: "#3F4238",
    CLR_SECONDARY: "#F9FFF4",
    CLR_TRANSPARENT: "transparent",
    CLR_WHITE_01: "#FFFFFF",
    CLR_BLACK_01: "#000000",
    TOP_NAV_HEIGHT: "100px",
    ASIDE_WIDTH: "20%",
    MAIN_WIDTH: "80%",
    CLR_MENU: "#624B50",
    CLR_CHART1: "#B2B08D",
    CLR_CHART2: "#D6D5B6",
  },
  {
    theme_code: "themeCode04",
    themeName: "orange",
    color_theme: "yellowTheme",
    CLR_PRIMARY: "rgb(237, 162, 138)",
    CLR_PRIMARY_LIGHT: "#C5979E",
    CLR_PRIMARY_LIGHT_02: "#EAAFA7",
    CLR_PRIMARY_DARK: "#3A3331",
    CLR_SECONDARY: "#FFD7B6",
    CLR_TRANSPARENT: "transparent",
    CLR_WHITE_01: "#FFFFFF",
    CLR_BLACK_01: "#000000",
    TOP_NAV_HEIGHT: "100px",
    ASIDE_WIDTH: "20%",
    MAIN_WIDTH: "80%",
    CLR_MENU: "#624B50",
  },
];

import React from "react";
import Select from "react-select";

const SelectField = ({ field, form, errorClass, isMulti, ...props }) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "hsl(349, 17%, 81%)" : provided.borderColor,
      boxShadow: state.isFocused ? "hsl(349, 17%, 81%)" : provided.boxShadow,
      // boxShadow: state.isFocused ? "0 0 0 0.2rem rgba(0, 123, 255, 0.25)" : provided.boxShadow,
      // Add other styles as needed

      "&:hover": {
        color: "black", // Change the text color on hover
      },
    }),

    option: (provided, state) => ({
      ...provided,

      backgroundColor: state.isSelected ? "#D6C5C8" : "#fff",
      color: state.data.isDeleted ? "grey" : "black",

      "&:hover": {
        backgroundColor: "#B18C93", // Change the background color on hover
        color: "white", // Change the text color on hover
      },
    }),
  };

  return (
    <>
      <Select
        {...field}
        {...props}
        onChange={(option) => form.setFieldValue(field.name, option)}
        onBlur={() => form.setFieldTouched(field.name, true)}
        isInvalid={props.isInvalid}
        styles={customStyles}
        isMulti={isMulti}
        // isError={props.isInvalid}
      />
      {props.isInvalid ? (
        <p id="errorObject" className={errorClass}>
          {props.isInvalid}
        </p>
      ) : null}
    </>
  );
};

export default SelectField;

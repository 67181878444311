import httpClient from "../httpClient";
const baseSlug = "/coachCategory";

class httpService {
  getAll() {
    return httpClient.get(`${baseSlug}`);
  }
  createCategory(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  getCoachCategoryById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }
  updateCategory(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }
}

export default new httpService();

import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { UserService } from "../../services";
import { faPencil, faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react";

import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import DeleteListModal from "../CustomComponent/DeleteListModal";

function CoachList({ pageTitle }) {
  const dispatch = useDispatch();
  const [activeTab, set_activeTab] = useState("tab01");
  const [coachList, set_coachList] = useState([]);
  const [approvedList, set_approvedList] = useState([]);
  const [unApprovedList, set_unApprovedList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [setError, set_Error] = useState();
  const [confirmData, setconfirmData] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchCoaches();
  }, []);

  useEffect(() => {
    categoriseCoaches();
  }, [coachList]);

  async function fetchCoaches() {
    try {
      const resp = await trackPromise(UserService.getCoaches());
      const { data } = resp;
      if (data.success) {
        let coachList = data?.data?.filter((itm) => !itm?.isDeleted);
        set_coachList(coachList);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function categoriseCoaches() {
    const apprv = [],
      unApprv = [];
    for (let i = 0; i < coachList.length; i++) {
      let coachItm = coachList[i];
      coachItm.approvalStatus === "accepted" ? apprv.push(coachItm) : unApprv.push(coachItm);
    }
    set_approvedList(apprv);
    set_unApprovedList(unApprv);
  }

  async function deleteCoaches(id) {
    const requestData = {
      confirmToDelete: confirmData,
    };
    try {
      const resp = await trackPromise(UserService.deleteCoach(id, requestData));
      const { data } = resp;

      if (data?.success) {
        if (data.data.serverConfirmation) {
          set_Error(data.message);

          setconfirmData(true);

          //await trackPromise(UserService.deleteStudent(id, { confirmToDelete: true }));
        } else {
          closeDeleteModal();
          toast.success("Coach Deleted Sucessfully.");
        }
        fetchCoaches();

        //toast.success("Cocheee Deleted Sucessfully");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  const deleteOrg = async (id) => {
    await deleteCoaches(id, confirmData);

    //await fetchStudentsByType();
    if (confirmData) {
      closeDeleteModal();
    }

    //closeDeleteModal();
  };

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
    set_Error("");
    setconfirmData(false);
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="tab-thm-01">
        <Tab.Container defaultActiveKey={activeTab}>
          <Row className="g-0 mb-4">
            <Nav variant="tabs">
              <Nav.Link className="" eventKey="tab01">
                Requested for Approval
              </Nav.Link>

              <Nav.Link className="" eventKey="tab02">
                Approved Coaches
              </Nav.Link>
            </Nav>
          </Row>
          <Row className="g-0">
            <Tab.Content>
              <Tab.Pane eventKey="tab01">
                <div className="page-heading">
                  <h4>Coach Requests For Approval</h4>
                </div>
                {unApprovedList?.length ? (
                  <CoachTable rowData={unApprovedList} needApproval={true} />
                ) : (
                  <div>No Coach to Approve</div>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="tab02">
                {approvedList?.length ? (
                  <CoachTable rowData={approvedList} fetchCoaches={fetchCoaches} openDeleteModal={openDeleteModal} />
                ) : (
                  <div>No Approved Coach Found</div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
        {selectedItem && (
          <DeleteListModal
            id={selectedItem}
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            deleteItem={deleteOrg}
            pageError={setError}
          />
        )}
      </div>
    </Container>
  );
}

export default CoachList;

function CoachTable({ rowData = [], needApproval = false, fetchCoaches, openDeleteModal }) {
  const gridRef = useRef();
  const [pageError, set_pageError] = useState("");

  const columnDefs = [
    {
      headerName: "First Name",
      field: "firstName",
      filter: true,
      // width: 150,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      field: "lastName",
      // width: 150,
      filter: true,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Username",
      field: "username",
      // width: 150,
      filter: true,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      field: "email",
      tooltipField: "email",
      // width: 300,
      filter: true,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Phone",
      field: "phone",
      // width: 150,
      filter: true,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center" },
    },

    {
      headerName: needApproval ? "" : "Action",
      sortable: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const isDelete = params?.data?.isDeleted;
        const iconClass = isDelete ? "disabled-icon" : "active-icon";
        if (needApproval) {
          return (
            <Link to={`approve/${params.data.id}`} className="btn btn-clr-primary verify-btn">
              Verify
            </Link>
          );
        } else {
          return (
            <>
              <Link className={`grid_action ${isDelete ? "disabled-link" : ""}`} to={`/coach/view/${params?.data?.id}`}>
                <FontAwesomeIcon
                  icon={faEye}
                  className={iconClass}
                  title="View"
                  onClick={(e) => {
                    if (isDelete) {
                      e.preventDefault(); // Prevent the edit action when isDelete is true
                    }
                  }}
                />
              </Link>
              <Link className={`grid_action ${isDelete ? "disabled-link" : ""}`} to={`edit/${params.data.id}`}>
                <FontAwesomeIcon
                  icon={faPencil}
                  title="Edit"
                  className={iconClass}
                  onClick={(e) => {
                    if (isDelete) {
                      e.preventDefault(); // Prevent the edit action when isDelete is true
                    }
                  }}
                />
              </Link>
              <Link className="grid_action" to="">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  title="Delete"
                  className={iconClass}
                  onClick={() => {
                    openDeleteModal(params?.data?.id);
                  }}
                />
              </Link>
            </>
          );
        }
      },
      suppressMovable: true,
    },
  ];

  const defaultColDef = useMemo(() => ({
    //filter: true,
    sortable: true,
    // flex: 1,
  }));

  //Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Row className="">
      <Col lg="12">
        <div className="ag-theme-alpine" style={{ height: "480px", width: "100%" }}>
          <AgGridReact
            id="myGrid"
            className="myGrid"
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rowData} // Pass the rowData here
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter} // Adjust as needed
          />
        </div>
        <div className="page-size-wrap">
          <div className="page-size">
            Records Per Page:
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </Col>
    </Row>
  );
}

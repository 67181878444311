import httpClient from "../httpClient";
const baseSlug = "/agoraChat";

class httpService {
  getChatCredentials(data) {
    return httpClient.post(`${baseSlug}/getCredentials`, data);
  }
  registerUser() {
    return httpClient.put(`${baseSlug}/registerUser`);
  }
}

export default new httpService();

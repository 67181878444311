import { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import { Container, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import moment from "moment-timezone";
import Calendar from "./Calender";
import { trackPromise } from "react-promise-tracker";
import { BookSlotServices, CoachSlotService } from "../../services";
import EventModal from "../CustomComponent/DeleteEvent";
import { formatDate, formatTime } from "../../utilities/functions";

function CalenderList({ pageTitle }) {
  const dispatch = useDispatch();
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [viewType, setViewType] = useState("month");
  const [pageError, set_pageError] = useState("");
  const [eventsData, setEventsData] = useState([]);
  const [selectedEvent, setSelectedEvent] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [bookSlotId, setBookSlotId] = useState("");
  const [slotDetails, setSlotDetails] = useState();
  const [loading, setLoading] = useState(false);
  const [confirmEvent, setConfirmEvent] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchEvents();
  }, []);

  useEffect(() => {
    if (bookSlotId) {
      fetchAppointmentDetail(bookSlotId);
    }
  }, [bookSlotId]);

  async function handleCurrentDate(newDate, view, action) {
    setSelectedDate(newDate);
    if (view === "month") {
      setViewType(view);
      await fetchEvents(newDate);
    }
  }

  async function fetchEvents(currentDate) {
    try {
      const resp = await trackPromise(CoachSlotService.getSlotByDates(createStartDate(currentDate)));
      const { data } = resp;
      if (data.success) {
        setEventsData(createEventArray(data?.data));
      } else {
        set_pageError(data.message || "Error in fetching slots");
      }
    } catch (err) {
      console.error("Fetch coach slots catch => ", err);
      if (err?.code !== "ERR_NETWORK") {
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  function createStartDate(selectDate) {
    return {
      startDate: moment(selectDate).local().startOf("month").format(), //.utc()
      endDate: moment(selectDate).local().endOf("month").format(), //.utc()
    };
  }

  // function createEventArray(filteredData) {
  //   return filteredData?.map((singleDayData) => {
  //     const eventData = {
  //       title: `${formatTime(singleDayData.fromDateTime)} - ${formatTime(singleDayData.toDateTime)}`,
  //       bookSlot: singleDayData?.bookSlot,
  //       fromDateTime: singleDayData.fromDateTime,
  //       toDateTime: singleDayData.toDateTime,
  //       start: moment(singleDayData.fromDateTime).toDate(),
  //       end: moment(singleDayData.toDateTime).toDate(),
  //       slotId: singleDayData.slotId,
  //       slotDate: singleDayData?.slotDate,
  //       subSlotId: singleDayData._id,
  //       slotStatus: singleDayData.slotStatus,
  //       coachSlot: singleDayData?.coachSlot,
  //       _id: singleDayData?._id,
  //       color: singleDayData.slotStatus ? "#D6C5C8" : "#B18C93",
  //     };
  //     return eventData;
  //   });
  // }

  function createEventArray(filteredData) {
    const currentDate = new Date();
    return filteredData?.map((singleDayData) => {
      const isPastDate = moment(singleDayData.slotDate).isBefore(currentDate, "day");
      const color = isPastDate ? "#D6C5C8" : "#B18C93";

      const eventData = {
        title: `${formatTime(singleDayData.fromDateTime)} - ${formatTime(singleDayData.toDateTime)}`,
        bookSlot: singleDayData?.bookSlot,
        fromDateTime: singleDayData.fromDateTime,
        toDateTime: singleDayData.toDateTime,
        start: moment(singleDayData.fromDateTime).toDate(),
        end: moment(singleDayData.toDateTime).toDate(),
        slotId: singleDayData.slotId,
        slotDate: singleDayData?.slotDate,
        subSlotId: singleDayData._id,
        slotStatus: singleDayData.slotStatus,
        coachSlot: singleDayData?.coachSlot,
        _id: singleDayData?._id,
        color: color,
      };
      return eventData;
    });
  }

  async function fetchAppointmentDetail() {
    try {
      const resp = await trackPromise(BookSlotServices.getSlotAppointmentDetail(bookSlotId));
      const { data } = resp;
      if (data?.success) {
        setSlotDetails(data?.data);
        setLoading(false);
      } else {
        set_pageError(data.message || "Error in fetching ");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const handleEventClick = (event) => {
    setBookSlotId(event?.bookSlot);
    setLoading(true);
    setSelectedEvent(event);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setConfirmEvent(false);
    setBookSlotId(null);
    setSelectedEvent(null);
    setIsModalOpen(false);
    setSlotDetails(null);
  };

  async function deleteEventApi(event) {
    try {
      const resp = await trackPromise(CoachSlotService.deleteSlot(event));
      const { data } = resp;
      if (data.success) {
      } else {
        set_pageError(data.message || "Error in deleting slots");
      }
    } catch (err) {
      console.error("Fetch delete slots catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err?.response?.data?.message?.textMsg + formatDate(err?.response?.data?.message?.msgData));
      } else {
        set_pageError(err.message);
      }
    }
  }

  const deleteEvent = async () => {
    const eventform = {
      slotDate: selectedEvent?.slotDate,
      coachSlot: selectedEvent?.coachSlot,
      fromDateTime: selectedEvent?.fromDateTime,
      coachSubSlot: selectedEvent?.subSlotId,
    };
    await deleteEventApi(eventform);
    closeModal();
    await fetchEvents(selectedDate);
    setConfirmEvent(false);
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <Button as={Link} to="create" variant="clr-primary" className="btn-add">
          Add New Slot
        </Button>
      </div>
      <div>
        {pageError && <div>{pageError}</div>}
        <Calendar
          events={eventsData}
          views={["month", "day"]}
          // eventPropGetter={(event) => {
          //   const backgroundColor = event?.color;
          //   return { style: { backgroundColor } };
          // }}
          eventPropGetter={(event) => {
            const backgroundColor = event?.color;
            const cursorStyle = backgroundColor === "#D6C5C8" ? "none" : "pointer";
            return {
              style: {
                backgroundColor,
                cursor: cursorStyle,
              },
            };
          }}
          // formats={
          //   (eventTimeRangeFormat = () => {
          //     return "";
          //   })
          // }
          titleAccessor={
            viewType === "day"
              ? function (e) {
                  return e.title;
                }
              : function (e) {
                  return e.title;
                }
          }
          onNavigate={handleCurrentDate}
          onSelectEvent={handleEventClick}
          onView={(e) => {
            setViewType(e);
          }}
          startAccessor="start"
          endAccessor="end"
          // defaultDate={new Date()}
          // min={new Date()}
        />
      </div>
      {selectedEvent && (
        <EventModal
          slotDetails={slotDetails}
          event={selectedEvent}
          isOpen={isModalOpen}
          onClose={closeModal}
          deleteEvent={deleteEvent}
          bookingId={bookSlotId}
          loading={loading}
          setConfirmEvent={setConfirmEvent}
          confirmEvent={confirmEvent}
        />
      )}
    </Container>
  );
}

export default CalenderList;

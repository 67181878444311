import { useSelector } from "react-redux";
import { createBrowserRouter, createHashRouter, RouterProvider } from "react-router-dom";
import { usePromiseTracker } from "react-promise-tracker";
import { ToastContainer } from "react-toastify";
import { GetRoutes } from "./AppRoutes";
import PageLoader from "./components/Loaders/Loaders";
import "react-toastify/dist/ReactToastify.css";


function App() {
  const { promiseInProgress } = usePromiseTracker();
  const { authState, appState } = useSelector((state) => state);
  const routerObject = createHashRouter(
    GetRoutes(authState?.isAuthenticated, authState?.user, authState?.user?.organisation)
  );

  return authState.isAuthenticated === undefined && authState?.user?.role?.roleCode === undefined ? (
    <PageLoader isLoading={true} />
  ) : (
    <>
      <PageLoader isLoading={promiseInProgress} />
      <ToastContainer />
      <RouterProvider router={routerObject} />
    </>
  );
}

export default App;
import { useCallback, useEffect, useRef, useState, useMemo, forwardRef, useImperativeHandle } from "react";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { ToggleButton, ToggleButtonGroup, ButtonGroup } from "react-bootstrap";
import { Link, useRouteLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faChartColumn, faDownload } from "@fortawesome/free-solid-svg-icons";
import { UserService, OrganisationService, StatsService, ReportService, TopicsService } from "../../services";
import ReportBarChart from "../Dashboard/Component/ReportComponentBarChart";
import ReportAdmin from "../Dashboard/Component/ReportComponent";

import ReactDatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css"; // theme css file
import "react-datepicker/dist/react-datepicker-cssmodules.css";
// import "react-datepicker/dist/react-datepicker.css";
import moment from "moment";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react";
import DropdownList from "../CustomComponent/DropdownList";

const item = [1, 2, 3, 4, 5, 6];
function SuperAdminReport({ pageTitle }) {
  const dispatch = useDispatch();
  const { authState, appState } = useSelector((state) => state);
  const [userStat, set_userStat] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [selectedOrganization, set_selectedOrganization] = useState([]);
  const [selectedCoach, set_selectedCoach] = useState([]);
  const [orgList, set_orgList] = useState([]);
  const [coachList, set_coachList] = useState([]);
  const [selectedMonth, setSelectedMonth] = useState(new Date());
  const [coachMonth, setCoachMonth] = useState(new Date());
  const [data, setData] = useState([]);
  const [dateError, setDateError] = useState(false);
  const [orgStartDate, setOrgStartDate] = useState(new Date());
  const [orgEndDate, setOrgEndDate] = useState(new Date());
  const [topicStartDate, settopicStartDate] = useState(new Date());
  const [topicEndDate, settopicEndDate] = useState(new Date());
  const [coachStartDate, setCoachStartDate] = useState(new Date());
  const [coachEndDate, setCoachEndDate] = useState(new Date());
  const [topicData, setTopicData] = useState([]);
  const [selectedTopic, set_selectedTopic] = useState([]);
  const [selectTopic, setSelectedTopic] = useState("table");
  const [topicList, set_topicList] = useState([]);
  const [coachData, setcoachData] = useState([]);
  const [selectedView, setSelectedView] = useState("table");
  const [selectCoach, setSelectedCoach] = useState("table");
  const [renewalData, setRenewalData] = useState([]);
  const orgTableRef = useRef();
  const coachTableRef = useRef();
  const topicTableRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchUserStat();
    fetchOrganisations();
    fetchCoaches();
    orgRenewalData();
    OrganizationHours();
    CoachHours();
    TopicHours();
    fetchTopic();
  }, []);

  const columnDefs = [
    {
      headerName: "Organisation Name",
      field: "orgName",
      sortable: true,
      suppressMovable: true,
      filter: true,
      cellStyle: { textAlign: "left" },
    },
  ];

  // Iterate over studentTypes to create columns dynamically
  data[0]?.studentTypes.forEach((studentType) => {
    columnDefs.push({
      headerName: studentType?.typeName,
      field: `studentTypes[${studentType?.typeName}].coachingSessions`,
      cellRenderer: function (params) {
        return params.data?.studentTypes.find((type) => type.typeName === studentType?.typeName)?.coachingSessions;
      },
      cellStyle: { textAlign: "center" },
    });
  });

  const topicColumnDefs = [
    { headerName: "Topic Name", field: "topicName", width: 200, cellStyle: { textAlign: "center" } },
    { headerName: "Coaching Sessions", field: "coachingSessions", width: 200, cellStyle: { textAlign: "center" } },
  ];

  const coachColumnDefs = [
    {
      headerName: "Coach",
      field: "firstName",
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Accreditation Level Name",
      field: "accreditationLevelName",
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Coaching Sessions",
      field: "coachingSessions",
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Amount",
      field: "totalAmount",
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
  ];

  const onExportOrgData = useCallback(() => {
    if (orgTableRef.current) {
      orgTableRef.current.exportData();
    }
  }, []);

  const onExportCoachData = useCallback(() => {
    if (coachTableRef.current) {
      coachTableRef.current.ExportClick();
    }
  }, []);
  const onExportTopicData = () => {
    if (topicTableRef.current) {
      topicTableRef.current.exportData();
    }
  };

  const localFormatDate = (date) => {
    const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";
    const convertedDate = moment(date).format(outputFormat);
    return convertedDate;
  };

  const localFormatStartDate = (date) => {
    const givenDate = moment(date);
    if (givenDate) {
      const result = givenDate.set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      return result.format();
    }
  };

  const endDateFormat = (date) => {
    const givenDate = moment(date);
    const result = givenDate.set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    return result.format();
  };

  const onChangeOrg = (dates) => {
    const [start, end] = dates;
    setOrgStartDate(start);
    setOrgEndDate(end);
    if (start) {
      setDateError(false);
    }
  };

  const onChangeCoach = (dates) => {
    const [start, end] = dates;
    setCoachStartDate(start);
    setCoachEndDate(end);
    if (start) {
      setDateError(false);
    }
  };

  const onChangeTopic = (dates) => {
    const [start, end] = dates;
    settopicStartDate(start);
    settopicEndDate(end);
    if (start) {
      setDateError(false);
    }
  };

  const startDateOrgSet = localFormatStartDate(orgStartDate);

  const startDateSet = localFormatStartDate(coachStartDate);

  const endDateOrgSet = endDateFormat(orgEndDate);

  const endDateSet = endDateFormat(coachEndDate);
  const startDatetopic = localFormatStartDate(topicStartDate);

  const endDateOrgTopic = endDateFormat(topicEndDate);

  const localStartDateOrg = localFormatDate(startDateOrgSet);
  const localEndDateOrg = localFormatDate(endDateOrgSet);

  const localStartDate = localFormatDate(startDateSet);
  const localEndDate = localFormatDate(endDateSet);
  const localStartDateTopic = localFormatDate(startDatetopic);
  const localEndDateTopic = localFormatDate(endDateOrgTopic);

  async function fetchUserStat() {
    try {
      const resp = await trackPromise(StatsService.getOverviewStats());
      const { data } = resp;
      if (data.success) {
        set_userStat(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  async function fetchOrganisations() {
    try {
      const resp = await trackPromise(OrganisationService.getList());
      const { data } = resp;
      if (data.success) {
        set_orgList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisations catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchCoaches() {
    try {
      const resp = await trackPromise(UserService.getCoaches());
      const { data } = resp;
      if (data.success) {
        set_coachList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchTopic() {
    try {
      const resp = await trackPromise(TopicsService.getList());
      const { data } = resp;
      if (data.success) {
        set_topicList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function orgRenewalData() {
    try {
      const resp = await trackPromise(ReportService.getorgRenewalData());
      const { data } = resp;
      if (data.success) {
        setRenewalData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function OrganizationHours() {
    try {
      const resp = await trackPromise(
        ReportService.getOrgHours({
          startDate: localStartDateOrg,
          endDate: localEndDateOrg,
          organisations: selectedOrganization,
        })
      );
      const { data } = resp;
      if (data.success) {
        setData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisationsHours catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  async function CoachHours() {
    try {
      const resp = await trackPromise(
        ReportService.getCoachHours({
          startDate: localStartDate,
          endDate: localEndDate,
          coaches: selectedCoach,
        })
      );
      const { data } = resp;
      if (data.success) {
        setcoachData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisations catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  async function TopicHours() {
    try {
      const resp = await trackPromise(
        ReportService.getTopicSessions({
          startDate: localStartDateTopic,
          endDate: localEndDateTopic,
          selectedTopics: selectedTopic,
        })
      );
      const { data } = resp;
      if (data.success) {
        setTopicData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisationsHours catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const handleSelectOraganization = (selectedList) => {
    set_selectedOrganization(selectedList);
  };

  const handleSelectCoach = (selectedList) => {
    set_selectedCoach(selectedList);
  };

  const handleSelectTopic = (selectedList) => {
    set_selectedTopic(selectedList);
  };

  const handlecoach = (selected) => {
    set_selectedCoach(selected);
  };
  const handleDateChange = (date) => {
    setSelectedMonth(date);
  };

  const handlemonthChange = (date) => {
    setCoachMonth(date);
  };
  const handleViewTopic = (value) => {
    setSelectedTopic(value);
  };

  const handleViewChange = (value) => {
    setSelectedView(value);
  };
  const handleView = (value) => {
    setSelectedCoach(value);
  };
  const topicText = {
    text: "Topic Name",
  };

  const getOptionLabelFunction1 = (option) => `${option?.orgName}`;
  const getOptionLabelFunction2 = (option) => `${option?.firstName} ${option?.lastName}`;
  const getOptionLabelFunction3 = (option) => `${option?.topicName}`;
  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">{/*<h4 className="hd-txt">View Reports</h4> */}</div>

      <Row className="px-3">
        <Col md={12}>
          <div>
            <Row className="">
              <Row>
                <h5 className="hd-txt">Organisation Sessions Overview</h5>
              </Row>
              <Row>
                <h6>Track the allocation and utilisation of sessions by organistions over different time periods.</h6>
              </Row>
              <Row className="my-2 report-row">
                {/* Organization Dropdown */}
                <Col xs={12} md={4}>
                  <DropdownList
                    name="oraganisation"
                    value={selectedOrganization}
                    allStudent={orgList}
                    handleSelect={handleSelectOraganization}
                    placeholder="Select Organisation"
                    getOptionLabel={getOptionLabelFunction1}
                    isMulti={true}
                  />
                </Col>

                {/* Date Picker */}
                <Col xs={12} md={"auto"} className="datePicker">
                  <ReactDatePicker
                    placeholderText="Select Date"
                    selected={orgStartDate}
                    onChange={onChangeOrg}
                    utcOffset={0}
                    startDate={orgStartDate}
                    endDate={orgEndDate}
                    selectsRange
                    onBlur={() => {
                      if (!orgStartDate) {
                        setDateError(true);
                      }
                    }}
                    className="datePickeradmin "
                  />
                  {dateError ? (
                    <p id="errorObject" className="fieldError">
                      Date is Required
                    </p>
                  ) : null}
                  {orgStartDate && !orgEndDate ? (
                    <p id="errorObject" className="fieldError">
                      End Date is Required
                    </p>
                  ) : null}
                </Col>

                {/* Apply Button */}
                <Col xs={12} md={12} xl={4} className="">
                  <Button variant="clr-primary" className="btn-report" onClick={OrganizationHours}>
                    Apply
                  </Button>
                  {/* </Col> */}
                  {/* View Toggle and Export Buttons */}
                  {/* <Col xs={12} md={12} xl={3} className=""> */}
                  <ButtonGroup className="btn-report justify-content-end ">
                    <Button
                      type="radio"
                      name="view"
                      value="table"
                      checked={selectedView === "table"}
                      onClick={() => handleViewChange("table")}
                      variant={
                        selectedView === "table"
                          ? "clr-primary border border-clr-primary"
                          : "bg-transparent border border-clr-primary"
                      }
                    >
                      <FontAwesomeIcon icon={faTable} variant="clr-primary" />
                    </Button>
                    <Button
                      type="radio"
                      name="view"
                      value="graph"
                      checked={selectedView === "graph"}
                      onClick={() => handleViewChange("graph")}
                      variant={
                        selectedView === "graph"
                          ? "clr-primary border border-clr-primary"
                          : "bg-transparent border border-clr-primary"
                      }
                    >
                      <FontAwesomeIcon icon={faChartColumn} />
                    </Button>
                  </ButtonGroup>
                  <Button variant="clr-primary" className=" btn-download ms-1" onClick={onExportOrgData}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </Col>
              </Row>

              <Row className="my-3">
                <Col md={10} sm={12}>
                  {selectedView === "table" ? (
                    <GenericTable
                      data={data}
                      ref={orgTableRef}
                      columnDefs={columnDefs}
                      onExportOrgData={onExportOrgData}
                    />
                  ) : (
                    <ReportAdmin data={data} />
                  )}
                </Col>
              </Row>
              {/* <Row className="mt-4">
              {selectedView === "table" && (
                <Col md={5}>
                  <div>
                    <Button variant="clr-primary" className="btn-add" onClick={handleExportClick}>
                      Download Data
                    </Button>
                  </div>
                </Col>
              )}
            </Row> */}
            </Row>
          </div>
          <div className="mt-3">
            <Row className="py-3 mt-3">
              <Row>
                <h5 className="hd-txt">Coach Activity Analysis</h5>
              </Row>
              <Row>
                <h6>
                  Monitor coach engagement and performance with insights into their sessions spent on the platform.
                </h6>
              </Row>
              <Row className="my-2 report-row">
                <Col xs={12} md={4}>
                  <Form.Group className="frm-group">
                    <DropdownList
                      name="coach"
                      value={selectedCoach}
                      allStudent={coachList}
                      handleSelect={handleSelectCoach}
                      placeholder="Select Coaches"
                      getOptionLabel={getOptionLabelFunction2}
                      isMulti={true}
                    />
                  </Form.Group>
                </Col>
                <Col xs={12} md={"auto"} className="custom-date-picker datePicker">
                  <ReactDatePicker
                    placeholderText="Select Date"
                    selected={coachStartDate}
                    onChange={onChangeCoach}
                    utcOffset={0}
                    startDate={coachStartDate}
                    endDate={coachEndDate}
                    selectsRange
                    onBlur={() => {
                      if (!coachStartDate) {
                        setDateError(true);
                      }
                    }}
                    className="datePickeradmin"
                    //wrapperClassName="datePickerWrapper"
                  />
                  {dateError ? (
                    <p id="errorObject" className="fieldError">
                      Date is Required
                    </p>
                  ) : null}
                  {coachStartDate && !coachEndDate ? (
                    <p id="errorObject" className="fieldError">
                      End Date is Required
                    </p>
                  ) : null}
                </Col>
                <Col xs={12} md={12} xl={4} className="">
                  <Button variant="clr-primary" className="btn-report" onClick={CoachHours}>
                    Apply
                  </Button>
                  {/* </Col>
                <Col xs={12} md={"auto"} xl={4}> */}
                  <ButtonGroup className="btn-report">
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="table"
                      checked={selectCoach === "table"}
                      onClick={() => handleView("table")}
                      variant={
                        selectCoach === "table"
                          ? "clr-primary border border-clr-primary"
                          : "bg-transparent border border-clr-primary"
                      }
                    >
                      <FontAwesomeIcon icon={faTable} variant="clr-primary" />
                    </Button>
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="graph"
                      checked={selectCoach === "graph"}
                      onClick={() => handleView("graph")}
                      variant={
                        selectCoach === "graph"
                          ? "clr-primary border border-clr-primary"
                          : "bg-transparent border border-clr-primary"
                      }
                    >
                      <FontAwesomeIcon icon={faChartColumn} />
                    </Button>
                  </ButtonGroup>
                  <Button variant="clr-primary" className="btn-download" onClick={onExportCoachData}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </Col>
              </Row>

              <Row className="py-2 px-2 mt-2">
                <Col md={10} sm={12}>
                  {selectCoach === "table" ? (
                    <GenericTable
                      data={coachData}
                      ref={coachTableRef}
                      onExportOrgData={onExportOrgData}
                      columnDefs={coachColumnDefs}
                    />
                  ) : (
                    <ReportBarChart
                      data={coachData}
                      xAxisLabel="Amount"
                      yAxisLabel="Coaches"
                      xValueKey="totalAmount"
                      yValueKey="firstName"
                    />
                  )}
                </Col>
              </Row>
            </Row>
          </div>
          <div className="mt-3">
            <Row className=" ">
              <Row>
                <h5 className="hd-txt">User Topic Sessions Breakdown</h5>
              </Row>
              <Row>
                <h6>Gain insights into how users allocate and utilize their sessions during different time frames.</h6>
              </Row>
              <Row className="my-2 report-row">
                <Col md={4}>
                  <DropdownList
                    name="topic"
                    value={selectedTopic}
                    allStudent={topicList}
                    handleSelect={handleSelectTopic}
                    placeholder="Select Topics"
                    getOptionLabel={getOptionLabelFunction3}
                    isMulti={true}
                  />
                </Col>
                <Col md={"auto"} className="custom-date-picker datePicker">
                  <ReactDatePicker
                    placeholderText="Select Date"
                    selected={topicStartDate}
                    // minDate={new Date()}
                    onChange={onChangeTopic}
                    utcOffset={0}
                    startDate={topicStartDate}
                    endDate={topicEndDate}
                    selectsRange
                    onBlur={() => {
                      if (!topicStartDate) {
                        setDateError(true);
                      }
                    }}
                    className="datePickeradmin"
                    // wrapperClassName="datePickerWrapper"
                  />
                  {dateError ? (
                    <p id="errorObject" className="fieldError">
                      Date is Required
                    </p>
                  ) : null}
                  {topicStartDate && !topicEndDate ? (
                    <p id="errorObject" className="fieldError">
                      End Date is Required
                    </p>
                  ) : null}
                </Col>
                <Col xs={12} md={12} lg={12} xl={4} className="">
                  <Button variant="clr-primary" className="btn-report  " onClick={TopicHours}>
                    Apply
                  </Button>
                  {/* </Col>
                <Col> */}
                  <ButtonGroup className="btn-report  justify-content-end">
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="table"
                      checked={selectTopic === "table"}
                      onClick={() => handleViewTopic("table")}
                      variant={selectTopic === "table" ? "clr-primary" : "bg-transparent border border-clr-primary"}
                    >
                      <FontAwesomeIcon icon={faTable} variant="clr-primary" />
                    </Button>
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="graph"
                      checked={selectTopic === "graph"}
                      onClick={() => handleViewTopic("graph")}
                      variant={selectTopic === "graph" ? "clr-primary" : "bg-transparent border border-clr-primary"}
                    >
                      <FontAwesomeIcon icon={faChartColumn} />
                    </Button>
                  </ButtonGroup>
                  <Button variant="clr-primary" className="btn-download ms-2" onClick={onExportTopicData}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col>
                <Row className="my-2 ">
                  <h6> Topic Name</h6>
                  {/* <Col md={7}>
                  {" "}
                  <ReportTopicBar data={topicData} />
                </Col>
                <Col md={5}>{topicData?.length ? <TopicTable Data={topicData} /> : <div></div>}</Col> */}
                  <Col md={10}>
                    {selectTopic === "table" ? (
                      <GenericTable
                        data={topicData}
                        columnDefs={topicColumnDefs}
                        ref={topicTableRef}
                        onExportOrgData={onExportTopicData}
                      />
                    ) : (
                      // <ReportTopicBar data={topicData} />
                      <ReportBarChart
                        data={topicData}
                        xAxisLabel="Sessions"
                        yAxisLabel="Topic Name"
                        xValueKey="coachingSessions"
                        yValueKey="topicName"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="mt-3">
            <Row className="py-3">
              <Row>
                <h5 className="hd-txt">Organisation Renewal Data</h5>
              </Row>
              <Col md={10} sm={12} className="mt-2">
                {renewalData?.length ? <RenewalData renewalData={renewalData} /> : <div></div>}
              </Col>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SuperAdminReport;

const GenericTable = forwardRef(({ data, columnDefs }, ref) => {
  const gridRef = useRef();

  // Use useImperativeHandle to expose a function to the parent component
  useImperativeHandle(
    ref,
    () => ({
      exportData: () => {
        if (gridRef.current) {
          gridRef.current.api.exportDataAsCsv({
            fileName: "Data", // Change the filename as needed
          });
        }
      },
    }),
    []
  );

  const defaultColDef = {
    flex: 1,
    sortable: true,
    suppressMovable: true,
    filter: true,
  };

  const onFirstDataRendered = (params) => {
    gridRef.current.api.sizeColumnsToFit();
  };

  return (
    <>
      <div className="ag-theme-alpine" style={{ height: "300px", width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={data}
          //domLayout="autoHeight"
          pagination={true}
          paginationPageSize={5}
          onFirstDataRendered={onFirstDataRendered}
          // localeText={{
          //   noRowsToShow: "No Data found.",
          //   textAlign: "left",
          // }}
        />
      </div>
    </>
  );
});

function RenewalData({ renewalData }) {
  const gridRef = useRef();
  const rowData = renewalData;
  const gridOptions = {
    // Define Ag-Grid options here
    columnDefs: [
      {
        headerName: "Organisation name",
        field: "orgName",
        sortable: true,
        // width: 200,
        suppressMovable: true,
        filter: true,
        filterParams: {
          buttons: ["reset"],
          closeOnApply: true,
        },
        cellStyle: { textAlign: "left" },
      },
      {
        headerName: "Start Date",
        field: "startDate",
        valueFormatter: dateFormatter,
        cellClass: "text-center",
        // sortable: true,
        width: 200,
        suppressMovable: true,
        // filter: true,
        // filterParams: {
        //   buttons: ["reset"],
        //   closeOnApply: true,
        // },
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "End Date",
        field: "endDate",
        valueFormatter: dateFormatter,
        // sortable: true,
        width: 200,
        suppressMovable: true,
        // filter: true,
        // filterParams: {
        //   buttons: ["reset"],
        //   closeOnApply: true,
        // },
        cellStyle: { textAlign: "center" },
      },
      {
        headerName: "Days to go",
        field: "daysToExpire",
        sortable: true,
        width: 200,
        suppressMovable: true,
        filter: true,
        filterParams: {
          buttons: ["reset"],
          closeOnApply: true,
        },
        cellStyle: { textAlign: "center" },

        // headerClass: { "text-align-center": "true" },
      },
    ],
  };

  const defaultColDef = useMemo(() => ({
    //sortable: true,
    flex: 1,
  }));
  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  // Custom value formatter function to format dates
  function dateFormatter(params) {
    return moment(params.value).format("DD/MM/YY");
  }

  return (
    <div className="ag-theme-alpine" style={{ height: "480px", width: "99%" }}>
      <AgGridReact
        ref={gridRef}
        gridOptions={gridOptions}
        defaultColDef={defaultColDef}
        rowData={rowData}
        // domLayout="autoHeight"
        pagination={true}
        paginationPageSize={10}
        onFirstDataRendered={onFirstDataRendered}
        localeText={{
          noRowsToShow: "No Data found.",
        }}
      />
    </div>
  );
}

import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { faPencil, faTrashCan, faTimes, faCheck, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setRouteData } from "../../stores/appSlice";
import { StudentTypeService } from "../../services";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import DeleteListModal from "../CustomComponent/DeleteListModal";

function StudentTypeList({ pageTitle }) {
  const dispatch = useDispatch();
  const [studentTypeList, set_studentTypeList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchStudentTypes();
  }, []);

  async function fetchStudentTypes() {
    try {
      const resp = await trackPromise(StudentTypeService.getAll());
      const { data } = resp;
      let studentTypeList = data?.data?.filter((itm) => !itm?.isDeleted);
      set_studentTypeList(studentTypeList);
    } catch (err) {
      console.error("Fetch coachee types catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function deleteStudentTypes(id) {
    try {
      const resp = await trackPromise(StudentTypeService.delete(id));
      const { data } = resp;

      if (data?.success === true) {
        toast.success("Coachee Type deleted Successfully.");

        fetchStudentTypes();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  async function deleteOrg(id) {
    deleteStudentTypes(id);
    closeDeleteModal();
  }

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
  };

  const columnDefs = [
    {
      headerName: "Coachee Type Name",
      field: "typeName",
      filter: true,
      // width: 300,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Coachee Type Code",
      field: "typeCode",
      // width: 300,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Accreditation Level",
      field: "accreditation.levelName",
      // width: 300,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },

    {
      field: "action",
      headerName: "Action",
      suppressMovable: true,
      sortable: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const isDelete = params.data.isDeleted;
        const iconClass = isDelete ? "disabled-icon" : "active-icon";

        return (
          <>
            <Link
              className={`grid_action ${isDelete ? "disabled-link" : ""}`}
              to={`/coachee/type/view/${params?.data?.id}`}
            >
              <FontAwesomeIcon
                icon={faEye}
                className={iconClass}
                title="View"
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault(); // Prevent the edit action when isDelete is true
                  }
                }}
              />
            </Link>

            <Link
              className={`grid_action ${isDelete ? "disabled-link" : ""}`}
              to={`/coachee/type/edit/${params.data.id}`}
            >
              <FontAwesomeIcon
                icon={faPencil}
                title="Edit"
                className={iconClass}
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault();
                  }
                }}
              />
            </Link>
            <Link className="grid_action" to="">
              <FontAwesomeIcon
                icon={faTrashCan}
                color="#3d3e45"
                title="Delete"
                onClick={() => {
                  openDeleteModal(params?.data?.id);
                }}
              />
            </Link>
          </>
        );
      },
      sortable: false,
    },
  ];
  const defaultColDef = useMemo(() => ({
    sortable: true,
    // flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  //Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container fluid className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Coachee Type List</h4>
        <Button as={Link} to="create" variant="clr-primary" className="btn-add">
          Add New
        </Button>
      </div>

      <Row>
        <Col lg="12">
          <>
            <div className="ag-theme-alpine" style={{ height: "480px", width: "100%" }}>
              <AgGridReact
                id="myGrid"
                className="myGrid"
                rowData={studentTypeList}
                columnDefs={columnDefs}
                defaultColDef={defaultColDef}
                pagination={true}
                paginationPageSize={10}
                ref={gridRef}
                paginationNumberFormatter={paginationNumberFormatter}
                onFirstDataRendered={onFirstDataRendered}
              />
            </div>
            <div className="page-size-wrap">
              <div className="page-size">
                Records Per Page:
                <select onChange={onPageSizeChanged} id="page-size">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </>
        </Col>
      </Row>
      {selectedItem && (
        <DeleteListModal
          id={selectedItem}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          deleteItem={deleteOrg}
          pageError={pageError}
        />
      )}
    </Container>
  );
}

export default StudentTypeList;

import { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { ChangeRequestService } from "../../services";

import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react";

import "react-confirm-alert/src/react-confirm-alert.css";
import { Rating } from "react-simple-star-rating";

function ChangeRequestList({ pageTitle }) {
  const dispatch = useDispatch();
  const [activeTab, set_activeTab] = useState("tab01");
  const [changeRequest, set_changeRequest] = useState([]);
  const [approvedList, set_approvedList] = useState([]);
  const [unApprovedList, set_unApprovedList] = useState([]);

  const [pageError, set_pageError] = useState("");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchRequest();
  }, []);

  useEffect(() => {
    categoriseCoaches();
  }, [changeRequest]);

  async function fetchRequest() {
    try {
      const resp = await trackPromise(ChangeRequestService.getchangeRequestByAdmin());
      const { data } = resp;
      if (data.success) {
        //let coachList = data?.data?.filter((itm) => !itm?.isDeleted);
        set_changeRequest(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Request");
      }
    } catch (err) {
      console.error("Fetch Request catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function categoriseCoaches() {
    const apprv = [],
      unApprv = [];
    for (let i = 0; i < changeRequest.length; i++) {
      let requestItm = changeRequest[i];
      requestItm.requestStatus === "pending approval" ? unApprv.push(requestItm) : apprv.push(requestItm);
    }
    set_approvedList(apprv);
    set_unApprovedList(unApprv);
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="tab-thm-01">
        <Tab.Container defaultActiveKey={activeTab}>
          <Row className="g-0 mb-4">
            <Nav variant="tabs">
              <Nav.Link className="" eventKey="tab01">
                Requested for Approval
              </Nav.Link>

              <Nav.Link className="" eventKey="tab02">
                Approved Request
              </Nav.Link>
              {/* <Nav.Link className="" eventKey="tab03">
                Rejected Request
              </Nav.Link> */}
            </Nav>
          </Row>
          <Row className="g-0">
            <Tab.Content>
              <Tab.Pane eventKey="tab01">
                <div className="page-heading">
                  <h4>Coach Change Requests For Approval</h4>
                </div>
                {unApprovedList?.length ? (
                  <CoachChangeTable rowData={unApprovedList} needApproval={true} />
                ) : (
                  <div>No Request to Approve</div>
                )}
              </Tab.Pane>

              <Tab.Pane eventKey="tab02">
                {approvedList?.length ? (
                  <CoachChangeTable rowData={approvedList} fetchRequest={fetchRequest} />
                ) : (
                  <div>No Approved Request Found</div>
                )}
              </Tab.Pane>
              {/* <Tab.Pane eventKey="tab03">
                {rejectedList?.length ? (
                  <CoachChangeTable rowData={rejectedList} />
                ) : (
                  <div>No Rejected Request Found</div>
                )}
              </Tab.Pane> */}
            </Tab.Content>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  );
}

export default ChangeRequestList;

function CoachChangeTable({ rowData = [], needApproval = false, fetchRequest }) {
  const gridRef = useRef();
  const [pageError, set_pageError] = useState("");

  const columnDefs = [
    {
      headerName: "Coach name",
      field: "coach",
      valueGetter: getFullName,
      filter: true,
      // width: 150,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Coachee Name",
      field: "student",
      suppressMovable: true,
      valueGetter: getFullName,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Reason",
      field: "requestReason",

      // width: 150,
      filter: true,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Coach Rating",
      field: "coachRating",

      // width: 150,
      filter: true,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const coachRating = params.data.coachRating;

        return (
          <div className="disabled-rating">
            <Rating initialValue={coachRating} size={25} fillColor="#B18C93" />
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "requestStatus",

      // width: 150,
      filter: true,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center", textTransform: "capitalize" },
    },

    {
      headerName: needApproval ? "" : "",
      sortable: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        if (needApproval) {
          return (
            <Link to={`approve/${params.data._id}`} className="btn btn-clr-primary verify-btn">
              Verify
            </Link>
          );
        }
      },
      suppressMovable: true,
    },
  ];

  function getFullName(params) {
    const { firstName, lastName } = params.data[params.colDef.field];
    return `${firstName} ${lastName}`;
  }

  const defaultColDef = useMemo(() => ({
    //filter: true,
    sortable: true,
    flex: 1,
  }));

  // const onFirstDataRendered = useCallback((params) => {
  //   gridRef.current.api.sizeColumnsToFit();
  // }, []);

  //Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Row className="">
      <Col lg="12">
        <div className="ag-theme-alpine" style={{ height: "480px", width: "100%" }}>
          <AgGridReact
            id="myGrid"
            className="myGrid"
            ref={gridRef}
            columnDefs={columnDefs}
            defaultColDef={defaultColDef}
            rowData={rowData} // Pass the rowData here
            pagination={true}
            paginationPageSize={10}
            paginationNumberFormatter={paginationNumberFormatter} // Adjust as needed
            // onFirstDataRendered={onFirstDataRendered}
            //cellRendererFramework={cellRendererFramework}
          />
        </div>
        <div className="page-size-wrap">
          <div className="page-size">
            Records Per Page:
            <select onChange={onPageSizeChanged} id="page-size">
              <option value="10">10</option>
              <option value="25">25</option>
              <option value="50">50</option>
            </select>
          </div>
        </div>
      </Col>
    </Row>
  );
}

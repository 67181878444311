import { useState } from "react";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "./Register.css";
import aggrement from "../../assets/files/Agreement.pdf";

function TermsCondition(props) {
  const [show, setShow] = useState(false);

  // const nextScreen = (screenNumber) => {
  //   props.setShowAcknowledge(false);
  //   props.nextScreen(screenNumber);
  // };

  return (
    // <Modal show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={false}>
    <Container fluid={true} className="term-container">
      <Row className="row-height ">
        <Col lg={{ span: 7, offset: 3 }}>
          <div className="register-wrap-term">
            <h4 className="frm-heading">Terms & Conditions</h4>
            <div>
              <div>
                <div className="terms-description px-3">
                  <h6>
                    <b>1. ACCEPTANCE OF TERMS</b>
                  </h6>
                  <div className="terms-para">
                    Rise & Thrive Global and Coach & Grow App are a property of
                    Quantum Co-Creation Pty Ltd (“Quantum Co-Creation”) which
                    provides online coaching and software products and services
                    to you subject to the following Terms and Conditions
                    (“T&C”), which may be updated from time to time without
                    notice. In addition, when using any particular Quantum
                    Co-Creation-owned or -operated services, you and Quantum
                    Co-Creation shall be subject to any posted guidelines or
                    rules applicable to such services, which may be modified
                    from time to time. All such guidelines or rules are hereby
                    incorporated by reference into the T&C. Quantum Co-Creation
                    may also offer other services that are governed by different
                    terms of service. <br />
                    <br />
                  </div>
                  <h6>
                    <b>2. DESCRIPTION OF SERVICE</b>
                  </h6>
                  <div className="terms-para">
                    Quantum Co-Creation provides users with access to a rich
                    collection of resources, including various educational
                    materials, communications tools, search services, and
                    personalised content through its network of properties which
                    may be accessed through any medium or device now known or
                    hereafter developed (the “Service”). You also understand and
                    agree that the Service may include advertisements and that
                    these advertisements are necessary for Quantum Co-Creation
                    to provide the Service. You also understand and agree that
                    the Service may include certain communications from Quantum
                    Co-Creation, such as service announcements, administrative
                    messages, and newsletter(s), and that these communications
                    are considered part of your membership. Unless explicitly
                    stated otherwise, any new features that augment or enhance
                    the current Service, including the release of new Quantum
                    Co-Creation properties, shall be subject to the T&C. You
                    understand and agree that the Service is provided “AS-IS”
                    and that Quantum Co-Creation assumes no responsibility for
                    the timeliness, deletion or failure to store any user
                    communications or personalisation settings. You are
                    responsible for obtaining access to the Service, and that
                    access may involve third-party fees (such as Internet
                    service provider or airtime charges). You are responsible
                    for those fees, including those fees associated with the
                    display or delivery of advertisements. In addition, you must
                    provide and are responsible for all equipment necessary to
                    access the Service.
                    <br />
                    <br />
                  </div>

                  <h6>
                    <b>3. YOUR REGISTRATION OBLIGATIONS</b>
                  </h6>
                  <div className="terms-para">
                    In consideration of your use of the Service, you represent
                    that you are of legal age to form a binding contract and are
                    not a person barred from receiving services under the laws
                    of the United States or other applicable jurisdictions. You
                    also agree to: (a) provide true, accurate, current and
                    complete information about yourself as prompted by the
                    Service’s registration form (the “Registration Data”) and
                    (b) maintain and promptly update the Registration Data to
                    keep it true, accurate, current and complete. If you provide
                    any information that is untrue, inaccurate, not current or
                    incomplete, or Quantum Co-Creation has reasonable grounds to
                    suspect that such information is untrue, inaccurate, not
                    current or incomplete, Quantum Co-Creation has the right to
                    suspend or terminate your account and refuse any and all
                    current or future use of the Service (or any portion
                    thereof).
                    <br />
                    <br />
                  </div>

                  <h6>
                    <b>4. QUANTUM CO-CREATION PRIVACY POLICY</b>
                  </h6>
                  <div className="terms-para">
                    Registration Data and certain other information about you
                    are subject to our Privacy Policy.
                  </div>
                  <div className="terms-para">
                    Rise & Thrive Global and Coach & Grow App believe that your
                    privacy is very important. Read the following to learn all
                    about our privacy policy. Rise & Thrive is a service
                    offering of Quantum Co-Creation Pty Ltd (“Quantum
                    Co-Creation”), and references in this document to “us,”
                    “our,” and “we” refer to Quantum Co-Creation.
                  </div>
                  <div className="terms-para">
                    Our policy applies to all personal information given and
                    received, including all information from your past use of
                    any of our products and services. We define personal
                    information as anything that would identify you: your
                    address, your phone and email contact information, your
                    name, your preferences, your financial information, as well
                    as other information that is not generally available to the
                    public. This policy applies to Rise & Thrive Global and no
                    other companies or entities.
                  </div>
                  <div className="terms-para">
                    We provide complete physical, electronic and procedural
                    security steps that comply with all federal government
                    regulations, including the European Union's General Data
                    Protection Regulation (GDPR), in order to protect your
                    information. We do not share your information with employees
                    without their demonstrated, reasonable need to access your
                    information in order to perform their jobs related to
                    providing you with products and services that you request.{" "}
                    <br /> <br />
                  </div>
                  <h6 className="left-margin">
                    <b>
                      4.1 What information do we collect, and what do we do with
                      it?
                    </b>
                  </h6>
                  <div className="terms-para left-margin">
                    When you register as a COACHEE or COACH (“user”) on our site
                    or related applications, we collect your personal
                    information, such as your name, address, and email address,
                    as part of the registration process.
                  </div>
                  <div className="terms-para left-margin">
                    Email marketing: we may send you emails about our site and
                    related service(s), registration, coaching sessions,
                    progress or other updates. We may also use your email to
                    inform you about changes to the program, survey you about
                    your usage, or collect your opinion.
                  </div>
                  <div className="terms-para left-margin">
                    You understand that through your use of the Service you
                    consent to the collection and use (as set forth in the
                    Privacy Policy) of this information, including the transfer
                    of this information to the United States and/or other
                    countries for storage, processing and use by Quantum
                    Co-Creation. <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>
                      4.2 What information do we collect, and what do we do with
                      it?
                    </b>
                  </h6>
                  <div className="terms-para left-margin">
                    When you provide us with personal information to become a
                    learner on our site, make a purchase, or participate in the
                    course, you imply that you consent to our collecting it and
                    using it for that specific reason only.
                  </div>
                  <div className="terms-para left-margin">
                    If we ask for your personal information for a secondary
                    reason, like marketing, we will either ask you directly for
                    your expressed consent or provide you with an opportunity to
                    say no. <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>4.3 Disclosure</b>
                  </h6>
                  <div className="terms-para left-margin">
                    We may disclose your personal information if we are required
                    by law to do so or if you violate our Terms and Conditions.{" "}
                    <br /> <br />
                  </div>
                  <h6 className="left-margin">
                    <b>4.4 Third-Party Services</b>
                  </h6>
                  <div className="terms-para left-margin">
                    In general, the third-party providers used by us will only
                    collect, use and disclose your information to the extent
                    necessary to allow them to perform the services they provide
                    to us.
                  </div>
                  <div className="terms-para left-margin">
                    However, certain third-party service providers, such as
                    payment gateways and other payment transaction processors,
                    have their own privacy policies with respect to the
                    information we are required to provide to them for your
                    purchase-related transactions.
                  </div>
                  <div className="terms-para left-margin">
                    For these providers, we recommend that you read their
                    privacy policies so you can understand the manner in which
                    your personal information will be handled by these
                    providers.
                  </div>
                  <div className="terms-para left-margin">
                    Certain providers may be located in or have facilities that
                    are located in a different jurisdiction than either you or
                    us. If you elect to proceed with a transaction that involves
                    the services of a third-party service provider, then your
                    information may become subject to the laws of the
                    jurisdiction(s) in which that service provider or its
                    facilities are located.
                  </div>
                  <div className="terms-para left-margin">
                    Once you leave our application/website or are redirected to
                    a third-party website or application, you are no longer
                    governed by this Privacy Policy or our website’s Terms and
                    Conditions. <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>Links</b>
                  </h6>
                  <div className="terms-para left-margin">
                    When you click on links on our site or app, they may direct
                    you away from our site. We are not responsible for the
                    privacy practices of other sites and encourage you to read
                    their privacy statements. <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>4.5 Security</b>
                  </h6>
                  <div className="terms-para left-margin">
                    To protect your personal information, we take reasonable
                    precautions and follow industry best practices to make sure
                    it is not inappropriately lost, misused, accessed,
                    disclosed, altered or destroyed.
                  </div>
                  <div className="terms-para left-margin">
                    If you provide us with your personal information (full name
                    and email address), the information is encrypted using
                    secure socket layer technology (SSL) using AES-256
                    encryption. <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>Cookies</b>
                  </h6>
                  <div className="terms-para left-margin">
                    We collect cookies or similar tracking technologies. This
                    means information that our website’s server transfers to
                    your computer. This information can be used to track your
                    session on our website. Cookies may also be used to
                    customise our website content for you as an individual. If
                    you are using one of the common Internet web browsers, you
                    can set up your browser to either let you know when you
                    receive a cookie or to deny cookie access to your computer.
                    <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>Web Analysis Tools</b>
                  </h6>
                  <div className="terms-para left-margin">
                    We may use web analysis tools that are built into the Rise &
                    Thrive Global website to measure and collect anonymous
                    session information.
                    <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>4.6 Age of Consent</b>
                  </h6>
                  <div className="terms-para left-margin">
                    By using this site, you represent and warrant that you have
                    reached the legal age of majority in your state or province
                    of residence.
                    <br />
                    <br />
                  </div>
                  <h6 className="left-margin">
                    <b>4.7 Changes to this Privacy Policy</b>
                  </h6>
                  <div className="terms-para left-margin">
                    We reserve the right to modify this privacy policy at any
                    time, so please review it frequently. Changes and
                    clarifications will take effect immediately upon their
                    posting on the website. If we make material changes to this
                    policy, we will notify you here that it has been updated, so
                    that you are aware of what information we collect, how we
                    use it, and under what circumstances, if any, we use and/or
                    disclose it.
                  </div>
                  <div className="terms-para left-margin">
                    If our site or course is acquired or merged with another
                    company, your information may be transferred to the new
                    owners so that we may continue to sell products to you.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>5. MEMBER ACCOUNT, PASSWORD, AND SECURITY</b>
                  </h6>
                  <div className="terms-para">
                    You will create a password and account designation upon
                    completing the Service’s registration process. You are
                    responsible for maintaining the confidentiality of the
                    password and account and are fully responsible for all
                    activities that occur under your password or account. You
                    agree to (a) immediately notify Quantum Co-Creation of any
                    unauthorised use of your password or account or any other
                    breach of security, and (b) ensure that you exit from your
                    account at the end of each session. Quantum Co-Creation
                    cannot and will not be liable for any loss or damage arising
                    from your failure to comply with this Section 5. <br />
                    <br />
                  </div>
                  <h6>
                    <b>6. MEMBER CONDUCT</b>
                  </h6>
                  <div className="terms-para">
                    You understand that all information, data, text, software,
                    music, sound, photographs, graphics, video, messages, tags,
                    or other materials, whether publicly posted or privately
                    transmitted (“Content”), are the sole responsibility of the
                    person from whom such Content originated. This means that
                    you, and not Quantum Co-Creation, are entirely responsible
                    for all Content that you upload, post, email, transmit or
                    otherwise make available via the Service. Quantum
                    Co-Creation does not control the Content posted via the
                    Service and, as such, does not guarantee the accuracy,
                    integrity or quality of such Content. You understand that by
                    using the Service, you may be exposed to Content that is
                    offensive, indecent, or objectionable. Under no
                    circumstances will Quantum Co-Creation be liable in any way
                    for any Content, including, but not limited to, any errors
                    or omissions in any Content, or any loss or damage of any
                    kind incurred as a result of the use of any Content posted,
                    emailed, transmitted or otherwise made available via the
                    Service.
                  </div>
                  <div className="terms-para">
                    You agree to not use the Service to:
                    <br />
                    <br />
                  </div>
                  <ol>
                    <li>
                      upload, post, email, transmit or otherwise make available
                      any Content that is unlawful, harmful, threatening,
                      abusive, harassing, tortious, defamatory, vulgar, obscene,
                      libellous, invasive of another’s privacy, hateful, or
                      racially, ethnically or otherwise objectionable;
                    </li>
                    <li>harm minors in any way;</li>
                    <li>
                      impersonate any person or entity, including, but not
                      limited to, a Quantum Co-Creation official, forum leader,
                      guide or host, or falsely state or otherwise misrepresent
                      your affiliation with a person or entity;
                    </li>
                    <li>
                      forge headers or otherwise manipulate identifiers in order
                      to disguise the origin of any Content transmitted through
                      the Service;
                    </li>
                    <li>
                      upload, post, email, transmit or otherwise make available
                      any Content that you do not have a right to make available
                      under any law or under contractual or fiduciary
                      relationships (such as inside information, proprietary and
                      confidential information learned or disclosed as part of
                      employment relationships or under nondisclosure
                      agreements);
                    </li>
                    <li>
                      upload, post, email, transmit or otherwise make available
                      any Content that infringes any patent, trademark, trade
                      secret, copyright or other intellectual or proprietary
                      rights (“Rights”) of any party;
                    </li>
                    <li>
                      upload, post, email, transmit or otherwise make available
                      any unsolicited or unauthorized advertising or promotional
                      materials, except in those areas (such as shopping) that
                      are designated for such purpose; in no event, however, is
                      junk mail, spam, chain letters, pyramid or Ponzi schemes,
                      or the like allowed;
                    </li>
                    <li>
                      upload, post, email, transmit or otherwise make available
                      any material that contains software viruses or any other
                      computer code, files or programs designed to interrupt,
                      destroy or limit the functionality of any computer
                      software or hardware or telecommunications equipment;
                    </li>
                    <li>
                      disrupt the normal flow of dialogue, cause a screen to
                      “scroll” faster than other users of the Service are able
                      to type, or otherwise act in a manner that negatively
                      affects other users’ ability to engage in real time
                      exchanges;
                    </li>
                    <li>
                      interfere with or disrupt the Service or servers or
                      networks connected to the Service, or disobey any
                      requirements, procedures, policies or regulations of
                      networks connected to the Service;
                    </li>
                    <li>
                      intentionally or unintentionally violate any applicable
                      local, state, national or international law;
                    </li>
                    <li>“stalk” or otherwise harass another; and/or</li>
                    <li>
                      collect or store personal data about other users in
                      connection with the prohibited conduct and activities set
                      forth in paragraphs a through m above.
                    </li>
                  </ol>
                  <div className="terms-para">
                    You acknowledge that Quantum Co-Creation may or may not
                    pre-screen Content, but that Quantum Co-Creation and its
                    designees shall have the right (but not the obligation) in
                    its sole discretion to pre-screen, refuse, or move any
                    Content that is available via the Service. Without limiting
                    the foregoing, Quantum Co-Creation and its designees shall
                    have the right to remove any Content that violates the T&C
                    or is otherwise objectionable. You agree that you must
                    evaluate, and bear all risks associated with, the use of any
                    Content, including any reliance on the accuracy,
                    completeness, or usefulness of such Content. In this regard,
                    you acknowledge that you may not rely on any Content,
                    including without limitation information in message boards
                    and in all other parts of the Service.
                  </div>
                  <div className="terms-para">
                    You acknowledge, consent, and agree that Quantum Co-Creation
                    may access, preserve and disclose your account information
                    and Content if required to do so by law or in a good faith
                    belief that such access preservation or disclosure is
                    reasonably necessary to: (a) comply with legal process; (b)
                    enforce the T&C; (c) respond to claims that any Content
                    violates the rights of third parties; (d) respond to your
                    requests for customer service; or (e) protect the rights,
                    property, or personal safety of Quantum Co-Creation, its
                    principals, employees, associates, affiliates, users, and
                    the public.
                  </div>
                  <div className="terms-para">
                    You understand that the technical processing and
                    transmission of the Service, including your Content, may
                    involve: (a) transmissions over various networks; and (b)
                    changes to conform and adapt to technical requirements of
                    connecting networks or devices.
                  </div>
                  <div className="terms-para">
                    You understand that the Service and software embodied within
                    the Service may include security components that permit
                    digital materials to be protected, and that use of these
                    materials is subject to usage rules set by Quantum
                    Co-Creation and/or content providers who provide content to
                    the Service. You may not attempt to override or circumvent
                    any of the usage rules embedded into the Service. Any
                    unauthorised reproduction, publication, further
                    distribution, or public exhibition of the materials provided
                    on the Service, in whole or in part, is strictly prohibited.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>
                      7. INTERSTATE NATURE OF COMMUNICATIONS ON RISE & THRIVE
                      NETWORK
                    </b>
                  </h6>
                  <div className="terms-para">
                    When you register, you acknowledge that in using Quantum
                    Co-Creation services to send electronic communications, you
                    will be causing communications to be sent through Quantum
                    Co-Creation’s computer networks, which are located in
                    various locations in the United States and portions of which
                    are located abroad. As a result, and also as a result of
                    Quantum Co-Creation’s network architecture, business
                    practices, and the nature of electronic communications, even
                    communications that seem to be intrastate in nature can
                    result in the transmission of interstate communications
                    regardless of where one is physically located at the time of
                    transmission.
                  </div>
                  <div className="terms-para">
                    Accordingly, by agreeing to this T&C, you acknowledge that
                    the use of the service results in interstate and
                    international data transmissions.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>8. SPECIAL ADMONITIONS FOR INTERNATIONAL USE</b>
                  </h6>
                  <div className="terms-para">
                    Recognising the global nature of the Internet, you agree to
                    comply with all local rules regarding online conduct and
                    acceptable Content. Specifically, you agree to comply with
                    all applicable laws regarding the transmission of technical
                    data exported from the United States or the country in which
                    you reside.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>
                      9. CONTENT SUBMITTED OR MADE AVAILABLE FOR INCLUSION ON
                      THE SERVICE
                    </b>
                  </h6>
                  <div className="terms-para">
                    Quantum Co-Creation does not claim ownership of Content you
                    submit or make available for inclusion on the Service,
                    however, with respect to Content you submit or make
                    available for inclusion in publicly accessible areas of the
                    Service, you grant Quantum Co-Creation and its Affiliate
                    Companies the following worldwide, royalty-free, and
                    non-exclusive license(s), as applicable:
                  </div>
                  <div className="terms-para">
                    * With respect to photos, graphics, audio, or video you
                    submit or make available for inclusion on publicly
                    accessible areas of the Service, the license to use,
                    distribute, reproduce, modify, adapt, publicly perform, and
                    publicly display such Content on the Service. This license
                    exists only for as long as you elect to continue to include
                    such Content on the Service and terminates at the time you
                    or Quantum Co-Creation removes such Content from the
                    Service.
                  </div>
                  <div className="terms-para">
                    * With respect to Content other than photos, graphics, audio
                    or video you submit or make available for inclusion in
                    publicly-accessible areas of the Service, the perpetual,
                    irrevocable and fully sublicensable license to use,
                    distribute, reproduce, modify, adapt, publish, translate,
                    publicly perform, and publicly display such Content (in
                    whole or in part) and to incorporate such Content into other
                    works in any format or medium now known or later developed.
                  </div>
                  <div className="terms-para">
                    “Publicly accessible” areas of the Service are those areas
                    of the Quantum Co-Creation network of properties that are
                    intended by Quantum Co-Creation to be available to the
                    general public.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>10. INDEMNITY</b>
                  </h6>
                  <div className="terms-para">
                    You agree to indemnify and hold Quantum Co-Creation and its
                    Affiliate Companies, and their officers, agents, employees,
                    partners, and licensors harmless from any claim or demand,
                    including reasonable attorneys’ fees, made by any third
                    party due to or arising out of Content you submit, post,
                    transmit or otherwise make available through the Service,
                    your use of the Service, your connection to the Service,
                    your violation of the T&C, or your violation of any rights
                    of another. <br />
                    <br />
                  </div>
                  <h6>
                    <b>11. NO RESALE OF SERVICE</b>
                  </h6>
                  <div className="terms-para">
                    You agree not to reproduce, duplicate, copy, sell, trade,
                    resell, or exploit for any commercial purposes, any portion
                    of the Service (including your membership ID), use of the
                    Service, or access to the Service. <br />
                    <br />
                  </div>
                  <h6>
                    <b>12. GENERAL PRACTICES REGARDING USE AND STORAGE</b>
                  </h6>
                  <div className="terms-para">
                    You acknowledge that Quantum Co-Creation may establish
                    general practices and limits concerning use of the Service,
                    including without limitation the maximum number of days that
                    uploaded Content will be retained by the Service, the
                    maximum number of email messages that may be sent from or
                    received by an account on the Service, the maximum size of
                    any email message that may be sent from or received by an
                    account on the Service, the maximum disk space that will be
                    allotted on Quantum Co-Creation’s servers on your behalf,
                    and the maximum number of times (and the maximum duration
                    for which) you may access the Service in a given period of
                    time. You agree that Quantum Co-Creation has no
                    responsibility or liability for the deletion or failure to
                    store any messages and other communications or other Content
                    maintained or transmitted by the Service. You acknowledge
                    that Quantum Co-Creation reserves the right to log off
                    accounts that are inactive for an extended period of time.
                    You further acknowledge that Quantum Co-Creation reserves
                    the right to modify these general practices and limits from
                    time to time without notice. <br />
                    <br />
                  </div>
                  <h6>
                    <b>13. MODIFICATIONS TO SERVICE</b>
                  </h6>
                  <div className="terms-para">
                    Quantum Co-Creation reserves the right at any time and from
                    time to time to modify or discontinue, temporarily or
                    permanently, the Service (or any part thereof) with or
                    without notice. You agree that Quantum Co-Creation shall not
                    be liable to you or to any third party for any modification,
                    suspension, or discontinuance of the Service for any reason.{" "}
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>14. TERMINATION</b>
                  </h6>
                  <div className="terms-para">
                    You agree that Quantum Co-Creation may, in its sole
                    discretion and without prior notice, immediately terminate
                    your account, deny access to any and all associated Content
                    entered by you or related parties, and deny access to the
                    Service for reasons that include, but are not limited to:
                    (a) breaches or violations of the T&C or other incorporated
                    agreements or guidelines; (b) requests by law enforcement or
                    other government agencies; (c) a request by you
                    (self-initiated account deletions); (d) discontinuance or
                    material modification to the Service (or any part thereof);
                    (e) unexpected technical or security issues or problems; (f)
                    extended periods of inactivity; (g) engagement by you in
                    fraudulent or illegal activities; and/or (h) nonpayment of
                    any fees owed by you in connection with the Services.
                    Termination of your account includes (a) removal of access
                    to all offerings within the Service, (b) deletion of your
                    password and all related information, files, and content
                    associated with or inside your account (or any part
                    thereof), and (c) barring of further use of the Service.
                    Further, you agree that Quantum Co-Creation shall not be
                    liable to you or any third party for any such termination of
                    your account, any associated email address, or access to the
                    Service. <br />
                    <br />
                  </div>
                  <h6>
                    <b>15. DEALINGS WITH ADVERTISERS</b>
                  </h6>
                  <div className="terms-para">
                    Your correspondence or business dealings with, or
                    participation in promotions of, advertisers found on or
                    through the Service, including payment and delivery of
                    related goods or services, and any other terms, conditions,
                    warranties, or representations associated with such
                    dealings, are solely between you and such advertiser. You
                    agree that Quantum Co-Creation shall not be responsible or
                    liable for any loss or damage of any sort incurred as the
                    result of any such dealings or as the result of the presence
                    of such advertisers on the Service. <br />
                    <br />
                  </div>
                  <h6>
                    <b>16. LINKS</b>
                  </h6>
                  <div className="terms-para">
                    The Service may provide, or third parties may provide, links
                    to other websites or resources. Because Quantum Co-Creation
                    has no control over such sites and resources, you
                    acknowledge and agree that Quantum Co-Creation is not
                    responsible for the availability of such external sites or
                    resources, and does not endorse and is not responsible or
                    liable for any Content, advertising, products, or other
                    materials on or available from such sites or resources. You
                    further acknowledge and agree that Quantum Co-Creation shall
                    not be responsible or liable, directly or indirectly, for
                    any damage or loss caused or alleged to be caused by or in
                    connection with use of or reliance on any such content,
                    goods or services available on or through any such site or
                    resource. <br />
                    <br />
                  </div>
                  <h6>
                    <b>17. QUANTUM CO-CREATION PROPRIETARY RIGHTS</b>
                  </h6>
                  <div className="terms-para">
                    You acknowledge and agree that the Service and any necessary
                    software used in connection with the Service (“Software”)
                    contain proprietary and confidential information that is
                    protected by applicable intellectual property and other
                    laws. You further acknowledge and agree that Content
                    contained in sponsor advertisements or information presented
                    to you through the Service or by advertisers is protected by
                    copyrights, trademarks, service marks, patents or other
                    proprietary rights and laws. Except as otherwise provided or
                    expressly authorised by Quantum Co-Creation, you agree not
                    to modify, rent, lease, loan, sell, distribute, or create
                    derivative works based on the Service or the Software, in
                    whole or in part. <br />
                    <br />
                  </div>
                  <h6>
                    <b>18. DISCLAIMER OF WARRANTIES</b>
                  </h6>
                  <div className="terms-para">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT: <br />
                    <br />
                  </div>
                  <ol>
                    <li>
                      YOUR USE OF THE SERVICE IS AT YOUR SOLE RISK. THE SERVICE
                      IS PROVIDED ON AN “AS IS” AND “AS AVAILABLE” BASIS.
                      QUANTUM CO-CREATION, AND THEIR OFFICERS, EMPLOYEES,
                      AGENTS, PARTNERS AND LICENSORS EXPRESSLY DISCLAIM ALL
                      WARRANTIES OF ANY KIND, WHETHER EXPRESS OR IMPLIED,
                      INCLUDING, BUT NOT LIMITED TO THE IMPLIED WARRANTIES OF
                      MERCHANTABILITY, FITNESS FOR A PARTICULAR PURPOSE AND
                      NON-INFRINGEMENT.
                    </li>
                    <li>
                      QUANTUM CO-CREATION AND ITS OFFICERS, EMPLOYEES, AGENTS,
                      PARTNERS, AND LICENSORS, MAKE NO WARRANTY THAT (i) THE
                      SERVICE WILL MEET YOUR REQUIREMENTS; (ii) THE SERVICE WILL
                      BE UNINTERRUPTED, TIMELY, SECURE, OR ERROR-FREE; (iii) THE
                      RESULTS THAT MAY BE OBTAINED FROM THE USE OF THE SERVICE
                      WILL BE ACCURATE OR RELIABLE; (iv) THE QUALITY OF ANY
                      PRODUCTS, SERVICES, INFORMATION, OR OTHER MATERIAL
                      PURCHASED OR OBTAINED BY YOU THROUGH THE SERVICE WILL MEET
                      YOUR EXPECTATIONS; AND (v) ANY ERRORS IN THE SOFTWARE WILL
                      BE CORRECTED.
                    </li>
                    <li>
                      ANY MATERIAL DOWNLOADED OR OTHERWISE OBTAINED THROUGH THE
                      USE OF THE SERVICE IS ACCESSED AT YOUR OWN DISCRETION AND
                      RISK, AND YOU WILL BE SOLELY RESPONSIBLE FOR ANY DAMAGE TO
                      YOUR COMPUTER SYSTEM OR LOSS OF DATA THAT RESULTS FROM THE
                      DOWNLOAD OF ANY SUCH MATERIAL.
                    </li>
                    <li>
                      NO ADVICE OR INFORMATION, WHETHER ORAL OR WRITTEN,
                      OBTAINED BY YOU FROM QUANTUM CO-CREATION OR THROUGH OR
                      FROM THE SERVICE SHALL CREATE ANY WARRANTY NOT EXPRESSLY
                      STATED IN THE T&C.
                    </li>
                    <li>
                      A SMALL PERCENTAGE OF USERS MAY EXPERIENCE EPILEPTIC
                      SEIZURES WHEN EXPOSED TO CERTAIN LIGHT PATTERNS OR
                      BACKGROUNDS ON A COMPUTER SCREEN OR WHILE USING THE
                      SERVICE. CERTAIN CONDITIONS MAY INDUCE PREVIOUSLY
                      UNDETECTED EPILEPTIC SYMPTOMS EVEN IN USERS WHO HAVE NO
                      HISTORY OF PRIOR SEIZURES OR EPILEPSY. IF YOU, OR ANYONE
                      IN YOUR FAMILY, HAVE AN EPILEPTIC CONDITION, CONSULT YOUR
                      PHYSICIAN PRIOR TO USING THE SERVICE. IMMEDIATELY
                      DISCONTINUE USE OF THE SERVICE AND CONSULT YOUR PHYSICIAN
                      IF YOU EXPERIENCE ANY OF THE FOLLOWING SYMPTOMS WHILE
                      USING THE SERVICE: DIZZINESS, ALTERED VISION, EYE OR
                      MUSCLE TWITCHES, LOSS OF AWARENESS, DISORIENTATION, ANY
                      INVOLUNTARY MOVEMENT, OR CONVULSIONS.
                      <br />
                      <br />
                    </li>
                  </ol>
                  <h6>
                    <b>19. LIMITATION OF LIABILITY</b>
                  </h6>
                  <div className="terms-para">
                    YOU EXPRESSLY UNDERSTAND AND AGREE THAT QUANTUM CO-CREATION,
                    AND ITS OFFICERS, EMPLOYEES, AGENTS, PARTNERS AND LICENSORS
                    SHALL NOT BE LIABLE TO YOU FOR ANY DIRECT, INDIRECT,
                    INCIDENTAL, SPECIAL, CONSEQUENTIAL, OR EXEMPLARY DAMAGES,
                    INCLUDING, BUT NOT LIMITED TO, DAMAGES FOR LOSS OF PROFITS,
                    GOODWILL, USE, DATA OR OTHER INTANGIBLE LOSSES (EVEN IF SUCH
                    LOSSES ARE REASONABLY FORESEEABLE OR Quantum Co-Creation HAS
                    ACTUAL NOTICE OF THE POSSIBILITY OF SUCH DAMAGES), RESULTING
                    FROM: (i) THE USE OR THE INABILITY TO USE THE SERVICE; (ii)
                    THE COST OF PROCUREMENT OF SUBSTITUTE GOODS AND SERVICES
                    RESULTING FROM ANY GOODS, DATA, INFORMATION OR SERVICES
                    PURCHASED OR OBTAINED OR MESSAGES RECEIVED OR TRANSACTIONS
                    ENTERED INTO THROUGH OR FROM THE SERVICE; (iii) UNAUTHORIZED
                    ACCESS TO OR ALTERATION OF YOUR TRANSMISSIONS OR DATA; (iv)
                    STATEMENTS OR CONDUCT OF ANY THIRD PARTY ON THE SERVICE; OR
                    (v) ANY OTHER MATTER RELATING TO THE SERVICE. <br />
                    <br />
                  </div>
                  <h6>
                    <b>20. EXCLUSIONS AND LIMITATIONS</b>
                  </h6>
                  <div className="terms-para">
                    SOME JURISDICTIONS DO NOT ALLOW THE EXCLUSION OF CERTAIN
                    WARRANTIES OR THE LIMITATION OR EXCLUSION OF LIABILITY FOR
                    INCIDENTAL OR CONSEQUENTIAL DAMAGES. ACCORDINGLY, SOME OF
                    THE ABOVE LIMITATIONS OF SECTIONS 17 AND 18 MAY NOT APPLY TO
                    YOU. <br />
                    <br />
                  </div>
                  <h6>
                    <b>21. EARNINGS DISCLAIMER</b>
                  </h6>
                  <div className="terms-para">
                    Your level of success in attaining the results from using
                    the Service and our other products depends on the time you
                    devote to the training, the ideas and techniques that you
                    use, your finances, knowledge, work ethic, and various other
                    skills.
                  </div>
                  <div className="terms-para">
                    Since these factors differ among each individual, we cannot
                    guarantee your success or income level, nor are we
                    responsible for any of your actions or non-actions. Any and
                    all forward-looking statements on this website or in any of
                    our products are intended to express our opinion about the
                    earnings potential that some people have achieved, and
                    others may achieve.
                  </div>
                  <div className="terms-para">
                    Many factors will be important in determining your actual
                    results, and we make no guarantees that you will achieve
                    results similar to ours or anyone else’s. In fact, we cannot
                    make any guarantees that you will achieve any results from
                    the ideas and techniques contained within the Service or in
                    our products, because that is ultimately out of our control.
                  </div>
                  <div className="terms-para">
                    Each case study and testimonial you encounter on this
                    website is about and/or from an actual, verifiable person,
                    and the results are accurate to the absolute best of our
                    knowledge. You should, however, assume that none of these
                    case studies and testimonials in any way represent the
                    “average” or “typical” customer experience.
                  </div>
                  <div className="terms-para">
                    In fact, as with any product or service, we know that some
                    people will purchase the Service or our products, but never
                    take action at all, and therefore will get no results
                    whatsoever. In that sense, you fully understand and agree
                    that we are not responsible for your success or failure, and
                    we make no representations or warranties of any kind
                    whatsoever regarding your particular results.
                  </div>
                  <div className="terms-para">
                    Even though we cannot make guarantees that the Service or
                    our other products will produce any particular result for
                    you please make sure you fully understand our refund policy,
                    outlined below:
                  </div>
                  <div className="terms-para">
                    We reserve the right to terminate this Agreement or your use
                    of the website or our community at any time in our absolute
                    discretion, with your sole entitlement being a right to
                    refund of any unused portion of membership payments.
                  </div>
                  <div className="terms-para">
                    Refunds are not provided for Services that have been
                    rendered by us, including where you have been given access
                    to online content or our online community, whether accessed
                    by you or not, unless we are in breach of the <a href="https://www.legislation.gov.au/C2004A00109/2011-01-01/text">Australian Competition and Consumer Act 2010</a>.
                  </div>
                  <div className="terms-para">
                    If you cancel your payments, services or subscriptions you
                    will no longer have access to the online content, our online
                    community or products. This covers all of the Rise & Thrive
                    Global Online Services. We strongly suggest that you
                    download all of our online content including audio and video
                    files the moment they become available. <br />
                    <br />
                  </div>
                  <h6>
                    <b>22. NO THIRD-PARTY BENEFICIARIES</b>
                  </h6>
                  <div className="terms-para">
                    You agree that, except as otherwise expressly provided in
                    this T&C, there shall be no third-party beneficiaries to
                    this agreement.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>23. NOTICE</b>
                  </h6>
                  <div className="terms-para">
                    Quantum Co-Creation may provide you with notices, including
                    those regarding changes to the T&C, by email, regular mail
                    or postings on the Service.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>24. TRADEMARK INFORMATION</b>
                  </h6>
                  <div className="terms-para">
                    All logos, product, and service names related to these T&C
                    are trademarks of Quantum Co-Creation (the “Quantum
                    Co-Creation Marks”). Without Quantum Co-Creation’s prior
                    permission, you agree not to display or use in any manner
                    the Quantum Co-Creation Marks.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>
                      25. NOTICE AND PROCEDURE FOR MAKING CLAIMS OF COPYRIGHT OR
                      INTELLECTUAL PROPERTY INFRINGEMENT
                    </b>
                  </h6>
                  <div className="terms-para">
                    Quantum Co-Creation respects the intellectual property of
                    others, and we ask our users to do the same. Quantum
                    Co-Creation may, in appropriate circumstances and at its
                    discretion, disable and/or terminate the accounts of users
                    who may be intentional or repeat infringers. If you believe
                    that your work has been copied in a way that constitutes
                    copyright infringement, or that your intellectual property
                    rights have been otherwise violated, please provide Quantum
                    Co-Creation’s IP agent the following information in a
                    writing signed (either physically or digitally) by the owner
                    of the copyright or other intellectual property interest or
                    a person authorized to act on his or her behalf:
                    <br />
                    <br />
                  </div>
                  <ol>
                    <li>
                      a description of the copyrighted work or other
                      intellectual property that you claim has been infringed;
                    </li>
                    <li>
                      a description of where the material that you claim is
                      infringing is located on the site;
                    </li>
                    <li>your address, telephone number, and email address;</li>
                    <li>
                      a statement by you that you have a good faith belief that
                      the disputed use is not authorised by the intellectual
                      property owner, its agent, or the law;
                    </li>
                    <li>
                      a statement by you, made under penalty of perjury, that
                      the above information in your notice is accurate and that
                      you are the copyright or intellectual property owner or
                      authorised to act on the copyright or intellectual
                      property owner’s behalf.
                    </li>
                  </ol>
                  <div className="terms-para">
                    You may give the above notice to Quantum Co-Creation’s IP
                    agent: By email: <a href="mailto:support@riseandthrive.com.au">support@riseandthrive.com.au</a> <br />
                    <br />
                  </div>
                  <h6>
                    <b>26. GENERAL INFORMATION</b>
                  </h6>
                  <div className="terms-para">
                    Entire Agreement. The T&C constitutes the entire agreement
                    between you and Quantum Co-Creation and governs your use of
                    the Service, superseding any prior agreements between you
                    and Quantum Co-Creation with respect to the Service. You
                    also may be subject to additional terms and conditions that
                    may apply when you use or purchase certain other Quantum
                    Co-Creation services, affiliate services, third-party
                    content, or third-party software.
                  </div>
                  <div className="terms-para">
                    Choice of Law and Forum. The T&C and the relationship
                    between you and Quantum Co-Creation shall be governed by the
                    laws of the State of Victoria, Australia without regard to
                    any principles of conflicts of law. You and Quantum
                    Co-Creation agree to submit to the personal and exclusive
                    jurisdiction of the courts located in Melbourne, Victoria,
                    Australia.
                  </div>
                  <div className="terms-para">
                    Waiver and Severability of Terms. The failure of Quantum
                    Co-Creation to exercise or enforce any right or provision of
                    the T&C shall not constitute a waiver of such right or
                    provision. If any provision of the T&C is found by a court
                    of competent jurisdiction to be invalid, the parties
                    nevertheless agree that the court should endeavor to give
                    effect to the parties’ intentions as reflected in the
                    provision, and the other provisions of the T&C remain in
                    full force and effect.
                  </div>
                  <div className="terms-para">
                    No Right of Survivorship and Non-Transferability. You agree
                    that your Quantum Co-Creation account is non-transferable
                    and any rights to your membership ID or contents within your
                    account terminate upon your death. Upon receipt of a copy of
                    a death certificate, your account may be terminated and all
                    contents therein permanently deleted.
                  </div>
                  <div className="terms-para">
                    Statute of Limitations. You agree that regardless of any
                    statute or law to the contrary, any claim or cause of action
                    arising out of or related to use of the Service or the T&C
                    must be filed within one (1) year after such claim or cause
                    of action arose or be forever barred.
                  </div>
                  <div className="terms-para">
                    The section titles in the T&C are for convenience only and
                    have no legal or contractual effect.
                    <br />
                    <br />
                  </div>
                  <h6>
                    <b>27. VIOLATIONS</b>
                  </h6>
                  <div className="terms-para">
                    Please report any violations of these T&C to <a href="mailto:support@riseandthrive.com.au">support@riseandthrive.com.au</a>
                  </div>
                </div>
              </div>
            </div>
            <div className="d-flex justify-content-center mt-4">
              <Button
                variant="clr-primary"
                type="button"
                //disabled={!checked}
                className="w-20"
                onClick={() => {
                  if (props.clickFlow) {
                    props.nextScreen(1);
                  }
                }}
              >
                Close
              </Button>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    // </Modal>
  );
}

export default TermsCondition;

import { useState, useEffect } from "react";
import { Link, useParams, useNavigate } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import AgoraRTC from "agora-rtc-sdk-ng";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { setRouteData } from "../../stores/appSlice";
import { BookSlotServices } from "../../services";
import { THEME } from "../../utilities/theme";
import "./Appointment.call.css";

const RtcClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

function AppointmentCall({ pageTitle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [pageError, set_pageError] = useState("");
  /* Video calling */
  const [rtcConfig, set_rtcConfig] = useState({});
  const [localUser, set_localUser] = useState(initUserObject());
  const [remoteUser, set_remoteUser] = useState(initUserObject());
  const [remoteMediaType, set_remoteMediaType] = useState("");

  useEffect(() => {
    console.log("video call page loaded...");
    dispatch(setRouteData({ pageTitle }));
    getRtcConfiguration();

    return leaveChannel;
  }, []);

  useEffect(() => {
    (async () => {
      if (rtcConfig.appId) {
        console.log("Effect rtcConfig => ", rtcConfig);
        prepareClient();
      }
    })();
  }, [rtcConfig]);

  useEffect(() => {
    (async () => {
      if (localUser.uid) {
        console.log("Effect localUser => ", localUser);
        localUser.videoTrack.play("local-video-stream");
        await RtcClient.publish([localUser.audioTrack, localUser.videoTrack]);
      }
    })();
  }, [localUser]);

  useEffect(() => {
    console.log("remoteUser => ", remoteUser);
    if (remoteUser.uid) {
      if (remoteMediaType === "video" || remoteMediaType === "all") {
        remoteUser?.videoTrack.play(`${remoteUser.uid}`);
      }
      if (remoteMediaType === "audio" || remoteMediaType === "all") {
        remoteUser.audioTrack.play();
      }
    }
  }, [remoteUser, remoteMediaType]);

  function initUserObject() {
    return { uid: 0, videoTrack: null, audioTrack: null };
  }

  async function getRtcConfiguration() {
    try {
      const resp = await trackPromise(BookSlotServices.getRtcCofiguration(params.slotId));
      const { data } = resp;
      if (data.success) {
        set_rtcConfig(data.data);
      } else {
        set_pageError(data.message || "Error in fetching RTC configuration.");
      }
    } catch (err) {
      console.error("Fetch RTC configuration catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function prepareClient() {
    try {
      RtcClient.on("user-published", handlePublish);
      RtcClient.on("user-unpublished", handleUnpublish);
      const joinUid = await RtcClient.join(rtcConfig.appId, rtcConfig.channel, rtcConfig.token, rtcConfig.user);
      console.log("joinUid => ", joinUid);
      if (joinUid) {
        let localvideo = await AgoraRTC.createCameraVideoTrack();
        let localaudio = await AgoraRTC.createMicrophoneAudioTrack();
        set_localUser({
          uid: joinUid,
          videoTrack: localvideo,
          audioTrack: localaudio,
        });
      }
    } catch (err) {
      console.error("Failed to prepare channel:", err);
      set_pageError(err.message || "Error in configuring the Video call");
    }
  }

  async function handlePublish(user, mediaType) {
    await RtcClient.subscribe(user, mediaType);
    console.log("subscribed to remote: user => ", user);
    set_remoteUser(user);
    set_remoteMediaType(mediaType);
  }

  function handleUnpublish() {
    console.log("user unpublished...");
    set_remoteUser(initUserObject());
    set_remoteMediaType("");
  }

  function joinChannel(channelName, token) {
    try {
      // join channel
    } catch (error) {
      console.error("Failed to join channel:", error);
    }
  }

  async function leaveChannel() {
    console.log("Leave channel called...");
    try {
      RtcClient.off("user-published", handlePublish);
      RtcClient.off("user-unpublished", handleUnpublish);
      const unpublishResp = await RtcClient.unpublish([localUser.audioTrack, localUser.videoTrack]);
      console.log("RtcClient.unpublish response => ", unpublishResp);
      await RtcClient.leave();
      clearTracks(localUser);
      set_localUser(initUserObject());
      set_remoteUser(initUserObject());
      set_remoteMediaType("");
      navigate(-1);
    } catch (err) {
      console.error("Error in leaving channel");
    }
  }

  function clearTracks(userObj) {
    userObj.videoTrack.stop();
    userObj.videoTrack.close();
    userObj.audioTrack.stop();
    userObj.audioTrack.close();
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Video Call</h4>
        <Button variant="clr-transparent" onClick={leaveChannel}>
          <FontAwesomeIcon icon={faArrowLeftLong} color={THEME.CLR_PRIMARY} title="Back" className="icon" />
        </Button>
      </div>

      {pageError ? <div className="text-danger">{pageError?.textMsg}</div> : <></>}

      <Row className="video-container">
        <Col>
          <div id="local-video-stream" className="video-wrapper"></div>
        </Col>
        {remoteUser?.uid ? (
          <Col>
            <div id={remoteUser.uid} className="video-wrapper"></div>
          </Col>
        ) : (
          <></>
        )}
      </Row>

      <div>
        <Button variant="clr-primary" onClick={leaveChannel}>
          End Call
        </Button>
      </div>
    </Container>
  );
}

export default AppointmentCall;

import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import Papa from "papaparse";
import sampleTemplate from "../../assets/files/sampleTemplate.csv";
import sampleTemplate1 from "../../assets/files/sampleTemplate1.csv";
import { SubscriptionService, UserService } from "../../services";
import { AgGridReact } from "ag-grid-react";

function BulkUpload({ pageTitle }) {
  const dispatch = useDispatch();
  const gridRef = useRef();
  const [studentTypeList, set_studentTypeList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [csvSelected, setCsvSelected] = useState();
  const [jsonData, setJsonData] = useState([]);
  const [noFileError, setNoFileError] = useState(false);
  const [gridData, setGridData] = useState([]);
  const [invalidFileError, setInvalidFileError] = useState(false);
  const [isVerify, setIsVerify] = useState(false);
  const [isUploaded, setIsUploaded] = useState(false);
  const [updatedData, setUpdatedData] = useState([]);
  // console.log("jsondata", jsonData);

  const inputRef = useRef();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchStudentTypes();
  }, []);

  const FormSchema = Yup.object().shape({});

  async function handleFormSubmit(values) {
    try {
      if (!jsonData) {
        setNoFileError(true);
      } else {
        const formValue = {
          studentList: jsonData
            .filter((items) => items.errorType == "Valid Data")
            .map((itm) => ({
              ...itm,
              agreementAccepted: false,
            })),
        };

        const resp = await trackPromise(UserService.bulkUpload(formValue));
        const { data } = resp;
        if (data.success) {
          set_pageError("");
          setIsUploaded(true);
          updateUploadedData(data?.data);
          toast.success(`${data.data.filter((itm) => itm.dbInserted).length} Coachee Created Successfully `);
          // navigate(-1);
        } else {
          set_pageError(data.message || "Error in creating coachee Login");
        }
      }
    } catch (err) {
      console.error("create error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Bulk upload error");
    }
  }

  async function fetchStudentTypes() {
    try {
      const resp = await trackPromise(SubscriptionService.studentTypeBySubscription());
      const { data } = resp;
      if (data.success) {
        set_studentTypeList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coachee types");
      }
    } catch (err) {
      console.error("Fetch Coachee types catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function Verify() {
    try {
      if (!jsonData) {
        setNoFileError(true);
      } else {
        const formValue = {
          studentList: jsonData,
        };
        const resp = await trackPromise(UserService.bulkUploadVerify(formValue));
        const { data } = resp;
        if (data.success) {
          updateVerifyData(data.data);
          setIsVerify(true);
          toast.success(`${data?.data.length} Verified successfully `);
          set_pageError("");
        } else {
          set_pageError(data?.message || "Error in List  Verification");
        }
      }
    } catch (err) {
      console.error("create error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err?.response.data.message);
      } else {
        set_pageError(err?.message);
      }
      toast.error(err?.response?.data?.message || err.message || "Bulk upload error");
    }
  }

  function updateVerifyData(VerifiedData) {
    const newjsonData = VerifiedData.map((newData) => {
      const newRowData = { ...newData.data, errorType: newData.hasError ? newData.result : "Valid Data" };
      return newRowData;
    });
    setJsonData(newjsonData);
  }

  function updateUploadedData(uploadedData) {
    const newjsonData = [
      ...uploadedData.map((rowData) => {
        const newRowData = { ...rowData.data, errorType: rowData.hasError ? rowData.result : "Valid Data" };
        return newRowData;
      }),
      ...jsonData.filter((item) => item.errorType !== "Valid Data"),
    ];

    setUpdatedData(newjsonData);
  }

  const handleCSVInputChange = (event) => {
    const file = event.target.files[0];

    const extn = file?.name.split(".").pop();

    if (extn === "csv") {
      setCsvSelected(file);
      if (file) {
        Papa.parse(file, {
          complete: (result) => {
            // Check if there were any parsing errors
            if (result.errors.length > 0) {
              // Handle parsing errors if needed
            } else {
              const newResult = result.data.map((items) => {
                // Rename "coacheeType" to "student type"
                const updatedItems = { ...items, studentType: items.coacheeType };
                delete updatedItems.coacheeType;
                updatedItems.errorType = "Check for Important Notification Error";
                return updatedItems;
              });
              setJsonData(newResult);
              console.log("json", newResult);
              setNoFileError(false);
              setInvalidFileError(false);
              setGridData(newResult);
            }
          },
          header: true, // Assumes the first row is a header row
          skipEmptyLines: true,
        });
      }
    } else {
      setInvalidFileError(true);
    }
  };

  const handleUpload = () => {
    setGridData([]);
    setIsVerify(false);
    setIsUploaded(false);
    set_pageError("");
    inputRef.current?.click();
  };

  const columnDefs = [
    {
      headerName: "First Name",
      field: "firstName",
      width: 180,
      suppressMovable: true,
      showDisabledCheckboxes: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Last Name",
      field: "lastName",
      width: 180,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      field: "email",
      width: 300,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      tooltipField: "email",
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Coachee Type",
      field: "studentType",
      width: 200,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Important Notification",
      tooltipField: "errorType",
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      field: "errorType",
      cellStyle: (params) => {
        if (params.value !== "Valid Data") {
          //mark police cells as red
          return { color: "black", textAlign: "left" };
        }
        return null;
      },
      width: 600,
      suppressMovable: true,
    },
  ];
  const defaultColDef = useMemo(() => ({
    sortable: true,
    // flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const selectThemeData = useSelector((state) => state.colorTheme.colorThemeData);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">{/*<h4 className="hd-txt">Bulk Upload</h4> */}</div>

      <Row className="">
        <Col>
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={{}}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Upload CSV File</Form.Label>
                    <input ref={inputRef} type="file" className="hidden-content" onChange={handleCSVInputChange} />
                    <div className="d-flex mb-3 upload-div">
                      <Button variant="clr-trasparent-new" type="button" className="ms-lg-3" onClick={handleUpload}>
                        Add a file
                      </Button>
                      {csvSelected ? <div className="item">{csvSelected.name}</div> : null}
                    </div>
                    {noFileError ? (
                      <p id="errorObject" className="fieldError">
                        Select File to Upload
                      </p>
                    ) : null}
                    {invalidFileError ? (
                      <p id="errorObject" className="fieldError">
                        Upload CSV File Only
                      </p>
                    ) : null}
                    <div>
                      <a href={sampleTemplate1} download style={{ color: selectThemeData[0]?.CLR_PRIMARY }}>
                        Download Bulk Upload Example File
                      </a>
                    </div>
                    <div>
                      <a href={sampleTemplate} download style={{ color: selectThemeData[0]?.CLR_PRIMARY }}>
                        Download Empty Bulk Upload Template
                      </a>
                    </div>
                  </Form.Group>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
          {studentTypeList.length > 0 && (
            <Row className="px-4">
              <Form.Label className="px-2 bd-highlight">Coachee Types</Form.Label>
              <Row className="py-2 px-4">
                {studentTypeList.map((item) => (
                  <Col md={2} key={item._id} value={item._id} className="pill-student">
                    {item.typeCode}
                  </Col>
                ))}
              </Row>
            </Row>
          )}
          {!isUploaded ? (
            <>
              <Row className="mb-4 px-4">
                <Col>
                  {gridData.length ? (
                    <>
                      <Form.Label className=" bd-highlight pt-2">Selected Data</Form.Label>
                      <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
                        <AgGridReact
                          columnDefs={columnDefs}
                          defaultColDef={defaultColDef}
                          rowData={jsonData}
                          pagination={true}
                          paginationPageSize={10}
                          rowSelection={"multiple"}
                          onFirstDataRendered={onFirstDataRendered}
                          ref={gridRef}
                        />
                      </div>
                    </>
                  ) : (
                    <div>Please Select File</div>
                  )}
                </Col>
              </Row>
              {gridData.length ? (
                !isVerify ? (
                  <div className="row g-0 frm-btn-grp">
                    <Button variant="clr-primary" type="submit" onClick={Verify} className="col-lg-3 ms-lg-3">
                      Verify File
                    </Button>
                  </div>
                ) : (
                  <div className="row g-0 frm-btn-grp">
                    <Button
                      variant="clr-primary"
                      type="submit"
                      onClick={handleFormSubmit}
                      disabled={jsonData.filter((item) => item.errorType == "Valid Data").length == 0}
                      className="col-lg-3 ms-lg-3"
                    >
                      {jsonData.filter((item) => item.errorType == "Valid Data").length <= jsonData.length
                        ? jsonData.filter((item) => item.errorType == "Valid Data").length == 0
                          ? "No Valid Records "
                          : `Proceed With ${
                              jsonData.filter((item) => item.errorType == "Valid Data").length
                            } Valid Records `
                        : "Upload File"}
                    </Button>
                  </div>
                )
              ) : null}
            </>
          ) : (
            <Row className="mb-4 px-4">
              <Col>
                <Form.Label className=" bd-highlight pt-4">Uploaded Data</Form.Label>
                {updatedData.length ? (
                  <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
                    <AgGridReact
                      columnDefs={columnDefs}
                      defaultColDef={defaultColDef}
                      rowData={updatedData}
                      pagination={true}
                      paginationPageSize={10}
                      rowSelection={"multiple"}
                      onFirstDataRendered={onFirstDataRendered}
                      paginationNumberFormatter={paginationNumberFormatter}
                      ref={gridRef}
                    />
                  </div>
                ) : (
                  <div>No Data Added</div>
                )}
                <div className="page-size-wrap">
                  <div className="page-size">
                    Records Per Page:
                    <select onChange={onPageSizeChanged} id="page-size">
                      <option value="10">10</option>
                      <option value="50">25</option>
                      <option value="100">50</option>
                    </select>
                  </div>
                </div>
              </Col>
            </Row>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default BulkUpload;

import React from "react";
import { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { trackPromise } from "react-promise-tracker";
import { TopicsService } from "../../services";

const ViewTopics = ({ pageTitle }) => {
  const [topicDetails, set_topicDetails] = useState([]);
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchTopicDetails() {
      try {
        const resp = await trackPromise(TopicsService.getTopicById(id.id));
        const { data } = resp;
        if (data.success) {
          set_topicDetails(data.data);
        } else {
          set_pageError(data.message || "Error in fetching topic details");
        }
      } catch (err) {
        console.error("Fetch topic details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchTopicDetails();
  }, [id.id]);

  return (
    <div className="text-left">
      <h4 className="col hd-txt">View Topics</h4>
      <div className="col-md">
        <table className="view-org-table table table-borderless bg-transparent w-50">
          <tbody className="bg-transparent">
            <tr>
              <td>Topic Name</td>
              <td>{topicDetails?.topicName}</td>
            </tr>
            <tr>
              <td>Topic Description</td>
              <td>{topicDetails?.topicDescription}</td>
            </tr>
          </tbody>
        </table>
        <div className="g-0 frm-btn-grp  mt-4">
          <Button as={Link} to="/topics" variant="clr-transparent" className="col-lg me-lg-3">
            Cancel
          </Button>
          <Button as={Link} to={`/topics/edit/${id.id}`} variant="clr-primary" type="submit" className="col-lg ms-lg-3">
            Edit Topic
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewTopics;

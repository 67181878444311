import { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { setLocalData, clearAppData, getUserProfile } from "../../stores/store";
import { setToken } from "../../stores/authSlice";
import UserService from "../../services/user.service";
import "./Login.css";
import RegisterAcknowledge from "../Register/RegisterAcknowledge";
import TermsCondition from "../Register/TermsCondition";

function Login() {
  const dispatch = useDispatch();
  const [formObject, set_formObject] = useState(getFormObject());
  const [formError, set_formError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showAcknowledge, setShowAcknowledge] = useState(1);

  const nextScreen = (screenNumber) => {
    setShowAcknowledge(screenNumber);
  };

  function getFormObject() {
    return {
      username: "",
      password: "",
    };
  }

  const FormSchema = Yup.object().shape({
    username: Yup.string().required("Username is Required"),
    password: Yup.string().min(8, "Password Should be Minimum ${min} Characters").required("Password is Required"),
  });

  function handleInputChanged(event) {
    set_formObject({ ...formObject, [event.target.name]: event.target.value });
  }

  async function handleFormSubmit(values) {
    try {
      const resp = await trackPromise(UserService.login(values));
      const { data } = resp;
      if (data.success) {
        dispatch(setToken({ token: data.data.token }));
        setLocalData();
        getUserProfile();
      } else {
        set_formError(data.message || "Login failed");
        clearAppData();
      }
    } catch (err) {
      console.error("Login error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_formError(err.response.data.message);
        clearAppData();
      } else {
        set_formError(err.message);
        toast.error(err.message || "Login error");
      }
    }
  }

  return (
    <Formik initialValues={formObject} validationSchema={FormSchema} onSubmit={handleFormSubmit}>
      {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
        <Container fluid={true} className="login-container">
          <Row className="">
            <Col
              lg={{ span: 6, offset: 3 }}
              md={{ span: 8, offset: 2 }}
              sm={{ span: 10, offset: 1 }}
              xs={{ span: 12, offset: 1 }}
            >
              {showAcknowledge == 1 ? (
                <div className="login-wrap">
                  <div className="login-header">
                    <h3>Log in</h3>
                    <h6>Coach & Grow App</h6>
                  </div>

                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="frm-group" controlId="formUsername">
                      <Form.Label>Email Address or Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Email Address or Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={touched.username && errors.username}
                      />
                      <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group" controlId="formPassword">
                      <Form.Label>Password</Form.Label>

                      <InputGroup>
                        <Form.Control
                          type={showPassword ? "text" : "password"}
                          // type="password"
                          placeholder="Enter Password"
                          name="password"
                          value={values.password}
                          onChange={handleChange}
                          isInvalid={touched.password && errors.password}
                        />
                        <InputGroup.Text>
                          {!showPassword ? (
                            <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword((prevState) => !prevState)} />
                          ) : (
                            <FontAwesomeIcon
                              icon={faEyeSlash}
                              onClick={() => setShowPassword((prevState) => !prevState)}
                            />
                          )}
                        </InputGroup.Text>

                        <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                      </InputGroup>
                    </Form.Group>

                    <div className="d-flex bottom-text">
                      <Link to="/forgot-password" className="mb-2 action-lnk">
                        Forgot Password?
                      </Link>
                    </div>

                    <Row>
                      <Col lg={{ span: 10, offset: 1 }}>
                        <div className="d-flex">
                          <Button
                            variant="clr-primary"
                            type="submit"
                            disabled={isSubmitting}
                            className="mt-2 mb-2 login-btn"
                          >
                            Login
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <div className="bottom-text">
                      By clicking Login, you agree to our{" "}
                      <Link
                        onClick={() => {
                          nextScreen(5);
                        }}
                        className="action-lnk"
                      >
                        Terms & Conditions
                      </Link>
                      .
                    </div>
                    {formError ? <div className="text-danger">{formError}</div> : <></>}
                  </Form>
                </div>
              ) : null}
            </Col>
          </Row>
          {showAcknowledge == 2 ? <RegisterAcknowledge nextScreen={nextScreen} clickFlow={true} /> : null}
          {showAcknowledge == 5 ? <TermsCondition nextScreen={nextScreen} clickFlow={true} /> : null}
        </Container>
      )}
    </Formik>
  );
}

export default Login;

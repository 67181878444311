import React, { useState, useEffect } from "react";

import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Link, useNavigate, useParams } from "react-router-dom";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { toast } from "react-toastify";
import { ChangeRequestService } from "../../services";
import { Formik } from "formik";
import * as Yup from "yup";
import { copyObjectByKeys } from "../../utilities/functions";
import { Rating } from "react-simple-star-rating";
import { getUserProfile } from "../../stores/store";

function AddNewRequest({ pageTitle }) {
  const dispatch = useDispatch();
  const params = useParams();

  const [pageError, set_pageError] = useState("");
  const [requestId, set_requestId] = useState(params.id);
  const [requestDetails, set_requestDetails] = useState({});
  const navigate = useNavigate();

  const [formObject, set_formObject] = useState(getFormObject());

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    if (requestId) fetchRequestDetails();
  }, [requestId]);

  useEffect(() => {
    if (requestDetails.id) populateForm();
  }, [requestDetails.id]);

  function getFormObject() {
    return {
      requestReason: "",
      coachRating: 0,
    };
  }
  const FormSchema = Yup.object().shape({
    requestReason: Yup.string().required("Reason is Required."),
    coachRating: Yup.number().moreThan(0, "Coach Rating is Required.").required("Coach Rating is Required"),
  });

  async function fetchRequestDetails() {
    try {
      const resp = await trackPromise(ChangeRequestService.getRequestById(requestId));
      const { data } = resp;
      if (data.success) {
        set_requestDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Request details");
      }
    } catch (err) {
      console.error("Fetch request details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function handleFormSubmit(values) {
    const submitText = requestId ? "Updated" : "Created";
    try {
      const resp = requestId
        ? await trackPromise(ChangeRequestService.updateRequest(requestId, values))
        : await trackPromise(ChangeRequestService.createChangeRequest(values));

      const { data } = resp;
      if (data.success) {
        toast.success(`Coach Change Request ${submitText} Successfully.`);
        getUserProfile();
        navigate(-1);
      } else {
        set_pageError(data.message || `Failed to ${submitText} Change Reuest`);
      }
    } catch (err) {
      console.error("Change Reuest error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Change Reuest error");
    }
  }
  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, requestDetails);
    set_formObject({ ...formobj });
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Reason for Coach Change</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Reason</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      // style={{ backgroundColor: "var(--clr-transparent)" }}
                      name="requestReason"
                      value={values.requestReason}
                      onChange={handleChange}
                      isInvalid={touched.requestReason && errors.requestReason}
                    />
                    <Form.Control.Feedback type="invalid">{errors.requestReason}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Label>Rate Your Coach</Form.Label>
                  <Rating
                    className="ms-3"
                    onChange={handleChange}
                    name="coachRating"
                    onClick={(value) => setFieldValue("coachRating", value)}
                    initialValue={values?.coachRating}
                    fillColor="#B18C93"
                  />
                  {touched.coachRating && errors.coachRating && (
                    <div className="text-danger mt-1">{errors.coachRating}</div>
                  )}
                  <div className="d-flex request-btn ">
                    <Button as={Link} to="../coachChangeRequest" variant="clr-transparent" className="col-lg me-2">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-lg-3">
                      {requestId ? "Edit Request" : "Submit Request"}
                    </Button>
                  </div>
                  {pageError ? <div className="mt-3 text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AddNewRequest;

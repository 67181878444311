import React, { useEffect, useState } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { trackPromise } from "react-promise-tracker";
import { StudentTypeService } from "../../services";

const StudentTypeView = ({ pageTitle }) => {
  const [studentTypeDetails, set_studentTypeDetails] = useState([]);
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchStudentTypeDetails() {
      try {
        const resp = await trackPromise(StudentTypeService.getStudentTypeById(id?.id));
        const { data } = resp;
        if (data.success) {
          set_studentTypeDetails(data.data);
        } else {
          set_pageError(data.message || "Error in fetching topic details");
        }
      } catch (err) {
        console.error("Fetch topic details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchStudentTypeDetails();
  }, [id?.id]);

  return (
    <div className="text-left">
      <h4 className="col hd-txt">View Coachee Type</h4>
      <div className="col-md">
        <table className="view-org-table table table-borderless bg-transparent w-50">
          <tbody className="bg-transparent">
            <tr>
              <td>Type Name</td>
              <td>{studentTypeDetails?.typeName}</td>
            </tr>
            <tr>
              <td>Type Code</td>
              <td>{studentTypeDetails?.typeCode}</td>
            </tr>
            <tr>
              <td>Accreditation</td>
              <td>
                {studentTypeDetails?.accreditation?.levelName ? studentTypeDetails?.accreditation?.levelName : ""}
              </td>
            </tr>
          </tbody>
        </table>
        <div className="g-0 frm-btn-grp  mt-4">
          <Button as={Link} to="/coachee/type" variant="clr-transparent" className="col-lg me-lg-3">
            Cancel
          </Button>
          <Button
            as={Link}
            to={`/coachee/type/edit/${id.id}`}
            variant="clr-primary"
            type="submit"
            className="col-lg ms-lg-3"
          >
            Edit Coachee
          </Button>
        </div>
      </div>
    </div>
  );
};

export default StudentTypeView;

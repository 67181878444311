import httpClient from "../httpClient";
const baseSlug = "/topic";

class httpService {
  getAll() {
    return httpClient.get(`${baseSlug}`);
  }

  getList() {
    return httpClient.get(`${baseSlug}/list`);
  }

  getTrending() {
    return httpClient.get(`${baseSlug}/trending`);
  }

  createTopics(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  getTopicById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  updateTopic(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  delete(id) {
    return httpClient.put(`${baseSlug}/delete/${id}`);
  }
}

export default new httpService();

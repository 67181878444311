import httpClient from "../httpClient";
const baseSlug = "/emailTemplate";

class httpService {
  getEmailTemplateTypes() {
    return httpClient.get(`${baseSlug}/types`);
  }
  getOrgEmailTemplateType(type) {
    return httpClient.get(`${baseSlug}/forOrgByType/${type}`);
  }
  getOrgEmailTemplates() {
    return httpClient.get(`${baseSlug}/forOrg`);
  }

  getOrgEmailTemplatesById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }
  createOrgTemplate(data) {
    return httpClient.post(`${baseSlug}/forOrg`, data);
  }
  updateOrgTemplate(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }
  deleteTemplate(id) {
    return httpClient.put(`${baseSlug}/delete/${id}`);
  }
}

export default new httpService();

import { useState, useEffect, useRef } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { UserService } from "../../services";
import { copyObjectByKeys } from "../../utilities/functions";
import PillWithDelete from "../CustomComponent/PillBadge";
import { getUserProfile } from "../../stores/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function ProfileCreate({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [profileDetails, set_profileDetails] = useState({});
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [profilePic, setProfilePic] = useState();
  const [customError, setCustomError] = useState({});
  const inputRef = useRef();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (profileDetails.id) populateForm();
  }, [profileDetails.id]);

  function getFormObject() {
    return {
      username: "",
      firstName: "",
      lastName: "",
      email: "",
    };
  }

  const FormSchema = Yup.object().shape({
    username: Yup.string().required("Username is Required"),
    firstName: Yup.string().required("First name is Required"),
    lastName: Yup.string().required("Last name is Required"),
    email: Yup.string().email("Email is invalid").required("Email is Required"),
  });

  async function fetchProfileData() {
    try {
      const resp = await trackPromise(UserService.profile());
      const { data } = resp;

      if (data.success) {
        set_profileDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching profile");
      }
    } catch (err) {
      console.error("Fetch profile catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, profileDetails);
    set_formObject({ ...formobj });
  }

  async function handleFormSubmit(values) {
    const newValue = { profileImage: profilePic, ...values };
    const submitText = "updated";
    try {
      const resp = await trackPromise(
        UserService.updateProfile(newValue, { headers: { "Content-Type": "multipart/form-data" } })
      );
      const { data } = resp;
      if (data.success) {
        toast.success(`Profile ${submitText} successfully.`);
        getUserProfile();
        navigate(-1);
      } else {
        set_pageError(data.message || `Failed to ${submitText} Profile`);
      }
    } catch (err) {
      console.error("Profile error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Profile error");
    }
  }

  const handleProfilePic = () => {
    if (inputRef.current.files) {
      setProfilePic(inputRef.current.files[0]);
    }
  };
  const handleUpload = () => {
    inputRef.current?.click();
  };

  const addCustomErrors = (errors) => {
    setCustomError((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  async function isUniqueCheck(value) {
    try {
      const resp = await trackPromise(UserService.isFieldUnique(value));
      const { data } = resp;
      if (value.username) {
        addCustomErrors({ username: data.data.isUnique ? "" : "Username Already Exist" });
      } else if (value.email) {
        addCustomErrors({ email: data.data.isUnique ? "" : "Email Address Already Exist" });
      }
    } catch {
      if (value.username) {
        addCustomErrors({ username: "" });
      } else if (value.email) {
        addCustomErrors({ email: "" });
      }
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Edit Profile Details</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="User Name"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ username: value.target.value, userId: profileDetails?.id })}
                      isInvalid={(touched.username && errors.username) || customError.username}
                      // isInvalid={touched.username && errors.username}
                    />
                    {/* <Form.Control.Feedback type="invalid">{errors.userName}</Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid">
                      {errors.username || customError.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={touched.firstName && errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={touched.lastName && errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="frm-group mb-4">
                    <Form.Label>Email Address</Form.Label>

                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      // isInvalid={touched.email && errors.email}
                      onBlur={(value) => isUniqueCheck({ email: value.target.value, userId: profileDetails?.id })}
                      isInvalid={(touched.email && errors.email) || customError.email}
                    />
                    {/* <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid">{errors.email || customError.email}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Row>
                      <Col className=" d-flex">
                        <Form.Label>Upload Profile Picture</Form.Label>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip02}>
                          <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                        </OverlayTrigger>
                      </Col>
                    </Row>

                    <input ref={inputRef} type="file" className="hidden-content" onChange={handleProfilePic} />
                    <div className="d-flex mb-3 upload-div">
                      <Button variant="clr-trasparent-new" type="button" className="ms-lg-3" onClick={handleUpload}>
                        Add a file
                      </Button>
                      {profilePic ? <div className="item">{profilePic.name}</div> : null}
                    </div>
                    <div className="item">
                      {profileDetails?.profileImage ? (
                        <PillWithDelete
                          text={profileDetails?.profileImage}
                          hideDelete={true}
                          assetsDir={profileDetails.assetsDir}
                        />
                      ) : null}
                    </div>
                  </Form.Group>

                  <div className="row g-0 frm-btn-grp gap-2">
                    <Button as={Link} to="/admin" variant="clr-transparent" className="col-lg me-lg-3">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-lg-3">
                      Edit
                    </Button>
                  </div>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ProfileCreate;

const RenderTooltip02 = (props) => (
  <Tooltip {...props}>Select proper format and file size must be less than 1 MB.</Tooltip>
);

import { DayPicker } from "react-day-picker";
import "./wizard.css";
import "react-day-picker/dist/style.css";
import { useState } from "react";
import { formatDate } from "../../utilities/functions";
import { useSelector } from "react-redux";

function CustomizedCalender(props) {
  const {
    bookedDates,
    selectedDate,
    bookDatesByStudent,
    endDate,
    setSelectedDate = () => {},
    handleEventMonthChange = () => {},
  } = props;
  const [localDate, setLocalDate] = useState(selectedDate);
  const [tooltip, setTooltip] = useState(null);
  const { authState } = useSelector((state) => state);
  const role = authState?.user?.role?.roleCode;
  const onDayClick = (e) => {
    setLocalDate(e);
  };

  const Tooltip = ({ text, position }) => (
    <div
      style={{
        position: "absolute",
        zIndex: 1000,
        backgroundColor: "white",
        padding: "5px",
        border: "1px solid #ccc",
        width: "200px",
        textAlign: "center",
        ...position,
      }}
    >
      {text}
    </div>
  );
  const handleDayMouseEnter = (day, modifiers, e) => {
    const filterTotalData = bookedDates?.filter((item) => formatDate(item) === formatDate(day));
    const filterBookedData = bookDatesByStudent?.filter((item) => formatDate(item) === formatDate(day));
    const tooltipText = `Total ${filterTotalData?.length} Slot/s ${filterBookedData?.length} Booked`;
    const dayPosition = e.target.getBoundingClientRect();
    setTooltip({ text: tooltipText, position: { top: dayPosition.bottom, left: dayPosition.left } });
  };

  const handleDayMouseLeave = () => {
    setTooltip(null);
  };

  return (
    <>
      {tooltip && <Tooltip text={tooltip.text} position={tooltip.position} />}
      <DayPicker
        {...props}
        selected={localDate}
        onSelect={(date) => setSelectedDate(date)}
        onDayClick={(e) => onDayClick(e)}
        mode="single"
        selectedDays={localDate}
        disabledDays={[{ before: new Date() }]}
        className="react-day-picker coachee-cal"
        modifiers={{ booked: bookedDates, disabled: [{ before: new Date() }, { after: new Date(endDate) }] }}
        pagedNavigation
        numberOfMonths={1}
        fromDate={new Date()}
        modifiersClassNames={{
          selected: "my-selected",
          booked: "my-available",
        }}
        onMonthChange={handleEventMonthChange}
        onDayMouseEnter={role === "coach" ? handleDayMouseEnter : null}
        onDayMouseLeave={role === "coach" ? handleDayMouseLeave : null}
      />
    </>
  );
}

export default CustomizedCalender;

import { configureStore } from "@reduxjs/toolkit";
import { trackPromise } from "react-promise-tracker";
import authReducer, { initAuthState, setAuth, setToken, setUser } from "./authSlice";
import appReducer, { setRouteData } from "./appSlice";
import UserService from "../services/user.service";
import { ChatService } from "../services";
import FcmService from "../services/fcmToken.service";
import colorThemeReducer from "./colorThemeSlice";
import { USER_ROLES } from "../AppRoutes";
const LOCAL_KEY = "COACHING_APP_DATA";

const store = configureStore({
  reducer: {
    authState: authReducer,
    appState: appReducer,
    colorTheme: colorThemeReducer,
  },
});

(function initLoad() {
  const localData = localStorage.getItem(LOCAL_KEY)
    ? JSON.parse(localStorage.getItem(LOCAL_KEY))
    : { authToken: undefined };

  if (localData.authToken) {
    store.dispatch(setToken({ token: localData.authToken }));
    getUserProfile();
  } else {
    store.dispatch(setAuth({ isAuthenticated: false }));
  }
})();

export async function getUserProfile() {
  try {
    const resp = await trackPromise(UserService.profile());
    const { data } = resp;

    if (data.success) {
      const { role, agoraUUID } = data.data;

      if ((role.roleCode === USER_ROLES.coach || role.roleCode === USER_ROLES.student) && !agoraUUID) {
        const updatedAgoraUUID = await registerUser();

        const updatedUserData = { ...data.data, agoraUUID: updatedAgoraUUID };
        console.log("data", updatedUserData);

        store.dispatch(setAuth({ isAuthenticated: true }));
        store.dispatch(setUser({ user: updatedUserData }));
      } else {
        store.dispatch(setAuth({ isAuthenticated: true }));
        store.dispatch(setUser({ user: data.data }));
        console.log(data.data ,"userdata");
      }
    } else {
      clearAppData();
    }
  } catch (err) {
    console.error("User Profile error catch => ", err);
    clearAppData();
  }
}
async function registerUser() {
  try {
    const resp = await trackPromise(ChatService.registerUser());
    const { data } = resp;
    if (data.success) {
      const agoraUUID = data.data.agoraUUID;
      console.log("a", agoraUUID);
      return agoraUUID;
      // set_Details(data.data);
    } else {
      console.error(data.message || "Error in fetching register details");
    }
  } catch (err) {
    console.error("Fetch register details catch => ", err);
  }
}

export async function deleteUserFcmToken(obj) {
  try {
    const resp = await trackPromise(FcmService.deleteToken(obj));
    const { data } = resp;
    if (data.success) {
      console.log("User Fcm token deleted successfully");
    } else {
      console.error("Error in deleting User Fcm token");
    }
  } catch (err) {
    console.error("User Fcm token delete catch => ", err);
  }
}

export function setLocalData() {
  localStorage.setItem(LOCAL_KEY, JSON.stringify({ authToken: store.getState().authState.token }));
}

export function removeLocalData() {
  if (localStorage.getItem(LOCAL_KEY)) localStorage.removeItem(LOCAL_KEY);
}

export async function clearAppData() {
  if (store.getState().authState.token){
    let deviceId=localStorage?.getItem("deviceId")??null;
    await deleteUserFcmToken(deviceId)};
  localStorage?.removeItem("deviceId");
  store.dispatch(initAuthState());
  removeLocalData();
}

export default store;

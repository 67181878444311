import httpClient from "../httpClient";
const baseSlug = "/coachRating";

class httpService {
  createCoachRating(data) {
    return httpClient.post(`${baseSlug}`, data);
  }
  updateCoachRating(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  getCoachRatingByBooking(id) {
    return httpClient.get(`${baseSlug}/booking/${id}`);
  }

  resetCoachRating(id, data) {
    return httpClient.put(`${baseSlug}/reset/${id}`, data);
  }
  getCoachRating(id, topicId) {
    return httpClient.get(`${baseSlug}/${id}/topic/${topicId}`);
  }
}

export default new httpService();

import httpClient from "../httpClient";
const baseSlug = "/moodleCourse";

class httpService {
  getAll() {
    return httpClient.get(`${baseSlug}`);
  }
  syncCouses() {
    return httpClient.get(`${baseSlug}/sync`);
  }
  getEnrolmentsByStudent(id) {
    return httpClient.get(`/courseEnrolment/student/${id}`);
  }
  createCourseEnrolment(data) {
    return httpClient.post(`/courseEnrolment`, data);
  }
  getUserEnrolments() {
    return httpClient.get(`/courseEnrolment/userCourses`);
  }
  getMoodleResource() {
    return httpClient.get(`/courseEnrolment/getWebLink`);
  }
}

export default new httpService();

import React, { useEffect } from "react";
import { Chart as ChartJS, ArcElement, Tooltip, Legend } from "chart.js";
import { Pie } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { getColorThemeData } from "../../../stores/colorThemeSlice";

ChartJS.register(ArcElement, Tooltip, Legend);

export const options = {
  plugins: {
    legend: {
      position: "top",
      align: "end",
    },
  },
};

// export const data = {
//   labels: ["active", "inactive"],
//   datasets: [
//     {
//       label: "Usage",
//       data: [12, 19],
//       backgroundColor: ["#D6C5C8", "#B18C93"],
//       borderWidth: 1,
//     },
//   ],
// };

export default function ReportAdminPie(props) {
  const { legends, countData } = props;

  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);
  const disptch = useDispatch();

  const colorThemeData = useSelector((state) => state?.colorTheme?.colorThemeData);

  useEffect(() => {
    disptch(getColorThemeData(colorTheme));
  }, [colorTheme, disptch]);

  const data = {
    labels: ["active", "inactive"],
    datasets: [
      {
        label: "Users",
        data: [countData?.active, countData?.inActive],
        backgroundColor:
          colorThemeData.length !== 0
            ? [colorThemeData[0]?.CLR_CHART1, colorThemeData[0]?.CLR_CHART2]
            : ["#D6C5C8", "#B18C93"],

        borderWidth: 1,
      },
    ],
  };
  return (
    <div
      style={{
        width: "100%",
        height: 300,
        borderWidth: 1,
        borderColor:
          colorThemeData.length !== 0
            ? [colorThemeData[0]?.CLR_CHART1, colorThemeData[0]?.CLR_CHART2]
            : ["#D6C5C8", "#B18C93"],

        // background: [colorThemeData[0]?.CLR_PRIMARY, colorThemeData[0]?.CLR_PRIMARY_LIGHT] ?? ["#D6C5C8", "#B18C93"],
        display: "flex",
        justifyContent: "center",
      }}
    >
      <Pie data={data} options={options} />
    </div>
  );
}

import React, { useEffect, useState } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { OrgTypeService } from "../../services";

const OrgTypeView = ({ pageTitle }) => {
  const [orgTypData, setOrgTypeData] = useState([]);
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchOrgTypeDetails() {
      try {
        const resp = await trackPromise(OrgTypeService.getOrgTypeById(id.id));
        const { data } = resp;
        if (data.success) {
          setOrgTypeData(data.data);
        } else {
          set_pageError(data.message || "Error in fetching organisation type details");
        }
      } catch (err) {
        console.error("Fetch organisation type details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchOrgTypeDetails();
  }, [id.id]);

  return (
    <div className="text-left">
      <h4 className="col hd-txt">View Organisation Type</h4>
      <div className="col-md">
        <table className="view-org-table table table-borderless bg-transparent ">
          <tbody className="bg-transparent">
            <tr>
              <td style={{ width: "200px" }}>Type Name</td>
              <td>{orgTypData?.typeName}</td>
            </tr>
            <tr>
              <td style={{ width: "200px" }}>Type Code</td>
              <td>{orgTypData?.typeCode}</td>
            </tr>
          </tbody>
        </table>
        <div className="g-0 frm-btn-grp mt-4">
          <Button as={Link} to="/org/type" variant="clr-transparent" className="col-lg me-2">
            Cancel
          </Button>
          <Button as={Link} to={`/org/type/edit/${id.id}`} variant="clr-primary" type="submit" className="col-lg ms-2">
            Edit Organisation Type
          </Button>
        </div>
      </div>
    </div>
  );
};

export default OrgTypeView;

import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { trackPromise } from "react-promise-tracker";
import { BookingServices } from "../../services";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { formatDate, formatTime } from "../../utilities/functions";
import { Link } from "react-router-dom";

function CoachAppointment({ pageTitle }) {
  const dispatch = useDispatch();

  const [appointmentList, set_appointmentList] = useState([]);
  const [pageError, set_pageError] = useState("");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));

    fetchAppointments();
  }, []);

  async function fetchAppointments() {
    try {
      const resp = await trackPromise(BookingServices.getUpcomingSlots());
      const { data } = resp;
      if (data.success) {
        set_appointmentList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  return (
    <Container fluid={true} className="main-content d-flex flex-sm-row flex-column">
      <Row className="col-sm-4">
        <Col>
          {appointmentList.map((apnt, index) => (
            <Row key={index} className="inner-card-border my-3 ">
              <Row className="mx-1 ms-2">
                {`${apnt.topic.topicName} | ${apnt.student.firstName} 
                        ${apnt.student.lastName}`}
              </Row>
              <div className="d-flex justify-content-start flex-row mx-2">
                <div>
                  <FontAwesomeIcon icon={faCalendar} />
                </div>
                <div className="mx-2">{formatDate(apnt.fromDateTime)}</div>
              </div>
              <div className="d-flex justify-content-start flex-row mx-2">
                <div>
                  <FontAwesomeIcon icon={faClock} />
                </div>
                <div className="mx-2">
                  {formatTime(apnt?.fromDateTime)} - {formatTime(apnt?.toDateTime)}
                </div>
              </div>
              <div className="my-1">
                <Button
                  as={Link}
                  to={`/coacheeProgress/appointment/${apnt?.booking}/${apnt?.id}`}
                  variant="clr-primary"
                  className="btn-add mx-1"
                >
                  View Appointment
                </Button>
              </div>
            </Row>
          ))}
        </Col>
      </Row>
    </Container>
  );
}

export default CoachAppointment;

import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { copyObjectByKeys } from "../../utilities/functions";
import { StudentTypeService, AccreditationService } from "../../services";

function StudentTypeCreate({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [accredLevelList, set_accredLevelList] = useState([]);
  const [studentTypeId, set_studentTypeId] = useState(params.id);
  const [studentTypeDetails, set_studentTypeDetails] = useState({});
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchAccreditationList();
  }, []);

  useEffect(() => {
    if (studentTypeId) fetchStudentTypeDetails();
  }, [studentTypeId]);

  useEffect(() => {
    if (studentTypeDetails.id) populateForm();
  }, [studentTypeDetails.id]);

  function getFormObject() {
    return {
      typeName: "",
      typeCode: "",
      accreditation: "",
    };
  }

  const FormSchema = Yup.object().shape({
    typeName: Yup.string().required("Type Name is Required"),
    typeCode: Yup.string().required("Type Code is Required"),
    accreditation: Yup.string().required("Select an Accredition Level"),
  });

  async function fetchAccreditationList() {
    try {
      const resp = await trackPromise(AccreditationService.getList());
      const { data } = resp;
      if (data.success) {
        set_accredLevelList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Accreditation List");
      }
    } catch (err) {
      console.error("Fetch Accreditation List catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchStudentTypeDetails() {
    try {
      const resp = await trackPromise(StudentTypeService.getStudentTypeById(studentTypeId));
      const { data } = resp;
      if (data.success) {
        set_studentTypeDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topic details");
      }
    } catch (err) {
      console.error("Fetch topic details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, studentTypeDetails);
    formobj.accreditation = studentTypeDetails.accreditation?.id;
    set_formObject({ ...formobj });
  }

  async function handleFormSubmit(values) {
    const submitText = studentTypeId ? "updated" : "created";
    try {
      const resp = studentTypeId
        ? await trackPromise(StudentTypeService.updateStudentType(studentTypeId, values))
        : await trackPromise(StudentTypeService.createStudentType(values));

      const { data } = resp;
      if (data.success) {
        toast.success(`Coachee Type ${submitText} successful!`);
        navigate(-1);
      } else {
        set_pageError(data.message || `Coachee Type ${submitText} failed`);
      }
    } catch (err) {
      console.error("Coachee Type error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || `Coachee Type ${submitText} error`);
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{studentTypeId ? "Edit" : "Add"} Coachee Type</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Type Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Type Name"
                      name="typeName"
                      value={values.typeName}
                      onChange={handleChange}
                      isInvalid={touched.typeName && errors.typeName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.typeName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Type Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Type Code"
                      name="typeCode"
                      value={values.typeCode}
                      onChange={handleChange}
                      isInvalid={touched.typeCode && errors.typeCode}
                    />
                    <Form.Control.Feedback type="invalid">{errors.typeCode}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Accreditation</Form.Label>
                    <Form.Select
                      name="accreditation"
                      value={values.accreditation}
                      onChange={handleChange}
                      isInvalid={touched.accreditation && errors.accreditation}
                    >
                      <option>Select Accreditation</option>
                      {accredLevelList?.length ? (
                        accredLevelList.map((itm, inx) => (
                          <option key={inx} value={itm._id}>
                            {itm.levelName}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.accreditation}</Form.Control.Feedback>
                  </Form.Group>

                  <div className="d-flex justify-content-center mb-3">
                    <Button as={Link} to="/coachee/type" variant="clr-transparent" className="col-lg me-2">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-2">
                      {studentTypeId ? "Edit" : "Add"}
                    </Button>
                  </div>

                  {pageError ? <div className="mt-3 text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default StudentTypeCreate;

import { useState } from "react";
import DatePicker from "react-datepicker";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";
import moment from "moment";

const Timepicker = ({ field, form, isInvalid, errorClass, ...props }) => {
  return (
    <div>
      <DatePicker
        {...field}
        {...props}
        selected={field.value}
        onChange={(date) => {
          const selected = moment(date).format("hh:mm a");

          form.setFieldValue(field.name, date);
        }}
        showTimeSelect
        showTimeSelectOnly
        timeIntervals={15}
        timeCaption="Time"
        dateFormat="h:mm a"
        className="custom-datepicker-input"
      />

      {isInvalid ? (
        <p id="errorObject" className={errorClass}>
          {isInvalid}
        </p>
      ) : (
        <></>
      )}
    </div>
  );
};

export default Timepicker;

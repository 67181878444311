import React from "react";
import { Button } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowRightFromBracket } from "@fortawesome/free-solid-svg-icons";
import { clearAppData } from "../../stores/store";
// import { THEME } from "../../utilities/theme";

function Footer({ isCollapsed, newTheme }) {
  return (
    <footer className="view-sidenav-footer">
      <Button variant="link" onClick={clearAppData}>
        <FontAwesomeIcon
          size="lg"
          icon={faArrowRightFromBracket}
          color={newTheme?.CLR_PRIMARY}
          title="Sidenav Logout"
          className="mr-3"
        />
        {isCollapsed ? <></> : <span className="btn-text">Logout</span>}
      </Button>
    </footer>
  );
}

export default Footer;

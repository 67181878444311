import { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import moment from "moment";
import { faEye, faPencil, faTrashCan, faTimes, faCheck } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setRouteData } from "../../stores/appSlice";
import { OrganisationService } from "../../services";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteListModal from "../CustomComponent/DeleteListModal";

function OrganisationList({ pageTitle }) {
  const dispatch = useDispatch();
  const [orgList, set_orgList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [setError, set_Error] = useState();
  const [confirmData, setconfirmData] = useState(false);

  // var checkboxSelection = function (params) {
  //   return params.columnApi.getRowGroupColumns().length === 0;
  // };

  // var headerCheckboxSelection = function (params) {
  //   return params.columnApi.getRowGroupColumns().length === 0;
  // };

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchOrganisations();
  }, []);

  async function fetchOrganisations() {
    try {
      const resp = await trackPromise(OrganisationService.getAll());
      const { data } = resp;

      let orgList = data?.data?.filter((itm) => !itm?.isDeleted);

      set_orgList(orgList);
    } catch (err) {
      console.error("Fetch organisations catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function deleteOrgApi(id) {
    const requestData = {
      confirmToDelete: confirmData,
    };
    try {
      const resp = await trackPromise(OrganisationService.delete(id, requestData));
      const { data } = resp;

      if (data?.success) {
        if (data.data.serverConfirmation) {
          set_Error(data.message);

          setconfirmData(true);

          //await trackPromise(UserService.deleteStudent(id, { confirmToDelete: true }));
        } else {
          toast.success("Organisation Deleted Sucessfully.");
          closeDeleteModal();
        }
        await fetchOrganisations();

        //toast.success("Cocheee Deleted Sucessfully");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        setTimeout(() => {
          closeDeleteModal();
        }, 5000);
        toast.error(err.response.data.message || "Delete error");
        set_Error(err.response.data.message);
      } else {
        set_Error(err.response.data.message);
      }
    }
  }

  async function deleteOrg(id) {
    deleteOrgApi(id, confirmData);
    if (confirmData) {
      closeDeleteModal();
    }
  }

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
    set_Error("");
    setconfirmData(false);
  };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "orgName",
      headerName: "Name",
      // width: 200,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Type",
      // width: 130,
      field: "orgType.typeName",
      tooltipField: "orgType.typeName",
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
      // cellRenderer: function (params) {
      //   // Check if "orgType" exists and has a "typeName"
      //   if (params.data.orgType && params.data.orgType.typeName) {
      //     return params.data.orgType.typeName;
      //   } else {
      //     return ""; // Return an empty string if no typeName is available
      //   }
      // },
    },

    // {
    //   field: "minSessionCount",
    //   headerName: "Min Sessions",
    //   // width: 120,
    //   suppressMovable: true,
    //   filter: true,
    //   filterParams: {
    //     buttons: ["reset"],
    //     closeOnApply: true,
    //   },
    //   cellStyle: { textAlign: "center" },
    // },
    {
      headerName: "Subscription Type",
      field: "subscription.scheme",
      // width: 180,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      valueFormatter: (params, e) => (params.value === "distributed" ? "Usage Based" : "Fixed"),
      cellStyle: { textAlign: "center" },
    },
    {
      field: "studentCount",
      headerName: "Coachees",
      suppressMovable: true,
      // width: 100,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      field: "startDate",
      headerName: "Start Date",
      // width: 120,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD/MM/YY");
      },
    },
    {
      field: "endDate",
      headerName: "End Date",
      // width: 120,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
      cellRenderer: (data) => {
        return moment(data.value).format("DD/MM/YY");
      },
    },

    {
      field: "action",
      headerName: "Action",
      suppressMovable: true,
      sortable: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        return (
          <>
            <Link className="grid_action" to={`/organisation/view/${params.data.id}`}>
              <FontAwesomeIcon icon={faEye} title="View" color="#3d3e45" />
            </Link>

            <Link className="grid_action" to={`/organisation/edit/${params.data.id}`}>
              <FontAwesomeIcon
                icon={faPencil}
                color="#3d3e45" // Disable icon if isDelete is true
                title="Edit"
              />
            </Link>

            <Link className="grid_action" to="">
              <FontAwesomeIcon
                icon={faTrashCan}
                title="Delete"
                color="#3d3e45"
                onClick={() => {
                  openDeleteModal(params?.data?.id);
                }}
              />
            </Link>
          </>
        );
      },
    },
  ]);

  // DefaultColDef sets props common to all Columns
  const defaultColDef = useMemo(() => ({
    sortable: true,
    // tooltipComponent: CustomTooltip,
    // filter: true,
    // flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  //Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <>
      <Container fluid={true} className="main-content">
        <div className="page-heading">
          <h4 className="col hd-txt">Organisation List</h4>
          <Button as={Link} to="create" variant="clr-primary" className="me-2">
            Add New
          </Button>
        </div>

        <Row className="">
          <Col lg="12">
            <div className="ag-theme-alpine" style={{ width: "100%", height: 550 }}>
              <AgGridReact
                id="myGrid"
                className="myGrid"
                ref={gridRef} // Ref for accessing Grid's API
                rowData={orgList} // Row Data for Rows
                columnDefs={columnDefs} // Column Defs for Columns
                defaultColDef={defaultColDef} // Default Column Properties
                animateRows={true} // Optional - set to 'true' to have rows animate when sorted
                rowSelection="multiple" // Options - allows click selection of rows
                rowMultiSelectWithClick={true}
                pagination={true}
                paginationPageSize={10}
                paginationNumberFormatter={paginationNumberFormatter}
                onFirstDataRendered={onFirstDataRendered}
                // onSelectionChanged={onSelectionChanged}
              />
            </div>
            <div className="page-size-wrap">
              <div className="page-size">
                Records Per Page:
                <select onChange={onPageSizeChanged} id="page-size">
                  <option value="10">10</option>
                  <option value="25">25</option>
                  <option value="50">50</option>
                </select>
              </div>
            </div>
          </Col>
        </Row>

        {/* Render the DeleteListModal component */}
        {selectedItem && (
          <DeleteListModal
            id={selectedItem}
            isOpen={isDeleteModalOpen}
            onClose={closeDeleteModal}
            deleteItem={deleteOrg}
            pageError={setError}
          />
        )}
      </Container>
    </>
  );
}

export default OrganisationList;

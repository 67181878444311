import { useState, useEffect, useRef } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { TopicsService, UserService } from "../../services";
import "./Register.css";
import SelectField from "../CustomComponent/Select";
import RegisterAcknowledge from "./RegisterAcknowledge";
import ThankYou from "./ThankYou";
import WelcomeScreen from "./WelcomeScreen";
import CoachAgreement from "../Agreement/CoachAgreement";
import TermsConditon from "./TermsCondition";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { documentSize, videoSize } from "../../envConfig";

function CoachRegister() {
  const [navigateTo, set_navigateTo] = useState("");
  const [topicList, set_topicList] = useState([]);
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [savedForm, set_savedForm] = useState({});
  const [clickFlow, set_clickFlow] = useState(false);
  const [regProfilePic, setRegProfilePic] = useState();
  const [regCertificates, setRegCertificates] = useState([]);
  const [customError, setCustomError] = useState({});
  const [regAboutMe, setRegAboutMe] = useState();

  const navigate = useNavigate();

  const inputRef = useRef();
  const multipleInputRef = useRef();
  const inputRefAbout = useRef();

  const [showAcknowledge, setShowAcknowledge] = useState(0);
  // const [contract, setcontract] = useState(false);
  const [agreement, setagreement] = useState(false);

  const nextScreen = (screenNumber) => {
    setShowAcknowledge(screenNumber);
  };

  useEffect(() => {
    fetchTopics();
  }, []);

  const addCustomErrors = (errors) => {
    setCustomError((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  function getFormObject() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      phone: "",
      email: "",
      bankCode: "",
      bankBranchNumber: "",
      bankAccountNumber: "",
      skills: "",
      topic: [],
      aboutSelf: "",
      experienceAchieved: "",
    };
  }

  const FormSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    username: Yup.string().required("Username is Required"),
    phone: Yup.string()
      .matches(/^[0-9]+$/, "Contact number must only contain numbers")
      .required("Contact Number is Required"),
    email: Yup.string().email("Email is invalid").required("Email is Required"),
    bankCode: Yup.string().required("Bank Code is Required"),
    bankBranchNumber: Yup.string().required("Bank Branch Number is Required"),
    bankAccountNumber: Yup.string().required("Bank Account Number is Required"),
    skills: Yup.string().required("Additional Qualifications and Credentials is Required"),
    topic: Yup.array().min(1, "Select at least one option").required("Select at least one option"),
    aboutSelf: Yup.string().required("Professional Biography info is Required"),
    experienceAchieved: Yup.string().required("Experience and Achievement info is Required"),
  });

  async function fetchTopics() {
    try {
      const resp = await trackPromise(TopicsService.getList());
      const { data } = resp;
      if (data.success) {
        set_topicList(
          data.data.map((topics) => {
            return { value: topics.id, label: topics.topicName };
          })
        );
      } else {
        set_pageError(data.message || "Error in fetching topics categories");
      }
    } catch (err) {
      console.error("Fetch coach topic catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function saveFormValue(values) {

    let size=100;
    if (!regProfilePic) {
      addCustomErrors({ regProfilePic: "Profile Image is Required." });
    } else if (regCertificates.length < 1) {
      addCustomErrors({ regCertificates: "Certificate is Required." });
    } else if (!regAboutMe) {
      addCustomErrors({ regAboutMe: "About Me Video is Required." });
    } else if (!isValidImage(regProfilePic)) {
      addCustomErrors({ regProfilePic: "Invalid file format. Only JPG, JPEG, and PNG files are allowed." });
    } else if (regProfilePic.size > (documentSize * 1024 * 1024)) {
      addCustomErrors({ regProfilePic: `Image size must be less than ${documentSize}MB.` });
    } else if (!isValidCertificateFormat(regCertificates)) {
      addCustomErrors({ regCertificates: "Invalid file format. Only DOC, DOCX, and PDF files are allowed." });
    } else if (regCertificates.some((cert) => cert.size >(documentSize * 1024 * 1024))){
      addCustomErrors({ regCertificates: `Certificate size must be less than ${documentSize}MB.` });
    } else if (regAboutMe.type !== "video/mp4") {
      addCustomErrors({ regAboutMe: "Invalid file format. Only MP4 files are allowed." });
    } else if (regAboutMe.size > (videoSize * 1024 * 1024)) {
      addCustomErrors({ regAboutMe: `Video size must be less than ${videoSize}MB.` });
    } else {
      if (checkCustomError()) {
        set_savedForm(values);
        set_clickFlow(false);
        nextScreen(2);
      }
    }
  }

  // Helper functions to check file formats
  function isValidImage(file) {
    const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
    return file && allowedFormats.includes(file.type);
  }

  function isValidCertificateFormat(files) {
    const allowedFormats = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    return files.every((file) => allowedFormats.includes(file.type));
  }

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleProfilePic = () => {
    if (inputRef.current.files) {
      setRegProfilePic(inputRef.current.files[0]);
      addCustomErrors({ regProfilePic: "" });
    }
  };

  const handleAboutmeUpload = () => {
    inputRefAbout.current?.click();
  };

  const handleAboutMe = () => {
    if (inputRefAbout.current.files) {
      setRegAboutMe(inputRefAbout.current.files[0]);
      addCustomErrors({ regAboutMe: "" });
    }
  };

  const handleMultiUpload = () => {
    multipleInputRef.current?.click();
  };

  const handleCertificatesPic = () => {
    if (multipleInputRef.current.files) {
      setRegCertificates([...multipleInputRef.current.files]);
      addCustomErrors({ regCertificates: "" });
    }
  };

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (typeof value === "object" && !value.uri) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  }

  async function handleFormSubmit(values) {
    try {
      const formatedValues = formatForm(values);
      formatedValues.agreementAccepted = true;
      formatedValues.termsAccepted = true;

      let formData;
      if (checkCustomError()) {
        formData = getFormData({ ...formatedValues });
        if (regProfilePic) {
          formData.append("profileImage", regProfilePic);
        }
        if (regAboutMe) {
          formData.append("profileVideo", regAboutMe);
        }
        if (regCertificates.length > 0) {
          regCertificates.forEach((file, i) => {
            formData.append("certificateDocs", file);
          });
        }
      }

      const resp = await trackPromise(
        UserService.createCoach(formData, { headers: { "Content-Type": "multipart/form-data" } })
      );

      const { data } = resp;
      if (data.success) {
        toast.success("Registration successful! Please wait for approval");
        // set_navigateTo("/login");
        nextScreen(4);
      } else {
        set_pageError(data.message || "Registration failed");
      }
    } catch (err) {
      console.error("Register error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
        nextScreen(1);

        // navigate(-1);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Register error");
    }
  }

  function formatForm(form) {
    const updatedForm = { ...form, topics: form.topic?.map((topic) => topic.value) };
    return updatedForm;
  }

  async function isUniqueCheck(value) {
    try {
      const resp = await trackPromise(UserService.isFieldUnique(value));
      const { data } = resp;
      if (value.username) {
        addCustomErrors({ username: data.data.isUnique ? "" : "Username Already Exist" });
      } else if (value.email) {
        addCustomErrors({ email: data.data.isUnique ? "" : "Email Address Already Exist" });
      }
    } catch {
      if (value.username) {
        addCustomErrors({ username: "" });
      } else if (value.email) {
        addCustomErrors({ email: "" });
      }
    }
  }

  function checkCustomError() {
    let error = true;
    Object.keys(customError).forEach((key) => {
      if (customError[key]) error = false;
    });

    return error;
  }

  return navigateTo ? (
    <Navigate to={navigateTo} replace={true} />
  ) : (
    <Formik initialValues={formObject} validationSchema={FormSchema} onSubmit={saveFormValue}>
      {({ handleSubmit, handleChange, values, errors, touched, setFieldValue }) => (
        <Container fluid={true} className="register-container">
          {showAcknowledge == 0 ? <WelcomeScreen nextScreen={nextScreen} /> : null}
          {showAcknowledge == 1 ? (
            <Row className="">
              <Col lg={{ span: 6, offset: 3 }}>
                <div className="register-wrap">
                  <h4 className="frm-heading mb-4">Coach Registration </h4>

                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="frm-group">
                      <Form.Label>First Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter First Name"
                        name="firstName"
                        value={values.firstName}
                        onChange={handleChange}
                        isInvalid={touched.firstName && errors.firstName}
                      />
                      <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Last Name</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Last Name"
                        name="lastName"
                        value={values.lastName}
                        onChange={handleChange}
                        isInvalid={touched.lastName && errors.lastName}
                      />
                      <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={(value) => isUniqueCheck({ username: value.target.value })}
                        isInvalid={(touched.username && errors.username) || customError.username}
                      />
                      <Form.Control.Feedback type="invalid">
                        {errors.username || customError.username}
                      </Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Contact Number</Form.Label>
                      <Form.Control
                        type="phone"
                        placeholder="Enter Contact"
                        name="phone"
                        value={values.phone}
                        onChange={handleChange}
                        isInvalid={touched.phone && errors.phone}
                      />
                      <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Email Address</Form.Label>
                      <Form.Control
                        type="email"
                        placeholder="Enter Email"
                        name="email"
                        value={values.email}
                        onChange={handleChange}
                        onBlur={(value) => isUniqueCheck({ email: value.target.value })}
                        isInvalid={(touched.email && errors.email) || customError.email}
                      />
                      <Form.Control.Feedback type="invalid">{errors.email || customError.email}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Swift Code or Bank Identification Code (BIC)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Code"
                        name="bankCode"
                        value={values.bankCode}
                        onChange={handleChange}
                        isInvalid={touched.bankCode && errors.bankCode}
                      />
                      <Form.Control.Feedback type="invalid">{errors.bankCode}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Bank-State-Branch (BSB) Number</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Number"
                        name="bankBranchNumber"
                        value={values.bankBranchNumber}
                        onChange={handleChange}
                        isInvalid={touched.bankBranchNumber && errors.bankBranchNumber}
                      />
                      <Form.Control.Feedback type="invalid">{errors.bankBranchNumber}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>International Bank Account Number (IBAN)</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Number"
                        name="bankAccountNumber"
                        value={values.bankAccountNumber}
                        onChange={handleChange}
                        isInvalid={touched.bankAccountNumber && errors.bankAccountNumber}
                      />
                      <Form.Control.Feedback type="invalid">{errors.bankAccountNumber}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="frm-group">
                      <Form.Label>Key Core Competencies</Form.Label>
                      <Field
                        name="topic"
                        component={SelectField}
                        isMulti={true}
                        id="topic"
                        placeholder="Select Competencies"
                        defaultValue={values.topic}
                        options={topicList}
                        setFieldValue={setFieldValue}
                        isInvalid={touched.topic && errors.topic}
                        errorClass="fieldError"
                      />
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Additional Qualifications and Credentials</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter Additional Qualifications and Credentials"
                        name="skills"
                        value={values.skills}
                        onChange={handleChange}
                        isInvalid={touched.skills && errors.skills}
                      />
                      <Form.Control.Feedback type="invalid">{errors.skills}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Form.Label>Professional Biography</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={3}
                        placeholder="Enter Your Professional Biography"
                        name="aboutSelf"
                        value={values.aboutSelf}
                        onChange={handleChange}
                        isInvalid={touched.aboutSelf && errors.aboutSelf}
                      />
                      <Form.Control.Feedback type="invalid">{errors.aboutSelf}</Form.Control.Feedback>
                    </Form.Group>
                    <Form.Group className="frm-group">
                      <Form.Label>Experience and Achievements</Form.Label>
                      <Form.Control
                        as="textarea"
                        rows={4}
                        placeholder="Showcase Your Professional Milestones and Significant Accomplishments in Your Coaching Career"
                        name="experienceAchieved"
                        value={values.experienceAchieved}
                        onChange={handleChange}
                        isInvalid={touched.experienceAchieved && errors.experienceAchieved}
                      />
                      <Form.Control.Feedback type="invalid">{errors.experienceAchieved}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Row>
                        <Col className=" d-flex">
                          <Form.Label>Upload Profile Picture</Form.Label>
                          <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip02}>
                            <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <input
                        ref={inputRef}
                        type="file"
                        accept=".jpg, .jpeg, .png"
                        className="hidden-content"
                        onChange={handleProfilePic}
                      />
                      <div className="d-flex mb-3 upload-div">
                        <Button variant="clr-trasparent-new" type="button" className="ms-lg-3" onClick={handleUpload}>
                          Add a file
                        </Button>
                        {regProfilePic ? <div className="item">{regProfilePic.name}</div> : null}
                      </div>
                      {customError.regProfilePic ? (
                        <p id="errorObject" className="fieldError">
                          {customError.regProfilePic}
                        </p>
                      ) : null}
                    </Form.Group>
                    <Form.Group className="frm-group">
                      <Row>
                        <Col className=" d-flex">
                          <Form.Label>Upload Certificates</Form.Label>
                          <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip01}>
                            <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <input
                        type="file"
                        accept=".doc, .docx, .pdf"
                        ref={multipleInputRef}
                        className="hidden-content"
                        onChange={handleCertificatesPic}
                        multiple
                      />
                      <div className="d-flex mb-3 upload-div">
                        <div>
                          <Button
                            variant="clr-trasparent-new"
                            type="button"
                            onClick={handleMultiUpload}
                            className="ms-lg-3"
                          >
                            Add a file
                          </Button>
                        </div>
                        <div className="item">
                          {regCertificates.length > 0
                            ? regCertificates.map((item) => <div className="item">{item.name}</div>)
                            : null}
                        </div>
                      </div>
                      {customError?.regCertificates?.length > 0 ? (
                        <p id="errorObject" className="fieldError">
                          {customError.regCertificates}
                        </p>
                      ) : null}
                    </Form.Group>

                    <Form.Group className="frm-group">
                      <Row>
                        <Col className=" d-flex">
                          <Form.Label>Upload a Professional Introduction Video</Form.Label>
                          <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip03}>
                            <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                          </OverlayTrigger>
                        </Col>
                      </Row>
                      <input
                        ref={inputRefAbout}
                        type="file"
                        accept=".mp4"
                        className="hidden-content"
                        onChange={handleAboutMe}
                      />
                      <div className="d-flex mb-3 upload-div">
                        <Button
                          variant="clr-trasparent-new"
                          type="button"
                          className="ms-lg-3"
                          onClick={handleAboutmeUpload}
                        >
                          Add a file
                        </Button>
                        {regAboutMe ? <div className="item">{regAboutMe.name}</div> : null}
                      </div>
                      {customError.regAboutMe ? (
                        <p id="errorObject" className="fieldError">
                          {customError.regAboutMe}
                        </p>
                      ) : null}
                    </Form.Group>

                    <div className="d-flex justify-content-center mb-3">
                      <Button
                        variant="clr-primary"
                        type="submit"
                        className="w-80 mb-2"
                        onClick={() => {
                          if (agreement) {
                            handleFormSubmit(savedForm);
                          }
                        }}
                      >
                        Register
                      </Button>
                    </div>

                    <div className="bottom-text">
                      By clicking Register, you agree to our{" "}
                      {/* <Link
                        onClick={() => {
                          set_clickFlow(1);
                          nextScreen(2);
                        }}
                        className="action-lnk"
                      >
                        Coaching Agreement
                      </Link>{" "}
                      and{" "} */}
                      <Link
                        onClick={() => {
                          set_clickFlow(1);
                          nextScreen(5);
                        }}
                        className="action-lnk"
                      >
                        Terms & Conditions
                      </Link>
                      .
                    </div>
                    {pageError ? <div className="mt-3 text-danger">{pageError}</div> : <></>}
                  </Form>
                </div>
              </Col>
            </Row>
          ) : null}
          {showAcknowledge == 2 && !agreement ? (
            <RegisterAcknowledge
              nextScreen={nextScreen}
              clickFlow={clickFlow}
              submit={handleFormSubmit}
              formValues={savedForm}
              setagreement={setagreement}
            />
          ) : null}

          {showAcknowledge == 4 ? <ThankYou /> : null}

          {showAcknowledge == 5 ? <TermsConditon clickFlow={clickFlow} nextScreen={nextScreen} /> : null}
        </Container>
      )}
    </Formik>
  );
}

export default CoachRegister;

const RenderTooltip02 = (props) => (
  <Tooltip {...props}>{`The profile picture must be at least 300x300px in size and in the format jpg/jpeg/png. The file size must be less than ${documentSize}MB.`}</Tooltip>
);
const RenderTooltip01 = (props) => (
  <Tooltip {...props}>{`Please select a file in the doc/docx/pdf format for your certificate(s). The file size must be less than ${documentSize}MB`}</Tooltip>
);
const RenderTooltip03 = (props) => (
  <Tooltip {...props}>{`Your video must be landscape and be in a 16:9 ratio. The file format for the video is MP4. The video must not be longer than 1.5 minutes and the file size must be less than ${videoSize}MB.`}</Tooltip>
);

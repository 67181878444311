import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { BookingServices, MoodleCoursesService } from "../../services";
import { AgGridReact } from "ag-grid-react";
import ReactProgressBar from "../CustomComponent/ReactProgressBar";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import DropdownList from "../CustomComponent/DropdownList";

function AssignMaterial({ pageTitle }) {
  const dispatch = useDispatch();
  const [allStudent, set_allStudent] = useState([]);
  const [courseList, set_CourseList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [selectedStudent, set_selectedStudent] = useState(undefined);
  const [studentCourses, set_studentCourses] = useState([]);
  const [studentCoursesId, set_studentCourseId] = useState([]);
  const [courseIds, set_courseIds] = useState([]);
  const [grid, set_grid] = useState(false);
  const [coursesAssigned, setCoursesAssigned] = useState(false); // Flag to track if courses are assigned

  const gridRef = useRef();
  const [courseProgress, setCourseProgress] = useState();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchCoachStudent();
    fetchMoodleCourses();
  }, []);

  useEffect(() => {
    if (selectedStudent) {
      fetchEnrolementByStudent(selectedStudent._id);
    } else {
      set_studentCourses([]);
      set_studentCourseId([]);
    }
  }, [selectedStudent]);

  useEffect(() => {
    if (studentCoursesId && selectedStudent && grid) {
      updateSelection();
    }
  }, [studentCoursesId]);

  useEffect(() => {
    if (grid) {
      updateSelection();
    }
  }, [grid]);

  const onSelectionChanged = useCallback((event) => {
    const rowCount = event.api.getSelectedNodes();

    set_courseIds(rowCount.map((item) => item?.data?.id));
  }, []);

  async function fetchEnrolementByStudent(studentId) {
    try {
      const resp = await trackPromise(MoodleCoursesService.getEnrolmentsByStudent(studentId));
      const { data } = resp;
      if (data.success) {
        // set_CourseList(data?.data);
        set_studentCourses(data?.data);

        set_studentCourseId(data?.data.map((item) => item.moodleCourse));
        if (!coursesAssigned) {
          setCoursesAssigned(true);
        }
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisations catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchMoodleCourses() {
    try {
      const resp = await trackPromise(MoodleCoursesService.getAll());
      const { data } = resp;
      if (data.success) {
        set_CourseList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topics");
      }
    } catch (err) {
      console.error("Fetch topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function CreateStudentMoodleCourses() {
    try {
      const resp = await trackPromise(
        MoodleCoursesService.createCourseEnrolment({ student: selectedStudent, courseIds })
      );
      const { data } = resp;
      if (data.success) {
        // set_CourseList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topics");
      }
    } catch (err) {
      console.error("Fetch topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchCoachStudent() {
    try {
      const resp = await trackPromise(BookingServices.getStudentByCoach());
      const { data } = resp;
      if (data.success) {
        let studentList = data?.data?.filter((itm) => !itm?.isDeleted);
        set_allStudent(studentList);
      } else {
        set_pageError(data.message || "Error in fetching Admins");
      }
    } catch (err) {
      console.error("Fetch Admins catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  const updateSelection = (params) => {
    const nodesToSelect = [];
    gridRef.current.api.deselectAll();
    gridRef.current.api.forEachNode((node) => {
      if (node.data && studentCoursesId.indexOf(node.data.id) !== -1) {
        nodesToSelect.push(node);
      }
    });
    gridRef.current.api.setNodesSelected({
      nodes: nodesToSelect,
      newValue: true,
    });
  };

  const onGridReady = useCallback((params) => {
    set_grid(true);
    updateSelection();
  }, []);

  const columnDefs = [
    // { headerName: "", headerCheckboxSelection: true, checkboxSelection: true, showDisabledCheckboxes: true },
    {
      headerName: "Display Name",
      field: "displayname",
      suppressMovable: true,
      headerCheckboxSelection: true,
      checkboxSelection: true,
      showDisabledCheckboxes: true,
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      field: "fullname",
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Short Name",
      field: "shortname",
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      suppressMovable: true,
      cellStyle: { textAlign: "center" },
    },

    {
      headerName: "Summary",
      field: "summary",
      suppressMovable: true,
      tooltipField: "summary",
      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Resource Progress",
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      width: 250,
      suppressMovable: true,
      cellRenderer: function (progress) {
        const filterProgressData = studentCourses.filter((item) => item?.moodleCourse === progress?.data?.id);
        return filterProgressData.length !== 0 && filterProgressData !== undefined ? (
          filterProgressData.map((item) => <ReactProgressBar key={item?._id} courseProgress={item?.courseProgress} />)
        ) : (
          <ReactProgressBar courseProgress={0} />
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    sortable: true,
    cellStyle: () => ({
      display: "flex",
      alignItems: "center",
      justifyContent: "center",
    }),
    // flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const handleSelect = (selectedList) => {
    set_selectedStudent(selectedList);
  };

  const getOptionLabelFunction = (option) => `${option.firstName} ${option.lastName}`;

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading d-flex justify-content-end">
        <Button
          onClick={CreateStudentMoodleCourses}
          variant="clr-primary"
          className="btn-add"
          disabled={!coursesAssigned && courseIds.length < 1}
        >
          {studentCourses.length > 0 ? "Assign Resource" : "Assign Resource"}
        </Button>
      </div>
      <Row className="mb-4">
        <Col lg="12">
          <Form.Group className="frm-group">
            <Form.Label>Select Coachee</Form.Label>
            <Col xs={12} md={4}>
              <DropdownList
                name="oraganisation"
                value={selectedStudent}
                allStudent={allStudent}
                handleSelect={handleSelect}
                placeholder="Select Coachee"
                getOptionLabel={getOptionLabelFunction}
                isMulti={false}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          {selectedStudent ? (
            <>
              {" "}
              <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
                <AgGridReact
                  ref={gridRef}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowData={courseList}
                  pagination={true}
                  paginationPageSize={10}
                  rowSelection={"multiple"}
                  // onGridReady={onGridReady}
                  onFirstDataRendered={onFirstDataRendered}
                  onSelectionChanged={onSelectionChanged}
                  onGridReady={onGridReady}
                />
              </div>
              <div className="page-size-wrap">
                <div className="page-size">
                  Records Per Page:
                  <select onChange={onPageSizeChanged} id="page-size">
                    <option value="10">10</option>
                    <option value="50">25</option>
                    <option value="100">50</option>
                  </select>
                </div>
              </div>
            </>
          ) : (
            <div>Please Select Coachee</div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default AssignMaterial;

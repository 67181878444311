import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { UserService } from "../../services";
import { copyObjectByKeys } from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function ChangePassword({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [pwdDetails, set_pwdDetails] = useState({});
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setShowNewPassword] = useState(false);
  const [showConfirmNewPwd, setShowConfirmNewPwd] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  function getFormObject() {
    return {
      oldPassword: "",
      newPassword: "",
      confirmNewPwd: "",
    };
  }

  const FormSchema = Yup.object().shape({
    oldPassword: Yup.string()
      .min(8, "Password should be minimum ${min} characters")
      .required("Old Password is Required"),
    newPassword: Yup.string()
      .min(8, "Password should be minimum ${min} characters")
      .required("New Password is Required"),
    confirmNewPwd: Yup.string()
      .required("Password Confirmation is Required")
      .oneOf([Yup.ref("newPassword")], "Passwords do not match"),
  });

  async function handleFormSubmit(values) {
    const submitText = "updated";
    try {
      const resp = await trackPromise(UserService.changePassword(values));

      const { data } = resp;
      if (data.success) {
        toast.success(`Password ${submitText} Successfully.`);
        navigate(-1);
      } else {
        set_pageError(data.message || `Failed to ${submitText} Change Password`);
      }
    } catch (err) {
      console.error("Change Password error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Change Password error");
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Edit Password</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Old Password</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        // type="password"
                        placeholder="Enter Old Password"
                        name="oldPassword"
                        value={values.oldPassword}
                        onChange={handleChange}
                        isInvalid={touched.oldPassword && errors.oldPassword}
                      />
                      <InputGroup.Text>
                        {!showPassword ? (
                          <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword((prevState) => !prevState)} />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => setShowPassword((prevState) => !prevState)}
                          />
                        )}
                      </InputGroup.Text>

                      <Form.Control.Feedback type="invalid">{errors.oldPassword}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>New Password</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type={showNewPassword ? "text" : "password"}
                        // type="password"
                        placeholder="Enter New Password"
                        name="newPassword"
                        value={values.newPassword}
                        onChange={handleChange}
                        isInvalid={touched.newPassword && errors.newPassword}
                      />
                      <InputGroup.Text>
                        {!showNewPassword ? (
                          <FontAwesomeIcon icon={faEye} onClick={() => setShowNewPassword((prevState) => !prevState)} />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => setShowNewPassword((prevState) => !prevState)}
                          />
                        )}
                      </InputGroup.Text>

                      <Form.Control.Feedback type="invalid">{errors.newPassword}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="frm-group mb-4">
                    <Form.Label>Confirm Password</Form.Label>

                    <InputGroup>
                      <Form.Control
                        type={showConfirmNewPwd ? "text" : "password"}
                        // type="password"
                        placeholder="Re-enter New Password"
                        name="confirmNewPwd"
                        value={values.confirmNewPwd}
                        onChange={handleChange}
                        isInvalid={touched.confirmNewPwd && errors.confirmNewPwd}
                      />
                      <InputGroup.Text>
                        {!showConfirmNewPwd ? (
                          <FontAwesomeIcon
                            icon={faEye}
                            onClick={() => setShowConfirmNewPwd((prevState) => !prevState)}
                          />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => setShowConfirmNewPwd((prevState) => !prevState)}
                          />
                        )}
                      </InputGroup.Text>

                      <Form.Control.Feedback type="invalid">{errors.confirmNewPwd}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="row g-0 frm-btn-grp gap-2">
                    <Button as={Link} to="/dashboard" variant="clr-transparent" className="col-lg ">
                      Cancel
                    </Button>

                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ">
                      Change Password
                    </Button>
                  </div>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ChangePassword;

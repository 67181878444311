import { NavLink, useLocation } from "react-router-dom";
import { useEffect, useState } from "react";
import { useSelector } from "react-redux";
import { Sidebar, Menu, SubMenu, MenuItem, sidebarClasses } from "react-pro-sidebar";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faHouseChimney,
  faBuilding,
  faUserShield,
  faUsers,
  faPeopleGroup,
  faNewspaper,
  faSchool,
  faLayerGroup,
  faUser,
  faUpload,
  faEnvelope,
  faCalendar,
  faKey,
  faBook,
  faFileAlt,
  faUsersRays,
  faComment,
} from "@fortawesome/free-solid-svg-icons";
import { faBarsProgress } from "@fortawesome/free-solid-svg-icons";
import SideHeader from "../../components/SideHeader/SideHeader";
import Footer from "../../components/Footer/Footer";
import { THEME } from "../../utilities/theme";
import { USER_ROLES } from "../../AppRoutes";
import "./Sidenav.css";
import { faCalendarAlt } from "@fortawesome/free-regular-svg-icons";

function Sidenav({ isCollapsed = false, collapseNav, changedTheme, openNavBar }) {
  const { authState, appState } = useSelector((state) => state);
  const [newTheme, setNewTheme] = useState();
  const hasGoal = authState?.user?.hasGoal;

  useEffect(() => {
    if (changedTheme) {
      const theme = THEME.filter((theme) => theme.color_theme === changedTheme);
      setNewTheme(theme);
    } else {
      const defaultTheme = THEME.filter((i) => i.color_theme === "defaultTheme");
      setNewTheme(defaultTheme);
    }
  }, [changedTheme]);

  return (
    <aside className={`view-aside ${openNavBar ? "top-nav-open" : "top-nav-close"}`}>
      <Sidebar
        collapsed={isCollapsed}
        toggled={isCollapsed}
        width="100%"
        // collapsedWidth="100%"
        breakPoint="lg"
        onBackdropClick={collapseNav}
        rootStyles={{
          position: "sticky",
          top: 0,
          zIndex: 1,
          borderRight: "none",
          backgroundColor: "transparent",
          overflowY: "auto",
          height: "100vh",
          [`.${sidebarClasses.container}`]: {
            display: "flex",
            flexDirection: "column",
            padding: isCollapsed ? 0 : "0 1.5rem",
            backgroundColor: newTheme?.CLR_TRANSPARENT,
          },
          [`.${sidebarClasses.backdrop}`]: {
            // top: THEME.TOP_NAV_HEIGHT,
          },
        }}
      >
        <SideHeader navCollapsed={isCollapsed} collapseNav={collapseNav} />
        <Menu
          rootStyles={{
            paddingTop: "1rem",
          }}
        >
          {authState.user.role.roleCode === USER_ROLES.superadmin ? (
            <SuperadmiMenus isCollapsed={isCollapsed} newTheme={newTheme} />
          ) : authState.user.role.roleCode === USER_ROLES.admin ? (
            <AdminMenus isCollapsed={isCollapsed} newTheme={newTheme} />
          ) : authState.user.role.roleCode === USER_ROLES.coach ? (
            <CoachMenus isCollapsed={isCollapsed} newTheme={newTheme} />
          ) : (
            <StudentMenus isCollapsed={isCollapsed} newTheme={newTheme} hasGoal={hasGoal} />
          )}
        </Menu>

        <Footer isCollapsed={isCollapsed} newTheme={newTheme} />
      </Sidebar>
    </aside>
  );
}

export default Sidenav;

const SuperadmiMenus = ({ isCollapsed, newTheme }) => (
  <>
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Dashboard"
      lnkTo="dashboard"
      lnkIcon={faHouseChimney}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Manage Organisations"
      lnkTo="organisation"
      lnkIcon={faBuilding}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Manage Admins"
      lnkTo="admin"
      lnkIcon={faUserShield}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Manage Coaches"
      lnkTo="coach"
      lnkIcon={faPeopleGroup}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Coach Category"
      lnkTo="coachCategory"
      lnkIcon={faUsersRays}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Manage Topics"
      lnkTo="topics"
      lnkIcon={faLayerGroup}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Coachee Type"
      lnkTo="coachee/type"
      lnkIcon={faSchool}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Organisation Type"
      lnkTo="org/type"
      lnkIcon={faBuilding}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Change Password"
      lnkTo="changePassword"
      lnkIcon={faKey}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Coaching Resources"
      lnkTo="moodleResource"
      lnkIcon={faBook}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="View Reports"
      lnkTo="viewreports"
      lnkIcon={faNewspaper}
      newTheme={newTheme}
    />
  </>
);
const AdminMenus = ({ isCollapsed, newTheme }) => (
  <>
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Dashboard"
      lnkTo="dashboard"
      lnkIcon={faHouseChimney}
      newTheme={newTheme}
    />
    <SnMenuItem isCollapsed={isCollapsed} title="Coachee Users" lnkTo="coachee" lnkIcon={faUsers} newTheme={newTheme} />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Bulk Upload"
      lnkTo="bulkupload"
      lnkIcon={faUpload}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Email Templates"
      lnkTo="emailTemplateList"
      lnkIcon={faEnvelope}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="View Reports"
      lnkTo="viewreports"
      lnkIcon={faNewspaper}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Subscription"
      lnkTo="subscription"
      lnkIcon={faCalendarAlt}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Manage Request"
      lnkTo="manageRequest"
      lnkIcon={faFileAlt}
      newTheme={newTheme}
    />
  </>
);
const CoachMenus = ({ isCollapsed, newTheme }) => (
  <>
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Dashboard"
      lnkTo="dashboard"
      lnkIcon={faHouseChimney}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Manage Profile"
      lnkTo="profile/edit"
      lnkIcon={faUser}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Coaching Slots"
      lnkTo="calendarEvent"
      lnkIcon={faCalendar}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Coachee Progress"
      lnkTo="coacheeProgress"
      lnkIcon={faBarsProgress}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Assign Resource"
      lnkTo="assignresource"
      lnkIcon={faBook}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Chat Messaging"
      lnkTo="chatCoach"
      lnkIcon={faComment}
      newTheme={newTheme}
    />
    <SnMenuItem
      isCollapsed={isCollapsed}
      title="Review Agreement"
      lnkTo="reviewagreement"
      lnkIcon={faNewspaper}
      newTheme={newTheme}
    />
    {/* <SnMenuItem isCollapsed={isCollapsed} title="View Reports" lnkTo="viewreports" lnkIcon={faNewspaper} /> */}
  </>
);
const StudentMenus = ({ isCollapsed, newTheme, hasGoal }) => {
  return (
    <>
      <SnMenuItem
        isCollapsed={isCollapsed}
        title="Dashboard"
        lnkTo="dashboard"
        lnkIcon={faHouseChimney}
        newTheme={newTheme}
      />
      <SnMenuItem
        isCollapsed={isCollapsed}
        title="Manage Sessions"
        lnkTo="manageCoaching"
        lnkIcon={faPeopleGroup}
        newTheme={newTheme}
      />
      {!hasGoal ? (
        <SnMenuItem
          isCollapsed={isCollapsed}
          title="Browse Coaches"
          lnkTo="bookslots"
          lnkIcon={faUser}
          newTheme={newTheme}
        />
      ) : (
        <></>
      )}
      <SnMenuItem
        isCollapsed={isCollapsed}
        title="View Resources"
        lnkTo="viewresources"
        lnkIcon={faBook}
        newTheme={newTheme}
      />
      <SnMenuItem
        isCollapsed={isCollapsed}
        title="Review Agreement"
        lnkTo="reviewagreement"
        lnkIcon={faNewspaper}
        newTheme={newTheme}
      />
      <SnMenuItem
        isCollapsed={isCollapsed}
        title="Coach Change Management"
        lnkTo="coachChangeRequest"
        lnkIcon={faEnvelope}
        newTheme={newTheme}
      />
      {hasGoal ? (
        <SnMenuItem
          isCollapsed={isCollapsed}
          title="Chat Messaging"
          lnkTo="chatApp"
          lnkIcon={faComment}
          newTheme={newTheme}
        />
      ) : (
        <></>
      )}
    </>
  );
};

function SnMenuItem({ isCollapsed = false, title, lnkTo, lnkIcon, newTheme, hasGoal }) {
  const location = useLocation();
  const activeStatus = location.pathname.includes(`/${lnkTo}/` || `/${lnkTo}`);

  return (
    <MenuItem
      active={activeStatus}
      icon={<FontAwesomeIcon icon={lnkIcon} title={title} />}
      component={<NavLink to={lnkTo} end />}
      rootStyles={({ active }) => {
        return active
          ? {
              backgroundColor: newTheme?.CLR_PRIMARY,
              color: newTheme?.CLR_MENU,
            }
          : {
              backgroundColor: newTheme?.CLR_TRANSPARENT,
              color: newTheme?.CLR_MENU,
            };
      }}
    >
      {!isCollapsed ? title : ""}
    </MenuItem>
  );
}

function SnSubMenuItem({ children, isCollapsed, title, lnkIcon }) {
  return (
    <SubMenu
      label={!isCollapsed ? title : ""}
      icon={
        <FontAwesomeIcon
          icon={lnkIcon}
          // color={THEME.CLR_PRIMARY}
          title={title}
        />
      }
      rootStyles={({ open }) => {
        return open
          ? {
              backgroundColor: THEME.CLR_PRIMARY_LIGHT,
              color: THEME.CLR_WHITE_01,
            }
          : {
              backgroundColor: THEME.CLR_TRANSPARENT,
              color: THEME.CLR_BLACK_01,
            };
      }}
    >
      {children}
    </SubMenu>
  );
}

const NormalSubmenu = {
  backgroundColor: THEME.CLR_TRANSPARENT,
  color: THEME.CLR_BLACK_01,
};
const ActiveSubmenu = {
  backgroundColor: THEME.CLR_PRIMARY_LIGHT,
  color: THEME.CLR_WHITE_01,
};
const NormalMenu = {
  backgroundColor: THEME.CLR_TRANSPARENT,
  color: "#624B50",
};
const ActiveMenu = {
  backgroundColor: THEME.CLR_PRIMARY,
  color: THEME.CLR_WHITE_01,
};

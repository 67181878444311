import React from "react";
import { useEffect } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { Button } from "react-bootstrap";
import { useState } from "react";
import { trackPromise } from "react-promise-tracker";
import { UserService } from "../../services";

const ViewAdmin = ({ pageTitle }) => {
  const [adminDetails, set_adminDetails] = useState([]);
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchAdminDetails() {
      try {
        const resp = await trackPromise(UserService.getAdminById(id.id));
        const { data } = resp;
        if (data.success) {
          set_adminDetails(data.data);
        } else {
          set_pageError(data.message || "Error in fetching admin details");
        }
      } catch (err) {
        console.error("Fetch admin details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchAdminDetails();
  }, [id.id]);

  return (
    <div className="text-left">
      <h4 className="col hd-txt">View Admin</h4>
      <div className="col-md">
        <table className="view-org-table table table-borderless bg-transparent w-50">
          <tbody className="bg-transparent">
            <tr>
              <td>Organisation</td>
              <td>{adminDetails?.organisation?.orgName}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>{adminDetails.username}</td>
            </tr>
            <tr>
              <td>Email address</td>
              <td>{adminDetails.email}</td>
            </tr>
          </tbody>
        </table>
        <div className="g-0 frm-btn-grp g-2 mt-3">
          <Button as={Link} to="/admin" variant="clr-transparent" className="col-lg me-2">
            Cancel
          </Button>
          <Button as={Link} to={`/admin/edit/${id.id}`} variant="clr-primary" type="submit" className="col-lg ms-2">
            Edit Admin
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewAdmin;

import React, { useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Image, Row } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getColorThemeData } from "../../stores/colorThemeSlice";
import { Rating } from "react-simple-star-rating";
import { Form, Formik } from "formik";
import EnvConfig from "../../envConfig";

const RatingPopUp = ({ isOpen, handleFormSubmit, setIsopen, onCancleRating, FormSchema, formObject, slotDetails }) => {
  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);
  const disptch = useDispatch();

  const colorThemeData = useSelector((state) => state?.colorTheme?.colorThemeData);

  useEffect(() => {
    disptch(getColorThemeData(colorTheme));
  }, [colorTheme, disptch]);

  return (
    <Modal show={isOpen} centered>
      <Formik
        initialValues={formObject}
        enableReinitialize="true"
        validationSchema={FormSchema}
        onSubmit={handleFormSubmit}
      >
        {({ handleSubmit, handleChange, values, setFieldValue }) => (
          <Form noValidate onSubmit={handleSubmit}>
            <Modal.Body>
              <Row className="col-auto text-center d-flex flex-column">
                {slotDetails.length === 0 ? (
                  <div className="text-center self-align-center">Loading...</div>
                ) : (
                  <>
                    <Modal.Title className="my-2">Rate Your Coach</Modal.Title>
                    <div className="profile-wrap pe-1 text-center">
                      {/* <img className="thumbnail-image" /> */}
                      <Image
                        src={`${EnvConfig.MediaBase}/${slotDetails?.coach?.assetsDir}/${slotDetails?.coach?.profileImage}`}
                        className="coach-Img"
                        alt=""
                      />
                      <div className="title coach-name mt-2 ">
                        <h4>{`${slotDetails?.coach?.firstName} 
                        ${slotDetails?.coach?.lastName}`}</h4>{" "}
                      </div>
                      <div className="fw-4">Please Rate Your Coach</div>
                      <div className="my-2">
                        <Rating
                          onChange={handleChange}
                          name="rating"
                          onClick={(value) => setFieldValue("rating", value)}
                          initialValue={values?.rating}
                          fillColor="#B18C93"
                        />
                      </div>

                      <Button variant="clr-primary" type="submit">
                        Submit Rating
                      </Button>
                    </div>
                  </>
                )}
              </Row>
            </Modal.Body>
          </Form>
        )}
      </Formik>
    </Modal>
  );
};

export default RatingPopUp;

import { useState } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form, Modal, InputGroup } from "react-bootstrap";
import "./Register.css";

function WelcomeScreen(props) {
  return (
    <Container fluid={true} className="register-container">
      <Row className="row-thank">
        <Col lg={{ span: 5, offset: 3 }} className="coach_welcome">
          <div className="register-wrap">
            <p className="frm-heading">Welcome to</p>
            <h4 className="frm-heading mb-4">Coach & Grow App</h4>
            <div>
              <div className="d-flex justify-content-center mt-4">
                <Button variant="clr-primary" type="submit" className="w-80 mb-2" onClick={() => props.nextScreen(1)}>
                  Register as a Coach
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default WelcomeScreen;

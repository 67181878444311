import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Image, OverlayTrigger } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { UserService, AccreditationService } from "../../services";
import { replaceWithBr } from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import PillWithDelete from "../CustomComponent/PillBadge";
import { DocTable } from "./Coach.edit";

function CoachApprove({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [coachId, set_coachId] = useState(params.id);
  const [coachDetails, set_coachDetails] = useState({});
  const [accredLevelList, set_accredLevelList] = useState([]);
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchAccreditationList();
  }, []);

  useEffect(() => {
    if (coachId) fetchCoachDetails();
  }, [coachId]);

  function getFormObject() {
    return {
      accreditationLevel: "",
      coachPrice: "",
      approvalStatus: "accepted",
    };
  }

  const FormSchema = Yup.object().shape({
    accreditationLevel: Yup.string().required("Select an Accredition Level"),
    coachPrice: Yup.number().required("Price is Required").positive("Enter positive value"),
  });

  async function fetchAccreditationList() {
    try {
      const resp = await trackPromise(AccreditationService.getList());
      const { data } = resp;
      if (data.success) {
        set_accredLevelList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Accreditation List");
      }
    } catch (err) {
      console.error("Fetch Accreditation List catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchCoachDetails() {
    try {
      const resp = await trackPromise(UserService.getCoachById(coachId));
      const { data } = resp;
      if (data.success) {
        set_coachDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching coach details");
      }
    } catch (err) {
      console.error("Fetch coach details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function handleFormSubmit(values) {
    try {
      const resp = await trackPromise(UserService.coachApproval(coachId, values));
      const { data } = resp;
      if (data.success) {
        toast.success("Approval successful!");
        navigate(-1);
      } else {
        set_pageError(data.message || "Approval failed");
      }
    } catch (err) {
      console.error("Approval error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Approval error");
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Approve Coach</h4>
      </div>

      <div className="details-wrap px-3">
        <DetailRow rwLabel="First Name" rwValue={coachDetails.firstName} />
        <DetailRow rwLabel="Last Name" rwValue={coachDetails.lastName} />
        <DetailRow rwLabel="Username" rwValue={coachDetails.username} />
        <DetailRow rwLabel="Contact Number" rwValue={coachDetails.phone} />
        <DetailRow rwLabel="Email Address" rwValue={coachDetails.email} />
        <DetailRow rwLabel="Swift Code or Bank Identification Code (BIC)" rwValue={coachDetails.bankCode} />
        <DetailRow rwLabel="Bank-State-Branch (BSB) Number" rwValue={coachDetails.bankBranchNumber} />
        <DetailRow rwLabel="International Bank Account Number (IBAN)" rwValue={coachDetails.bankAccountNumber} />

        <DetailRow
          rwLabel="Key Core Competencies"
          rwValue={coachDetails.topics}
          arrayDataClassName={"d-flex flex-wrap gap-2"}
        />
        <DetailRow rwLabel="Additional Qualifications and Credentials" rwValue={coachDetails.skills} />
        <DetailRow rwLabel="Professional Biography" rwValue={coachDetails.aboutSelf} />
        <DetailRow rwLabel="Experience and Achievements" rwValue={coachDetails.experienceAchieved} />

        <div className="form-01-wrap">
          <Formik
            initialValues={formObject}
            enableReinitialize="true"
            validationSchema={FormSchema}
            onSubmit={handleFormSubmit}
          >
            {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group as={Row} className="frm-group detail-row">
                  <Form.Label column lg={4} className="drw-label">
                    Level of Accreditation
                  </Form.Label>
                  <Col lg={3}>
                    <Form.Select
                      name="accreditationLevel"
                      value={values.accreditationLevel}
                      onChange={handleChange}
                      isInvalid={touched.accreditationLevel && errors.accreditationLevel}
                    >
                      <option>Select Accreditation</option>
                      {accredLevelList?.length ? (
                        accredLevelList.map((itm, inx) => (
                          <option key={inx} value={itm._id}>
                            {itm.levelName}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.accreditationLevel}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group as={Row} className="frm-group detail-row">
                  <Form.Label column lg={4} className="drw-label">
                    Assign Hourly Price
                  </Form.Label>
                  <Col lg={3}>
                    <Form.Control
                      type="text"
                      placeholder="Enter Price"
                      name="coachPrice"
                      value={values.coachPrice}
                      onChange={handleChange}
                      isInvalid={touched.coachPrice && errors.coachPrice}
                    />
                    <Form.Control.Feedback type="invalid">{errors.coachPrice}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <Form.Group className="frm-group">
                    <Row>
                      <Col className=" d-flex">
                        <Form.Label>Upload Profile Picture</Form.Label>
                      </Col>
                    </Row>
                    <div className="item">
                      {coachDetails?.profileImage ? (
                        <PillWithDelete
                          text={coachDetails?.profileImage}
                          hideDelete={true}
                          assetsDir={coachDetails.assetsDir}
                        />
                      ) : null}
                    </div>
                  </Form.Group>

                <Form.Group className="frm-group">
                    <Form.Label>Upload Certificates</Form.Label>
                    {coachDetails?.certificateDocs?.length > 0 ? (
                      <DocTable tblData={coachDetails?.certificateDocs}  coachDetails={coachDetails}  hideDelete={true}/>
                    ) : null}
                  </Form.Group>

                 <Form.Group className="frm-group">
                    <Form.Label>Upload a 1-minute Professional Introduction Video</Form.Label>
                    <div className="item">
                      {coachDetails?.profileVideo ? (
                        <PillWithDelete
                          text={coachDetails?.profileVideo}
                          hideDelete={true}
                          assetsDir={coachDetails.assetsDir}
                        />
                      ) : null}
                    </div>
                  </Form.Group> 

                
                <div className="row g-0 frm-btn-grp gap-2">
                  <Button as={Link} to="/coach" variant="clr-transparent" className="col-lg-3 me-lg-3">
                    Cancel
                  </Button>
                  <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg-3 ms-lg-3">
                    Approve
                  </Button>
                </div>

                {pageError ? <div className="text-danger">{pageError}</div> : <></>}
              </Form>
            )}
          </Formik>
        </div>
      </div>
    </Container>
  );
}

export default CoachApprove;

function DetailRow({ rwLabel = "", rwValue = "", arrayDataClassName }) {
  return (
    <Row className="detail-row">
      <Col lg={4} className="drw-label">
        {rwLabel}
      </Col>
      <Col lg={8} className={`drw-value ${arrayDataClassName}`}>
        {Array.isArray(rwValue) ? (
          rwValue?.map((itm, inx) => (
            <span key={inx} className="drw-pill me-2">
              {itm.topicName}
            </span>
          ))
        ) : (
          <div dangerouslySetInnerHTML={{ __html: replaceWithBr(rwValue) }}></div>
        )}
      </Col>
    </Row>
  );
}

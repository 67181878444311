import React from "react";
import Modal from "react-bootstrap/Modal";
import { useState, useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getColorThemeData } from "../../stores/colorThemeSlice";

function DeleteModal({ id, isOpen, onClose, deleteAppoint, pageError }) {
  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);
  const disptch = useDispatch();

  const colorThemeData = useSelector((state) => state?.colorTheme?.colorThemeData);

  useEffect(() => {
    disptch(getColorThemeData(colorTheme));
  }, [colorTheme, disptch]);

  const confirmDeletion = () => {
    // Pass the 'id' to 'deleteAppoint' function
    deleteAppoint(id);
  };
  return (
    <Modal show={isOpen} onHide={onClose}>
      <Modal.Header closeButton>
        <Modal.Title>Delete Confirmation</Modal.Title>
      </Modal.Header>
      <Modal.Body>Are you sure you want to delete this appointment?</Modal.Body>
      <Modal.Footer>
        <Button
          onClick={onClose}
          variant="clr-transparent"
          style={{
            backgroundColor: colorThemeData[0]?.CLR_TRANSPARENT,
            color: colorThemeData[0]?.CLR_PRIMARY,
            borderColor: colorThemeData[0]?.CLR_PRIMARY,
          }}
        >
          Cancel
        </Button>
        <Button
          // variant="clr-primary"
          style={{
            backgroundColor: colorThemeData[0]?.CLR_PRIMARY,
            color: "white",
            borderColor: colorThemeData[0]?.CLR_PRIMARY,
          }}
          onClick={confirmDeletion}
        >
          Confirm
        </Button>
        {pageError ? <div className="text-danger">{pageError}</div> : <></>}
      </Modal.Footer>
    </Modal>
  );
}

export default DeleteModal;

import httpClient from "../httpClient";
const baseSlug = "/users";

class httpService {
  login(data) {
    return httpClient.post(`${baseSlug}/login`, data);
  }

  profile() {
    return httpClient.get(`${baseSlug}/profile`);
  }

  getprofileById(id) {
    return httpClient.get(`${baseSlug}/profile/${id}`);
  }

  updateProfile(data, config = {}) {
    return httpClient.put(`${baseSlug}/profile`, data, config);
  }

  logout() {
    return httpClient.get(`${baseSlug}/logout`);
  }
  forgotPassword(data) {
    return httpClient.put(`${baseSlug}/forgotPassword`, data);
  }

  getAll() {
    return httpClient.get(`${baseSlug}`);
  }

  getAdmins() {
    return httpClient.get(`${baseSlug}/admin`);
  }

  getAdminById(id) {
    return httpClient.get(`${baseSlug}/admin/${id}`);
  }
  getStudentById(id) {
    return httpClient.get(`${baseSlug}/student/${id}`);
  }

  getCoaches() {
    return httpClient.get(`${baseSlug}/coach`);
  }

  getCoachById(id) {
    return httpClient.get(`${baseSlug}/coach/${id}`);
  }

  getCoachByTopics(data) {
    return httpClient.post(`${baseSlug}/getCoaches`, data);
  }

  getOrgStudents() {
    return httpClient.get(`${baseSlug}/orgStudents`);
  }

  getByToken(token) {
    return httpClient.get(`${baseSlug}/byToken/${token}`);
  }

  get(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  create(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  createAdmin(data) {
    return httpClient.post(`${baseSlug}/admin`, data);
  }

  updateAdmin(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  createCoach(data, config) {
    return httpClient.post(`${baseSlug}/coach`, data, config);
  }

  createStudent(data) {
    return httpClient.post(`${baseSlug}/student`, data);
  }

  updateStudent(id, data) {
    return httpClient.put(`${baseSlug}/student/${id}`, data);
  }

  updateCoach(id, data, config) {
    return httpClient.put(`${baseSlug}/coach/${id}`, data, config);
  }

  update(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  coachApproval(id, data) {
    return httpClient.put(`${baseSlug}/coach/approval/${id}`, data);
  }

  createPassword(token, data) {
    return httpClient.put(`${baseSlug}/createPassword/${token}`, data);
  }

  delete(id) {
    return httpClient.put(`${baseSlug}/delete/${id}`);
  }
  deleteStudent(id, data) {
    return httpClient.put(`${baseSlug}/delete/student/${id}`, data);
  }
  deleteCoach(id, data) {
    return httpClient.put(`${baseSlug}/delete/coach/${id}`, data);
  }
  bulkUpload(data) {
    return httpClient.post(`${baseSlug}/student/bulk`, data);
  }
  bulkUploadVerify(data) {
    return httpClient.post(`${baseSlug}/student/bulkValidate`, data);
  }
  isFieldUnique(data) {
    return httpClient.post(`${baseSlug}/isFieldUnique`, data);
  }
  changePassword(data) {
    return httpClient.put(`${baseSlug}/changePassword`, data);
  }
  getCoachDetails(topicId, id) {
    return httpClient.get(`${baseSlug}/coach/${id}/details`);
  }
  getUserStat() {
    return httpClient.get(`/statistics/overview`);
  }
  getStreamUserDetail() {
    return httpClient.get(`${baseSlug}/StreamChat/UserDetail`);
  }
  updateStudentProfile(data, config = {}) {
    return httpClient.put(`${baseSlug}/profile/student`, data, config);
  }

  updateSession(id, data) {
    return httpClient.put(`${baseSlug}/student/sessions/${id}`, data);
  }
}

export default new httpService();

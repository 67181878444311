import httpClient from "../httpClient";
const baseSlug = "/bookSlot";

class httpService {
  getUserBooking() {
    return httpClient.get(`${baseSlug}/user`);
  }
  getSlotAppointmentDetail(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  getUpcomingSlots() {
    return httpClient.get(`bookSlot/upcoming`);
  }
  getBookSlotsByAssociatedUser(id) {
    return httpClient.get(`${baseSlug}/associatedUser/${id}`);
  }

  getRtcCofiguration(id) {
    return httpClient.get(`bookSlot/videoCallToken/${id}`);
  }
  cancelSlot(id, requestData) {
    return httpClient.put(`${baseSlug}/cancel/${id}`, requestData);
  }
  bookingExist(data) {
    return httpClient.post(`booking/exists`, data);
  }
  rescheduleAppoint(id, data) {
    return httpClient.put(`${baseSlug}/reschedule/${id}`, data);
  }
}

export default new httpService();

import { faDownload, faPencil, faTrash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import React from "react";
import Badge from "react-bootstrap/Badge";
import Button from "react-bootstrap/Button";
import { Link } from "react-router-dom";
import EnvConfig from "../../envConfig";

function PillWithDelete({ text, onDelete, onDownload, hideDelete, assetsDir }) {
  return (
    <Badge pill bg="white" text="black">
      {text}
      <Button variant="light" size="sm" onClick={onDownload} aria-label="Delete" className="pill-align">
        <Link to={`${EnvConfig.MediaBase}/${assetsDir}/${text}`} target="_blank" download>
          <FontAwesomeIcon icon={faDownload} />
        </Link>
      </Button>
      {!hideDelete ? (
        <Button
          variant="light"
          size="sm"
          onClick={() => {
            onDelete(text);
          }}
          aria-label="Delete"
          className="pill-align"
        >
          <FontAwesomeIcon icon={faTrash} />
        </Button>
      ) : null}
    </Badge>
  );
}

export default PillWithDelete;

import { useState } from "react";

import { Container, Row, Col, Button, Modal } from "react-bootstrap";
import "./Register.css";
import aggrement from "../../assets/files/Agreement.pdf";

function RegisterAcknowledge(props) {
  const [showAcknowledge, setShow] = useState(false);
  const [agree, setAgree] = useState(false);

  // const nextScreen = (screenNumber) => {
  //   props.setShowAcknowledge(false);
  //   props.nextScreen(screenNumber);
  // };

  return (
    // <Modal show={true} size="lg" aria-labelledby="contained-modal-title-vcenter" centered fullscreen={false}>
    <Container fluid={true} className="register-container">
      <Row className="row-height ">
        <Col lg={{ span: 7, offset: 3 }}>
          <div className="register-wrap-term">
            <h4 className="frm-heading">Rise & Thrive Coaching Agreement</h4>
            <div>
              <div>
                <CoachingAggrement />
                <div />
              </div>
            </div>
            <div className=" justify-content-center mt-2 px-2">
              {!props.clickFlow ? (
                <div className="terms-check ">
                  <input
                    type="checkbox"
                    id="check3"
                    onClick={(e) => setAgree(e.target.checked)}
                  />{" "}
                  I acknowledge that I have read the agreement, I have{" "}
                  <a
                    href={aggrement}
                    download="Agreement.pdf"
                    style={{ color: "var(--clr-primary)" }}
                  >
                    {" "}
                    downloaded a copy{" "}
                  </a>{" "}
                  of the agreement and that by clicking on 'I Accept' below, I
                  am formally accepting this coaching agreement.
                  <label htmlFor="check3">
                    <span className="fa fa-check" />
                  </label>
                  {/* {console.log(props.formValues, props.clickFlow, "valu")} */}
                </div>
              ) : null}
              <div className="d-flex justify-content-center mt-3">
                <Button
                  variant="clr-primary"
                  type="button"
                  className="w-20"
                  onClick={() => {
                    if (props.clickFlow) {
                      props.nextScreen(1);
                    } else {
                      props.submit(props.formValues);
                      props.setagreement(true);
                    }
                  }}
                  disabled={!props.clickFlow && !agree}
                >
                  {!props.clickFlow ? "I Accept" : "Close"}
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
    // </Modal>
  );
}

export default RegisterAcknowledge;

export function CoachingAggrement() {
  return (
    <div className="terms-description">
      <h6>
        <u>
          <b>The Coaching Relationship</b>
        </u>
      </h6>
      <div className="terms-para">
        A coaching relationship can be a powerful experience involving the
        development of new possibilities, new levels of achievement and personal
        growth. The relationship between the coach and the coachee is founded
        upon mutual trust and respect, and the central focus is the client's
        well-being.
      </div>

      <div className="terms-para">
        Fundamental to a successful coaching experience is learning. The coach
        supports the coachee in reaching the desired outcome of the coachee. The
        coach supports the coachee in exploring new ways to view situations,
        individuals, and possibilities and develop new approaches, plans,
        strategies, and actions. This innovation involves learning. At times,
        the learning may be challenging. There may be times when the learning
        involves discoveries about how an individual’s actions play a part in
        situations the individual wishes to change. This type of self-discovery
        can, at times, be uncomfortable.
      </div>

      <div className="terms-para">
        We keep all coaching conversations confidential and will not divulge
        information about a coaching relationship without the client's written
        or verbal permission. We follow the International Coach Federation (ICF)
        Code of Ethics.
      </div>
      <div className="terms-para">
        <b>Session Scheduling:</b> The [COACHEE] is responsible for selecting an
        appropriate coach based on their personal and professional outcome
        aspirations. Upon choosing a coach, the [COACHEE] is responsible for
        booking and confirming the coaching sessions in accordance with their
        availability preferences. The scheduling of coaching sessions shall be
        subject to the coach's availability as set forth in their respective
        coaching calendar. <br />
        <br />
      </div>
      <h6>
        <u>
          <b>The Coaching Arrangement</b>
        </u>
      </h6>
      <div className="terms-para">
        This agreement, entered into by [COACH] and [COACHEE], shall commence
        upon the acceptance of its terms and conditions. The total number of
        coaching sessions shall be determined by the [COACHEE] and the number of
        coaching sessions allocated by their organisation/sponsor, with each
        session lasting forty-five (45) minutes. All coaching sessions must be
        conducted via the Video Call function of the Coach & Grow App at the
        pre-scheduled and confirmed times.
        <br />
        <br />
      </div>

      <h6>
        <u>
          <b>The Coaching Agreement</b>
        </u>
      </h6>
      <div className="terms-para">
        <b>
          I understand that coaching is not therapy, nor is the coach qualified
          to give legal or financial advice. I take full responsibility for all
          actions I take as a result of coaching, and neither Rise & Thrive
          Global nor [COACH] can be held responsible for any action taken or not
          taken as a result of coaching. I also agree to the following:
        </b>
      </div>
      <br />
      <ul>
        <li>
          I agree to be fully present and on time for all coaching
          conversations.
        </li>
        <li>
          I realise that I am completely responsible for my own growth and
          learning.
        </li>
        <li>
          I realise that there may be times in the coaching process when I may
          confront realisations that may be uncomfortable.
        </li>
        <li>
          I realise I need not discuss anything I am not comfortable discussing.
        </li>
        <li>
          I realise that I am responsible for my own actions and inactions.
        </li>
        <li>
          I realise that my coaching relationship is a resource and that it is
          up to me to fully utilise this resource.
        </li>
        <li>
          I agree to communicate any concerns I have about the nature of my
          coaching relationship, my experience of the coaching relationship or
          any particular interactions with the coach.
        </li>
        <li>
          I agree to give at least 24 hours notice if I need to change or cancel
          a session. Failure to provide the required notice will result in the
          missed session being counted as a completed session.
        </li>
      </ul>

      <div className="terms-para">
        [COACH] and [COACHEE] hereby acknowledge and affirm their commitment to
        the coaching agreement and relationship as established herein.
      </div>
    </div>
  );
}

import { useEffect } from "react";
import { Container } from "react-bootstrap";
import { useRouteLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { CoachingAggrement } from "../Register/RegisterAcknowledge";

function ReviewAgreement({ pageTitle }) {
  const routeData = useRouteLoaderData();
  const dispatch = useDispatch();
  const { authState } = useSelector((state) => state);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  return (
    <Container fluid={true}>
      <div className="page-heading">
        <h5 className="hd-txt">{`Welcome, ${authState.user.firstName} ${authState.user.lastName}!`}</h5>
      </div>
      <div className="review-wrap-term">
        <h4 className="frm-heading">Rise & Thrive Coaching Agreement</h4>
        <div>
          <div>
            {/* <div className="terms-description">
              <div className="terms-para">
                Lorem ipsum dolor sit amet. Et deserunt corporis aut quaerat placeat ea fugiat iusto. Non quasi deleniti
                et adipisci autem ad sunt consequatur. Et nesciunt illo sit dicta obcaecati non odio quidem hic quod
                voluptatem eos repellat possimus vel tenetur sunt vel nihil nesciunt. Sed quis nesciunt ut porro dolorem
                aut dolor quia 33 iusto omnis qui repudiandae odio ut reiciendis veniam. Et consequatur quam est
                nesciunt ullam non delectus voluptas est dicta aspernatur. <br />
              </div>
              <div className="terms-para">
                Sed voluptatem quod est voluptatibus omnis id galisum quis eos sequi optio aut quasi magni. Sed
                excepturi accusantium id obcaecati quas aut maiores omnis quo cupiditate esse et praesentium nobis in
                sapiente voluptate id impedit ducimus. Est quia recusandae sit dicta accusamus sed eaque optio! Et quos
                fuga cum quos fugit et reprehenderit suscipit est rerum odio in facilis voluptatum ad veniam rerum ea
                dicta odio. Non nesciunt odit aut modi veniam qui facilis explicabo. <br />
              </div>
              <div className="terms-para">
                Sed voluptatem quod est voluptatibus omnis id galisum quis eos sequi optio aut quasi magni. Sed
                excepturi accusantium id obcaecati quas aut maiores omnis quo cupiditate esse et praesentium nobis in
                sapiente voluptate id impedit ducimus. Est quia recusandae sit dicta accusamus sed eaque optio! Et quos
                fuga cum quos fugit et reprehenderit suscipit est rerum odio in facilis voluptatum ad veniam rerum ea
                dicta odio. Non nesciunt odit aut modi veniam qui facilis explicabo.
              </div>
              <div className="terms-para">
                Sed voluptatem quod est voluptatibus omnis id galisum quis eos sequi optio aut quasi magni. Sed
                excepturi accusantium id obcaecati quas aut maiores omnis quo cupiditate esse et praesentium nobis in
                sapiente voluptate id impedit ducimus. Est quia recusandae sit dicta accusamus sed eaque optio! Et quos
                fuga cum quos fugit et reprehenderit suscipit est rerum odio in facilis voluptatum ad veniam rerum ea
                dicta odio. Non nesciunt odit aut modi veniam qui facilis explicabo.
              </div>
            </div> */}
            <CoachingAggrement/>
          </div>
        </div>
      </div>
    </Container>
  );
}

export default ReviewAgreement;

import React, { useState, useEffect } from "react";
import { trackPromise } from "react-promise-tracker";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { AccreditationService } from "../../services";

export const ViewCoachCategory = ({ pageTitle }) => {
  const [categoryDetails, set_categoryDetails] = useState([]);
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchCategoryDetails() {
      try {
        const resp = await trackPromise(AccreditationService.getAccreditationById(id.id));
        const { data } = resp;
        if (data.success) {
          set_categoryDetails(data.data);
        } else {
          set_pageError(data.message || "Error in fetching Category details");
        }
      } catch (err) {
        console.error("Fetch category details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchCategoryDetails();
  }, [id.id]);
  return (
    <div className="text-left">
      <h4 className="col hd-txt">View Coach Category</h4>
      <div className="col-md">
        <table className="view-org-table table table-borderless bg-transparent w-50">
          <tbody className="bg-transparent">
            <tr>
              <td>Category Name</td>
              <td>{categoryDetails?.levelName}</td>
            </tr>
            <tr>
              <td>Category Code</td>
              <td>{categoryDetails?.levelCode}</td>
            </tr>
          </tbody>
        </table>
        <div className="g-0 frm-btn-grp  mt-4">
          <Button as={Link} to="/coachCategory" variant="clr-transparent" className="col-lg me-lg-3">
            Cancel
          </Button>
          <Button as={Link} to={`/topics/edit/${id.id}`} variant="clr-primary" type="submit" className="col-lg ms-lg-3">
            Edit Category
          </Button>
        </div>
      </div>
    </div>
  );
};

import { useState } from "react";
import { Button, Col, Container, Modal, Row } from "react-bootstrap";
import { useSelector } from "react-redux";
import { UserService } from "../../services";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { getUserProfile } from "../../stores/store";
import aggrement from "../../assets/files/Agreement.pdf";
import { CoachingAggrement } from "../Register/RegisterAcknowledge";

const StudentAgreement = (props) => {
  const { authState } = useSelector((state) => state);
  const [checked, setChecked] = useState(false);
  const [pageError, set_pageError] = useState("");
  const agreementAccepted = checked;
  //const [agreementAccepted, setagreementAccepted] = useState(false);
  const id = authState?.user?._id;

  const selectThemeData = useSelector(
    (state) => state.colorTheme.colorThemeData
  );

  async function onAcceptTerms() {
    try {
      const resp = await trackPromise(
        UserService.update(id, {
          agreementAccepted: agreementAccepted,
          contractAccepted: false,
        })
      );
      const { data } = resp;

      if (data.success) {
        // setagreementAccepted(true);
        toast.success(`Agreement Accepted`);
        getUserProfile();
      } else {
        set_pageError(data.message || "Error in fetching coach details");
      }
    } catch (err) {
      console.error("Fetch coach details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  return (
    <Modal
      show={true}
      size="lg"
      aria-labelledby="contained-modal-title-vcenter"
      centered
      fullscreen={false}
    >
      <Container fluid={true} className="modal-container">
        <Row className="row-height ">
          <Col xl={{ span: 10, offset: 1 }} className="">
            <div className="text-center my-3">
              <h4 className="hd-txt ">{`Welcome, ${authState.user.firstName}!`}</h4>
            </div>
            <div className="">
              <h4 className="frm-heading">Rise & Thrive Coaching Agreement</h4>
              <div>
                <div>
                  {/* <div className="terms-description">
                    <div className="terms-para">
                      Lorem ipsum dolor sit amet. Et deserunt corporis aut quaerat placeat ea fugiat iusto. Non quasi
                      deleniti et adipisci autem ad sunt consequatur. Et nesciunt illo sit dicta obcaecati non odio
                      quidem hic quod voluptatem eos repellat possimus vel tenetur sunt vel nihil nesciunt. Sed quis
                      nesciunt ut porro dolorem aut dolor quia 33 iusto omnis qui repudiandae odio ut reiciendis veniam.
                      Et consequatur quam est nesciunt ullam non delectus voluptas est dicta aspernatur. <br />
                    </div>
                    <div className="terms-para">
                      Sed voluptatem quod est voluptatibus omnis id galisum quis eos sequi optio aut quasi magni. Sed
                      excepturi accusantium id obcaecati quas aut maiores omnis quo cupiditate esse et praesentium nobis
                      in sapiente voluptate id impedit ducimus. Est quia recusandae sit dicta accusamus sed eaque optio!
                      Et quos fuga cum quos fugit et reprehenderit suscipit est rerum odio in facilis voluptatum ad
                      veniam rerum ea dicta odio. Non nesciunt odit aut modi veniam qui facilis explicabo. <br />
                    </div>
                    <div className="terms-para">
                      Sed voluptatem quod est voluptatibus omnis id galisum quis eos sequi optio aut quasi magni. Sed
                      excepturi accusantium id obcaecati quas aut maiores omnis quo cupiditate esse et praesentium nobis
                      in sapiente voluptate id impedit ducimus. Est quia recusandae sit dicta accusamus sed eaque optio!
                      Et quos fuga cum quos fugit et reprehenderit suscipit est rerum odio in facilis voluptatum ad
                      veniam rerum ea dicta odio. Non nesciunt odit aut modi veniam qui facilis explicabo.
                    </div>
                    <div className="terms-para">
                      Sed voluptatem quod est voluptatibus omnis id galisum quis eos sequi optio aut quasi magni. Sed
                      excepturi accusantium id obcaecati quas aut maiores omnis quo cupiditate esse et praesentium nobis
                      in sapiente voluptate id impedit ducimus. Est quia recusandae sit dicta accusamus sed eaque optio!
                      Et quos fuga cum quos fugit et reprehenderit suscipit est rerum odio in facilis voluptatum ad
                      veniam rerum ea dicta odio. Non nesciunt odit aut modi veniam qui facilis explicabo.
                    </div>
                    <div className="terms-para">
                      Sed voluptatem quod est voluptatibus omnis id galisum quis eos sequi optio aut quasi magni. Sed
                      excepturi accusantium id obcaecati quas aut maiores omnis quo cupiditate esse et praesentium nobis
                      in sapiente voluptate id impedit ducimus. Est quia recusandae sit dicta accusamus sed eaque optio!
                      Et quos fuga cum quos fugit et reprehenderit suscipit est rerum odio in facilis voluptatum ad
                      veniam rerum ea dicta odio. Non nesciunt odit aut modi veniam qui facilis explicabo.
                    </div>
                  </div> */}
                  <CoachingAggrement />
                </div>
                {!props.clickFlow ? (
                  <div className=" terms-check my-2">
                    <input
                      className="termsCheckBox"
                      type="checkbox"
                      name="terms"
                      value={checked}
                      onChange={(e) => setChecked((prevState) => !prevState)}
                    />
                    <span className="colorTheme ms-2" htmlFor="terms">
                      I acknowledge that I have read the agreement, I have{" "}
                      <a
                        href={aggrement}
                        download="Agreement.pdf"
                        style={{ color: "var(--clr-primary)" }}
                      >
                        downloaded a copy
                      </a>{" "}
                      of the agreement and that by clicking on 'Next' below, I
                      am formally accepting this coaching agreement.
                    </span>
                  </div>
                ) : null}
              </div>
              <div className="text-center">
                <Button
                  variant="clr-primary"
                  type="button"
                  disabled={!checked}
                  className="align-self-center px-4"
                  onClick={onAcceptTerms}
                >
                  Next
                </Button>
              </div>
            </div>
          </Col>
        </Row>
      </Container>
    </Modal>
  );
};

export default StudentAgreement;

import httpClient from "../httpClient";
const baseSlug = "/accreditation";

class httpService {
  getAll() {
    return httpClient.get(`${baseSlug}`);
  }

  getList() {
    return httpClient.get(`${baseSlug}/list`);
  }

  createAccreditation(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  getAccreditationById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  updateAccreditation(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }

  delete(id) {
    return httpClient.put(`${baseSlug}/delete/${id}`);
  }
}

export default new httpService();

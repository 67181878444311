import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button, Tab, Nav } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { BookingServices } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { faCalendar, faClock, faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import DropdownList from "../CustomComponent/DropdownList";

function StudentProgress({ pageTitle }) {
  const dispatch = useDispatch();
  const [allStudent, set_allStudent] = useState([]);
  const [activeTab, set_activeTab] = useState("tab01");
  const [appointmentList, set_appointmentList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [selectedStudent, set_selectedStudent] = useState(undefined);
  const [inProgessList, set_inProgessList] = useState([]);
  const [completedList, set_completedList] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchCoachStudent();
  }, []);

  useEffect(() => {
    if (selectedStudent) {
      fetchStudentAppointment(selectedStudent._id);
    } else {
      set_appointmentList([]);
    }
  }, [selectedStudent]);

  useEffect(() => {
    categoriseAppointment();
  }, [appointmentList]);

  async function fetchStudentAppointment(studentId) {
    try {
      const resp = await trackPromise(BookingServices.getBookingsByAssociatedUser(studentId));
      const { data } = resp;
      if (data.success) {
        set_appointmentList(data?.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisations catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchCoachStudent() {
    try {
      const resp = await trackPromise(BookingServices.getStudentByCoach());
      const { data } = resp;
      if (data.success) {
        set_allStudent(data?.data);
      } else {
        set_pageError(data.message || "Error in fetching Admins");
      }
    } catch (err) {
      console.error("Fetch Admins catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function categoriseAppointment() {
    const inProgess = [],
      completed = [];
    for (let i = 0; i < appointmentList.length; i++) {
      let bookingItem = appointmentList[i];
      if (bookingItem.bookStatus === "in-progress" || bookingItem.bookStatus === "confirmed") {
        inProgess.push(bookingItem);
      }
      if (bookingItem.bookStatus === "completed") {
        completed.push(bookingItem);
      }
    }
    set_inProgessList(inProgess);
    set_completedList(completed);
  }

  const handleSelect = (selectedList) => {
    set_selectedStudent(selectedList);
  };

  const getOptionLabelFunction = (option) => `${option?.firstName} ${option?.lastName}`;

  return (
    <Container fluid={true} className="main-content">
      {/* <div className="page-heading"> */}
      {/* <h4 className="col hd-txt">View Student Progress</h4>*/}
      {/* </div> */}

      <Row className="mb-4">
        <Col lg="12">
          <Form.Group className="frm-group">
            <Form.Label>Select Coachee</Form.Label>
            <Col xs={12} md={4}>
              <DropdownList
                name="oraganisation"
                value={selectedStudent}
                allStudent={allStudent}
                handleSelect={handleSelect}
                placeholder="Select Coachee"
                getOptionLabel={getOptionLabelFunction}
                isMulti={false}
              />
            </Col>{" "}
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col>
          {selectedStudent ? (
            <div className="tab-thm-01">
              <Tab.Container defaultActiveKey={activeTab}>
                <Row className="g-0 mb-4">
                  <Nav variant="tabs" className="">
                    <Nav.Link className="" eventKey="tab01">
                      All
                    </Nav.Link>
                    <Nav.Link className="" eventKey="tab02">
                      In Progress
                    </Nav.Link>
                    <Nav.Link className="" eventKey="tab03">
                      Completed
                    </Nav.Link>
                  </Nav>
                </Row>
                <Row className="g-0">
                  <Tab.Content>
                    <Tab.Pane eventKey="tab01">
                      {appointmentList?.length ? <CourseTable tblData={appointmentList} /> : <div></div>}
                    </Tab.Pane>

                    <Tab.Pane eventKey="tab02">
                      {inProgessList?.length ? <CourseTable tblData={inProgessList} /> : <div></div>}
                    </Tab.Pane>
                    <Tab.Pane eventKey="tab03">
                      {completedList?.length ? <CourseTable tblData={completedList} /> : <div></div>}
                    </Tab.Pane>
                  </Tab.Content>
                </Row>
              </Tab.Container>
            </div>
          ) : (
            <div>Please Select Coachee</div>
          )}
        </Col>
      </Row>
    </Container>
  );
}

export default StudentProgress;

function CourseTable({ tblData = [], needApproval = false }) {
  return (
    <div className="container-fluid">
      {tblData.map((itm, inx) => (
        <Row key={itm?._id}>
          <Col lg={3} md={4} xs={12}>
            <Row key={inx} className="inner-card-border my-1   mt-2 ">
              <Row className="mx-1 title-name">{itm.topic.topicName}</Row>
              {/* <Row className="mx-1 full-name">
                {`${itm.coach.firstName} 
                      ${itm.coach.lastName}`}
              </Row> */}
              <div className="d-flex justify-content-start flex-row mx-1 icon-status ">
                <div>
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
                <div className="mx-2 text-capitalize">{itm.bookStatus}</div>
              </div>
              <div className="d-flex justify-content-start flex-row mx-1 mt-1">
                {/* <Button
                  as={Link}
                  to="/assignresource"
                  size="sm"
                  variant="clr-transparent"
                  className="col-md-6 me-lg-6  "
                >
                  View Resources
                </Button> */}
                <Button
                  as={Link}
                  variant="clr-primary"
                  size="sm"
                  to={`appointment/${itm._id}`}
                  className="view-btn px-3 py-2 ms-0 mt-0"
                >
                  View Appointments
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
}

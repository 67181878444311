import React from "react";
import { useState } from "react";
import { useEffect } from "react";
import { Button, Form } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { Link, useParams } from "react-router-dom";
import { UserService } from "../../services";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import PillWithDelete from "../CustomComponent/PillBadge";
import { DocTable } from "./Coach.edit";

const ViewCoaches = ({ pageTitle }) => {
  const [coachDetails, set_coachDetails] = useState([]);
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchCoachDetails() {
      try {
        const resp = await trackPromise(UserService.getCoachById(id.id));
        const { data } = resp;
        if (data.success) {
          set_coachDetails(data.data);
        } else {
          set_pageError(data.message || "Error in fetching Coach details");
        }
      } catch (err) {
        console.error("Fetch coach details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchCoachDetails();
  }, [id.id]);

  return (
    <div className="text-left">
      <h4 className="col hd-txt">View Coach</h4>
      <div className="col-md">
        <table className="view-org-table table table-borderless bg-transparent w-75">
          <tbody className="bg-transparent">
            <tr>
              <td>First Name</td>
              <td>{coachDetails?.firstName}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{coachDetails?.lastName}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>{coachDetails?.username}</td>
            </tr>

            <tr>
              <td>Contact Number</td>
              <td>{coachDetails?.phone}</td>
            </tr>
            <tr>
              <td>Email Address</td>
              <td>{coachDetails?.email}</td>
            </tr>
            <tr>
              <td>Swift Code or Bank Identification Code (BIC)</td>
              <td>{coachDetails?.bankCode}</td>
            </tr>
            <tr>
              <td>Bank-State-Branch (BSB) Number</td>
              <td>{coachDetails?.bankBranchNumber}</td>
            </tr>
            <tr>
              <td>International Bank Account Number (IBAN)</td>
              <td>{coachDetails?.bankAccountNumber}</td>
            </tr>

            <tr>
              <td>Key Core Competencies</td>
              <td>
                {coachDetails?.topics?.map((i, index, array) => (
                  <span key={i.id}>
                    {i?.topicName} {index === array.length - 1 ? "" : ", "}
                  </span>
                ))}
              </td>
            </tr>
            <tr>
              <td>Additional Qualifications and Credentials</td>
              <td>{coachDetails?.skills}</td>
            </tr>
            <tr>
              <td>Professional Biography</td>
              <td>{coachDetails?.aboutSelf}</td>
            </tr>
            <tr>
              <td>Experience and Achievements</td>
              <td>{coachDetails?.experienceAchieved}</td>
            </tr>
            <tr>
              <td>
                <Form.Group className="frm-group">
                  <Form.Label>Profile Picture</Form.Label>
                  <div className="item">
                    {coachDetails?.profileImage ? (
                      <PillWithDelete
                        text={coachDetails?.profileImage}
                        hideDelete={true}
                        assetsDir={coachDetails.assetsDir}
                      />
                    ) : null}
                  </div>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td>
                <Form.Group className="frm-group">
                  <Form.Label>Certificates</Form.Label>
                  {coachDetails.certificateDocs?.length > 0 ? (
                    <DocTable tblData={coachDetails.certificateDocs} coachDetails={coachDetails} hideDelete={true} />
                  ) : null}
                </Form.Group>
              </td>
            </tr>

            <tr>
              <td>
                <Form.Group className="frm-group">
                  <Form.Label>'Professional Introduction Video</Form.Label>
                  <div className="item">
                    {coachDetails?.profileVideo ? (
                      <PillWithDelete
                        text={coachDetails?.profileVideo}
                        hideDelete={true}
                        assetsDir={coachDetails.assetsDir}
                      />
                    ) : null}
                  </div>
                </Form.Group>
              </td>
            </tr>
            <tr>
              <td>Level of Accreditation</td>
              <td>{coachDetails?.accreditationLevel?.levelName}</td>
            </tr>
            <tr>
              <td>Assign Hourly Price</td>
              <td>{coachDetails?.coachPrice}</td>
            </tr>
          </tbody>
        </table>
        <div className="g-0 frm-btn-grp mt-4">
          <Button as={Link} to={"/coach"} variant="clr-transparent" className="col-lg me-2">
            Cancel
          </Button>
          <Button as={Link} to={`/coach/edit/${id.id}`} variant="clr-primary" type="submit" className="col-lg ms-2">
            Edit Coach
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewCoaches;

import { useEffect, useState, useMemo, useRef, useCallback, forwardRef, useImperativeHandle } from "react";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { ToggleButton, ToggleButtonGroup, ButtonGroup } from "react-bootstrap";
import { Link, useRouteLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { trackPromise } from "react-promise-tracker";
import { StatsService, ReportService, UserService } from "../../services";
import ReportBarChart from "../Dashboard/Component/ReportComponentBarChart";
import ReportAdminLine from "../Dashboard/Component/ReportComponentBarChart";

import ReportAdminPie from "../Dashboard/Component/ReportComponentPie";

import DropdownList from "../CustomComponent/DropdownList";

import ReactDatePicker from "react-datepicker";
import moment from "moment";
import "ag-grid-community/styles/ag-grid.css"; // Core grid CSS, always needed
import "ag-grid-community/styles/ag-theme-alpine.css"; // Optional theme CSS
import { AgGridReact } from "ag-grid-react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faTable, faChartColumn, faDownload } from "@fortawesome/free-solid-svg-icons";

function AdminReport({ pageTitle }) {
  const dispatch = useDispatch();

  const [userStat, set_userStat] = useState([]);
  const [pageError, set_pageError] = useState("");

  const [userCount, set_userCount] = useState([]);
  const [studentTypeList, set_studentType] = useState([]);
  const [selectedStudentType, set_selectedStudentType] = useState(undefined);

  const [StudentData, setData] = useState([]);
  const [topicList, set_topicList] = useState([]);
  const [selectedTopic, set_selectedTopic] = useState(undefined);

  const [topicData, setTopicData] = useState([]);
  const [orgCoachList, set_coachList] = useState([]);
  const [selectedorgCoach, set_selectedorgCoach] = useState(undefined);

  const [startDate, setStartDate] = useState(new Date());
  const [endDate, setEndDate] = useState(new Date());

  const [dateError, setDateError] = useState(false);
  const [selectedView, setSelectedView] = useState("table");
  const [selectCoach, setSelectedCoach] = useState("table");
  const [selectTopic, setSelectedTopic] = useState("table");
  const [orgStartDate, setOrgStartDate] = useState(new Date());
  const [orgEndDate, setOrgEndDate] = useState(new Date());

  const studentTableRef = useRef();
  const topicTableRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchStudentCount();
    fetchStudentType();
    StudentusesHours();
    fetchTopic();
    TopicHours();
    fetchorgCoach();
    // orgCoachHours();
  }, []);

  const columnDefs = [
    { headerName: "Coachee Name", field: "firstName", width: 150, cellStyle: { textAlign: "center" } },
    { headerName: "Coachee Type Name ", field: "studentTypeName", width: 200, cellStyle: { textAlign: "center" } },
    { headerName: "Sessions", field: "usedSessions", width: 100, cellStyle: { textAlign: "center" } },
  ];

  const topicColumnDefs = [
    { headerName: "Topic Name", field: "topicName", width: 200, cellStyle: { textAlign: "center" } },
    { headerName: "Coaching Sessions", field: "coachingSessions", width: 200, cellStyle: { textAlign: "center" } },
  ];

  const onExportStudentData = () => {
    if (studentTableRef.current) {
      studentTableRef.current.exportData();
    }
  };

  const onExportTopicData = () => {
    if (topicTableRef.current) {
      topicTableRef.current.exportData();
    }
  };

  const localFormatDate = (date) => {
    const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";
    const convertedDate = moment(date).format(outputFormat);
    return convertedDate;
  };

  const localFormatStartDate = (date) => {
    const givenDate = moment(date);
    if (givenDate) {
      const result = givenDate.set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      return result.format();
    }
  };

  const endDateFormat = (date) => {
    const givenDate = moment(date);
    const result = givenDate.set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    return result.format();
  };

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start) {
      setDateError(false);
    }
  };
  const onChangeOrg = (dates) => {
    const [start, end] = dates;
    setOrgStartDate(start);
    setOrgEndDate(end);
    if (start) {
      setDateError(false);
    }
  };

  const startDateOrgSet = localFormatStartDate(orgStartDate);

  const startDateSet = localFormatStartDate(startDate);

  const endDateOrgSet = endDateFormat(orgEndDate);

  const endDateSet = endDateFormat(endDate);

  const localStartDateOrg = localFormatDate(startDateOrgSet);
  const localEndDateOrg = localFormatDate(endDateOrgSet);

  const localStartDate = localFormatDate(startDateSet);
  const localEndDate = localFormatDate(endDateSet);

  // async function fetchUserStat() {
  //   try {
  //     const resp = await trackPromise(StatsService.getOverviewStats());
  //     const { data } = resp;
  //     if (data.success) {
  //       set_userStat(data.data);
  //     } else {
  //       set_pageError(data.message || "Error in fetching Coaches");
  //     }
  //   } catch (err) {
  //     console.error("Fetch Coaches catch => ", err);
  //     if (err.code !== "ERR_NETWORK") {
  //       set_pageError(err.response.data.message);
  //     } else {
  //       set_pageError(err.message);
  //     }
  //   }
  // }

  async function fetchStudentCount() {
    try {
      const resp = await trackPromise(ReportService.getStudentCount());
      const { data } = resp;
      if (data.success) {
        set_userCount(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchStudentType() {
    try {
      const resp = await trackPromise(UserService.getOrgStudents());
      const { data } = resp;
      if (data.success) {
        let studList = data?.data?.filter((itm) => !itm?.isDeleted);
        set_studentType(studList);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchTopic() {
    try {
      const resp = await trackPromise(ReportService.getTopic());
      const { data } = resp;
      if (data.success) {
        set_topicList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchorgCoach() {
    try {
      const resp = await trackPromise(ReportService.getorgCoach());
      const { data } = resp;
      if (data.success) {
        set_coachList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function StudentusesHours() {
    try {
      const resp = await trackPromise(
        ReportService.getOrgStudentUsage({
          startDate: localStartDate,
          endDate: localEndDate,
          orgUsers: selectedStudentType,
        })
      );
      const { data } = resp;
      if (data.success) {
        setData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisationsHours catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function TopicHours() {
    try {
      const resp = await trackPromise(
        ReportService.getOrgTopicHours({
          startDate: localStartDateOrg,
          endDate: localEndDateOrg,
          orgTopics: selectedTopic,
        })
      );
      const { data } = resp;
      if (data.success) {
        setTopicData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisationsHours catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const handleOrgCoach = (selectedList) => {
    set_selectedorgCoach(selectedList);
  };

  const handleViewTopic = (value) => {
    setSelectedTopic(value);
  };
  const handleViewCoach = (value) => {
    setSelectedCoach(value);
  };
  const handleViewChange = (value) => {
    setSelectedView(value);
  };

  const handleSelectStudent = (selectedList) => {
    set_selectedStudentType(selectedList);
  };

  const handleSelectTopic = (selectedList) => {
    set_selectedTopic(selectedList);
  };

  const getOptionLabelFunction1 = (option) => `${option.firstName} ${option.lastName}`;
  const getOptionLabelFunction2 = (option) => `${option.topicName}`;

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">{/*<h4 className="hd-txt">View Reports</h4> */}</div>

      <Row className="px-3">
        <Col md={12}>
          <div>
            <Row className="">
              <Row>
                <h5 className="hd-txt">User Sessions Breakdown</h5>
              </Row>
              <Row>
                <h6>Gain insights into how users allocate and utilize their sessions during different time frames.</h6>
              </Row>
              <Row className="my-2 report-row">
                <Col xs={12} md={4}>
                  <DropdownList
                    name="studenttype"
                    value={selectedStudentType}
                    allStudent={studentTypeList}
                    handleSelect={handleSelectStudent}
                    placeholder="Select Coachee"
                    getOptionLabel={getOptionLabelFunction1}
                    isMulti={true}
                  />
                </Col>
                <Col md={"auto"} className="custom-date-picker datePicker">
                  <ReactDatePicker
                    placeholderText="Select Date"
                    selected={startDate}
                    // minDate={new Date()}
                    onChange={onChange}
                    utcOffset={0}
                    startDate={startDate}
                    endDate={endDate}
                    selectsRange
                    onBlur={() => {
                      if (!startDate) {
                        setDateError(true);
                      }
                    }}
                    className="datePickeradmin"
                    // wrapperClassName="datePickerWrapper"
                  />
                </Col>
                <Col xs={12} md={12} lg={12} xl={4} className="">
                  <Button variant="clr-primary" className="btn-report " onClick={StudentusesHours}>
                    Apply
                  </Button>
                  {/* </Col>
                <Col> */}
                  <ButtonGroup className="btn-report  justify-content-end">
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="table"
                      checked={selectedView === "table"}
                      onClick={() => handleViewChange("table")}
                      variant={selectedView === "table" ? "clr-primary" : "bg-transparent border border-clr-primary"}
                    >
                      <FontAwesomeIcon icon={faTable} variant="clr-primary" />
                    </Button>
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="graph"
                      checked={selectedView === "graph"}
                      onClick={() => handleViewChange("graph")}
                      variant={selectedView === "graph" ? "clr-primary" : "bg-transparent border border-clr-primary"}
                    >
                      <FontAwesomeIcon icon={faChartColumn} />
                    </Button>
                  </ButtonGroup>
                  <Button variant="clr-primary" className="btn-download ms-2" onClick={onExportStudentData}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col>
                <Row className="py-2 px-2 my-2">
                  <h6> User Name</h6>

                  <Col md={9}>
                    {selectedView === "table" ? (
                      // <StudentTable tableData={StudentData} />
                      <GenericTable
                        data={StudentData}
                        columnDefs={columnDefs}
                        ref={studentTableRef}
                        onExportOrgData={onExportStudentData}
                      />
                    ) : (
                      // <ReportAdminBar data={StudentData} />
                      <ReportBarChart
                        data={StudentData}
                        xAxisLabel="Sessions"
                        yAxisLabel="User Name"
                        xValueKey="usedSessions"
                        yValueKey="firstName"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>
          <div className="mt-5">
            <Row className=" ">
              <Row>
                <h5 className="hd-txt">User Topic Sessions Breakdown</h5>
              </Row>
              <Row>
                <h6>Gain insights into how users allocate and utilize their sessions during different time frames.</h6>
              </Row>
              <Row className="my-2 report-row">
                <Col md={4}>
                  <DropdownList
                    name="topic"
                    value={selectedTopic}
                    allStudent={topicList}
                    handleSelect={handleSelectTopic}
                    placeholder="Select Topics"
                    getOptionLabel={getOptionLabelFunction2}
                    isMulti={true}
                  />
                </Col>
                <Col md={"auto"} className="custom-date-picker datePicker">
                  <ReactDatePicker
                    placeholderText="Select Date"
                    selected={orgStartDate}
                    // minDate={new Date()}
                    onChange={onChangeOrg}
                    utcOffset={0}
                    startDate={orgStartDate}
                    endDate={orgEndDate}
                    selectsRange
                    onBlur={() => {
                      if (!orgStartDate) {
                        setDateError(true);
                      }
                    }}
                    className="datePickeradmin"
                    // wrapperClassName="datePickerWrapper"
                  />
                </Col>
                <Col xs={12} md={12} lg={12} xl={4} className="">
                  <Button variant="clr-primary" className="btn-report  " onClick={TopicHours}>
                    Apply
                  </Button>
                  {/* </Col>
                <Col> */}
                  <ButtonGroup className="btn-report  justify-content-end">
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="table"
                      checked={selectTopic === "table"}
                      onClick={() => handleViewTopic("table")}
                      variant={selectTopic === "table" ? "clr-primary" : "bg-transparent border border-clr-primary"}
                    >
                      <FontAwesomeIcon icon={faTable} variant="clr-primary" />
                    </Button>
                    <Button
                      type="radio"
                      // size="sm"
                      // variant="clr-primary"
                      name="view"
                      value="graph"
                      checked={selectTopic === "graph"}
                      onClick={() => handleViewTopic("graph")}
                      variant={selectTopic === "graph" ? "clr-primary" : "bg-transparent border border-clr-primary"}
                    >
                      <FontAwesomeIcon icon={faChartColumn} />
                    </Button>
                  </ButtonGroup>
                  <Button variant="clr-primary" className="btn-download ms-2" onClick={onExportTopicData}>
                    <FontAwesomeIcon icon={faDownload} />
                  </Button>
                </Col>
              </Row>
            </Row>
            <Row>
              <Col>
                <Row className="my-2 ">
                  <h6> Topic Name</h6>

                  <Col md={9}>
                    {selectTopic === "table" ? (
                      <GenericTable
                        data={topicData}
                        columnDefs={topicColumnDefs}
                        ref={topicTableRef}
                        onExportOrgData={onExportTopicData}
                      />
                    ) : (
                      // <ReportTopicBar data={topicData} />
                      <ReportBarChart
                        data={topicData}
                        xAxisLabel="Sessions"
                        yAxisLabel="Topic Name"
                        xValueKey="coachingSessions"
                        yValueKey="topicName"
                      />
                    )}
                  </Col>
                </Row>
              </Col>
            </Row>
          </div>

          <div className="mt-5">
            <Row className="py-2 mt-3">
              <Row>
                <h5 className="hd-txt">User Engagement Report</h5>
              </Row>
              <Row>
                <h6>Monitor user activity levels to identify trends in user engagement and app usage.</h6>
              </Row>

              <Row>
                <Col>
                  <Row className="py-2 px-2">
                    {/* <h6>Coach Name</h6> */}
                    <Col md={8}>
                      <ReportAdminPie legend={false} countData={userCount} />
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Row>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminReport;

const GenericTable = forwardRef(({ data, columnDefs }, ref) => {
  const gridRef = useRef();

  // Use useImperativeHandle to expose a function to the parent component
  useImperativeHandle(
    ref,
    () => ({
      exportData: () => {
        if (gridRef.current) {
          gridRef.current.api.exportDataAsCsv({
            fileName: "Data", // Change the filename as needed
          });
        }
      },
    }),
    []
  );

  const defaultColDef = {
    flex: 1,
    sortable: true,
    suppressMovable: true,
    filter: true,
  };

  const onFirstDataRendered = (params) => {
    gridRef.current.api.sizeColumnsToFit();
  };

  return (
    <>
      <div className="ag-theme-alpine" style={{ height: "300px", width: "100%" }}>
        <AgGridReact
          ref={gridRef}
          columnDefs={columnDefs}
          defaultColDef={defaultColDef}
          rowData={data}
          //domLayout="autoHeight"
          pagination={true}
          paginationPageSize={5}
          onFirstDataRendered={onFirstDataRendered}
          localeText={{
            noRowsToShow: "No Data found.",
            textAlign: "left",
          }}
        />
      </div>
    </>
  );
});

import { useState } from "react";
import { Link } from "react-router-dom";
import {
  Container,
  Row,
  Col,
  Button,
  Form,
  Modal,
  InputGroup,
} from "react-bootstrap";
import "./Register.css";

function ThankYou() {
  function handleClick(){
 window.open('', '_self', '');
    window.close();

  }
  return (
    <Container fluid={true} className="register-container" centered>
      <Row className="row-thank">
        <Col lg={{ span: 6, offset: 3 }}>
          <div className="register-wrap">
            <h4 className="frm-heading mb-0">Thank You for Registering</h4>
            <div>
              <div>
                <div className="terms-description thanks-terms-description">
                  <div className="terms-para  thanks-terms-para">
                    Thank you for creating your profile as a coach with the
                    Coach & Grow App. We are delighted to have you join our
                    community.
                    </div>
                    <div className="terms-para thanks-terms-para">
                    If our team requires any additional information, they will
                    contact you directly. Once your registration is approved,
                    you will receive an email with instructions on creating your
                    password and accessing the app.
                    </div>
                    <div className="terms-para thanks-terms-para">
                    We appreciate your patience and look forward to your active
                    participation.
                  </div>
                  <div className="terms-para thanks-footer">The Rise & Thrive Global Team</div>

                </div>
              </div>

              <div className="d-flex justify-content-center mt-4">
              <Button
                  variant="clr-primary"
                  type="submit"
                  className="w-80 mb-2"
                  as={Link}
                  to="/login"
                >
                  Close
                </Button>
              </div>
            </div>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default ThankYou;

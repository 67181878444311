import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useRouteLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faUser, faListNumeric, faPeopleGroup } from "@fortawesome/free-solid-svg-icons";
import { trackPromise } from "react-promise-tracker";
import { BookingServices, StatsService } from "../../services";
import { faCalendar, faCalendarAlt, faCircleCheck, faClock } from "@fortawesome/free-regular-svg-icons";
import { formatDate, formatTime } from "../../utilities/functions";

function DashboardStudent() {
  const routeData = useRouteLoaderData("dashboard");
  const dispatch = useDispatch();
  const { authState, appState } = useSelector((state) => state);
  const [userStat, set_userStat] = useState([]);
  const [appointmentList, set_appointmentList] = useState([]);
  const [upcomingSlotList, set_upcomingSlotListList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [allocatedsession, set_allocatedsession] = useState();

  useEffect(() => {
    dispatch(setRouteData({ ...routeData }));
    fetchAppointments();
    fetchUpcomingSlots();
    fetchUserStat();
  }, []);

  async function fetchAppointments() {
    try {
      const resp = await trackPromise(BookingServices.getUserBooking());
      const { data } = resp;
      if (data.success) {
        set_appointmentList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchUpcomingSlots() {
    try {
      const resp = await trackPromise(BookingServices.getUpcomingSlots());
      const { data } = resp;
      if (data.success) {
        set_upcomingSlotListList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchUserStat() {
    try {
      const resp = await trackPromise(StatsService.getOverviewStats());
      const { data } = resp;
      if (data.success) {
        set_userStat(data.data);
        const totalSessions = data.data.studentSessions + data.data.consumedSessions;
        set_allocatedsession(totalSessions);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const colorThemeData = useSelector((state) => state.colorTheme.colorThemeData);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{`Welcome, ${authState.user.firstName} ${authState.user.lastName}!`}</h4>
      </div>
      <Row className="">
        <Col>
          <Row className="">
            <Col>
              <Row className="mb-3">
                <h4 className="hd-txt">Quick Actions</h4>
              </Row>
              <Row className="d-flex flex-wrap">
                <Col xs={12} md={6} lg={6} xl={3} className="mb-3">
                  <Col className="inner-card">
                    <Row className="text-dash">
                      <Col xs={9} md={8}>
                        <h5 className="hd-txt">{allocatedsession ? allocatedsession : 0}</h5>
                        <h6>Sessions</h6>
                      </Col>
                      <Col xs={2} md={2} className="d-flex align-items-center justify-content-">
                        <FontAwesomeIcon icon={faPeopleGroup} size="3x" color="#B18C93" />
                      </Col>
                    </Row>

                    <Row className="px-2 mt-2">
                      <Button variant="clr-primary" style={{ cursor: "default" }} to="">
                        Allocated Sessions
                      </Button>
                    </Row>
                  </Col>
                </Col>

                <Col xs={12} md={6} lg={6} xl={3} className="mb-3">
                  <Col className="inner-card">
                    <Row className="py-2 d-flex justify-content-center">
                      <FontAwesomeIcon icon={faUser} size="3x" color={colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"} />
                    </Row>
                    <Row className="px-2 mt-1">
                      <Button
                        variant="clr-primary"
                        as={Link}
                        to={
                          authState.user.hasGoal
                            ? `/${authState.user.organisation.orgSlug}/manageCoaching`
                            : `/${authState.user.organisation.orgSlug}/bookslots`
                        }
                      >
                        {authState.user.hasGoal ? "Manage Sessions" : "Browse Coaches"}
                      </Button>
                    </Row>
                  </Col>
                </Col>
                <Col xs={12} md={6} lg={6} xl={3} className="mb-3">
                  <Col className="inner-card">
                    <Row className="text-dash">
                      <Col xs={9} md={8}>
                        <h5 className="hd-txt">{upcomingSlotList.length ? upcomingSlotList.length : 0}</h5>
                        <h6>Appointments</h6>
                      </Col>
                      <Col xs={2} md={2} className="d-flex align-items-center justify-content-">
                        <FontAwesomeIcon icon={faCalendarAlt} size="3x" color="#B18C93" />
                      </Col>
                    </Row>

                    <Row className="px-2 mt-2">
                      <Button
                        variant="clr-primary"
                        as={Link}
                        to={`/${authState.user.organisation.orgSlug}/manageCoaching`}
                        onClick={() => {}}
                      >
                        Upcoming Appointments
                      </Button>
                    </Row>
                  </Col>
                </Col>

                <Col xs={12} md={6} lg={6} xl={3} className="mb-3">
                  <Col className="inner-card">
                    <Row className="py-2 d-flex justify-content-center">
                      <FontAwesomeIcon icon={faBook} size="3x" color={colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"} />
                    </Row>
                    <Row className="px-2 mt-1">
                      <Button
                        variant="clr-primary"
                        as={Link}
                        to={`/${authState.user.organisation.orgSlug}/viewresources`}
                      >
                        View Resources
                      </Button>
                    </Row>
                  </Col>
                </Col>
              </Row>
            </Col>
          </Row>

          <Row className=" my-2 py-3">
            <Col>
              <Row>
                <Col md={3} xs={12} sm={12}>
                  <h5 className="hd-txt mx-2">Upcoming Appointments</h5>
                </Col>
                <Col
                  className="d-flex  text-decoration-underline"
                  as={Link}
                  to={`/${authState.user.organisation.orgSlug}/manageCoaching`}
                  style={{ color: `${colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"}` }}
                >
                  View More
                </Col>
              </Row>
              <Row>
                <Col md={4} xs={12} sm={12}>
                  {upcomingSlotList.slice(0, 5).map((a, index) => (
                    <Row key={index} className="inner-card-border g-1  my-1 mx-2">
                      <Row className="mx-1  ">
                        {`${a.topic.topicName} | ${a.coach.firstName} 
                        ${a.coach.lastName}`}
                      </Row>
                      <div className="d-flex justify-content-start flex-row mx-2">
                        <div>
                          <FontAwesomeIcon icon={faCalendar} />
                        </div>
                        <div className="mx-2">{formatDate(a?.fromDateTime)}</div>
                      </div>
                      <div className="d-flex justify-content-start flex-row mx-2">
                        <div>
                          <FontAwesomeIcon icon={faClock} />
                        </div>
                        <div className="mx-2">
                          {formatTime(a?.fromDateTime)} - {formatTime(a?.toDateTime)}
                        </div>
                      </div>
                    </Row>
                  ))}

                  {upcomingSlotList.length === 0 && <Row className=" mx-2">No Appointment Found</Row>}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        {/* <Col>
          <Row className="px-4 quick-action mx-1">
            <Col>
              <Row>
                <Col>
                  <h4 className="hd-txt">My Sessions</h4>
                </Col>
                <Col
                  className="d-flex justify-content-end text-decoration-underline "
                  as={Link}
                  to={`/${authState.user.organisation.orgSlug}/manageCoaching`}
                  style={{ color: `${colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"}` }}
                >
                  View More
                </Col>
              </Row>
              <Row>
                <Col>
                  {appointmentList.map((a, index) => (
                    <Row key={index} className="inner-card-border my-1  me-5 mt-3">
                      <Row className="mx-1 title-name">{a.topic.topicName}</Row>
                      <Row className="mx-1 full-name">
                        {`${a.coach.firstName} 
                        ${a.coach.lastName}`}
                      </Row>
                      <div className="d-flex justify-content-start flex-md-row mx-1 icon-status">
                        <div>
                          <FontAwesomeIcon icon={faCircleCheck} />
                        </div>
                        <div className="mx-2">{a.bookStatus === "confirmed" ? "In Progress" : "Completed"}</div>
                      </div>
                      <div className="d-flex justify-content-start flex-row mx-1 mt-2">
                        {/* <Button
                          as={Link}
                          to={`/${authState.user.organisation.orgSlug}/viewresources`}
                          size="sm"
                          variant="clr-transparent"
                          className="col-md-3 me-lg-1"
                        >
                          View Resources
                        </Button>\
                        <Button
                          variant="clr-primary"
                          size="sm"
                          className="col md-9 ms-2"
                          as={Link}
                          to={`/${authState.user.organisation.orgSlug}/manageCoaching/appointment/${a._id}`}
                        >
                          View Appointments
                        </Button>
                      </div>
                    </Row>
                  ))}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col> */}
      </Row>
    </Container>
  );
}

export default DashboardStudent;

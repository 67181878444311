import { useContext, useEffect, useState } from 'react'
import { Chat, useCreateChatClient } from 'stream-chat-react';
import { chatApikey } from './envConfig';

//   useCreateChatClient is custom hook for connection estabilsh and  when it unmount it also disconnect   the connection 
export function ChatConfig(streamDetail,userdetail,image){
    const client = useCreateChatClient({
        apiKey: chatApikey,
        tokenOrProvider: streamDetail.token,
        userData: { id: streamDetail.id},
        image:image
      });

      return client;
}


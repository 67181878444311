import { useState, useEffect } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Outlet } from "react-router-dom";
import { Container } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBars, faXmark } from "@fortawesome/free-solid-svg-icons";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { requestNotificationPermission, fcmMessaging } from "../firebaseInit";
import FcmService from "../services/fcmToken.service";
import Header from "../components/Header/Header";
import Sidenav from "../components/Sidenav/Sidenav";
import { debounceFn } from "../utilities/functions";
import { BREAK_POINTS } from "../utilities/constants";
import { USER_ROLES } from "../AppRoutes";
import "./ProtectedLayout.css";
import { getColorTheme, getColorThemeData } from "../stores/colorThemeSlice";
import { onMessage } from "firebase/messaging";
import { v4 as uuidv4 } from 'uuid';

function ProtectedLayout() {
  const { authState } = useSelector((state) => state);

  const [winDim, set_winDim] = useState({
    width: window.innerWidth,
    height: window.innerHeight,
  });
  const [navBroken, set_navBroken] = useState(false);
  const [navToggled, set_navToggled] = useState(false);
  const [navCollapsed, set_navCollapsed] = useState(false);
  const [navClasses, set_navClasses] = useState(["main-wrapper"]);
  const [openNavBar, setOpenNavBar] = useState(false);
  const dispatch = useDispatch();

  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);

  const adminStudentTheme = authState?.user?.organisation?.uiTheme;

  useEffect(() => {
    dispatch(getColorTheme(adminStudentTheme));
    dispatch(getColorThemeData(adminStudentTheme));
  }, [adminStudentTheme, dispatch]);
  useEffect(() => {  
    const fetchData = async () => {
      const token = await requestNotificationPermission();
    //foreground
      onMessage(fcmMessaging , (payload) => {
         console.log(payload)
    const notificationTitle = payload?.data?.title??"";
    const notificationOptions = {
      body: payload?.data?.body?? '',
    };
   console.log("notification coming.......................")
    new Notification (notificationTitle, notificationOptions);    
  });
    if(token){
             //we create or update device id
            let deviceId= createOrUpdateDeviceIdentifier()
       await  saveOrupdateUserFcmToken(token,deviceId);
      }
    }
 fetchData();
  }, [])

  useEffect(() => {
    const debouncedHandleResize = debounceFn(function handleResize() {
      set_winDim({
        height: window.innerHeight,
        width: window.innerWidth,
      });
    }, 100);
    window.addEventListener("resize", debouncedHandleResize);
    return () => window.removeEventListener("resize", debouncedHandleResize);
  }, []);

  useEffect(() => {
    winDim.width < BREAK_POINTS.LG ? set_navBroken(true) : set_navBroken(false);
  }, [winDim]);

  useEffect(() => {
    let nav_classes = [...navClasses];
    if (navBroken) nav_classes.push("sidenav-broken");
    else nav_classes = nav_classes.filter((cls) => cls !== "sidenav-broken");
    set_navClasses(nav_classes);
  }, [navBroken]);

  useEffect(() => {
    let nav_classes = [...navClasses];
    if (navCollapsed) nav_classes.push("sidenav-collapsed");
    else nav_classes = nav_classes.filter((cls) => cls !== "sidenav-collapsed");
    set_navClasses(nav_classes);
  }, [navCollapsed]);

  const toggleSidenav = () => set_navToggled(!navToggled);

  const collapseSidenav = () => set_navCollapsed(!navCollapsed);

  async function saveOrupdateUserFcmToken(token,deviceId) {
    try {
      const resp = await trackPromise(FcmService.saveOrUpdateToken({ token,deviceId }));
      const { data } = resp;
      if (data.success) {
        // toast.success("User Fcm token success", { position: "bottom-left" });
      } else {
        toast.error(data.message || "User Fcm token error");
      }
    } catch (err) {
      console.error("User Fcm token create catch => ", err);
      toast.error(err.message || "User Fcm token error");
    }
  }

  function createOrUpdateDeviceIdentifier(){
    let deviceId = localStorage.getItem('deviceId');
    if (!deviceId) {
        deviceId = uuidv4();
        localStorage.setItem('deviceId', deviceId);
    }
    return deviceId;
  }

  const handleOpenNavBar = () => {
    setOpenNavBar(!openNavBar);
  };

  return (
    <Container
      fluid={true}
      className={`protected-layout g-0 ${adminStudentTheme ? adminStudentTheme : ""} ${colorTheme ? colorTheme : ""}`}
    >
      <div className={navClasses.join(" ")}>
        {/* {navBroken ? (
          <div className="sidenav-toggler" onClick={collapseSidenav}>
            <FontAwesomeIcon size="xl" icon={faAlignLeft} color={THEME.CLR_PRIMARY} title="Toggle Sidenav" />
          </div>
        ) : (
          <></>
        )} */}
        <Sidenav
          isCollapsed={navCollapsed}
          collapseNav={collapseSidenav}
          changedTheme={adminStudentTheme}
          openNavBar={openNavBar}
        />

        <FontAwesomeIcon
          icon={openNavBar ? faXmark : faBars}
          size="1x"
          // color={adminStudentTheme.CLR_PRIMARY}
          onClick={handleOpenNavBar}
          className="sidebar-toggle-btn"
        />
        <main className="view-main">
          <header className="view-header">
            <Header collapseSidenav={collapseSidenav} />
          </header>
          <article className="view-article">
            <Outlet />
          </article>
        </main>
      </div>
    </Container>
  );
}

export default ProtectedLayout;

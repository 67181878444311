import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import store, { clearAppData } from "./stores/store";
import reportWebVitals from "./reportWebVitals";
import { injectStoreDependencies } from "./httpClient";
import "bootstrap/dist/css/bootstrap.min.css";
import "./styles/theme.css";
import "./styles/override.scss";
import "./styles/buttons.css";
import "./styles/custom.css";

import AgoraRTC, { AgoraRTCProvider } from "agora-rtc-react";
// AgoraRTC.createCameraVideoTrack({
//   encoderConfig: '360p_1',
// }).then(localVideoTrack => {/** ... **/ });

// const client = AgoraRTC.createClient({ mode: "live", codec: "vp8" });


injectStoreDependencies(store, clearAppData);
const root = ReactDOM.createRoot(document.getElementById("root"));
root.render(
  // <React.StrictMode>
  <Provider store={store}>
    {/* <AgoraRTCProvider client={client}> */}
      <App />
    {/* </AgoraRTCProvider> */}
  </Provider>
  // </React.StrictMode>
);

// If you want to start measuring performance in your app, pass a function
// to log results (for example: reportWebVitals(console.log))
// or send to an analytics endpoint. Learn more: https://bit.ly/CRA-vitals
reportWebVitals();

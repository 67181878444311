import {
  CallingState,
  SpeakerLayout,
  StreamCall,
  StreamTheme,
  StreamVideo,
  StreamVideoClient,
  useCallStateHooks,
  CancelCallButton,
  ScreenShareButton,
  SpeakingWhileMutedNotification,
  ToggleAudioPublishingButton,
  ToggleVideoPublishingButton,
  useCall,
} from "@stream-io/video-react-sdk";
import { useEffect, useState } from "react";
import "./StreamVideo.css";
import { useSelector } from "react-redux";

import "@stream-io/video-react-sdk/dist/css/styles.css";

import EnvConfig, { chatApikey } from "../../envConfig";

// const apiKey ='66r6javkrvp7'; // the API key can be found in the "Credentials" section
// const token = 'eyJhbGciOiJIUzI1NiIsInR5cCI6IkpXVCJ9.eyJ1c2VyX2lkIjoiQXNhampfVmVudHJlc3MiLCJpc3MiOiJodHRwczovL3Byb250by5nZXRzdHJlYW0uaW8iLCJzdWIiOiJ1c2VyL0FzYWpqX1ZlbnRyZXNzIiwiaWF0IjoxNzEyNzQxMzcwLCJleHAiOjE3MTMzNDYxNzV9.nnafB_Y9XIyN5ufQpGlLcLsFVnGrEhkGgGFqOefUTxk'; // the token can be found in the "Credentials" section
// const userId = 'Asajj_Ventresd'; // the user id can be found in the "Credentials" section
// const callId = 'ZiBNKzhZITlS'; // the call id can be found in the "Credentials" section

// set up the user object
// const user= {
//   id: userId,
//   name: 'Oliver',
//   image: 'https://getstream.io/random_svg/?id=oliver&name=Oliver',
// };

// const client = new StreamVideoClient({ apiKey, user, token });
//     const call = client.call('default', callId);// it is like  create channel
//     call.join({ create: true });

//call.unblockUser('Asajj_Ventress' );
//call.blockUser( 'Asajj_Ventress' );

export default function StreamVideoComponent({ channel, handleCallEnd }) {
  const [client, setClient] = useState(null);
  const [call, setCall] = useState(null);

  const userData = useSelector((store) => {
    return store.authState.user;
  });
  const [pageError, set_pageError] = useState("");

  useEffect(() => {
    clientCreation();

    return () => {
      console.log(call, ".........");
    };
  }, []);

  const clientCreation = () => {
    const stream = {
      apiKey: chatApikey,
      user: {
        id: userData.StreamChat.id,
        name: userData.username,
        image: `${EnvConfig?.MediaBase}/${userData?.assetsDir}/${userData?.profileImage}`,
      },
      token: userData.StreamChat.token,
    };

    let client = new StreamVideoClient(stream);
    const call = client.call("default", channel); // it is like  create channel
    call.join({ create: true }); // but if channel is not created   then create call and join call
    setCall(call);
    setClient(client);
  };

  return (
    <div className="main-content w-100 d-flex">
      {pageError ? <div className="error-msg">{pageError}</div> : <></>}
      <div className="video-container">
        <div>
          {client ? (
            <StreamVideo client={client}>
              <StreamCall call={call}>
                <MyUILayout handleCallEnd={handleCallEnd} />
              </StreamCall>
            </StreamVideo>
          ) : null}
        </div>
      </div>
    </div>
  );
}

export const MyUILayout = ({ handleCallEnd }) => {
  const { useCallCallingState } = useCallStateHooks();
  const callingState = useCallCallingState();

  if (callingState !== CallingState.JOINED) {
    return <div>Loading...</div>;
  }

  return (
    <StreamTheme>
      <SpeakerLayout participantsBarPosition="bottom" />
      <CallControls handleCallEnd={handleCallEnd} />
    </StreamTheme>
  );
};

 const CallControls = ({ handleCallEnd }) => {
  const call = useCall();

  //  it is use to handle clean up event means to end the  call we use call.leave()
  useEffect(() => {
    return () => {
      call?.leave();
    };
  }, []);

  return (
    <div className="str-video__call-controls">
      <SpeakingWhileMutedNotification>
        <ToggleAudioPublishingButton />
      </SpeakingWhileMutedNotification>
      <ToggleVideoPublishingButton />
      <ScreenShareButton />
      <CancelCallButton onClick={handleCallEnd} />
    </div>
  );
};

import { useState, useEffect } from "react";
import { Link, Navigate, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Field, Formik } from "formik";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { CoachSlotService } from "../../services";
import "react-datepicker/dist/react-datepicker.css"; // theme css file
import "react-datepicker/dist/react-datepicker-cssmodules.css";
import ReactDatePicker from "react-datepicker";
import DatePicker from "react-datepicker";
import moment from "moment";
import momentDate from "moment-timezone";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faXmark } from "@fortawesome/free-solid-svg-icons";
// import TimePicker from "react-time-picker";
// import "react-time-picker/dist/TimePicker.css";
// import "react-clock/dist/Clock.css";
import Timepicker from "../CustomComponent/Timepicker";
import { formatDate, formatTime } from "../../utilities/functions";

import { set } from "lodash";
import coachSlotService from "../../services/coachSlot.service";

function SlotCreate({ pageTitle }) {
  const dispatch = useDispatch();
  const [pageError, set_pageError] = useState("");
  const [dateError, setDateError] = useState(false);
  const [startDate, setStartDate] = useState();
  const [endDate, setEndDate] = useState(null);
  const [dummySlotList, setDummySlotList] = useState([]);
  const [breakValError, setBreakValError] = useState(false);
  const [navigateTo, setNavigateTo] = useState(false);
  const [existingSlot, setExistingSlot] = useState(null);
  const [existingValError,setExistingValError]=useState(null);
  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  const startFromNowDate = moment(0, "HH").diff(startDate, "days") === 0;

  const localFormatDate = (date) => {
    const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";
    const convertedDate = moment(date).format(outputFormat);
    return convertedDate;
  };

  const onChange = async (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
    if (start && end) {
      let payload = {
        startDate: momentDate(start).local().startOf("day").format(), //.utc()
        endDate: momentDate(end).local().endOf("day").format(), //.utc()
      };
      console.log(payload);
      const resp = await trackPromise(coachSlotService.getSlotByDates(payload));
      console.log(resp.data);
      setExistingSlot(resp.data.data);
    }

    if (start) {
      setDateError(false);
    }
  };

  const localStartDate = localFormatDate(startDate);
  const localEndDate = localFormatDate(endDate);

  const convertToMinutes = (timeArray) => {
    const resultArray = timeArray.map(({ fromTime, toTime }) => {
      const startTime = moment.duration(fromTime);
      const endTime = moment.duration(toTime);

      return { fromTime: startTime.asMinutes(), toTime: endTime.asMinutes() };
    });

    return resultArray;
  };

  const slotInMinutesData = convertToMinutes(dummySlotList);
  const slotsData = {
    startDate: localStartDate,
    endDate: localEndDate,
    startFromNow: startFromNowDate,
    timeSlots: slotInMinutesData,
  };

  async function handleFormSubmit() {
    try {
      const resp = await trackPromise(CoachSlotService.createSlots(slotsData));
      const { data } = resp;
      if (
        (data.success === true && data.data.updatedSlot != 0) ||
        data.data.createdSlot != 0
      ) {
        setNavigateTo(true);
        toast.success("Slot Created Successfully.");
      } else {
        if (data.data.updatedSlot == 0 || data.data.createdSlot == 0) {
          set_pageError(
            "Failed to created slot because slot timing is overlapping with  existing slot timing "
          );
        } else {
          set_pageError(data.message || `Failed to create slots`);
        }
      }
    } catch (err) {
      console.error("create slot error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(
        err.response.data.message || err.message || "create slot error"
      );
    }
  }

  let timeFormat = "HH:mm";

  const onCreateSlot = (data, resetForm) => {
    // const fromTime = data?.fromTime;
    const fromTime = moment(data?.fromTime).format(timeFormat);

    const toTime = moment(fromTime, timeFormat)
      .add(45, "minutes")
      .format(timeFormat);
    const slotObj = {
      fromTime: fromTime,
      toTime: toTime,
    };



    // let slotEndtimestamp = (parsedDate.utc().add(45, 'minutes')).valueOf();

    let existingvalidation = existingSlot.map((item) => {  
      console.log(moment(item?.fromDateTime))
      const itemStart = moment(item?.fromDateTime).format(timeFormat);  // 12:30
      const itemEnd = moment(item?.toDateTime).format(timeFormat);      //13:15
      const minTimeDifference = 15;

       let slotArr=slotObj?.fromTime.split(":");
       let itemArr=itemStart.split(":");

       let slotEndArr=slotObj?.toTime.split(":");
       let itemEndArr=itemEnd.split(":");

       if (slotObj?.fromTime==(itemStart) || slotObj?.toTime==(itemStart) || slotObj?.fromTime==itemEnd) {
        return false;
      }
       else {
        if (Math.abs(itemArr[0]-slotArr[0])>=2) {
        return true;
        }
        else if(Math.abs(itemArr[0]-slotArr[0])==1){
          
            if(itemArr[0]>slotArr[0]){
              if(slotEndArr[0]==itemArr[0]){
                  return Math.abs(itemArr[1]-slotEndArr[1])>=minTimeDifference
              }
              else{
                if(itemArr[0]==slotEndArr[0]){
                  return itemArr[1] -slotEndArr[1]>=15
                }
                else{
                  return  itemArr[1] +(60-slotEndArr[1])>=15
                }              
              }
            }
            else if(itemArr[0]<slotArr[0]){
              if(slotEndArr[0]==itemArr[0]){
                return Math.abs(slotArr[1]-itemEndArr[1])>=minTimeDifference
            }
            else{
              if(slotArr[0]==itemEndArr[0]){
                return slotArr[1] -itemEndArr[1]>=15
              }
              else{
                return  slotArr[1] +(60-itemEndArr[1])>=15
              }
            }
 
            }
          }
 
        else {
            return false;
          }
        }
      })
      if(!existingvalidation.every((ele)=>{return ele})){
        let string=existingvalidation.map((ele,index)=>{
           return ele==true?"":existingSlot[index].fromDateTime
        })
        setExistingValError(`Selected Time slot is overlapping with existing created time slot`);
        return;
      }
      else{
        setExistingValError(null)
      }


    setDummySlotList((listData) => {
      const isBreakValidation = dummySlotList?.map((item) => {
        const slotStart = moment(slotObj?.fromTime, timeFormat);
        const slotEnd = moment(slotObj?.toTime, timeFormat);

        const itemStart = moment(item.fromTime, timeFormat);
        const itemEnd = moment(item.toTime, timeFormat);

        const isAfterEnd = slotStart.isSameOrAfter(itemEnd);
        const isBeforeStart = slotEnd.isSameOrBefore(itemStart);

        const minTimeDifference = 15;

        const isMinimumDifference = () => {
          if (isAfterEnd) {
            return (
              Math.abs(slotStart.diff(itemEnd, "minutes")) >= minTimeDifference
            );
          }
          if (isBeforeStart) {
            return (
              Math.abs(itemStart.diff(slotEnd, "minutes")) >= minTimeDifference
            );
          }
        };

        // If all conditions are true, there is no overlap
        return (isAfterEnd || isBeforeStart) && isMinimumDifference();
      
      });

      const breakValidation = isBreakValidation?.every((i) => i === true);

      if (listData?.length === 0 || breakValidation) {
        const updatedList = [...listData, slotObj];
        resetForm();
        return updatedList;
      }

      if (!breakValidation) {
        const updatedList = [...listData];
        setBreakValError(true);
        resetForm();
        return updatedList;
      }
    });
   };

  const removeItem = (i) => {
    const filteredList = dummySlotList.filter((item, index) => i !== index);
    setDummySlotList(filteredList);
  };

  const SlotField = () => {
    const formatTime = (time) => {
      const formattedTime = moment(time, "HH:mm").format("h.mm A");
      return formattedTime;
    };
    return (
      <div className="d-flex g-2 flex-wrap">
        {dummySlotList?.map((item, i) => (
          <span
            key={i}
            className="slot-pills d-flex justify-content-between align-middle align-items-baseline gap-2 p-2 rounded"
          >
            {/* {item?.fromTime} - {item?.toTime} */}
            {formatTime(item?.fromTime)} - {formatTime(item?.toTime)}
            <FontAwesomeIcon
              className="align-self-center"
              icon={faXmark}
              onClick={() => removeItem(i)}
            />
          </span>
        ))}
      </div>
    );
  };

  return navigateTo ? (
    <Navigate to="/calendarEvent" />
  ) : (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Create a New Slot</h4>
      </div>
      {/* <TimePicker onChange={onExp} value={value} /> */}
      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-1">
            <Formik
              initialValues={{ fromTime: "" }}
              enableReinitialize="true"
              onSubmit={handleFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                touched,
                isSubmitting,
                resetForm,
                setFieldValue,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group " name="dateRange">
                    <Form.Label>Select a Date Range</Form.Label>
                    <div className="datePicker">
                      <ReactDatePicker
                        name="dateRange"
                        placeholderText="Select Date"
                        selected={startDate}
                        minDate={new Date()}
                        onChange={onChange}
                        utcOffset={0}
                        startDate={startDate}
                        endDate={endDate}
                        selectsRange
                        onBlur={() => {
                          if (!startDate) {
                            setDateError(true);
                          }
                        }}
                        onFocus={() => {
                          setBreakValError(false);
                        }}
                        className="custom-datepicker-input"
                        // wrapperClassName="datePickerWrapper"
                      />
                    </div>
                    {dateError ? (
                      <p id="errorObject" className="fieldError">
                        Date is Required
                      </p>
                    ) : null}
                    {startDate && !endDate ? (
                      <p id="errorObject" className="fieldError">
                        End Date is Required
                      </p>
                    ) : null}
                    {errors.dateRange && touched.dateRange
                      ? -errors.dateRange
                      : null}
                  </Form.Group>
                  <div className="d-flex gap-2 justify-content-start">
                    <Form.Group className="frm-group col-lg-4">
                      <Form.Label>Start</Form.Label>
                      <div className="timePicker">
                        <Field
                          component={Timepicker}
                          name="fromTime"
                          setFieldValue={setFieldValue}
                          value={values.fromTime}
                          errorClass="fieldError"
                          onFocus={() => {
                            setBreakValError(false);
                          }}
                          isInvalid={touched.fromTime && errors.fromTime}
                        />
                      </div>

                      {/* <Form.Control
                        type="time"
                        placeholder="Enter Slot"
                        name="fromTime"
                        value={values.fromTime}
                        onChange={handleChange}
                        isInvalid={touched.fromTime && errors.fromTime}
                        onFocus={() => {
                          setBreakValError(false);
                        }}
                        style={{ backgroundColor: "red" }}
                        className="time"
                      /> */}
                    </Form.Group>
                    <Button
                      variant="clr-primary"
                      className="align-self-center mt-3 mb-1"
                      disabled={
                        values.fromTime === "" || !startDate || !endDate
                          ? true
                          : false
                      }
                      onClick={() => onCreateSlot(values, resetForm)}
                    >
                      Add Slot
                    </Button>
                  </div>
                  {
                    existingValError &&
                      <div style={{ color: "#7F4850" }}>
                        {existingValError}
                    </div>
                    
                  }
                  {breakValError && (
                    <div style={{ color: "#7F4850" }}>
                      Selected Time slot is overlapping with previous time slots
                    </div>
                  )}

                  <div className="align-middle my-2 mb-3 text-center">
                    Slots will be created for 45 minutes and there will be 15
                    minutes of break between two slots
                  </div>
                  {dummySlotList?.length !== 0 && (
                    <Form.Group className="frm-group col-lg-8 w-100">
                      <Form.Label>Slots</Form.Label>
                      <Field
                        name="slot"
                        component={SlotField}
                        isMulti={true}
                        id="slot"
                        isInvalid={errors.slot}
                        errorClass="fieldError"
                      />
                    </Form.Group>
                  )}

                  <div className="row g-0 frm-btn-grp gap-3">
                    <Button
                      as={Link}
                      to="/calendarEvent"
                      variant="clr-transparent"
                      className="col-lg me-lg-3"
                    >
                      Cancel
                    </Button>
                    <Button
                      // as={Link}
                      // to="/calendarEvent"
                      variant="clr-primary"
                      type="submit"
                      disabled={
                        dummySlotList.length === 0 ||
                        dummySlotList === undefined
                          ? true
                          : isSubmitting
                      }
                      className="col-lg ms-lg-3"
                    >
                      Create Slot/s
                    </Button>
                  </div>
                  {pageError ? (
                    <div className="text-danger">{pageError}</div>
                  ) : (
                    <></>
                  )}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default SlotCreate;

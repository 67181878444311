import { useSelector } from "react-redux";
import "./wizard.css";

function StepperLine({ steps, activeStep = 0 }) {
  const colorThemeData = useSelector((state) => state.colorTheme.colorThemeData);
  return (
    <div className="horizontal-stepper">
      {steps.map((step, index) => (
        <div key={step} className={`step ${index === activeStep ? "step_active" : ""}`}>
          <div className="step-icon">
            <svg xmlns="http://www.w3.org/2000/svg" width="24" height="24" viewBox="0 0 24 24">
              <circle
                cx="11"
                cy="11"
                r="7"
                fill={
                  index <= activeStep
                    ? (colorThemeData ? colorThemeData[0]?.CLR_PRIMARY : colorThemeData[0]?.CLR_PRIMARY_LIGHT) ??
                      "#B18C93"
                    : "#fff"
                }
                strokeWidth={1}
                stroke={
                  index <= activeStep
                    ? (colorThemeData ? colorThemeData[0]?.CLR_PRIMARY : colorThemeData[0]?.CLR_PRIMARY_LIGHT) ??
                      "#B18C93"
                    : "#fff"
                }
              />
              <circle cx="11" cy="11" r="2" fill="#fff" />
            </svg>
            <div className="step-name">{step}</div>
          </div>
          {<div className={index <= activeStep ? "line-filled" : "line"} />}
        </div>
      ))}
    </div>
  );
}

export default StepperLine;

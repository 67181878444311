import { Button, Col, Row } from "react-bootstrap";
import EnvConfig from "../../envConfig";
import "./wizard.css";

function StepCoachSelection(props) {
  const {
    coachesList,
    handleCoachSelect,
    handleImageError,
    navigateToNext,
    coachesListReadMore,
    coachId,
    navigateToBack,
    setCoachProfile,
    viewMore,
  } = props;

  const handleBackAction = () => {
    handleCoachSelect(null);
    navigateToBack();
  };

  return (
    <>
      <div className="page-heading ">
        <h4 className="col hd-txt  ">Choose a Coach</h4>
        <Button variant="clr-primary" className="btn-add back-continue mx-4 " onClick={handleBackAction}>
          Back
        </Button>
        {/* <Button variant="clr-primary" className="btn-add back-continue " disabled={!coachId} onClick={navigateToNext}>
          Continue
        </Button> */}
      </div>

      <Row className="skill-container-coach d-flex flex-column">
        {coachesList &&
          coachesList?.map((item, index) => (
            <Col key={index} className="col-lg-4">
              <div
                className={coachId === item?.id ? "skill-card-coach" : "skill-card-coach"}
                onClick={() => {
                  handleCoachSelect(item);
                }}
              >
                <div className="profile-wrap">
                  <img
                    className="coach-Img me-2"
                    src={`${EnvConfig.MediaBase}/${item?.assetsDir}/${item?.profileImage}`}
                    alt="user pic"
                    onError={handleImageError}
                  />
                </div>
                <div className="skill-detail">
                  <div className="profile-info">
                    <div className="title coach-name">{`${item?.firstName} ${item?.lastName}`}</div>
                  </div>
                  <div className="header-text">{item?.label}</div>
                  <div>★{Math.round(item?.avgRating * 10) / 10}</div>
                  <div className="mt-2 d-flex justify-content-between gap-2">
                    <Button
                      variant={coachId === item?.id ? "clr-primary" : "clr-primary"}
                      onClick={() => {
                        handleCoachSelect(item);
                        setCoachProfile(1);
                      }}
                    >
                      View Profile
                    </Button>
                  </div>
                </div>
              </div>
            </Col>
          ))}
      </Row>

      <Row className="mt-3 mx-2">
        <Col md={12} xs={12}>
          {coachesList?.length !== coachesListReadMore?.length ? (
            <Button
              variant={"clr-primary"}
              onClick={() => {
                viewMore();
              }}
            >
              View More
            </Button>
          ) : null}
        </Col>
      </Row>
    </>
  );
}

export default StepCoachSelection;

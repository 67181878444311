import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { TopicsService, UserService } from "../../services";
import { copyObjectByKeys } from "../../utilities/functions";
import SelectField from "../CustomComponent/Select";
import PillWithDelete from "../CustomComponent/PillBadge";
import { getUserProfile } from "../../stores/store";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";
import { documentSize, videoSize } from "../../envConfig";

function CoachProfileUpdate({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [topicList, set_topicList] = useState([]);
  const [profileDetails, set_profileDetails] = useState({});
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [profilePic, setProfilePic] = useState();
  const [certificates, setCertificates] = useState([]);
  const [Listcertificates, setListCertificates] = useState([]);
  const [customError, setCustomError] = useState({});
  const [deletedList, setDeletedlist] = useState([]);
  const [regAboutMe, setRegAboutMe] = useState();

  const [imageError,setImageError]=useState(null);
  const [certificateError,setCertificateError]=useState(null);
  const [videoError,setVideoError]=useState(null)



  // Helper functions to check file formats
  function isValidImage(file) {
    const allowedFormats = ["image/jpeg", "image/jpg", "image/png"];
    return file && allowedFormats.includes(file.type);
  }

  
  function isValidCertificateFormat(files) {
    const allowedFormats = [
      "application/msword",
      "application/vnd.openxmlformats-officedocument.wordprocessingml.document",
      "application/pdf",
    ];
    return files.every((file) => allowedFormats.includes(file.type));
  }

  function saveFormValue() {
    let size=100;
    let error=true;
     if(inputRef.current.files.length){
   if (!isValidImage(inputRef.current.files[0])) {
      error=false;
      setImageError( "Invalid file format. Only JPG, JPEG, and PNG files are allowed.");
    }  else if (inputRef.current.files[0].size > (documentSize *1024 * 1024)){
      error=false;
      setImageError(`Image size must be less than ${documentSize}MB.`);
    }
  }
  else{
    setImageError(null);
  }
     if (multipleInputRef.current.files.length) {
      
      if (!isValidCertificateFormat(certificates)) {
      error=false;
      setCertificateError("Invalid file format. Only DOC, DOCX, and PDF files are allowed.");
    }  else if (certificates.some((cert) => cert.size > (documentSize *1024 * 1024))) {
      error=false;
      setCertificateError( `Certificate size must be less than ${documentSize}MB.`);
    } 
  }
  else{
    setCertificateError(null)
  }

    if (inputRefAbout.current.files.length) {
      if (inputRefAbout.current.files[0].type !== "video/mp4") {
      error=false;
      setVideoError("Invalid file format. Only MP4 files are allowed.");
    } else if (inputRefAbout.current.files[0].size > (videoSize * 1024 * 1024)) {
      error=false;
      setVideoError(`Video size must be less than ${videoSize} MB.`);
    }
  }
  else{
    setVideoError(null)
  }
    return error;
  }

  const inputRef = useRef();
  const multipleInputRef = useRef();
  const inputRefAbout = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchTopics();
    fetchProfileData();
  }, []);

  useEffect(() => {
    if (profileDetails.id) populateForm();
  }, [profileDetails.id]);

  function getFormObject() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      phone: "",
      email: "",
      bankCode: "",
      bankBranchNumber: "",
      bankAccountNumber: "",
      skills: "",
      topics: [],
      aboutSelf: "",
      experienceAchieved: "",
    };
  }

  const FormSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    username: Yup.string().required("Username is Required"),
    phone: Yup.string().required("Contact Number is Required"),
    email: Yup.string().email("Email is invalid").required("Email is Required"),
    bankCode: Yup.string().required("Bank Code is Required"),
    bankBranchNumber: Yup.string().required("Bank Branch Number is Required"),
    bankAccountNumber: Yup.string().required("Bank Branch Number is Required"),
    skills: Yup.string().required("Skills is Required"),
    topics: Yup.array().min(1, "Select at least one option").required("Select at least one option"),
    aboutSelf: Yup.string().required("Professional Biography info is Required"),
    experienceAchieved: Yup.string().required("Experience and Achievement info is Required"),
  });

  async function isUniqueCheck(value) {
    try {
      const resp = await trackPromise(UserService.isFieldUnique(value));
      const { data } = resp;
      if (value.username) {
        if (value.username == profileDetails.username) {
          addCustomErrors({ username: "" });
        } else {
          addCustomErrors({ username: data.data.isUnique ? "" : "Username Already Exist" });
        }
      } else if (value.email) {
        if (value.email == profileDetails.email) {
          addCustomErrors({ email: "" });
        } else {
          addCustomErrors({ email: data.data.isUnique ? "" : "Email Address Already Exist" });
        }
      }
    } catch {
      if (value.username) {
        addCustomErrors({ username: "" });
      } else if (value.email) {
        addCustomErrors({ email: "" });
      }
    }
  }

 

  const addCustomErrors = (errors) => {
    setCustomError((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  function checkCustomError() {
    let error = true;
    Object.keys(customError).forEach((key) => {
      if (customError[key]) error = false;
    });

    return error;
  }

  async function fetchProfileData() {
    try {
      const resp = await trackPromise(UserService.profile());
      const { data } = resp;
      if (data.success) {
        set_profileDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching profile");
      }
    } catch (err) {
      console.error("Fetch profile catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchTopics() {
    try {
      const resp = await trackPromise(TopicsService.getList());
      const { data } = resp;
      if (data.success) {
        set_topicList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topics");
      }
    } catch (err) {
      console.error("Fetch coach topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleProfilePic = () => {
    if (inputRef.current.files) {
      setProfilePic(inputRef.current.files[0]);
    }
  };

  const handleAboutmeUpload = () => {
    inputRefAbout.current?.click();
  };

  const handleAboutMe = () => {
    if (inputRefAbout.current.files) {
      setRegAboutMe(inputRefAbout.current.files[0]);
    }
  };

  const handleMultiUpload = () => {
    multipleInputRef.current?.click();
  };

  const handleCertificatesPic = () => {
    if (multipleInputRef.current.files) {
      setCertificates([...multipleInputRef.current.files]);
    }
  };

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, profileDetails);
    set_formObject({ ...formobj });
    setListCertificates(profileDetails?.certificateDocs);
  }

  function formatForm(form) {
    const updatedForm = { ...form, topics: form.topics?.map((topic) => topic._id) };
    if (deletedList.length > 0) {
      updatedForm.certDocsToDelete = deletedList;
    }
    return updatedForm;
  }

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (typeof value === "object" && !value.uri) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  }

  async function handleFormSubmit(values) {
        if(!saveFormValue()){
           return ;
        }
    try {
      if (checkCustomError()) {
        const formatedValues = formatForm(values);
        const formData = getFormData({ ...formatedValues });
        if (profilePic) {
          formData.append("profileImage", profilePic);
        }
        if (regAboutMe) {
          formData.append("profileVideo", regAboutMe);
        }
        if (certificates.length > 0) {
          certificates.forEach((file, i) => {
            formData.append("certificateDocs", file);
          });
        }

        const resp = await trackPromise(
          UserService.updateProfile(formData, { headers: { "Content-Type": "multipart/form-data" } })
        );
        const { data } = resp;
        if (data.success) {
          toast.success("Coach updated successfully.");
          getUserProfile();
          navigate(-1);
        } else {
          set_pageError(data.message || "Coach updated failed");
        }
      }
    } catch (err) {
      console.error("Coach error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Register error");
    }
  }

  function removeFile(name) {
    const deletedNewList = [...deletedList, name];
    setDeletedlist(deletedNewList);
    const newList = Listcertificates.filter((files) => files != name);
    setListCertificates(newList);
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Edit Profile Details</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              validateOnBlur={true}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={touched.firstName && errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={touched.lastName && errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Username"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ username: value.target.value, userId: profileDetails.id })}
                      isInvalid={(touched.username && errors.username) || customError.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username || customError.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="phone"
                      placeholder="Enter Contact"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={touched.phone && errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ email: value.target.value, userId: profileDetails.id })}
                      isInvalid={(touched.email && errors.email) || customError.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email || customError.email}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Swift Code or Bank Identification Code (BIC)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Code"
                      name="bankCode"
                      value={values.bankCode}
                      onChange={handleChange}
                      isInvalid={touched.bankCode && errors.bankCode}
                    />
                    <Form.Control.Feedback type="invalid">{errors.bankCode}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Bank-State-Branch (BSB) Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      name="bankBranchNumber"
                      value={values.bankBranchNumber}
                      onChange={handleChange}
                      isInvalid={touched.bankBranchNumber && errors.bankBranchNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.bankBranchNumber}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>International Bank Account Number (IBAN)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      name="bankAccountNumber"
                      value={values.bankAccountNumber}
                      onChange={handleChange}
                      isInvalid={touched.bankAccountNumber && errors.bankAccountNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.bankAccountNumber}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Key Core Competencies</Form.Label>
                    <Field
                      name="topics"
                      getOptionValue={(option) => option._id}
                      getOptionLabel={(option) => option.topicName}
                      component={SelectField}
                      isMulti={true}
                      id="topics"
                      placeholder="Select Topics"
                      options={topicList}
                      isInvalid={touched.topics && errors.topics}
                      errorClass="fieldError"
                    />
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Form.Label>Additional Qualifications and Credentials</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Skills"
                      name="skills"
                      value={values.skills}
                      onChange={handleChange}
                      isInvalid={touched.skills && errors.skills}
                    />
                    <Form.Control.Feedback type="invalid">{errors.skills}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Form.Label>Professional Biography</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Your Professional Biography"
                      name="aboutSelf"
                      value={values.aboutSelf}
                      onChange={handleChange}
                      isInvalid={touched.aboutSelf && errors.aboutSelf}
                    />
                    <Form.Control.Feedback type="invalid">{errors.aboutSelf}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Form.Label>Experience and Achievements</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Showcase Your Professional Milestones and Significant Accomplishments in  Career"
                      name="experienceAchieved"
                      value={values.experienceAchieved}
                      onChange={handleChange}
                      isInvalid={touched.experienceAchieved && errors.experienceAchieved}
                    />
                    <Form.Control.Feedback type="invalid">{errors.experienceAchieved}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Row>
                      <Col className=" d-flex">
                        <Form.Label>Upload Profile Picture</Form.Label>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip02}>
                          <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                        </OverlayTrigger>
                      </Col>
                    </Row>
                    <input ref={inputRef} type="file" className="hidden-content" onChange={handleProfilePic} />
                    <div className="d-flex mb-3 upload-div">
                      <Button variant="clr-trasparent-new" type="button" className="ms-lg-3" onClick={handleUpload}>
                        Add a file
                      </Button>
                      {profilePic ? <div className="item">{profilePic.name}</div> : null}
                    </div>
                    <div className="item">
                      {profileDetails?.profileImage ? (
                        <PillWithDelete
                          text={profileDetails?.profileImage}
                          hideDelete={true}
                          assetsDir={profileDetails.assetsDir}
                        />
                      ) : null}
                    </div>
                    {imageError && (
    <div className="text-danger">{imageError}</div>
  )}
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Form.Label>Upload Certificates</Form.Label>
                    <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip01}>
                          <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                        </OverlayTrigger>
                    <input
                      type="file"
                      ref={multipleInputRef}
                      className="hidden-content"
                      onChange={handleCertificatesPic}
                      multiple
                    />
                    <div className="d-flex mb-3 upload-div">
                      <div>
                        <Button
                          variant="clr-trasparent-new"
                          type="button"
                          onClick={handleMultiUpload}
                          className="ms-lg-3"
                        >
                          Add a file
                        </Button>
                      </div>
                      <div className="item">
                        {certificates.length > 0
                          ? certificates.map((item) => <div className="item">{item.name}</div>)
                          : null}
                      </div>
                    </div>

                    {Listcertificates?.length > 0 ? (
                      <DocTable tblData={Listcertificates} removeFile={removeFile} coachDetails={profileDetails} />
                    ) : null}
                                        {certificateError && (
    <div className="text-danger">{certificateError}</div>
  )}
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Upload a Professional Introduction Video</Form.Label>
                    <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip03}>
                          <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                        </OverlayTrigger>
                    <input ref={inputRefAbout} type="file" className="hidden-content" onChange={handleAboutMe} />
                    <div className="d-flex mb-3 upload-div">
                      <Button
                        variant="clr-trasparent-new"
                        type="button"
                        className="ms-lg-3"
                        onClick={handleAboutmeUpload}
                      >
                        Add a file
                      </Button>

                      {regAboutMe ? <div className="item">{regAboutMe.name}</div> : null}
                    </div>
                    <div className="item">
                      {profileDetails?.profileVideo ? (
                        <PillWithDelete
                          text={profileDetails?.profileVideo}
                          hideDelete={true}
                          assetsDir={profileDetails.assetsDir}
                        />
                      ) : null}
                    </div>
                    {videoError && (
    <div className="text-danger">{videoError}</div>
  )}
                  </Form.Group>

                  <div className="d-flex justify-content-center mb-3 gap-2">
                    <Button as={Link} to="/coach" variant="clr-transparent" className="col-lg me-lg-3">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-lg-3">
                      Save
                    </Button>
                  </div>
                  {pageError ? <div className="mt-3 text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CoachProfileUpdate;

function DocTable({ tblData, removeFile, onDownload, coachDetails }) {
  return (
    <span>
      {tblData.length > 0
        ? tblData.map((item, index) => (
            <Col key={index} className="mb-1">
              <PillWithDelete
                text={item}
                onDelete={removeFile}
                onDownload={onDownload}
                assetsDir={coachDetails.assetsDir}
              />
            </Col>
          ))
        : null}
    </span>
  );
}

const RenderTooltip02 = (props) => (
  <Tooltip {...props}>{`The profile picture must be at least 300x300px in size and in the format jpg/jpeg/png. The file size must be less than ${documentSize}MB.`}</Tooltip>
);
const RenderTooltip01 = (props) => (
  <Tooltip {...props}>{`Please select a file in the doc/docx/pdf format for your certificate(s). The file size must be less than ${documentSize}MB`}</Tooltip>
);
const RenderTooltip03 = (props) => (
  <Tooltip {...props}>{`Your video must be landscape and be in a 16:9 ratio. The file format for the video is MP4. The video must not be longer than 1.5 minutes and the file size must be less than ${videoSize}MB.`}</Tooltip>
);
import httpClient from "../httpClient";
const baseSlug = "/statistics";

class httpService {
  getOverviewStats() {
    return httpClient.get(`${baseSlug}/overview`);
  }
}

export default new httpService();

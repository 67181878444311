import { Calendar as BigCalendar, momentLocalizer } from "react-big-calendar";
import moment from "moment-timezone";

import "react-big-calendar/lib/css/react-big-calendar.css";

const localizer = momentLocalizer(moment);
// moment.tz.setDefault("Etc/UTC");

export default function Calendar(props) {
  return (
    <div>
      <BigCalendar {...props} localizer={localizer} style={{ height: 560 }} formats={props.formats} />
    </div>
  );
}

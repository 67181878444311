import moment from "moment";

export function debounceFn(fn, ms) {
  let timer;
  return (_) => {
    clearTimeout(timer);
    timer = setTimeout((_) => {
      timer = null;
      fn.apply(this, arguments);
    }, ms);
  };
}

export function copyObjectByKeys(destObj, srcObj) {
  Object.keys(destObj).forEach((key) => {
    if (srcObj.hasOwnProperty(key) && destObj.hasOwnProperty(key)) destObj[key] = srcObj[key];
  });
}

export function convertCSVToJson(csvData) {
  const lines = csvData.split("\n");
  const headers = lines[0].split(",");
  const result = [];

  for (let i = 1; i < lines.length; i++) {
    const obj = {};
    const currentLine = lines[i].split(",");

    for (let j = 0; j < headers.length; j++) {
      obj[headers[j].trim()] = currentLine[j].trim();
    }

    result.push(obj);
  }

  return result;
}

// new functions from dev-funcitonality
export function formatTime(dateIn = new Date()) {
  return moment(dateIn).local().format("hh:mm A");
}

export function localDayStart(dateIn = new Date()) {
  return moment(dateIn).local().startOf("day");
}

export function localDayEnd(dateIn = new Date()) {
  return moment(dateIn).local().endOf("day");
}

export function formatDate(dateIn = new Date()) {
  return moment(dateIn).local().format("MMMM Do YYYY");
}

export function yearDateFormat(dateIn) {
  return moment.utc(dateIn).format("MMMM Do YYYY, h:mm:ss a");
}
export const startDayFromzero = (date) => {
  return date.set({
    hour: moment().hours(),
    minute: moment().minutes(),
    second: moment().seconds(),
  });
};

export const endDateFormat = (date) => {
  const givenDate = moment(date);
  const result = givenDate.set({
    hour: 23,
    minute: 59,
    second: 59,
  });
  return result.format();
};

export function replaceWithBr(value) {
  return value?.replace(/\n/g, "<br />");
}

import React from "react";
import { useEffect } from "react";
import { Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { trackPromise } from "react-promise-tracker";
import { StudentTypeService, UserService } from "../../services";
import { useState } from "react";

const ViewStudent = ({ pageTitle }) => {
  const [studentTypeDetails, set_studentTypeDetails] = useState([]);
  const [pageError, set_pageError] = useState("");

  const id = useParams();

  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchStudentDetails() {
      try {
        const resp = await trackPromise(UserService.getStudentById(id.id));
        const { data } = resp;
        if (data.success) {
          set_studentTypeDetails(data.data);
        } else {
          set_pageError(data.message || "Error in fetching coachee details");
        }
      } catch (err) {
        console.error("Fetch coachee details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchStudentDetails();
  }, [id.id]);

  return (
    <div className="text-left">
      <h4 className="col hd-txt">View Coachee</h4>
      <div className="col-md">
        <table className="view-org-table table table-borderless bg-transparent w-50">
          <tbody className="bg-transparent">
            <tr>
              <td>Coachee Type</td>
              <td>{studentTypeDetails?.studentType?.typeName}</td>
            </tr>
            <tr>
              <td>First Name</td>
              <td>{studentTypeDetails?.firstName}</td>
            </tr>
            <tr>
              <td>Last Name</td>
              <td>{studentTypeDetails?.lastName}</td>
            </tr>
            <tr>
              <td>Username</td>
              <td>{studentTypeDetails?.username}</td>
            </tr>
            <tr>
              <td>Email address</td>
              <td>{studentTypeDetails?.email}</td>
            </tr>
            <tr>
              <td>Contact Number</td>
              <td>{studentTypeDetails?.phone}</td>
            </tr>
            <tr>
              <td>Coachee Sessions</td>
              <td>{studentTypeDetails?.studentSessions}</td>
            </tr>
          </tbody>
        </table>
        <div className="g-0 frm-btn-grp  mt-3">
          <Button as={Link} to="../coachee" variant="clr-transparent" className="col-lg me-lg-3">
            Cancel
          </Button>
          <Button
            as={Link}
            to={`../coachee/edit/${id.id}`}
            variant="clr-primary"
            type="submit"
            className="col-lg ms-lg-3"
          >
            Edit Coachee
          </Button>
        </div>
      </div>
    </div>
  );
};

export default ViewStudent;

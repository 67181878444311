import { createSlice } from "@reduxjs/toolkit";
import { THEME } from "../utilities/theme";
const colorTheme = createSlice({
  name: "organisation",
  initialState: {
    colorTheme: "",
    colorThemeData: [],
  },
  reducers: {
    getColorTheme: (state, action) => {
      state.colorTheme = action.payload;
    },
    getColorThemeData: (state, action) => {
      state.colorThemeData = THEME.filter((item) => item.theme_code === action.payload);
    },
    setColorThemeData: (state, action) => {
      if (state.colorThemeData.length === 0) {
        state.colorThemeData.push(action.payload);
      } else {
        state.colorThemeData.length = 0;
        state.colorThemeData.push(action.payload);
      }
    },
  },
});

export default colorTheme.reducer;
export const { getColorTheme, getColorThemeData, setColorThemeData } = colorTheme.actions;

import { useState, useEffect } from "react";
import { Outlet } from "react-router-dom";
import { Container, Row, Col } from "react-bootstrap";
import { THEME } from "../utilities/theme";
import logoimg from "../assets/images/Rise_Thrive_20b.png";
import "./AuthLayout.css";

function AuthLayout() {
  return (
    <Container fluid={true} className="auth-layout g-0">
      <div className="comp-logo-container">
        <img src={logoimg} alt="company logo" className="comp-logo" />
      </div>
      <Row className="g-0">
        <Col>
          <Outlet />
        </Col>
      </Row>
    </Container>
  );
}

export default AuthLayout;

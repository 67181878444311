import React, { useEffect, useState, useMemo } from "react";
import { useDispatch } from "react-redux";
import { useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { toast } from "react-toastify";
import { Container, Row, Col, Button } from "react-bootstrap";

import { trackPromise } from "react-promise-tracker";
import { SubscriptionService } from "../../services";
import { AgGridReact } from "ag-grid-react"; // Import Ag-Grid
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { useRef } from "react";

const Subscription = ({ pageTitle }) => {
  const [subDetails, set_subDetails] = useState({});
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();
  const gridRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    async function fetchsubsStat() {
      try {
        const resp = await trackPromise(SubscriptionService.getOrgSubscription());
        const { data } = resp;
        if (data.success) {
          set_pageError("");
          set_subDetails(data.data);
        } else {
          set_pageError(data.message || "Error in fetching org details");
        }
      } catch (err) {
        console.error("Fetch org details catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          toast.error(err.response.data.message);
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchsubsStat();
  }, []);

  const columnDefs = [
    // Define your column definitions
    { headerName: "Hours", field: "hours", cellStyle: { textAlign: "center" } },
    { headerName: "Total Sessions", field: "availableSessions", cellStyle: { textAlign: "center" } },
    { headerName: "Used Sessions", field: "usedSessions", cellStyle: { textAlign: "center" } },
    { headerName: "Balance Minutes", field: "balanceMinutes", cellStyle: { textAlign: "center", width: "300px" } },
  ];
  const defaultColDef = useMemo(() => ({
    // filter: true,
    // sortable: true,
    flex: 1,
    suppressMovable: true,
    //filter: true,
  }));

  return (
    <Container fluid={true} className="main-content">
      <Row className="">
        <Col lg="12">
          <div className="col-md">
            {subDetails.scheme === "fixed" && (
              <div>
                <h5 className="col hd-txt" style={{ fontWeight: "bold" }}>
                  Fixed Hours and Fixed Amount
                </h5>
                <div className="ag-theme-alpine mt-4" style={{ height: "200px", width: "70%" }}>
                  <AgGridReact
                    columnDefs={columnDefs}
                    rowData={[subDetails]}
                    defaultColDef={defaultColDef}
                    domLayout="autoHeight"
                  />
                </div>
              </div>
            )}
            {subDetails.scheme === "distributed" && (
              <div>
                <h5 className="col hd-txt" style={{ fontWeight: "bold" }}>
                  Usage Based
                </h5>
                <div className=" mt-4">
                  <div className="ag-theme-alpine" style={{ height: "200", width: "100%" }}>
                    <AgGridReact
                      columnDefs={[
                        { headerName: "Management Level", field: "studentType.typeName", width: "300px" },
                        {
                          headerName: "Coachee Count",
                          field: "studentCount",
                          width: "150px",
                          cellStyle: { textAlign: "center" },
                        },
                        // { headerName: "Hours", field: "hours", width: "150px", cellStyle: { textAlign: "center" } },
                        {
                          headerName: "Total Sessions",
                          field: "availableSessions",
                          width: "150px",
                          cellStyle: { textAlign: "center" },
                        },
                        {
                          headerName: "Used Sessions",
                          field: "usedSessions",
                          width: "150px",
                          cellStyle: { textAlign: "center" },
                        },
                        // {
                        //   headerName: "Balance Minutes",
                        //   field: "balanceMinutes",
                        //   width: "200px",
                        //   cellStyle: { textAlign: "center" },
                        // },
                      ]}
                      rowData={subDetails.distribution}
                      defaultColDef={defaultColDef}
                      domLayout="autoHeight"
                    />
                  </div>
                </div>
              </div>
            )}
          </div>
          {pageError ? <div className="text-danger">{pageError}</div> : <></>}
        </Col>
      </Row>
    </Container>
  );
};

export default Subscription;

import httpClient from "../httpClient";
const baseSlug = "/changeRequest";

class httpService {
  getchangeRequestByAdmin() {
    return httpClient.get(`${baseSlug}/admin`);
  }
  getchangeRequestByOrg(id) {
    return httpClient.get(`${baseSlug}/organisation/${id}`);
  }

  createChangeRequest(data) {
    return httpClient.post(`${baseSlug}`, data);
  }
  getChangeRequestByStudent() {
    return httpClient.get(`${baseSlug}/student`);
  }
  getRequestById(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }
  updateRequest(id, data) {
    return httpClient.put(`${baseSlug}/${id}`, data);
  }
  updateRequestStatus(id, data) {
    return httpClient.put(`${baseSlug}/status/${id}`, data);
  }

  deleteRequest(id) {
    return httpClient.put(`${baseSlug}/delete/${id}`);
  }
}

export default new httpService();

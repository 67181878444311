import httpClient from "../httpClient";
const baseSlug = "/booking";

class httpService {
  getUserBooking() {
    return httpClient.get(`${baseSlug}/user`);
  }
  getUserGroupBooking() {
    return httpClient.get(`${baseSlug}/user/grouped`);
  }
  getAppointmentDetail(id) {
    return httpClient.get(`${baseSlug}/${id}`);
  }

  getStudentByCoach() {
    return httpClient.get(`${baseSlug}/coach/students`);
  }
  getBookingsByAssociatedUser(id) {
    return httpClient.get(`${baseSlug}/associatedUser/${id}`);
  }
  getUpcomingSlots() {
    return httpClient.get(`bookSlot/upcoming`);
  }
  getCoachReviewById(id) {
    return httpClient.get(`/coachRating/${baseSlug}/${id}`);
  }
  getStudentActiveBooking() {
    return httpClient.get(`${baseSlug}/active/student`);
  }
}

export default new httpService();

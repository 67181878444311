import { Button } from "react-bootstrap";
import "./wizard.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock, faCheck } from "@fortawesome/free-solid-svg-icons";

import { Link } from "react-router-dom";
import { formatDate, formatTime } from "../../utilities/functions";

function StepConfirmationSuccess(props) {
  const { bookedSlotsData } = props;

  return (
    <>
      <div className="page-heading">
        <h4 className="col hd-txt">Confirmation</h4>
      </div>

      <div className="">
        <div className="col-lg-4 mb-2">
          <div style={{ display: "flex", justifyContent: "center" }}>
            <div
              style={{
                display: "flex",
                justifyContent: "center",
                alignItems: "center",
                backgroundColor: "green",
                width: "50px",
                height: "50px",
                borderRadius: "50%",
              }}
            >
              <FontAwesomeIcon
                size="lg"
                icon={faCheck}
                style={{ color: "white", backgroundColor: "green", width: "30px", height: "30px" }}
              />
            </div>
          </div>
          <div style={{ display: "flex", justifyContent: "center", marginTop: "10px" }} className="hd-txt mb-1">
            {bookedSlotsData?.lenght > 1 ? "Appointments Confirmed!" : "Appointment Confirmed!"}
          </div>
        </div>
        <div className="col-lg-4 ms-0 mt-4">
          <h5>Appointment Information</h5>
        </div>
        {bookedSlotsData.map((slots, index) => (
          <div className="skill-card-time-management col-lg-4 mt-3 ms-0" key={index}>
            <div style={{ display: "flex", flexDirection: "row", padding: "5px 0px" }}>
              <div style={{ display: "flex", padding: "5px 0px" }}>
                <FontAwesomeIcon size="lg" icon={faCalendar} />
              </div>
              <div>
                {/* <div style={{ padding: "0px 10px" }}>Selected Date</div> */}
                <div style={{ paddingInline: "10px" }}>{formatDate(slots?.fromDateTime)}</div>{" "}
              </div>
            </div>
            <div style={{ display: "flex", flexDirection: "row", padding: "5px 0px" }}>
              <div style={{ display: "flex", padding: "5px 0px" }}>
                <FontAwesomeIcon size="lg" icon={faClock} />
              </div>
              <div>
                {/* <div style={{ padding: "0px 10px" }}>Selected Time</div> */}
                <div style={{ paddingInline: "10px" }}>
                  {formatTime(slots?.fromDateTime)} - {formatTime(slots?.toDateTime)}
                </div>
              </div>
            </div>
          </div>
        ))}
      </div>
      <div className="view-btn d-flex justify-content-center text-center col-lg-4 ps-0 ms-0">
        <Button as={Link} to="../manageCoaching" variant="clr-primary" className="w-100">
          View Slots
        </Button>
      </div>
    </>
  );
}

export default StepConfirmationSuccess;

//const ServerBase =  "http://localhost:3300";
  //const ServerBase="https://coachandgrow.venturatechnologies.in";
  const ServerBase= "https://app.riseandthrive.com.au:3300"

  // Coach And Grow
  const chatApikey="wp88y3vxgdn3"

  //Ventura technologies
  //const chatApikey="c4pu3hzj5hgn"

const EnvConfig = {
  ApiBase: `${ServerBase}/api`,
  MediaBase: `${ServerBase}/media`,
};
let  documentSize= 5;
let videoSize=100;

export default EnvConfig;

export {documentSize,videoSize,chatApikey}

import httpClient from "../httpClient";
const baseSlug = "/coachSlot";
const baseSlugMeta = "/metaSlot";

class httpService {
  getAllSlots() {
    return httpClient.get(`${baseSlugMeta}`);
  }

  createSlots(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  getSlotByDates(data) {
    return httpClient.post(`${baseSlug}/getByDates`, data);
  }
  getSlotByCoachId(id, data) {
    return httpClient.post(`${baseSlug}/${id}/getByDates`, data);
  }
  bookSlots(data) {
    return httpClient.post(`/booking`, data);
  }

  deleteSlot(data) {
    return httpClient.put(`${baseSlug}/deleteSlot`, data);
  }
}

export default new httpService();

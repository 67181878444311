import { useEffect, useState } from "react";
import { Button, Col, Form, Row } from "react-bootstrap";
import "./wizard.css";

function StepTopicSelection(props) {
  const { topicList, handleCheckboxChange, navigateToNext, topicId, handleCheckboxChangeDiv } = props;
  const [topicLists, set_topicLists] = useState(topicList);
  const [topicListsReadMore, set_topicListsReadMore] = useState([]);
  useEffect(() => {
    set_topicLists(topicList);
  }, [topicList]);

  const FilterTopics = (v) => {
    if (v?.target?.value) {
      let newTopicLists = topicList.filter(
        (itm) =>
          itm.label.toLowerCase().includes(v?.target?.value.toLowerCase()) ||
          itm.topicDescription.toLowerCase().includes(v?.target?.value.toLowerCase())
      );
      set_topicLists(newTopicLists);
    } else {
      set_topicLists(topicList);
    }
  };

  return (
    <>
      <div className="page-heading  flex-md-row flex-column">
        <h4 className="col hd-txt mt-2">Please Select a Skill</h4>
        <Form.Group className="frm-group me-3 mt-2">
          <Form.Control type="text" placeholder="Search skills.." name="firstName" onChange={FilterTopics} />
        </Form.Group>
        <Button variant="clr-primary" className="btn-add back-continue" disabled={!topicId} onClick={navigateToNext}>
          Continue
        </Button>
      </div>
      <div className="skill-container">
        <Row className="g-3">
          {topicLists &&
            topicLists?.map((item, index) => (
              <Col
                md={5}
                xs={12}
                className={topicId == item.value ? "skill-card-selected     " : "skill-card    "}
                key={index}
                onClick={() => handleCheckboxChangeDiv(item.value)}
              >
                <Form.Check
                  name="skill"
                  value={item.value}
                  type="radio"
                  id="skill"
                  checked={topicId == item.value}
                  className="radio-align"
                  onChange={handleCheckboxChange}
                />
                <div className="skill-detail">
                  <div className="header-text">{item.label}</div>
                  <div>{item.topicDescription}</div>
                </div>
              </Col>
            ))}
        </Row>
      </div>
    </>
  );
}

export default StepTopicSelection;

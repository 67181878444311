import { useState, useEffect, useRef } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, Table } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { TopicsService, UserService, AccreditationService, CoachRatingServices } from "../../services";
import { copyObjectByKeys } from "../../utilities/functions";
import SelectField from "../CustomComponent/Select";
import PillWithDelete from "../CustomComponent/PillBadge";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import OverlayTrigger from "react-bootstrap/OverlayTrigger";
import Tooltip from "react-bootstrap/Tooltip";

function CoachEdit({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [coachId, set_coachId] = useState(params.id);
  const [topicList, set_topicList] = useState([]);
  const [coachDetails, set_coachDetails] = useState({});
  const [accredLevelList, set_accredLevelList] = useState([]);
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [profilePic, setProfilePic] = useState();
  const [certificates, setCertificates] = useState([]);
  const [Listcertificates, setListCertificates] = useState([]);
  const [customError, setCustomError] = useState({});
  const [deletedList, setDeletedlist] = useState([]);
  const [regAboutMe, setRegAboutMe] = useState();
  const [resetRatingValue, setResetRatingValue] = useState();
  const [RatingValue, setRatingValue] = useState([]);
  const [reseterror, setError] = useState("");

  const inputRef = useRef();
  const multipleInputRef = useRef();
  const inputRefAbout = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchAccreditationList();
    fetchTopics();
  }, []);

  useEffect(() => {
    if (coachId) fetchCoachDetails();
  }, [coachId]);

  useEffect(() => {
    if (coachDetails.id) populateForm();
  }, [coachDetails.id]);

  function getFormObject() {
    return {
      firstName: "",
      lastName: "",
      username: "",
      phone: "",
      email: "",
      bankCode: "",
      bankBranchNumber: "",
      bankAccountNumber: "",
      skills: "",
      topic: [],
      aboutSelf: "",
      accreditationLevel: "",
      coachPrice: "",
      resetRating: "",
      experienceAchieved: "",
    };
  }

  const FormSchema = Yup.object().shape({
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    username: Yup.string().required("Username is Required"),
    phone: Yup.string().required("Contact Number is Required"),
    email: Yup.string().email("Email is invalid").required("Email is Required"),
    bankCode: Yup.string().required("Bank Code is Required"),
    bankBranchNumber: Yup.string().required("Bank Branch Number is Required"),
    bankAccountNumber: Yup.string().required("Bank Branch Number is Required"),
    skills: Yup.string().required("Skills is Required"),
    topic: Yup.array().min(1, "Select at least one option").required("Select at least one option"),
    aboutSelf: Yup.string().required("About Self info is Required"),
    accreditationLevel: Yup.string().required("Select an Accredition Level"),
    coachPrice: Yup.number().required("Price is Required").positive("Enter positive value"),
    experienceAchieved: Yup.string().required("Experience and Achievement info is Required"),
  });

  async function isUniqueCheck(value) {
    try {
      const resp = await trackPromise(UserService.isFieldUnique(value));
      const { data } = resp;
      if (value.username) {
        if (value.username == coachDetails.username) {
          addCustomErrors({ username: "" });
        } else {
          addCustomErrors({ username: data.data.isUnique ? "" : "Username Already Exist" });
        }
      } else if (value.email) {
        if (value.email == coachDetails.email) {
          addCustomErrors({ email: "" });
        } else {
          addCustomErrors({ email: data.data.isUnique ? "" : "Email Address Already Exist" });
        }
      }
    } catch {
      if (value.username) {
        addCustomErrors({ username: "" });
      } else if (value.email) {
        addCustomErrors({ email: "" });
      }
    }
  }

  const addCustomErrors = (errors) => {
    setCustomError((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  function checkCustomError() {
    let error = true;
    Object.keys(customError).forEach((key) => {
      if (customError[key]) error = false;
    });

    return error;
  }

  async function fetchAccreditationList() {
    try {
      const resp = await trackPromise(AccreditationService.getList());
      const { data } = resp;
      if (data.success) {
        set_accredLevelList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Accreditation List");
      }
    } catch (err) {
      console.error("Fetch Accreditation List catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchTopics() {
    try {
      const resp = await trackPromise(TopicsService.getList());
      const { data } = resp;
      if (data.success) {
        set_topicList(
          data.data.map((topics) => {
            return { value: topics.id, label: topics.topicName };
          })
        );
      } else {
        set_pageError(data.message || "Error in fetching topics categories");
      }
    } catch (err) {
      console.error("Fetch coach topic catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const handleUpload = () => {
    inputRef.current?.click();
  };

  const handleProfilePic = () => {
    if (inputRef.current.files) {
      setProfilePic(inputRef.current.files[0]);
    }
  };

  const handleAboutmeUpload = () => {
    inputRefAbout.current?.click();
  };

  const handleAboutMe = () => {
    if (inputRefAbout.current.files) {
      setRegAboutMe(inputRefAbout.current.files[0]);
    }
  };

  const handleMultiUpload = () => {
    multipleInputRef.current?.click();
  };

  const handleCertificatesPic = () => {
    if (multipleInputRef.current.files) {
      setCertificates([...multipleInputRef.current.files]);
    }
  };

  async function fetchCoachDetails() {
    try {
      const resp = await trackPromise(UserService.getCoachById(coachId));
      const { data } = resp;
      if (data.success) {
        set_coachDetails(data.data);
      } else {
        setError("Rating must be between 1 and 5");
        set_pageError(data.message || "Error in fetching Coach details");
      }
    } catch (err) {
      console.error("Fetch coach details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const handleRating = (e) => {
    const inputValue = e.target.value;
    setResetRatingValue(inputValue);

    if (inputValue === "") {
      setError("Rating is required.");
    } else if (inputValue < 1 || inputValue > 5) {
      setError("Rating must be between 1 and 5.");
    } else if (inputValue <= 0) {
      setError("Rating must be a positive value.");
    } else {
      setError(""); // Reset the error if the input is valid
    }
  };

  async function resetRating() {
    const payload = {
      resetRating: resetRatingValue, // Use the state variable as the payload
    };

    try {
      const resp = await trackPromise(CoachRatingServices.resetCoachRating(coachId, payload));
      const { data } = resp;
      if (data.success) {
        setRatingValue(data.data);
        toast.success("Rating Reset Sucessfully.");
      } else {
        setError(data.message);
        set_pageError(data.message || "Error in fetching Coach details");
      }
    } catch (err) {
      console.error("Fetch coach details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, coachDetails);
    formobj.accreditationLevel = coachDetails.accreditationLevel?.id;
    formobj.topic = coachDetails.topics?.map((topic) => {
      return { value: topic.id, label: topic.topicName };
    });
    set_formObject({ ...formobj });
    setListCertificates(coachDetails?.certificateDocs);
    setResetRatingValue(coachDetails?.resetRating);
  }

  function formatForm(form) {
    const updatedForm = { ...form, topics: form.topic?.map((topic) => topic.value) };
    if (deletedList.length > 0) {
      updatedForm.certDocsToDelete = deletedList;
    }
    delete updatedForm.topic;
    return updatedForm;
  }

  function getFormData(object) {
    const formData = new FormData();
    Object.keys(object).forEach((key) => {
      const value = object[key];
      if (typeof value === "object" && !value.uri) {
        formData.append(key, JSON.stringify(value));
      } else {
        formData.append(key, value);
      }
    });
    return formData;
  }

  async function handleFormSubmit(values) {
    try {
      if (checkCustomError()) {
        const formatedValues = formatForm(values);
        const formData = getFormData({ ...formatedValues });
        if (profilePic) {
          formData.append("profileImage", profilePic);
        }
        if (regAboutMe) {
          formData.append("profileVideo", regAboutMe);
        }
        if (certificates.length > 0) {
          certificates.forEach((file, i) => {
            formData.append("certificateDocs", file);
          });
        }

        const resp = await trackPromise(
          UserService.updateCoach(coachId, formData, { headers: { "Content-Type": "multipart/form-data" } })
        );
        const { data } = resp;
        if (data.success) {
          toast.success("Coach updated successfully.");
          navigate(-1);
        } else {
          set_pageError(data.message || "Coach updated failed");
        }
      }
    } catch (err) {
      console.error("Coach error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Register error");
    }
  }

  function removeFile(name) {
    const deletedNewList = [...deletedList, name];
    setDeletedlist(deletedNewList);
    const newList = Listcertificates.filter((files) => files != name);
    setListCertificates(newList);
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{coachId ? "Edit" : "Add"} Coach</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              validateOnBlur={true}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={touched.firstName && errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={touched.lastName && errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Username"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ username: value.target.value, userId: coachId })}
                      isInvalid={(touched.username && errors.username) || customError.username}
                    />
                    <Form.Control.Feedback type="invalid">
                      {errors.username || customError.username}
                    </Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="phone"
                      placeholder="Enter Contact"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={touched.phone && errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Email Address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ email: value.target.value, userId: coachId })}
                      isInvalid={(touched.email && errors.email) || customError.email}
                    />
                    <Form.Control.Feedback type="invalid">{errors.email || customError.email}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Swift Code or Bank Identification Code (BIC)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Code"
                      name="bankCode"
                      value={values.bankCode}
                      onChange={handleChange}
                      isInvalid={touched.bankCode && errors.bankCode}
                    />
                    <Form.Control.Feedback type="invalid">{errors.bankCode}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Bank-State-Branch (BSB) Number</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      name="bankBranchNumber"
                      value={values.bankBranchNumber}
                      onChange={handleChange}
                      isInvalid={touched.bankBranchNumber && errors.bankBranchNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.bankBranchNumber}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>International Bank Account Number (IBAN)</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Number"
                      name="bankAccountNumber"
                      value={values.bankAccountNumber}
                      onChange={handleChange}
                      isInvalid={touched.bankAccountNumber && errors.bankAccountNumber}
                    />
                    <Form.Control.Feedback type="invalid">{errors.bankAccountNumber}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Form.Label>Key Core Competencies</Form.Label>
                    <Field
                      name="topic"
                      component={SelectField}
                      isMulti={true}
                      id="topic"
                      placeholder="Select Topics"
                      defaultValue={values.topic}
                      options={topicList}
                      setFieldValue={setFieldValue}
                      isInvalid={touched.topic && errors.topic}
                      errorClass="fieldError"
                    />
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Additional Qualifications and Credentials</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Skills"
                      name="skills"
                      value={values.skills}
                      onChange={handleChange}
                      isInvalid={touched.skills && errors.skills}
                    />
                    <Form.Control.Feedback type="invalid">{errors.skills}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Professional Biography</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Tell us about your self"
                      name="aboutSelf"
                      value={values.aboutSelf}
                      onChange={handleChange}
                      isInvalid={touched.aboutSelf && errors.aboutSelf}
                    />
                    <Form.Control.Feedback type="invalid">{errors.aboutSelf}</Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Form.Label>Experience and Achievements</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={4}
                      placeholder="Showcase Your Professional Minestone and Significant Accomplishments in Your Coaching Carrer"
                      name="experienceAchieved"
                      value={values.experienceAchieved}
                      onChange={handleChange}
                      isInvalid={touched.experienceAchieved && errors.experienceAchieved}
                    />
                    <Form.Control.Feedback type="invalid">{errors.experienceAchieved}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Row>
                      <Col className=" d-flex">
                        <Form.Label>Upload Profile Picture</Form.Label>
                        <OverlayTrigger placement="left" delay={{ show: 250, hide: 400 }} overlay={RenderTooltip02}>
                          <FontAwesomeIcon className="ms-2" icon={faInfoCircle} size="xl" />
                        </OverlayTrigger>
                      </Col>
                    </Row>
                    <input ref={inputRef} type="file" className="hidden-content" onChange={handleProfilePic} />
                    <div className="d-flex mb-3 upload-div">
                      <Button variant="clr-trasparent-new" type="button" className="ms-lg-3" onClick={handleUpload}>
                        Add a file
                      </Button>
                      {profilePic ? <div className="item">{profilePic.name}</div> : null}
                    </div>
                    <div className="item">
                      {coachDetails?.profileImage ? (
                        <PillWithDelete
                          text={coachDetails?.profileImage}
                          hideDelete={true}
                          assetsDir={coachDetails.assetsDir}
                        />
                      ) : null}
                    </div>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Upload Certificates</Form.Label>
                    <input
                      type="file"
                      ref={multipleInputRef}
                      className="hidden-content"
                      onChange={handleCertificatesPic}
                      multiple
                    />
                    <div className="d-flex mb-3 upload-div">
                      <div>
                        <Button
                          variant="clr-trasparent-new"
                          type="button"
                          onClick={handleMultiUpload}
                          className="ms-lg-3"
                        >
                          Add a file
                        </Button>
                      </div>
                      <div className="item">
                        {certificates.length > 0
                          ? certificates.map((item) => <div className="item">{item.name}</div>)
                          : null}
                      </div>
                    </div>

                    {Listcertificates?.length > 0 ? (
                      <DocTable tblData={Listcertificates} removeFile={removeFile} coachDetails={coachDetails} />
                    ) : null}
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Upload a 1-minute Professional Introduction Video</Form.Label>
                    <input ref={inputRefAbout} type="file" className="hidden-content" onChange={handleAboutMe} />
                    <div className="d-flex mb-3 upload-div">
                      <Button
                        variant="clr-trasparent-new"
                        type="button"
                        className="ms-lg-3"
                        onClick={handleAboutmeUpload}
                      >
                        Add a file
                      </Button>
                      {regAboutMe ? <div className="item">{regAboutMe.name}</div> : null}
                    </div>
                    <div className="item">
                      {coachDetails?.profileVideo ? (
                        <PillWithDelete
                          text={coachDetails?.profileVideo}
                          hideDelete={true}
                          assetsDir={coachDetails.assetsDir}
                        />
                      ) : null}
                    </div>
                  </Form.Group>

                  <Form.Group className="frm-group mb-4">
                    <Form.Label>Level of Accreditation</Form.Label>
                    <Form.Select
                      name="accreditationLevel"
                      value={values.accreditationLevel}
                      onChange={handleChange}
                      isInvalid={touched.accreditationLevel && errors.accreditationLevel}
                    >
                      <option value={""}>Select Your Level of Accreditation</option>
                      {accredLevelList?.length ? (
                        accredLevelList.map((itm, inx) => (
                          <option key={inx} value={itm._id}>
                            {itm.levelName}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>

                    <Form.Control.Feedback type="invalid">{errors.accreditationLevel}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Assign Hourly Price</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Price"
                      name="coachPrice"
                      value={values.coachPrice}
                      onChange={handleChange}
                      isInvalid={touched.coachPrice && errors.coachPrice}
                    />
                    <Form.Control.Feedback type="invalid">{errors.coachPrice}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Reset Rating</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Rating"
                      name="resetRating"
                      value={resetRatingValue}
                      onChange={handleRating}
                      isInvalid={touched.resetRating && errors.resetRating}
                    />
                    {reseterror ? <div className="mt-3 text-danger">{reseterror}</div> : <></>}
                    <Form.Control.Feedback type="invalid">
                      {errors.resetRating || reseterror.resetRating}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Button variant="clr-primary" className="me-2 mb-3" onClick={() => resetRating()}>
                    Reset
                  </Button>

                  <div className="d-flex justify-content-center mb-3">
                    <Button as={Link} to="/coach" variant="clr-transparent" className="col-lg me-2">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-2">
                      Save
                    </Button>
                  </div>
                  {pageError ? <div className="mt-3 text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CoachEdit;

export function DocTable({ tblData, removeFile, coachDetails, hideDelete }) {
  return (
    <span>
      {tblData.length > 0
        ? tblData.map((item) => (
            <Col className="mb-1" key={item}>
              <PillWithDelete
                text={item}
                onDelete={removeFile}
                assetsDir={coachDetails.assetsDir}
                hideDelete={hideDelete}
              />
            </Col>
          ))
        : null}
    </span>
  );
}

const RenderTooltip02 = (props) => (
  <Tooltip {...props}>Select proper format and file size must be less than 1 MB.</Tooltip>
);

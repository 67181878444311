import React from "react";
import { Navbar, NavLink } from "react-bootstrap";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faAlignLeft } from "@fortawesome/free-solid-svg-icons";
import { THEME } from "../../utilities/theme";
import Logo from "../../assets/images/Rise_Thrive_20a.png";

function SideHeader({ navCollapsed, collapseNav }) {
  return (
    <div className="side-header flx-center-xy">
      {/* <div className="sidenav-toggler" onClick={collapseNav}>
        <FontAwesomeIcon size="xl" icon={faAlignLeft} color={THEME.CLR_PRIMARY} title="Toggle Sidenav" />
      </div> */}
      {navCollapsed ? (
        <></>
      ) : (
        <Navbar.Brand className="">
          <img src={Logo} alt="Coaching App" />
        </Navbar.Brand>
      )}
    </div>
  );
}

export default SideHeader;

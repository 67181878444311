import { useState, useEffect } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { OrganisationService, UserService } from "../../services";
import { copyObjectByKeys } from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";

function AdminCreate({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [adminId, set_adminId] = useState(params.id);
  const [adminDetails, set_adminDetails] = useState({});
  const [orgList, set_orgList] = useState([]);
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [createFormObj, setCreateFormObj] = useState(getFormObject());
  const [customError, setCustomError] = useState({});
  const location = useLocation();

  const { selectedData } = location.state ?? "";

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchOrgList();
  }, []);

  useEffect(() => {
    if (adminId) fetchAdminDetails();
  }, [adminId]);

  useEffect(() => {
    if (adminDetails.id) populateForm();
  }, [adminDetails.id]);

  useEffect(() => {
    createNewPopulateForm();
  }, [selectedData]);

  function getFormObject() {
    return {
      organisation: "",
      username: "",
      firstName: "",
      lastName: "",
      password: "",
      email: "",
      edit: false,
    };
  }

  const FormSchema = Yup.object().shape({
    organisation: Yup.string().required("Select an Organisation"),
    username: Yup.string().required("Username is Required"),
    firstName: Yup.string().required("First name is Required"),
    lastName: Yup.string().required("Last name is Required"),
    edit: Yup.boolean(),
    password: Yup.string().when("edit", {
      is: false,
      then: () => Yup.string().required("Password is Required"),
    }),
    email: Yup.string().email("Email is Invalid").required("Email is Required"),
  });

  async function fetchOrgList() {
    try {
      const resp = await trackPromise(OrganisationService.getList());
      const { data } = resp;
      if (data.success) {
        set_orgList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching org list");
      }
    } catch (err) {
      console.error("Fetch org list catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    formobj.edit = true;
    copyObjectByKeys(formobj, adminDetails);
    formobj.organisation = adminDetails.organisation.id;
    set_formObject({ ...formobj });
  }

  function createNewPopulateForm() {
    const data = { organisation: selectedData };
    let formObj = { ...createFormObj };
    formObj.edit = true;
    copyObjectByKeys(formObj, data);
    setCreateFormObj({ ...formObj });
  }

  async function handleFormSubmit(values) {
    const submitText = adminId ? "Updated" : "Created";
    try {
      const resp = adminId
        ? await trackPromise(UserService.updateAdmin(adminId, values))
        : await trackPromise(UserService.createAdmin(values));

      const { data } = resp;
      if (data.success) {
        toast.success(`Admin ${submitText} Successfully`);
        navigate(-1);
      } else {
        set_pageError(data.message || `Failed to ${submitText} Admin`);
      }
    } catch (err) {
      console.error("Admin error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Admin error");
    }
  }

  async function fetchAdminDetails() {
    try {
      const resp = await trackPromise(UserService.getAdminById(adminId));
      const { data } = resp;
      if (data.success) {
        set_adminDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching admin details");
      }
    } catch (err) {
      console.error("Fetch admin details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  const addCustomErrors = (errors) => {
    setCustomError((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  async function isUniqueCheck(value) {
    try {
      const resp = await trackPromise(UserService.isFieldUnique(value));
      const { data } = resp;
      if (value.username) {
        addCustomErrors({ username: data.data.isUnique ? "" : "Username Already Exist" });
      } else if (value.email) {
        addCustomErrors({ email: data.data.isUnique ? "" : "Email Address Already Exist" });
      }
    } catch {
      if (value.username) {
        addCustomErrors({ username: "" });
      } else if (value.email) {
        addCustomErrors({ email: "" });
      }
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{adminId ? "Edit" : "Create"} Admin for Organisation</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={adminId && selectedData === undefined ? formObject : createFormObj}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Organisation</Form.Label>
                    <Form.Select
                      name="organisation"
                      value={values.organisation}
                      onChange={handleChange}
                      isInvalid={touched.organisation && errors.organisation}
                      disabled={adminId}
                    >
                      <option>Select Organisation</option>
                      {orgList?.length ? (
                        orgList.map((itm, inx) => (
                          <option key={inx} value={itm.id}>
                            {itm.orgName}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.organisation}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Username</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Username"
                      name="username"
                      value={values.username}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ username: value.target.value, userId: adminId })}
                      isInvalid={(touched.username && errors.username) || customError.username}
                      //isInvalid={touched.username && errors.username}
                    />
                    {/* <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid">
                      {errors.username || customError.username}
                    </Form.Control.Feedback>
                  </Form.Group>
                  <Form.Group className="frm-group">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={touched.firstName && errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={touched.lastName && errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Password</Form.Label>
                    <InputGroup className="mb-3">
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={touched.password && errors.password}
                        disabled={adminId && values.edit}
                      />
                      <InputGroup.Text>
                        {!showPassword ? (
                          <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword((prevState) => !prevState)} />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => setShowPassword((prevState) => !prevState)}
                          />
                        )}
                      </InputGroup.Text>
                      {adminId ? (
                        <Button variant="clr-primary" onClick={() => setFieldValue("edit", !values.edit)}>
                          Edit
                        </Button>
                      ) : null}

                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="frm-group mb-4">
                    <Form.Label>Email Address</Form.Label>

                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ email: value.target.value, userId: adminId })}
                      isInvalid={(touched.email && errors.email) || customError.email}
                      // isInvalid={touched.email && errors.email}
                    />
                    {/* <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid">{errors.email || customError.email}</Form.Control.Feedback>
                  </Form.Group>

                  <div className="row g-0 frm-btn-grp gap-2">
                    <Button as={Link} to="/admin" variant="clr-transparent" className="col-lg me-lg-3">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-lg-3">
                      {adminId ? "Edit" : "Create New"} Admin
                    </Button>
                  </div>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default AdminCreate;

import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Table, Button, Tab, Nav, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { BookingServices, UserService, BookSlotServices } from "../../services";
import { faPencil } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faCircleCheck, faClock, faTrashCan } from "@fortawesome/free-regular-svg-icons";
import moment from "moment";
import DeleteModal from "../CustomComponent/DeleteAppointment";

import { toast } from "react-toastify";
import EnvConfig from "../../envConfig";
import Profile from "../../assets/images/avtar.png";
import { Rating } from "react-simple-star-rating";
import { formatDate, formatTime } from "../../utilities/functions";

function StudentAppointmentList({ pageTitle }) {
  const dispatch = useDispatch();
  const params = useParams();
  const { authState } = useSelector((state) => state);
  const navigate = useNavigate();
  const [activeTab, set_activeTab] = useState("tab01");
  const [bookingId, set_bookingId] = useState(params.bookingId);
  const [appointmentList, set_appointmentList] = useState();
  const [inProgessList, set_inProgessList] = useState([]);
  const [completedList, set_completedList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [selectedAppoint, setselectedAppoint] = useState(null);
  const [isModalOpen, setIsModalOpen] = useState(false);
  const [modalMessage, setModalMessage] = useState("");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);
  useEffect(() => {
    if (bookingId) fetchBooking();
  }, [bookingId]);

  useEffect(() => {
    categoriseAppointment();
  }, [appointmentList]);

  async function fetchBooking() {
    try {
      const resp = await trackPromise(BookingServices.getAppointmentDetail(bookingId));
      const { data } = resp;

      if (data.success) {
        
        set_appointmentList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function categoriseAppointment() {
    const inProgess = [],
      completed = [];
    for (let i = 0; i < appointmentList?.bookedSlots.length; i++) {
      let bookingItem = appointmentList?.bookedSlots[i];
      bookingItem.coach = appointmentList.coach;
      bookingItem.topicName = appointmentList?.topic?.topicName;
      if (bookingItem.slotStatus === "in-progress" || bookingItem.slotStatus === "confirmed") {
        inProgess.push(bookingItem);
      }
      if (bookingItem.slotStatus === "completed") {
        completed.push(bookingItem);
      }
    }
    set_inProgessList(inProgess);
    set_completedList(completed);
  }

  function navToSlotDetail(itm) {
    navigate(`/${authState.user.organisation.orgSlug}/manageCoaching/slotDetail/${itm.subSlot}`);
  }

  const handleEventClick = (id) => {
    setselectedAppoint(id);
    deleteAppointApi(id);
    setIsModalOpen(true);
  };

  const closeModal = () => {
    setselectedAppoint(null);
    setIsModalOpen(false);
  };

  const deleteAppointApi = async (id) => {
    try {
      const resp = await BookSlotServices.cancelSlot(id);
      const { data } = resp;

      if (data.success) {
        if (data.data.confirmToForce) {
          const userConfirmed = set_pageError(data.message);
          //   forceCancelAppointment(id);
          // } else {
          //   fetchBooking();
          // }
          if (userConfirmed) {
            await forceCancelAppointment(id);
            fetchBooking();
          }
        } else {
          fetchBooking();
          set_pageError(data.message);
        }
      } else {
        toast.error(data.message || "Cancel error");
        set_pageError(data.message || "Error in deleting slots");
      }
    } catch (err) {
      console.error("Fetch delete slots catch => ", err);
      set_pageError(err);
    }
  };

  const forceCancelAppointment = async (id) => {
    try {
      // Call the cancelSlot API with the { forceAbort: true } payload
      const resp = await BookSlotServices.cancelSlot(id, { forceAbort: true });
      const { data } = resp;

      toast.success("Appointment Cancelled Successfully");
      fetchBooking();
      closeModal();
    } catch (err) {
      console.error("Error in force canceling the appointment: ", err);
      set_pageError(err);
    }
  };

  // async function deleteAppointApi(id) {
  //   try {
  //     const resp = await trackPromise(BookSlotServices.cancelSlot(id));
  //     const { data } = resp;

  //     if (data?.success === true) {
  //       toast.success("Appointment cancel Successfully");
  //       console.log("Appointment cancel Successfully");
  //       fetchBooking();
  //     } else {
  //       toast.error(data?.message);
  //     }
  //   } catch (err) {
  //     console.error("Cancel error catch => ", err);
  //     if (err.code !== "ERR_NETWORK") {
  //       toast.error(err.response.data.message || "Cancel error");
  //       set_pageError(err.response.data.message);
  //     } else {
  //       set_pageError(err.response.data.message);
  //     }
  //   }
  // }

  const deleteAppoint = async (id, forceAbort) => {
    const requestData = {
      forceAbort: forceAbort || false,
    };

    forceCancelAppointment(id, requestData);

    fetchBooking();
    closeModal();
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt"></h4>
      </div>
      <div className="tab-thm-01">
        <Tab.Container defaultActiveKey={activeTab}>
          <Row className="g-0 mb-4">
            <Nav variant="tabs" className="">
              <Nav.Link className="" eventKey="tab01">
                Upcoming
              </Nav.Link>
              <Nav.Link className="" eventKey="tab02">
                Completed
              </Nav.Link>
              <Nav.Link className="" eventKey="tab03">
                All
              </Nav.Link>
            </Nav>
          </Row>
          <Row className="g-0">
            <Tab.Content>
              <Tab.Pane eventKey="tab01">
                {inProgessList?.length ? (
                  <AppointmentTable
                    navToSlotDetail={navToSlotDetail}
                    tblData={inProgessList}
                    coach={appointmentList?.coach}
                    handleEventClick={handleEventClick}
                  />
                ) : (
                  <div></div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="tab02">
                {completedList?.length ? (
                  <AppointmentTable
                    navToSlotDetail
                    tblData={completedList}
                    coach={appointmentList?.coach}
                    //handleEventClick={handleEventClick}
                  />
                ) : (
                  <div></div>
                )}
              </Tab.Pane>
              <Tab.Pane eventKey="tab03">
                {appointmentList?.bookedSlots.length ? (
                  <AppointmentTable
                    navToSlotDetail={navToSlotDetail}
                    tblData={appointmentList?.bookedSlots}
                    coach={appointmentList.coach}
                    handleEventClick={handleEventClick}
                  />
                ) : (
                  <div></div>
                )}
              </Tab.Pane>
            </Tab.Content>
          </Row>
        </Tab.Container>
      </div>
      {selectedAppoint && (
        <DeleteModal
          id={selectedAppoint}
          isOpen={isModalOpen}
          onClose={closeModal}
          deleteAppoint={deleteAppoint}
          pageError={pageError}
        />
      )}
    </Container>
  );
}

export default StudentAppointmentList;

function AppointmentTable({ tblData = [], navToSlotDetail, coach, handleEventClick }) {
  return (
    <div className="container-fluid">
      {tblData.map((itm, inx) =>{
        let slotStatus=(itm?.slotStatus).replace(/student/ig,"coachee");
        return (
        <Col lg={6} xl={6} xxl={6} key={inx} className="inner-card-border  my-2  mb-3">
          <Row className="mx-1 ms-0 title-name">
            {/* <Col className="d-flex justify-content-between ps-1">
              <div>{`${itm.topic.topicName} `}</div>
            </Col> */}
          </Row>
          <div className="d-flex justify-content-start flex-md-row flex-lg-column flex-xl-row flex-column mx-1 my-1">
            <div className="me-3 mb-2">
              <FontAwesomeIcon icon={faCalendar} />
              <span className="mx-2">{formatDate(itm.fromDateTime)}</span>
            </div>
            <div className="me-3 mb-2">
              <FontAwesomeIcon icon={faClock} />
              <span className="mx-2">
                {formatTime(itm?.fromDateTime)} - {formatTime(itm?.toDateTime)}
              </span>
            </div>
            <div className="mb-2">
              <FontAwesomeIcon icon={faCircleCheck} />
              <span className="mx-2 text-capitalize">{slotStatus}</span>
            </div>
          </div>

          <div className="d-flex justify-content-start flex-row mx-1">
            {/* <Button
              as={Link}
              to="/assignresource"
              size="sm"
              variant="clr-transparent"
              className="col-md-6 me-lg-6 btn-add"
            >
              View Resources
            </Button> */}
            <Button as={Link} to={itm._id} variant="clr-primary" size="sm" className="view-btn px-3 py-2 ms-0 mt-1">
              View Appointment
            </Button>
          </div>
        </Col>
      )})}
    </div>
  );
}

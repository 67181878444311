import React from "react";
import Select from "react-select";

const DropdownList = ({
  name,
  value,
  isMulti,
  allStudent,
  placeholder,
  selectedStudent,
  handleSelect,
  getOptionLabel,
  ...props
}) => {
  const customStyles = {
    control: (provided, state) => ({
      ...provided,
      borderColor: state.isFocused ? "hsl(349, 17%, 81%);" : provided.borderColor,
      boxShadow: state.isFocused ? "hsl(349, 17%, 81%)" : provided.boxShadow,
      // Add other styles as needed

      "&:hover": {
        color: "black", // Change the text color on hover
      },
    }),

    option: (provided, state) => ({
      ...provided,

      backgroundColor: state.isSelected ? "#D6C5C8" : "#fff",
      color: state.data.isDeleted ? "grey" : "black",

      "&:hover": {
        backgroundColor: "#B18C93", // Change the background color on hover
        color: "white", // Change the text color on hover
      },
    }),
  };

  return (
    <Select
      name={name}
      value={value}
      isMulti={isMulti}
      getOptionLabel={getOptionLabel}
      getOptionValue={(option) => option._id}
      onChange={handleSelect}
      options={allStudent}
      placeholder={placeholder}
      closeMenuOnSelect={isMulti ? false : true}
      styles={customStyles}
      // style={{ color: option.isDeleted ? "gray" : "black" }}
    />
  );
};

export default DropdownList;

import { useState, useEffect } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Tab, Nav, Dropdown } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { BookingServices, TopicsService } from "../../services";

import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCircleCheck } from "@fortawesome/free-regular-svg-icons";
import { navigateToNext } from "../AppointmentWizard/AppointmentWizard";

function MyCoursesList({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const [activeTab, set_activeTab] = useState("tab01");
  const [appointmentList, set_appointmentList] = useState([]);
  const [activeList, set_activeList] = useState([]);
  const [completedList, set_completedList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [topicList, setTopicList] = useState([]);
  const [selectedGoal, setSelectedGoal] = useState(null);
  const [activeCoachId, setActiveCoachId] = useState(null);

  const { authState } = useSelector((state) => state);
  const orgSlug = authState?.user?.organisation?.orgSlug;

  const handleGoalSelect = (topic) => {
    setSelectedGoal(topic);

    // setActiveCoachId(topic?.coach?._id);
  };

  // const { handleBookAnotherAppointment } = props;

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchCoaches();
    fetchTopicofCoach();
  }, []);

  useEffect(() => {
    categoriseAppointment();
  }, [appointmentList]);

  async function fetchCoaches() {
    try {
      const resp = await trackPromise(BookingServices.getUserGroupBooking());
      const { data } = resp;
      if (data.success) {
        set_appointmentList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function categoriseAppointment() {
    const active = [],
      completed = [];
    for (let i = 0; i < appointmentList?.length; i++) {
      let bookingItem = appointmentList[i];
      if (bookingItem.bookStatus === "active") {
        active.push(bookingItem);
      }
      if (bookingItem.bookStatus === "completed") {
        completed.push(bookingItem);
      }
    }
    set_activeList(active);
    set_completedList(completed);
  }

  async function fetchTopicofCoach() {
    try {
      const resp = await trackPromise(BookingServices.getStudentActiveBooking());
      const { data } = resp;
      if (data.success) {
        const coachId = data.data?.coach?._id; // Access the coach ID from the payload
        setTopicList(data.data);
        setActiveCoachId(coachId); // Assuming you have a state variable to store the coach ID
      } else {
        set_pageError(data.message || "Error in fetching Topics");
      }
    } catch (err) {
      console.error("Fetch Topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">My Coaching Sessions</h4>

        <Dropdown>
          <Dropdown.Toggle
            variant="clr-primary"
            className="btn-add back-continue "
            id="dropdown-basic"
            disabled={!(topicList?.coach && topicList.balancedSessions > 0)}
          >
            {selectedGoal ? selectedGoal.topicName : "Choose Goal"}
          </Dropdown.Toggle>

          <Dropdown.Menu>
            {topicList?.coach?.topics.map((topic) => (
              <Dropdown.Item key={topic._id} onClick={() => handleGoalSelect(topic)}>
                <span style={{ color: "black", textDecoration: "none" }}>{topic.topicName}</span>
              </Dropdown.Item>
            ))}
          </Dropdown.Menu>
        </Dropdown>

        <Button
          variant="clr-primary"
          className="btn-add back-continue mx-4 "
          onClick={() => {
            navigate(`/${orgSlug}/bookslots`, {
              replace: true,
              state: {
                coach: activeCoachId,
                coachData: topicList?.coach,
                topic: selectedGoal ? selectedGoal._id : topicList?.topic?._id,
              },
            });
          }}
          disabled={!(topicList?.coach && topicList.balancedSessions > 0)}
        >
          Book Another Appointment
        </Button>
      </div>
      <div className="tab-thm-01">
        <Tab.Container defaultActiveKey={activeTab}>
          <Row className="g-0 mb-4">
            <Nav variant="tabs" className="">
              <Nav.Link className="" eventKey="tab01">
                Active
              </Nav.Link>
              <Nav.Link className="" eventKey="tab02">
                Completed
              </Nav.Link>
              {/* <Nav.Link className="" eventKey="tab03">
                Completed
              </Nav.Link> */}
            </Nav>
          </Row>
          <Row className="g-0">
            <Tab.Content>
              <Tab.Pane eventKey="tab01">
                {activeList?.length ? <CourseTable tblData={activeList} /> : <div></div>}
              </Tab.Pane>

              <Tab.Pane eventKey="tab02">
                {completedList?.length ? <CourseTable tblData={completedList} /> : <div></div>}
              </Tab.Pane>
              {/* <Tab.Pane eventKey="tab03">
                {completedList?.length ? <CourseTable tblData={completedList} /> : <div></div>}
              </Tab.Pane> */}
            </Tab.Content>
          </Row>
        </Tab.Container>
      </div>
    </Container>
  );
}

export default MyCoursesList;

function CourseTable({ tblData = [], needApproval = false }) {
  return (
    <div className="container-fluid">
      {tblData?.map((itm, inx) => (
        <Row key={itm._id}>
          <Col lg={4}>
            <Row key={inx} className="inner-card-border g-1 my-1 mb-3 ">
              {/* <Row className="mx-0 title-name">{itm.topic.topicName}</Row> */}
              <Row className="mx-0 title-name mt-0">Coach: {`${itm.coach.firstName} ${itm.coach.lastName}`}</Row>
              <div className="d-flex justify-content-start  mx-0 icon-status">
                <div className="ms-2">
                  <FontAwesomeIcon icon={faCircleCheck} />
                </div>
                <div className="mx-2 text-capitalize">{itm.bookStatus}</div>
              </div>
              <div className="d-flex justify-content-start flex-row mx-1">
                <Button
                  as={Link}
                  variant="clr-primary"
                  size="sm"
                  to={`coach/${itm?.coach?._id}`}
                  className="view-btn ms-0 mt-1 px-3 py-2"
                >
                  View Appointments
                </Button>
              </div>
            </Row>
          </Col>
        </Row>
      ))}
    </div>
  );
}

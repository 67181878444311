import { useState, useEffect } from "react";
import { Navigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { UserService } from "../../services";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CreatePassword() {
  const params = useParams();
  const [navigateTo, set_navigateTo] = useState("");
  const [userInfo, set_userInfo] = useState({});
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [showPassword, setShowPassword] = useState(false);
  const [showNewPassword, setNewShowPassword] = useState(false);
  useEffect(() => {
    fetchUserByToken(params.token);
  }, []);

  function getFormObject() {
    return {
      password: "",
      confirmPwd: "",
    };
  }

  const FormSchema = Yup.object().shape({
    password: Yup.string().min(8, "Password should be minimum 8 characters").required("Password is Required"),
    confirmPwd: Yup.string()
      .required("Password confirmation is Required")
      .oneOf([Yup.ref("password")], "Passwords do not match"),
  });

  async function fetchUserByToken(token) {
    try {
      const resp = await trackPromise(UserService.getByToken(token));
      const { data } = resp;
      if (data.success) {
        set_userInfo(data.data);
      } else {
        set_pageError(data.message || "Error in fetching user info");
      }
    } catch (err) {
      console.error("Fetch user info catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function handleFormSubmit(values) {
    try {
      const resp = await trackPromise(UserService.createPassword(params.token, values));
      const { data } = resp;
      if (data.success) {
        toast.success("Password created successfully! Please Login");
        set_navigateTo("/login");
      } else {
        set_pageError(data.message || "Failed to create Password");
      }
    } catch (err) {
      console.error("Create Password error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Create Password error");
    }
  }

  return navigateTo ? (
    <Navigate to={navigateTo} replace={true} />
  ) : (
    <Container fluid={true} className="register-container">
      <Row className="">
        <Col lg={{ span: 6, offset: 3 }}>
          <div className="register-wrap">
            <Formik initialValues={formObject} validationSchema={FormSchema} onSubmit={handleFormSubmit}>
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <h4 className="frm-heading">
                    Welcome, {userInfo.firstName} {userInfo.lastName}
                  </h4>
                  <p className="frm-subheading mb-4">Please create a password in order to login</p>

                  <Form.Group className="frm-group">
                    <Form.Label>Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        name="password"
                        value={values.password}
                        onChange={handleChange}
                        isInvalid={touched.password && errors.password}
                      />
                      <InputGroup.Text>
                        {!showPassword ? (
                          <FontAwesomeIcon icon={faEye} onClick={() => setShowPassword((prevState) => !prevState)} />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => setShowPassword((prevState) => !prevState)}
                          />
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">{errors.password}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <Form.Group className="frm-group mb-4">
                    <Form.Label>Confirm Password</Form.Label>
                    <InputGroup>
                      <Form.Control
                        type={showNewPassword ? "text" : "password"}
                        placeholder="Enter Password"
                        name="confirmPwd"
                        value={values.confirmPwd}
                        onChange={handleChange}
                        isInvalid={touched.confirmPwd && errors.confirmPwd}
                        id="confirmPwd"
                      />
                      <InputGroup.Text>
                        {!showNewPassword ? (
                          <FontAwesomeIcon icon={faEye} onClick={() => setNewShowPassword((prevState) => !prevState)} />
                        ) : (
                          <FontAwesomeIcon
                            icon={faEyeSlash}
                            onClick={() => setNewShowPassword((prevState) => !prevState)}
                          />
                        )}
                      </InputGroup.Text>
                      <Form.Control.Feedback type="invalid">{errors.confirmPwd}</Form.Control.Feedback>
                    </InputGroup>
                  </Form.Group>

                  <div className="d-flex">
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="mb-2">
                      Create Password
                    </Button>
                  </div>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default CreatePassword;

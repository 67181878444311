import React, { useState, useEffect, useRef, useMemo, useCallback } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { StudentTypeService, UserService, SubscriptionService } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { confirmAlert } from "react-confirm-alert";
import { toast } from "react-toastify";
import { getColorTheme } from "../../stores/colorThemeSlice";
import DeleteListModal from "../CustomComponent/DeleteListModal";
import { object } from "yup";
import DropdownList from "../CustomComponent/DropdownList";

function StudentList({ pageTitle }) {
  const dispatch = useDispatch();
  const [stdTypeList, set_stdTypeList] = useState([]);
  const [selectedType, set_selectedType] = useState("");
  const [allStudents, set_allStudents] = useState([]);
  const [studentList, set_studentList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();
  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [setError, set_Error] = useState();
  const [confirmData, setconfirmData] = useState(false);
  const [serverconfirmData, setseverconfirmData] = useState();
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchStudentTypes();
    fetchStudentsByType();
    dispatch(getColorTheme(colorTheme));
  }, []);

  useEffect(() => {
    if (selectedType._id) {
      set_studentList(
        allStudents.filter(
          (itm) => itm.studentType?.id === selectedType._id || itm.studentType?.typeName === selectedType.typeName
        )
      );
    }
  }, [selectedType, allStudents]);

  async function fetchStudentTypes() {
    // types of student
    try {
      const resp = await trackPromise(SubscriptionService.studentTypeBySubscription());
      const { data } = resp;
      if (data.success) {
        set_stdTypeList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching coachee types");
      }
    } catch (err) {
      console.error("Fetch coachee types catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchStudentsByType() {
    try {
      const resp = await trackPromise(UserService.getOrgStudents());

      const { data } = resp;
      if (data.success) {
        let studentList = data?.data?.filter((itm) => !itm?.isDeleted);
        set_allStudents(studentList);
      } else {
        set_pageError(data.message || "Error in fetching Coachees");
      }
    } catch (err) {
      console.error("Fetch coachee catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  const colorThemeData = useSelector((state) => state.colorTheme.colorThemeData);

  async function deleteStudent(id) {
    const requestData = {
      confirmToDelete: confirmData,
    };
    try {
      const resp = await trackPromise(UserService.deleteStudent(id, requestData));
      const { data } = resp;

      if (data?.success) {
        if (data.data.serverConfirmation) {
          set_Error(data.message);

          setconfirmData(true);

          //await trackPromise(UserService.deleteStudent(id, { confirmToDelete: true }));
        } else {
          toast.success("Cochee Deleted Sucessfully.");
          closeDeleteModal();
        }
        await fetchStudentsByType();

        //toast.success("Cocheee Deleted Sucessfully");
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  const deleteOrg = async (id) => {
    await deleteStudent(id, confirmData);

    //await fetchStudentsByType();
    if (confirmData) {
      closeDeleteModal();
    }

    //closeDeleteModal();
  };

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
    set_Error("");
    setconfirmData(false);
  };

  // const openErrorModal = () => {
  //   // setselectedItem(id);
  //   setShowErrorModal(true);
  // };

  const closeErrorModal = () => {
    // setselectedItem(null);
    setShowErrorModal(false);
    set_Error("");
    setconfirmData(false);
    // Additional logic if needed
  };

  // Define column definitions for Ag-Grid
  const columnDefs = [
    {
      headerName: "First Name",
      field: "firstName",
      // width: 150,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      sortable: true,
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Last Name",
      field: "lastName",
      // width: 120,
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Username",
      field: "username",
      // width: 120,
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Email",
      field: "email",
      // width: 200,
      sortable: true,
      tooltipField: "email",
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Contact",
      field: "phone",
      // width: 150,
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Type",
      field: "studentType.typeName",
      tooltipField: "studentType.typeName",
      // width: 250,
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Sessions",
      field: "studentSessions",
      // width: 100,
      sortable: true,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "center" },
    },
    {
      field: "action",
      headerName: "Action",
      suppressMovable: true,
      // width: 150,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const isDelete = params.data.isDeleted;
        const iconClass = isDelete ? "disabled-icon" : "active-icon";
        return (
          <>
            <Link className="grid_action" to={isDelete ? "" : `view/${params?.data?.id}`}>
              <FontAwesomeIcon
                icon={faEye}
                title="View"
                className={iconClass}
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault(); // Prevent the edit action when isDelete is true
                  }
                }}
              />
            </Link>
            <Link to={isDelete ? "" : `edit/${params.data.id}`}>
              <FontAwesomeIcon
                icon={faPencil}
                title="Edit"
                className={iconClass}
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault(); // Prevent the edit action when isDelete is true
                  }
                }}
              />
            </Link>
            <Link className="grid_action" to="">
              <FontAwesomeIcon
                icon={faTrashCan}
                title="Delete"
                className={iconClass}
                onClick={() => {
                  openDeleteModal(params?.data?.id);
                }}
              />
            </Link>
          </>
        );
      },
    },
  ];
  const defaultColDef = useMemo(() => ({
    sortable: true,
    flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);
  //Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const handleSelect = (selectedItem) => {
    set_selectedType(selectedItem);
  };

  const getOptionLabelFunction = (option) => `${option.typeName}`;

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        {/*  <h4 className="col hd-txt">Coachees List</h4> */}
        <Button
          as={Link}
          to="create"
          variant="clr-primary"
          title="Add Coachee"
          className="btn-add"
          state={{ selectedData: selectedType?._id }}
        >
          Add New
        </Button>
      </div>

      <Row className="mb-4">
        <Col lg="12">
          <Form.Group className="frm-group">
            <Form.Label>Coachee Type</Form.Label>
            <Col xs={12} md={4}>
              <DropdownList
                name="studentType"
                value={selectedType}
                allStudent={stdTypeList}
                handleSelect={handleSelect}
                placeholder="Select Coachee Type"
                getOptionLabel={getOptionLabelFunction}
                isMulti={false}
              />
            </Col>

            {/* <Form.Select name="studentType" value={selectedType} onChange={(e) => set_selectedType(e.target.value)}>
              <option>Select Type</option>
              {stdTypeList?.length ? (
                stdTypeList.map((itm, inx) => (
                  <option key={inx} value={itm.id}>
                    {itm.typeName}
                  </option>
                ))
              ) : (
                <></>
              )}
            </Form.Select> */}
          </Form.Group>
        </Col>
      </Row>

      <Row className="">
        <Col lg="12">
          {studentList?.length ? (
            <>
              <div className="ag-theme-alpine" style={{ height: "480px", width: "100%" }}>
                <AgGridReact
                  id="myGrid"
                  className="myGrid"
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  ref={gridRef}
                  rowData={studentList}
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                  onFirstDataRendered={onFirstDataRendered}
                />
                <div className="page-size-wrap">
                  <div className="page-size">
                    Records Per Page:
                    <select onChange={onPageSizeChanged} id="page-size">
                      <option value="10">10</option>
                      <option value="25">25</option>
                      <option value="50">50</option>
                    </select>
                  </div>
                </div>
              </div>
            </>
          ) : (
            <div>No Coachee Found</div>
          )}
        </Col>
      </Row>

      {selectedItem && (
        <DeleteListModal
          id={selectedItem}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          deleteItem={deleteOrg}
          pageError={setError}
          serverconfirmData={serverconfirmData}
          showErrorModal={showErrorModal}
          closeErrorModal={closeErrorModal}
        />
      )}
    </Container>
  );
}

export default StudentList;

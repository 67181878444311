import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Field, Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { copyObjectByKeys } from "../../utilities/functions";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";
import { EmailTemplateService } from "../../services";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";

function EmailTrigger({ pageTitle }) {
  const dispatch = useDispatch();
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [emailType, set_emailType] = useState("");
  const [emailTypeCode, set_emailTypeCode] = useState("");
  const [templateTypes, set_templateTypes] = useState([]);
  const [templateByorgType, set_templateByorgType] = useState({});
  const [templateByType, set_templateByType] = useState({});
  const [showPill, setShowPill] = useState(false);
  const params = useParams();
  const [typeId, set_adminId] = useState(params.id);
  const [isEdit, setIsEdit] = useState(false);
  const [createFormObj, setCreateFormObj] = useState(getFormObject());

  const navigate = useNavigate();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    set_pageError("");
    // Check if params contain data for editing
  }, []);

  useEffect(() => {
    if (typeId) fetchDataForEdit();
  }, [typeId]);

  useEffect(() => {
    if (templateByorgType?.id) populateForm();
    setShowPill(true);
  }, [templateByorgType?.id]);

  useEffect(() => {
    if (templateByType?.id) populateForm();
    setShowPill(true);
  }, [templateByType?.id]);

  // function populateForm() {
  //   let formobj = { ...formObject };
  //   formobj.edit = true;
  //   copyObjectByKeys(formobj, templateByorgType);
  //   copyObjectByKeys(formobj, templateByType);
  //   set_emailType(templateByType?.emailTypeCode);

  //   set_formObject({ ...formobj });
  //   //set_formObject({ formobj, templateByType });
  // }

  function populateForm() {
    let formobj = {
      ...formObject,
      ...templateByorgType, // Copy properties from templateByorgType
    };

    // If you want to prioritize values from templateByType, uncomment the line below
    formobj = { ...formobj, ...templateByType };

    set_formObject(formobj);
    if (templateByType && templateByType.emailTypeCode) {
      set_emailType(templateByType.emailTypeCode);
      setShowPill(true);
    }
  }

  // function createNewPopulateForm() {
  //   // const data = { emailTypeCode: typeCode };
  //   let formObj = { ...createFormObj };
  //   formObj.edit = true;
  //   copyObjectByKeys(formObj, templateByType);

  //   setCreateFormObj({ ...formObj });
  // }

  useEffect(() => {
    async function fetchtemplateTypes() {
      try {
        const resp = await trackPromise(EmailTemplateService.getEmailTemplateTypes());
        const { data } = resp;
        if (data.success) {
          set_templateTypes(data.data);
        } else {
          set_pageError(data.message || "Error in fetching Email Template Types");
        }
      } catch (err) {
        console.error("Fetch Email Template Types catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
    fetchtemplateTypes();
  }, []);

  const SelectedType = templateTypes.filter((typ) => typ.emailTypeCode === emailType);

  function getFormObject() {
    return {
      emailSender: "",
      emailSubject: "",
      emailContent: "",
      emailTriggerFor: "",
      emailTypeCode: "",
    };
  }

  const getTemplateByType = async (type) => {
    if (SelectedType.length !== 0 && SelectedType !== null) {
      try {
        const resp = await trackPromise(EmailTemplateService.getOrgEmailTemplateType(type));
        const { data } = resp;

        if (data.success) {
          set_templateByorgType(data.data);
          setShowPill(true);
          //populateForm(data.data);
          set_templateByType({});
        } else {
          set_pageError(data.message || "Error in fetching Email Template ORG Types");
        }
      } catch (err) {
        console.error("Fetch Email Template ORG Types catch => ", err);
        if (err.code !== "ERR_NETWORK") {
          set_pageError(err.response.data.message);
        } else {
          set_pageError(err.message);
        }
      }
    }
  };

  async function fetchDataForEdit() {
    // Fetch data for editing based on emailTypeCode
    // Set the fetched data to the formObject
    try {
      const resp = await trackPromise(EmailTemplateService.getOrgEmailTemplatesById(typeId));
      const { data } = resp;
      if (data.success) {
        set_templateByType(data.data);

        // let formobj = { ...formObject };
        // formobj.edit = true;
        // copyObjectByKeys(formobj, data.data);
        // set_formObject({ ...formobj });

        setShowPill(true);
        //setShowPill(true);
      } else {
        set_pageError(data.message || "Error in fetching Email Template ORG Types");
      }
    } catch (err) {
      console.error("Fetch Email Template ORG Types catch => ", err);
      // Handle error
    }
  }

  const FormSchema = Yup.object().shape({
    // emailSender: Yup.string().required("Email is Required"),
    emailSubject: Yup.string().required("Subject  is Required"),
    emailContent: Yup.string().required("Body is Required"),
    // email: Yup.string().email("Email is invalid").required("Email is Required"),
  });

  // async function fetchEmailData() {
  //   try {
  //     const resp = await trackPromise(UserService.profile());
  //     const { data } = resp;
  //     if (data.success) {
  //       set_Details(data.data);
  //     } else {
  //       set_pageError(data.message || "Error in fetching profile");
  //     }
  //   } catch (err) {
  //     console.error("Fetch profile catch => ", err);
  //     if (err.code !== "ERR_NETWORK") {
  //       set_pageError(err.response.data.message);
  //     } else {
  //       set_pageError(err.message);
  //     }
  //   }
  // }

  // async function handleFormSubmit(values) {
  //   const submitText = "update";
  //   try {
  //     const resp = await trackPromise(UserService.updateProfile(profileDetails.id, values));

  //     const { data } = resp;
  //     if (data.success) {
  //       toast.success(`Profile ${submitText} successfully`);
  //       navigate(-1);
  //     } else {
  //       set_pageError(data.message || `Failed to ${submitText} Profile`);
  //     }
  //   } catch (err) {
  //     console.error("Profile error catch => ", err);
  //     if (err.code !== "ERR_NETWORK") {
  //       set_pageError(err.response.data.message);
  //     } else {
  //       set_pageError(err.message);
  //     }
  //     toast.error(err.response.data.message || err.message || "Profile error");
  //   }
  // }

  var modules = {
    toolbar: [
      [{ size: ["small", false, "large", "huge"] }],
      ["bold", "italic", "underline", "strike", "blockquote"],
      [{ list: "ordered" }, { list: "bullet" }],
      [{ list: "ordered" }, { list: "bullet" }, { indent: "-1" }, { indent: "+1" }, { align: [] }],
      ["link"],
      [
        {
          color: [
            "#000000",
            "#e60000",
            "#ff9900",
            "#ffff00",
            "#008a00",
            "#0066cc",
            "#9933ff",
            "#ffffff",
            "#facccc",
            "#ffebcc",
            "#ffffcc",
            "#cce8cc",
            "#cce0f5",
            "#ebd6ff",
            "#bbbbbb",
            "#f06666",
            "#ffc266",
            "#ffff66",
            "#66b966",
            "#66a3e0",
            "#c285ff",
            "#888888",
            "#a10000",
            "#b26b00",
            "#b2b200",
            "#006100",
            "#0047b2",
            "#6b24b2",
            "#444444",
            "#5c0000",
            "#663d00",
            "#666600",
            "#003700",
            "#002966",
            "#3d1466",
            "custom-color",
          ],
        },
      ],
    ],
  };
  var formats = [
    "header",
    "height",
    "bold",
    "italic",
    "underline",
    "strike",
    "blockquote",
    "list",
    "color",
    "bullet",
    "indent",
    "link",
    "align",
    "size",
  ];

  async function handleFormSubmit(values, { resetForm }) {
    const formObject = getFormObject();
    const submitValues = {};

    Object.keys(formObject).forEach((key) => {
      submitValues[key] = values[key];
    });

    const submitText = typeId ? "updated" : "created";
    try {
      const resp = typeId
        ? await trackPromise(EmailTemplateService.updateOrgTemplate(typeId, submitValues))
        : await trackPromise(EmailTemplateService.createOrgTemplate(submitValues));

      const { data } = resp;
      toast.success(`Email Template ${submitText} successfully.`);
      navigate(-1);
      if (data.success) {
      } else {
        set_pageError(data.message || `Email Template ${submitText} failed`);
      }
    } catch (err) {
      console.error("Email Template error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || `Email Template ${submitText} error`);
    }
  }

  const onEmailTypeChange = (e) => {
    const inputValue = e.target.value;
    set_emailType(inputValue);
    setShowPill(false);
    set_formObject(getFormObject);
    // set_emailTypeCode();
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Email Templates for End User Communication</h4>
      </div>

      <Row className="mb-4 px-4 gap-3">
        <Col lg="4" className="">
          <Form.Group className="frm-group">
            <Form.Label>Email Template</Form.Label>
            <Form.Select name="emailTemplate" value={emailType} onChange={onEmailTypeChange} disabled={typeId}>
              <option value={""}>Select Email Template</option>
              {templateTypes.map((typ, i) => (
                <option key={i} value={typ?.emailTypeCode}>
                  {typ?.emailTypeName}
                </option>
              ))}
            </Form.Select>
          </Form.Group>
        </Col>
        {!typeId && (
          <Col lg="4" className="align-self-end">
            <Button
              variant="clr-primary"
              type="button"
              onClick={() => getTemplateByType(SelectedType[0]?.emailTypeCode)}
            >
              View Email Template
            </Button>
          </Col>
        )}
      </Row>
      <Row className="mb-4 px-2">
        <Col lg="7">
          {showPill && (
            <div>
              {SelectedType?.map((i) =>
                i.emailPlaceholders?.map((plh, i) => (
                  <div key={i} className="selected-slot">
                    {plh}
                  </div>
                ))
              )}
            </div>
          )}
        </Col>
      </Row>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting, setFieldValue }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  {/* <Form.Group className="frm-group">
                    <Form.Label>From</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="From"
                      name="emailSender"
                      value={values.emailSender}
                      onChange={handleChange}
                      isInvalid={touched.emailSender && errors.emailSender}
                    />
                    <Form.Control.Feedback type="invalid">{errors.emailSender}</Form.Control.Feedback>
                  </Form.Group> */}

                  <Form.Group className="frm-group">
                    <Form.Label>Subject</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Subject"
                      name="emailSubject"
                      value={values.emailSubject}
                      onChange={handleChange}
                      isInvalid={touched.emailSubject && errors.emailSubject}
                    />
                    <Form.Control.Feedback type="invalid">{errors.emailSubject}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group mb-5">
                    <Form.Label>Body </Form.Label>
                    <div style={{ height: "200px" }}>
                      <Field name="emailContent">
                        {({ field, name }) => (
                          <ReactQuill
                            theme="snow"
                            modules={modules}
                            formats={formats}
                            name="emailContent"
                            value={field.value}
                            onChange={field?.onChange(field?.name)}
                            placeholder="Write your content ..."
                            rows={3}
                            style={{ height: "120px" }}
                          />
                        )}
                      </Field>
                    </div>
                    <Form.Control.Feedback type="invalid">{errors?.name}</Form.Control.Feedback>
                  </Form.Group>
                  <div className="row g-0 frm-btn-grp mt-5 gap-2">
                    <Button as={Link} to="../emailTemplateList" variant="clr-transparent" className="col-lg me-lg-3">
                      Cancel
                    </Button>

                    <Button
                      variant="clr-primary"
                      type="submit"
                      // disabled={Object.keys(templateByorgType).length === 0 || emailType === "" ? true : isSubmitting}
                      disabled={!showPill}
                      className="col-lg ms-lg-3"
                    >
                      Save
                    </Button>
                  </div>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default EmailTrigger;

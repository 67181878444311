import React from "react";
import { useEffect, useMemo, useCallback, useRef } from "react";
import { useDispatch } from "react-redux";
import { Link, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import { Button } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { OrganisationService, ChangeRequestService } from "../../services";
import { useState } from "react";
import moment from "moment";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faDownload } from "@fortawesome/free-solid-svg-icons";
import { Rating } from "react-simple-star-rating";

const ViewOrganisation = ({ pageTitle }) => {
  const [orgData, setOrgData] = useState([]);
  const [orgDetails, set_orgDetails] = useState([]);
  const [pageError, set_pageError] = useState("");
  const id = useParams();
  const dispatch = useDispatch();
  const gridRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, [dispatch, pageTitle]);

  useEffect(() => {
    if (id.id) fetchOrgDetails();
    fetchOrgChangeRequest();
  }, [id.id]);

  async function fetchOrgDetails() {
    try {
      const resp = await trackPromise(OrganisationService.getById(id.id));
      const { data } = resp;
      if (data.success) {
        setOrgData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching org details");
      }
    } catch (err) {
      console.error("Fetch org details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchOrgChangeRequest() {
    try {
      const resp = await trackPromise(ChangeRequestService.getchangeRequestByOrg(id.id));
      const { data } = resp;
      if (data.success) {
        set_orgDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching org details");
      }
    } catch (err) {
      console.error("Fetch org details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const columnDefs = [
    { headerName: "Coach Name", field: "coach", suppressMovable: true, valueGetter: getFullName },
    { headerName: "Student Name", field: "student", suppressMovable: true, valueGetter: getFullName },
    { headerName: "Request Reason", field: "requestReason", suppressMovable: true },

    {
      headerName: "Coach Rating",
      field: "coachRating",
      suppressMovable: true,
      // width: 150,

      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const coachRating = params.data.coachRating;

        return (
          <div className="disabled-rating">
            <Rating initialValue={coachRating} size={25} fillColor="#B18C93" />
          </div>
        );
      },
    },
    { headerName: "Request Status", field: "requestStatus" },
  ];

  function getFullName(params) {
    const { firstName, lastName } = params.data[params.colDef.field];
    return `${firstName} ${lastName}`;
  }
  const defaultColDef = useMemo(() => ({
    // filter: true,
    // sortable: true,
    flex: 1,
  }));
  const onExportClick = useCallback(() => {
    gridRef?.current?.api.exportDataAsCsv({
      fileName: "OrgRequest",
    });
  }, []);

  const downloadIcon = (
    <Button
      variant="clr-primary"
      className=" btn-download ms-3 "
      style={{ float: "right", marginRight: "120px" }}
      onClick={onExportClick}
    >
      <FontAwesomeIcon icon={faDownload} />
    </Button>
  );

  return (
    <>
      <div className="text-left">
        <h4 className="col hd-txt">View Organisation</h4>
        <div className="col-md">
          <table className="view-org-table table table-borderless bg-transparent w-50">
            <tbody className="bg-transparent">
              <tr>
                <td>Name</td>
                <td>{orgData?.orgName}</td>
              </tr>
              <tr>
                <td>Organisation Address</td>
                <td>{orgData?.orgAddress}</td>
              </tr>
              <tr>
                <td>Organisation Type</td>
                <td>{orgData?.orgType?.typeName}</td>
              </tr>

              {orgData?.coachingPlan?.scheme === "fixed" && (
                <tr>
                  <td>Assigned Coaching Sessions</td>
                  <td>{orgData?.coachingPlan?.availableSessions}</td>
                </tr>
              )}
              <tr>
                <td>Amount</td>
                <td>{orgData?.coachingPlan?.amount}</td>
              </tr>
              <tr>
                <td>Start Date</td>
                <td>{moment(orgData.startDate).format("DD-MM-YYYY")}</td>
              </tr>
              <tr>
                <td>End Date</td>
                <td>{moment(orgData.endDate).format("DD-MM-YYYY")}</td>
              </tr>
            </tbody>
          </table>
          <div className="ps-2">
            {orgData?.coachingPlan?.scheme === "distributed" && orgData?.coachingPlan?.distribution.length >= 1 && (
              <div className="ag-theme-alpine mt-3" style={{ height: "195px", width: "90%" }}>
                <AgGridReact
                  columnDefs={[
                    { headerName: "Management Level", field: "studentType.typeName", suppressMovable: true },
                    {
                      headerName: "User Count",
                      field: "studentCount",
                      suppressMovable: true,
                      cellStyle: { textAlign: "center" },
                    },
                    {
                      headerName: "Total Sessions",
                      field: "availableSessions",
                      suppressMovable: true,
                      cellStyle: { textAlign: "center" },
                    },
                    {
                      headerName: "Used Sessions",
                      field: "usedSessions",
                      suppressMovable: true,
                      cellStyle: { textAlign: "center" },
                    },
                  ]}
                  rowData={orgData?.coachingPlan?.distribution}
                  defaultColDef={{ flex: 1 }}
                  ref={gridRef}
                />
              </div>
            )}
          </div>

          <div className="ps-2 mt-5">
            <div className="">Change Request: {downloadIcon}</div>
            <div className="ag-theme-alpine  mt-3" style={{ height: "300px", width: "90%" }}>
              <AgGridReact
                columnDefs={columnDefs}
                rowData={orgDetails}
                pagination={true}
                paginationPageSize={5}
                defaultColDef={defaultColDef}
                ref={gridRef}
              />
            </div>
          </div>

          <div className="g-0 frm-btn-grp g-2 mt-4">
            <Button as={Link} to="/organisation" variant="clr-transparent" className="col-lg me-2">
              Cancel
            </Button>
            <Button
              as={Link}
              to={`/organisation/edit/${id.id}`}
              variant="clr-primary"
              type="submit"
              className="col-lg ms-2"
            >
              Edit Organisation
            </Button>
          </div>
        </div>
      </div>
    </>
  );
};

export default ViewOrganisation;

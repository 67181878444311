import httpClient from "../httpClient";
const baseSlug = "/fcmToken";

class httpService {
  saveOrUpdateToken(data) {
    return httpClient.post(`${baseSlug}`, data);
  }

  deleteToken(deviceId) {
    return httpClient.delete(`${baseSlug}/${deviceId}`);
  }
}

export default new httpService();

import { useState, useEffect } from "react";
import { Link, useNavigate, useParams, useLocation } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { StudentTypeService, UserService, SubscriptionService } from "../../services";
import { copyObjectByKeys } from "../../utilities/functions";

function StudentCreate({ pageTitle }) {
  const { authState, appState } = useSelector((state) => state);
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [studentId, set_studentId] = useState(params.id);
  const [studentDetails, set_studentDetails] = useState({});
  const [stdTypeList, set_stdTypeList] = useState([]);
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [createFormObj, setCreateFormObj] = useState(getFormObject());
  const [customError, setCustomError] = useState({});
  const location = useLocation();
  const { selectedData } = location.state ?? "";

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchStudentTypes();
  }, []);

  useEffect(() => {
    if (studentId) fetchStudentDetails();
  }, [studentId]);

  useEffect(() => {
    if (studentDetails.id) populateForm();
  }, [studentDetails.id]);

  useEffect(() => {
    createNewPopulateForm();
  }, [selectedData]);

  function getFormObject() {
    return {
      studentType: "",
      firstName: "",
      lastName: "",
      username: "",
      phone: "",
      email: "",
      studentSessions: "0",
      studentId,
      agreementAccepted: false,
    };
  }

  const FormSchema = Yup.object().shape({
    studentType: Yup.string().required("Select a Type"),
    firstName: Yup.string().required("First Name is Required"),
    lastName: Yup.string().required("Last Name is Required"),
    username: Yup.string().when("$studentId", ([studentId], schema) => {
      return studentId && schema.required("Username is Required");
    }),
    phone: Yup.string().required("Contact Number is Required"),
    email: Yup.string().email("Email is invalid").required("Email is Required"),
    studentSessions: Yup.number().when("$studentId", ([studentId], schema) => {
      return (
        studentId &&
        schema
          .required("No of Sessions Required")
          .positive("Enter Positive Value")
          .integer("Enter Integer Value")
          .min(0)
      );
    }),
  });

  async function fetchStudentTypes() {
    try {
      const resp = await trackPromise(SubscriptionService.studentTypeBySubscription());
      const { data } = resp;
      if (data.success) {
        set_stdTypeList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching coachee types");
      }
    } catch (err) {
      console.error("Fetch coachee types catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchStudentDetails() {
    try {
      const resp = await trackPromise(UserService.getStudentById(studentId));
      const { data } = resp;
      if (data.success) {
        set_studentDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching coachee details");
      }
    } catch (err) {
      console.error("Fetch coachee details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function handleFormSubmit(values) {
    const submitText = studentId ? "updated" : "created";
    try {
      const resp = studentId
        ? await trackPromise(UserService.updateStudent(studentId, values))
        : await trackPromise(UserService.createStudent(values));
      const { data } = resp;
      if (data.success) {
        toast.success(`Coachee ${submitText} successfully`);
        navigate(-1);
      } else {
        set_pageError(data.message || `Failed to ${submitText} Coachee`);
      }
    } catch (err) {
      console.error("Coachee error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Coachee error");
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    formobj.edit = true;
    copyObjectByKeys(formobj, studentDetails);

    formobj.studentType = studentDetails.studentType?.id;
    if (studentId) {
      formobj.username = studentDetails.username;
    }
    set_formObject({ ...formobj });
  }

  function createNewPopulateForm() {
    const data = { studentType: selectedData };
    let formObj = { ...createFormObj };
    formObj.edit = true;
    copyObjectByKeys(formObj, data);
    setCreateFormObj({ ...formObj });
  }

  const onUpdateSession = async (value) => {
    const studentSessionData = { studentSessions: value };
    try {
      const resp = await trackPromise(UserService.updateSession(studentId, studentSessionData));
      const { data } = resp;
      if (data.success) {
        if (data?.data?.prevSessions !== data?.data?.newSessions) {
          toast.success("Sessions updated sucessfully.");
        } else {
          toast.warning("Could not update sessions .");
        }
      } else {
        set_pageError(data.message || `Failed to  Coachee`);
      }
    } catch (err) {
      console.error("Coachee error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Coachee error");
    }
  };

  const addCustomErrors = (errors) => {
    setCustomError((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  async function isUniqueCheck(value) {
    try {
      const resp = await trackPromise(UserService.isFieldUnique(value));
      const { data } = resp;
      if (value.username) {
        addCustomErrors({ username: data.data.isUnique ? "" : "Username Already Exist" });
      } else if (value.email) {
        addCustomErrors({ email: data.data.isUnique ? "" : "Email Address Already Exist" });
      }
    } catch {
      if (value.username) {
        addCustomErrors({ username: "" });
      } else if (value.email) {
        addCustomErrors({ email: "" });
      }
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{studentId ? "Edit" : "Create"} Coachee</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={studentId && selectedData === undefined ? formObject : createFormObj}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Coachee Type</Form.Label>
                    <Form.Select
                      name="studentType"
                      value={values.studentType}
                      onChange={handleChange}
                      isInvalid={touched.studentType && errors.studentType}
                    >
                      <option>Select Type</option>
                      {stdTypeList?.length ? (
                        stdTypeList.map((itm, inx) => (
                          <option key={inx} value={itm._id}>
                            {itm.typeName}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.studentType}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>First Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter First Name"
                      name="firstName"
                      value={values.firstName}
                      onChange={handleChange}
                      isInvalid={touched.firstName && errors.firstName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.firstName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Last Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Last Name"
                      name="lastName"
                      value={values.lastName}
                      onChange={handleChange}
                      isInvalid={touched.lastName && errors.lastName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.lastName}</Form.Control.Feedback>
                  </Form.Group>

                  {studentId ? (
                    <Form.Group className="frm-group">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        onBlur={(value) => isUniqueCheck({ username: value.target.value, userId: studentId })}
                        isInvalid={(touched.username && errors.username) || customError.username}
                        // isInvalid={touched.username && errors.username}
                      />
                      {/* <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback> */}
                      <Form.Control.Feedback type="invalid">
                        {errors.username || customError.username}
                      </Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <></>
                  )}

                  <Form.Group className="frm-group">
                    <Form.Label>Email address</Form.Label>
                    <Form.Control
                      type="email"
                      placeholder="Enter Email"
                      name="email"
                      value={values.email}
                      onChange={handleChange}
                      onBlur={(value) => isUniqueCheck({ email: value.target.value, userId: studentId })}
                      isInvalid={(touched.email && errors.email) || customError.email}
                      //isInvalid={touched.email && errors.email}
                    />
                    {/* <Form.Control.Feedback type="invalid">{errors.email}</Form.Control.Feedback> */}
                    <Form.Control.Feedback type="invalid">{errors.email || customError.email}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group mb-4">
                    <Form.Label>Contact Number</Form.Label>
                    <Form.Control
                      type="phone"
                      placeholder="Enter Contact"
                      name="phone"
                      value={values.phone}
                      onChange={handleChange}
                      isInvalid={touched.phone && errors.phone}
                    />
                    <Form.Control.Feedback type="invalid">{errors.phone}</Form.Control.Feedback>
                  </Form.Group>

                  {studentId ? (
                    <Form.Group className="frm-group mb-4">
                      <Form.Label>Coachee Sessions</Form.Label>
                      <Form.Control
                        type="number"
                        placeholder="Enter Session Count"
                        min={0}
                        name="studentSessions"
                        value={values.studentSessions ? values.studentSessions : 0}
                        onChange={handleChange}
                        isInvalid={touched.studentSessions && errors.studentSessions}
                      />
                      <Button
                        variant="clr-primary"
                        className="mt-3"
                        onClick={() => onUpdateSession(values.studentSessions)}
                      >
                        Update Sessions
                      </Button>
                      <Form.Control.Feedback type="invalid">{errors.studentSessions}</Form.Control.Feedback>
                    </Form.Group>
                  ) : (
                    <></>
                  )}

                  <div className="row g-0 frm-btn-grp gap-2">
                    <Button as={Link} to="../coachee" variant="clr-transparent" className="col-lg me-lg-3">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-lg-3">
                      {studentId ? "Edit" : "Create New"} Coachee
                    </Button>
                  </div>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default StudentCreate;

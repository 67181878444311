import { useEffect, useState } from "react";
import { Container } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { setRouteData } from "../../stores/appSlice";
import StepperLine from "../AppointmentWizard/StepperLine";

import { trackPromise } from "react-promise-tracker";
import { BookSlotServices, CoachSlotService, TopicsService, UserService } from "../../services";
import Profile from "../../assets/images/avtar.png";
import "../AppointmentWizard/wizard.css";
// import StepTopicSelection from "./StepTopicSelection";
// import StepCoachSelection from "./StepCoachSelection";
import StepSlotSelection from "../AppointmentWizard/StepSlotSelection";
import StepConfirmation from "../AppointmentWizard/StepConfirmation";
import StepConfirmationSuccess from "../AppointmentWizard/StepConfirmationSuccess";
import CoachDetail from "../AppointmentWizard/CoachDetail";
import moment from "moment";
import { toast } from "react-toastify";
import { getUserProfile } from "../../stores/store";

const steps = ["Choose Time", "Confirm"];
const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions();
// The TZ format I want everything to be displayed and submitted as
const apiTZ = "utc";

function AnotherAppointmentWizard({ pageTitle }) {
  const [activeStep, setActiveStep] = useState(0);
  const [coachProfile, setCoachProfile] = useState(0);
  const [topicList, set_topicList] = useState([]);
  const [coachesList, set_coachesList] = useState([]);
  const [coachesListReadMore, set_coachesListReadMore] = useState([]);
  const [timeSlot, set_timeSlot] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [topic, setTopicId] = useState("");
  const [coach, setCoachId] = useState("");
  const [coachData, setCoachData] = useState(null);
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [slotSelected, setSlotSelected] = useState([]);
  const [confirmSlots, setConfirmSlots] = useState([]);
  const [dateAvailable, setDateAvailable] = useState([]);
  const [bookedDates, setBookedDates] = useState([]);
  const [bookedSlotsData, setBookedSlotsData] = useState([]);
  const [bookingExist, setBookingExists] = useState();
  const dispatch = useDispatch();

  const { authState } = useSelector((state) => state);
  const location = useLocation();
  const coachId = location.state?.coach;
  const topicId = location.state?.topic;
  //const stepFromState = location.state?.step;

  const minBookigCount = authState?.user?.organisation?.minBookingCount;
  const endDate = moment(authState?.user?.organisation?.endDate).format();

  const endDateFormat = (date) => {
    const givenDate = moment(date);
    const result = givenDate.set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    return result.format();
  };
  const dateValidationForApi = moment(endDateFormat(selectedDate)).isAfter(moment(endDate));

  useEffect(() => {
    if (topicId && activeStep == 0) {
      fetchCoaches();
    }
  }, [activeStep, topicId]);

  useEffect(() => {
    if (coachId && activeStep == 0 && !dateValidationForApi) {
      fetchTimeSlots();
    }
  }, [activeStep, coachId, selectedDate, dateValidationForApi]);

  useEffect(() => {
    if (coachId && activeStep == 0) {
      fetchTimeSlotsForMonth();
    }
  }, [currentMonth, activeStep]);
  useEffect(() => {
    if (coachId && topicId) checkBookingExist();
  }, [coachId, topicId]);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchTopics();
  }, []);

  async function checkBookingExist() {
    const bookData = {
      coach: coachId,
      topic: topicId,
    };
    try {
      const resp = await trackPromise(BookSlotServices.bookingExist(bookData));
      const { data } = resp;
      if (data.success) {
        setBookingExists(data?.data?.exists);
      } else {
        set_pageError(data.message);
      }
    } catch (err) {
      console.error("Fetch Slot Booking => ", err);
      if (err?.code !== "ERR_NETWORK") {
        toast.error(err?.response?.data?.message);
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  async function fetchTopics() {
    try {
      const resp = await trackPromise(TopicsService.getTrending());
      const { data } = resp;
      if (data.success) {
        set_topicList(
          data.data.map((topics) => {
            return { value: topics.id, label: topics.topicName, topicDescription: topics.topicDescription };
          })
        );
      } else {
        set_pageError(data.message || "Error in fetching topics categories");
      }
    } catch (err) {
      console.error("Fetch coach topic catch => ", err);
      if (err?.code !== "ERR_NETWORK") {
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  async function fetchCoaches() {
    try {
      const resp = await trackPromise(UserService.getCoachByTopics({ topicIds: [topicId] }));
      const { data } = resp;
      if (data.success) {
        set_coachesListReadMore(data.data);
        set_coachesList(data.data.slice(0, 3));
        console.log("da", data);
      } else {
        set_pageError(data.message || "Error in fetching coach");
      }
    } catch (err) {
      if (err?.code !== "ERR_NETWORK") {
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";

  const localFormatStartDate = (date) => {
    const startFromNowDate = moment(0, "HH").diff(date, "days") === 0;
    const givenDate = moment(date);
    if (startFromNowDate) {
      const result = givenDate.set({
        hour: moment().hours(),
        minute: moment().minutes(),
        second: moment().seconds(),
      });

      const convertedDate = moment(result).format(outputFormat);
      return convertedDate;
    }
    if (!startFromNowDate) {
      const convertedDate = moment(date).format(outputFormat);
      return convertedDate;
    }
  };

  async function fetchTimeSlots() {
    try {
      const resp = await trackPromise(
        CoachSlotService.getSlotByCoachId(coachId, {
          startDate: localFormatStartDate(selectedDate),
          endDate: endDateFormat(selectedDate),
        })
      );
      const { data } = resp;
      if (data.success) {
        const mapperSlots =
          data?.data?.length &&
          data?.data?.map((singleDataSlot) => {
            return {
              date: singleDataSlot?.slotDate,
              slotId: singleDataSlot?._id,
              slotStatus: singleDataSlot?.slotStatus,
              fromDateTime: singleDataSlot?.fromDateTime,
              coachSlot: singleDataSlot?.coachSlot,
              id: singleDataSlot?._id,
              toDateTime: singleDataSlot?.toDateTime,
            };
          });
        setSlotSelected(mapperSlots);
        set_timeSlot(data?.data);

        // setSlotSelected(data?.data);
      } else {
        set_pageError(data.message || "Error in fetching slots");
      }
    } catch (err) {
      console.error("Fetch coach catch => ", err);
      if (err?.code !== "ERR_NETWORK") {
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  async function fetchTimeSlotsForMonth() {
    try {
      const resp = await trackPromise(CoachSlotService.getSlotByCoachId(coachId, createStartDate(currentMonth)));
      const { data } = resp;
      if (resp.status === 200) {
        const bookDatesArr = data?.data.map((item) => new Date(moment(item?.fromDateTime).toDate()));
        setBookedDates(bookDatesArr);
      }
      if (data.success) {
        const mapperSlots = data?.data.map((eventData) => {
          return new Date(eventData.date);
        });

        setDateAvailable(mapperSlots);
      } else {
        set_pageError(data.message || "Error in fetching slots");
      }
    } catch (err) {
      console.error("Fetch coach catch => ", err);
      if (err?.code !== "ERR_NETWORK") {
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  function createStartDate(selectDate) {
    return {
      startDate: moment(formatDate(selectDate)).startOf("month").utc().format(),
      endDate: moment(formatDate(selectDate)).endOf("month").utc().format(),
    };
  }

  const formatDate = (date) => {
    const apiTimeFromPickerLocalDate = moment(date)
      .tz(localTZ)
      // change TZ without changing date/time
      .tz(apiTZ, true)
      .format();
    return apiTimeFromPickerLocalDate;
  };

  const handleImageError = (e) => {
    e.target.src = Profile; // Set the default image source
  };

  const navigateToNext = () => {
    setActiveStep((value) => value + 1);
  };

  const navigateToBack = () => {
    setActiveStep((value) => value - 1);
    setSelectedDate(new Date());
    set_pageError();
  };

  // const handleCheckboxChange = (event) => {
  //   setTopicId(event.target.value);
  // };
  // const handleCheckboxChangeDiv = (value) => {
  //   setTopicId(value);
  // };

  const handleCoachSelect = (item) => {
    setCoachData(item);

    setCoachId(coachId);
  };
  const handleEventMonthChange = (currentMonth) => {
    setCurrentMonth(new Date(currentMonth));
  };

  async function bookSlots() {
    try {
      const slotDetails = formatSlotData();
      const resp = await trackPromise(CoachSlotService.bookSlots(slotDetails));
      const { data } = resp;
      if (data.success) {
        navigateToNext();
        getUserProfile();
      } else {
        set_pageError(data.message || "Error in Slot Booking Slots");
      }
    } catch (err) {
      console.error("Fetch Slot Booking => ", err);
      if (err?.code !== "ERR_NETWORK") {
        toast.error(err?.response?.data?.message);
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  const formatSlotData = () => {
    const formdata = {
      coach: coachId,
      topic: topicId,
      dateSlots: bookedSlotsData,
    };
    return formdata;
  };

  const viewMore = () => {
    set_coachesList(coachesListReadMore.slice(0, coachesList.length + 3));
  };

  const removeSlots = (id) => {
    const updatedSelcetion = bookedSlotsData.filter((item) => item?.coachSubSlot !== id);
    setBookedSlotsData(updatedSelcetion);
  };

  return (
    <Container fluid={true} className="main-content">
      {activeStep !== 2 ? (
        <div className="mb-3">
          <StepperLine steps={steps} activeStep={activeStep} />
        </div>
      ) : null}
      {/* {activeStep == 0 ? (
        <StepTopicSelection
          topicList={topicList}
          handleCheckboxChange={handleCheckboxChange}
          navigateToNext={navigateToNext}
          handleCheckboxChangeDiv={handleCheckboxChangeDiv}
          topicId={topicId}
        />
      ) : null}
      {activeStep == 1 && coachProfile == 0 && (
        <StepCoachSelection
          coachesList={coachesList}
          handleCoachSelect={handleCoachSelect}
          handleImageError={handleImageError}
          coachId={coachId}
          coachesListReadMore={coachesListReadMore}
          navigateToNext={navigateToNext}
          navigateToBack={navigateToBack}
          topicId={topicId}
          viewMore={viewMore}
          setCoachProfile={setCoachProfile}
          pageError={pageError}
        />
      )} */}

      {/* {activeStep == 0 && coachProfile == 0 && (
        <CoachDetail
          coachesList={coachesList}
          coachesListReadMore={coachesListReadMore}
          handleCoachSelect={handleCoachSelect}
          handleImageError={handleImageError}
          coachId={coachId}
          navigateToNext={navigateToNext}
          navigateToBack={navigateToBack}
          topicId={topicId}
          coachData={coachData}
          viewMore={viewMore}
          setCoachProfile={setCoachProfile}
        />
      )} */}

      {activeStep == 0 && coachProfile == 0 && (
        <StepSlotSelection
          handleImageError={handleImageError}
          navigateToNext={navigateToNext}
          handleCoachSelect={handleCoachSelect}
          coachId={coachId}
          topicId={topicId}
          coachData={coachData}
          selectedDate={selectedDate}
          navigateToBack={navigateToBack}
          setSelectedDate={setSelectedDate}
          timeSlot={timeSlot}
          setSlotSelected={setSlotSelected}
          setConfirmSlots={setConfirmSlots}
          confirmSlots={confirmSlots}
          slotSelected={slotSelected}
          dateAvailable={dateAvailable}
          bookedDates={bookedDates}
          handleEventMonthChange={handleEventMonthChange}
          setBookedSlotsData={setBookedSlotsData}
          bookingExist={bookingExist}
          minBookigCount={minBookigCount}
          setCoachProfile={setCoachProfile}
        />
      )}
      {activeStep == 1 && (
        <StepConfirmation
          handleImageError={handleImageError}
          coachData={coachData}
          confirmSlots={confirmSlots}
          navigateToBack={navigateToBack}
          slotBooking={bookSlots}
          setSlotSelected={setSlotSelected}
          setConfirmSlots={setConfirmSlots}
          removeSlots={removeSlots}
          slotSelected={slotSelected}
          bookedSlotsData={bookedSlotsData}
          pageError={pageError}
          topicId={topicId}
          topicList={topicList}
        />
      )}
      {activeStep == 2 && (
        <StepConfirmationSuccess
          confirmSlots={confirmSlots}
          slotSelected={slotSelected}
          bookedSlotsData={bookedSlotsData}
        />
      )}
    </Container>
  );
}

export default AnotherAppointmentWizard;

import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Table, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { faPencil, faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setRouteData } from "../../stores/appSlice";
import { AgGridReact } from "ag-grid-react"; // React Grid Logic
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
// import coachCategoryService from "../../services/coachCategory.service";
import AccreditationService from "../../services/accredatation.service";
import DeleteListModal from "../CustomComponent/DeleteListModal";

function CoachCategoryList({ pageTitle }) {
  const dispatch = useDispatch();
  const [categoryList, set_categoryList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchCoachCategories();
  }, []);

  async function fetchCoachCategories() {
    try {
      const resp = await trackPromise(AccreditationService.getAll());

      const { data } = resp;
      if (data.success) {
        set_categoryList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching coach categories");
      }
    } catch (err) {
      console.error("Fetch coach categories catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function deleteCategory(id) {
    try {
      const resp = await trackPromise(AccreditationService.delete(id));
      const { data } = resp;

      if (data?.success === true) {
        toast.success("Category deleted Successfully.");

        fetchCoachCategories();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  async function deleteOrg(id) {
    deleteCategory(id);
    closeDeleteModal();
  }

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
  };

  const columnDefs = [
    {
      headerName: "Category Name",
      field: "levelName",
      width: 500,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },

    {
      headerName: "Category Code",
      field: "levelCode",
      width: 200,
      tooltipField: "levelCode",
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },

    {
      field: "action",
      suppressMovable: true,
      headerName: "Action",
      sortable: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const isDelete = params.data.isDeleted;
        const iconClass = isDelete ? "disabled-icon" : "active-icon";
        return (
          <>
            <Link
              className={`grid_action ${isDelete ? "disabled-link" : ""}`}
              to={`/coachCategory/view/${params?.data?.id}`}
            >
              <FontAwesomeIcon
                icon={faEye}
                className={iconClass}
                title="View"
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault(); // Prevent the edit action when isDelete is true
                  }
                }}
              />
            </Link>

            <Link
              className={`grid_action ${isDelete ? "disabled-link" : ""}`}
              to={`/coachCategory/edit/${params.data.id}`}
            >
              <FontAwesomeIcon
                icon={faPencil}
                title="Edit"
                className={iconClass}
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault(); // Prevent the edit action when isDelete is true
                  }
                }}
              />
            </Link>
            <Link className={`grid_action ${isDelete ? "disabled-link" : ""}`} to="">
              <FontAwesomeIcon
                icon={faTrashCan}
                color="#3d3e45"
                title="Delete"
                className={iconClass}
                onClick={() => {
                  openDeleteModal(params?.data?.id);
                }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    sortable: true,
    //filter: true,
    flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  //Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Coach Category List</h4>
        <Button as={Link} to="create" variant="clr-primary" className="btn-add">
          Add New
        </Button>
      </div>

      <Row className="mb-4">
        <Col lg="12">
          <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
            <AgGridReact
              id="myGrid"
              className="myGrid"
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={categoryList}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
          <div className="page-size-wrap">
            <div className="page-size">
              Records Per Page:
              <select onChange={onPageSizeChanged} id="page-size">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        </Col>
      </Row>
      {selectedItem && (
        <DeleteListModal
          id={selectedItem}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          deleteItem={deleteOrg}
          pageError={pageError}
        />
      )}
    </Container>
  );
}

export default CoachCategoryList;

import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useRouteLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBook, faChartSimple } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import CustomizedCalender from "../AppointmentWizard/CustomizedCalender";

import { trackPromise } from "react-promise-tracker";
import { BookingServices, CoachSlotService } from "../../services";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";
import { formatTime } from "../../utilities/functions";

const { timeZone: localTZ } = Intl.DateTimeFormat().resolvedOptions();
// The TZ format I want everything to be displayed and submitted as
const apiTZ = "utc";

function DashboardCoach() {
  const routeData = useRouteLoaderData("dashboard");
  const dispatch = useDispatch();
  const { authState, appState } = useSelector((state) => state);
  const [appointmentList, set_appointmentList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [selectedDate, setSelectedDate] = useState(new Date());
  const [dateAvailable, setDateAvailable] = useState([]);
  const [currentMonth, setCurrentMonth] = useState(new Date());
  const [bookedDates, setBookedDates] = useState([]);

  useEffect(() => {
    dispatch(setRouteData({ ...routeData }));
    fetchAppointments();
    // fetchTimeSlotsForMonth();
  }, []);

  useEffect(() => {
    if (authState?.user?.id) {
      fetchTimeSlotsForMonth();
    }
  }, [currentMonth]);

  const handleEventMonthChange = (currentMonth) => {
    setCurrentMonth(new Date(currentMonth));
  };

  function createStartDate(selectDate) {
    return {
      startDate: moment(selectDate).local().startOf("month").format(), //.utc()
      endDate: moment(selectDate).local().endOf("month").format(), //.utc()
    };
  }

  async function fetchTimeSlotsForMonth() {
    try {
      const resp = await trackPromise(CoachSlotService.getSlotByDates(createStartDate(currentMonth)));
      const { data } = resp;
      if (resp.status === 200) {
        const bookDatesArr = data?.data.map((item) => moment(item.fromDateTime).toDate());
        setBookedDates(bookDatesArr);
      }

      if (data.success) {
        const mapperSlots = data?.data
          .filter((singleData) => singleData?.slots?.length > 0)
          .map((eventData) => {
            return new Date(eventData.date);
          });

        setDateAvailable(mapperSlots);
      } else {
        set_pageError(data?.message || "Error in fetching slots");
      }
    } catch (err) {
      console.error("Fetch coach catch => ", err);
      if (err?.code !== "ERR_NETWORK") {
        set_pageError(err?.response?.data?.message);
      } else {
        set_pageError(err?.message);
      }
    }
  }

  const bookDatesByStudent = appointmentList.map((item) => new Date(item?.fromDateTime));

  // const formatDate = (date) => {
  //   const apiTimeFromPickerLocalDate = moment(date).tz(localTZ).tz(apiTZ, true).format();
  //   return apiTimeFromPickerLocalDate;
  // };

  async function fetchAppointments() {
    try {
      const resp = await trackPromise(BookingServices.getUpcomingSlots());
      const { data } = resp;
      if (data?.success) {
        set_appointmentList(data?.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const dashboardAppList = appointmentList.slice(0, 6);

  return (
    <Container fluid={true} className="main-content">
      <Row className="">
        <Col>
          <Row className=" ">
            <Col>
              <Row>
                <h4 className="hd-txt">Quick Actions</h4>
              </Row>
              <Row>
                <Col className="inner-card mx-1">
                  <Row className=" py-2 d-flex justify-content-center">
                    <FontAwesomeIcon icon={faChartSimple} size="4x" color="#B18C93" />
                  </Row>
                  <Row className="mx-5 px-2">
                    <Button variant="clr-primary" size="md" as={Link} to="/coacheeProgress">
                      Coachee Progress
                    </Button>
                  </Row>
                </Col>
                <Col className="inner-card">
                  <Row className=" py-2 d-flex justify-content-center">
                    <FontAwesomeIcon icon={faBook} size="4x" color="#B18C93" />
                  </Row>
                  <Row className="mx-5 px-2">
                    <Button variant="clr-primary" size="md" as={Link} to="/assignresource">
                      Assign Resource
                    </Button>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className=" my-2 mt-3">
            <Col>
              <Row className="">
                <Col>
                  <h5 className="hd-txt">Upcoming Appointments</h5>
                </Col>
                <Col
                  className="d-flex justify-content-end text-decoration-underline"
                  as={Link}
                  to="/coachAppointment"
                  style={{ color: "#B18C93" }}
                >
                  View More
                </Col>
              </Row>
              <Row className="mt-2">
                <Col className="col-lg-7">
                  {dashboardAppList?.map((a, index) => (
                    <Row key={index} className="inner-card-border ms-0 mb-3">
                      <Row className="mx-2">
                        {`${a?.topic?.topicName} | ${a?.student?.firstName} 
                        ${a?.student?.lastName}`}
                      </Row>
                      <div className="d-flex justify-content-start flex-row mx-2">
                        <div>
                          <FontAwesomeIcon icon={faCalendar} />
                        </div>
                        <div className="mx-2">{moment(a?.fromDateTime).format("dddd, DD, MMM, YYYY")}</div>
                      </div>
                      <div className="d-flex justify-content-start flex-row mx-2">
                        <div>
                          <FontAwesomeIcon icon={faClock} />
                        </div>
                        <div className="mx-2 mb-1">
                          {formatTime(a?.fromDateTime)} - {formatTime(a?.toDateTime)}
                        </div>
                      </div>
                    </Row>
                  ))}
                  {appointmentList.length === 0 && <Row className="px-3 my-2">No Appointment Found</Row>}
                </Col>
              </Row>
            </Col>
          </Row>
        </Col>
        <Col>
          <Row className="px-2 mx-3 quick-action">
            <Row>
              <Col className="d-flex justify-content-end">
                <Row className="py-2">
                  <Button variant="clr-primary" size="sm" as={Link} to="/calendarEvent/create">
                    Create a New Slot
                  </Button>
                </Row>
              </Col>
            </Row>
            <Row>
              <CustomizedCalender
                selectedDate={selectedDate}
                setSelectedDate={setSelectedDate}
                dateAvailable={dateAvailable}
                handleEventMonthChange={handleEventMonthChange}
                bookedDates={bookedDates}
                bookDatesByStudent={bookDatesByStudent}
              />
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardCoach;

// import {
//   LocalUser,
//   RemoteUser,
//   useIsConnected,
//   useJoin,
//   useLocalMicrophoneTrack,
//   useLocalCameraTrack,
//   usePublish,
//   useRemoteUsers,
// } from "agora-rtc-react";
// import { useState } from "react";

// export const Basics = () => {
//   const [calling, setCalling] = useState(false);
//   const isConnected = useIsConnected(); // Store the user's connection status
//   const [appId, setAppId] = useState("");
//   const [channel, setChannel] = useState("");
//   const [token, setToken] = useState("");

//   useJoin({appid: appId, channel: channel, token: token ? token : null}, calling);

//   const [micOn, setMic] = useState(true);
//   const [cameraOn, setCamera] = useState(true);
//   const { localMicrophoneTrack } = useLocalMicrophoneTrack(micOn);
//   const { localCameraTrack } = useLocalCameraTrack(cameraOn);
//   usePublish([localMicrophoneTrack, localCameraTrack]);

//   const remoteUsers = useRemoteUsers();

//   return (
//     <>
//       <div className="room">
//         {isConnected ? (
//           <div className="user-list">
//             <div className="user">
//               <LocalUser
//                 audioTrack={localMicrophoneTrack}
//                 cameraOn={cameraOn}
//                 micOn={micOn}
//                 videoTrack={localCameraTrack}
//                 cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg"
//               >
//                 <samp className="user-name">You</samp>
//               </LocalUser>
//             </div>
//             {remoteUsers.map((user) => (
//               <div className="user" key={user.uid}>
//                 <RemoteUser cover="https://www.agora.io/en/wp-content/uploads/2022/10/3d-spatial-audio-icon.svg" user={user}>
//                   <samp className="user-name">{user.uid}</samp>
//                 </RemoteUser>
//               </div>
//             ))}
//           </div>
//         ) : (
//           <div className="join-room">
//             <img alt="agora-logo" className="logo"  />
//             <input
//               onChange={e => setAppId(e.target.value)}
//               placeholder="<Your app ID>"
//               value={appId}
//             />
//             <input
//               onChange={e => setChannel(e.target.value)}
//               placeholder="<Your channel Name>"
//               value={channel}
//             />
//             <input
//               onChange={e => setToken(e.target.value)}
//               placeholder="<Your token>"
//               value={token}
//             />

//             <button
//               className={`join-channel ${!appId || !channel ? "disabled" : ""}`}
//               disabled={!appId || !channel}
//               onClick={() => setCalling(true)}
//             >
//               <span>Join Channel</span>
//             </button>
//           </div>
//         )}
//       </div>
//       {isConnected && (
//         <div className="control">
//           <div className="left-control">
//             <button className="btn" onClick={() => setMic(a => !a)}>
//               <i className={`i-microphone ${!micOn ? "off" : ""}`} />
//             </button>
//             <button className="btn" onClick={() => setCamera(a => !a)}>
//               <i className={`i-camera ${!cameraOn ? "off" : ""}`} />
//             </button>
//           </div>
//           <button
//             className={`btn btn-phone ${calling ? "btn-phone-active" : ""}`}
//             onClick={() => setCalling(a => !a)}
//           >
//             {calling ? <i className="i-phone-hangup" /> : <i className="i-mdi-phone" />}
//           </button>
//         </div>
//       )}
//     </>
//   );
// };

// export default Basics;


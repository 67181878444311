import React, { useState, useEffect } from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Form, FormLabel } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { getColorThemeData } from "../../stores/colorThemeSlice";

function DeleteListModal({
  id,
  isOpen,
  onClose,
  pageError,
  deleteItem,
  serverconfirmData,
  showErrorModal,
  closeErrorModal,
}) {
  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);
  const dispatch = useDispatch();
  const [inputValue, setInputValue] = useState("");
  // const [showErrorModal, setShowErrorModal] = useState(false);

  const colorThemeData = useSelector((state) => state?.colorTheme?.colorThemeData);

  useEffect(() => {
    dispatch(getColorThemeData(colorTheme));
  }, [colorTheme, dispatch]);

  // const confirmDeletion = () => {
  //   // Check if the checkbox is checked before confirming the deletion
  //   if ((serverconfirmData = true)) {
  //     setShowErrorModal(true);
  //   } else {
  //     deleteItem(id);
  //   }
  // };

  // const closeErrorModal = () => {
  //   setShowErrorModal(false);
  // };

  return (
    <>
      <Modal show={isOpen} onHide={onClose} centered className={colorTheme}>
        {/* First Modal */}

        {isOpen && (
          <>
            <Modal.Header closeButton>
              <Modal.Title>Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body>
              <Form.Group className="frm-group">
                <FormLabel>Are you sure you want to delete this item?</FormLabel>
                <FormLabel className="mt-1">To confirm deletion type DELETE in the field below.</FormLabel>
                <Form.Control
                  variant="clr-primary"
                  type="text"
                  placeholder="DELETE"
                  value={inputValue}
                  onChange={(e) => setInputValue(e.target.value.toUpperCase())}
                  style={{
                    borderColor: colorThemeData[0]?.CLR_PRIMARY,
                  }}
                />
              </Form.Group>
            </Modal.Body>
            <Modal.Footer>
              <Button
                className="btn-add"
                onClick={onClose}
                variant="clr-transparent"
                style={{
                  backgroundColor: colorThemeData[0]?.CLR_TRANSPARENT,
                  color: colorThemeData[0]?.CLR_PRIMARY,
                  borderColor: colorThemeData[0]?.CLR_PRIMARY,
                }}
              >
                No
              </Button>
              <Button
                variant="clr-primary"
                className="btn-add "
                style={{
                  backgroundColor: colorThemeData[0]?.CLR_PRIMARY,
                  color: "white",
                  borderColor: colorThemeData[0]?.CLR_PRIMARY,
                }}
                onClick={() => deleteItem(id)}
                disabled={inputValue !== "DELETE"}
              >
                Yes
              </Button>
              {pageError && <div className="text-danger mt-2">{pageError}</div>}
            </Modal.Footer>
          </>
        )}
      </Modal>
      {/* Second Modal */}
      {/* {showErrorModal && (
          <>
     
      <Modal show={true} onHide={closeErrorModal} centered className={colorTheme}>
        
            <Modal.Header closeButton>
              <Modal.Title> Delete Confirmation</Modal.Title>
            </Modal.Header>
            <Modal.Body style={{ height: "110px" }} className="d-flex flex-column justify-content-center text-center">
              {pageError && <div className="text-danger mt-2">{pageError}</div>}
            </Modal.Body>
            <Modal.Footer>
              <Button onClick={closeErrorModal} variant="clr-transparent">
                Cancel
              </Button>
              <Button variant="clr-primary" onClick={() => deleteItem(id)}>
                Delete
              </Button>
            </Modal.Footer>
         
      </Modal>
       </>
        )} */}
    </>
  );
}

export default DeleteListModal;

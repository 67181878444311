import { useState, useEffect, useRef } from "react";
import { Link, useLocation, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form, OverlayTrigger, Tooltip } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faInfoCircle } from "@fortawesome/free-solid-svg-icons";
import moment from "moment";
import { setRouteData } from "../../stores/appSlice";
import { OrgTypeService, OrganisationService, StudentTypeService } from "../../services";
import { copyObjectByKeys, localDayStart, localDayEnd } from "../../utilities/functions";
import { THEME } from "../../utilities/theme";
import { getColorTheme, setColorThemeData } from "../../stores/colorThemeSlice";

function OrganisationCreate({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [orgId, set_orgId] = useState(params.id);
  const [orgDetails, set_orgDetails] = useState({});
  const [orgTypeList, set_orgTypeList] = useState([]);
  const [studentTypeList, set_studentTypeList] = useState([]);
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [customError, setCustomError] = useState({});
  const [noFieldError, setNoFieldError] = useState(false);
  const location = useLocation();
  const formRef = useRef();
  const [totalSessions, setTotalSessions] = useState(0);
  const [userCounts, setUserCounts] = useState({});
  const [minSessions, setminSessions] = useState({});

  const colorThemeName = useSelector((state) => state.colorTheme.colorTheme);
  // const colorThemeData = useSelector((state) => state.colorTheme.colorThemeData);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchOrgTypes();
    fetchStudentTypes();
    return () => dispatch(getColorTheme(""));
  }, []);

  useEffect(() => {
    if (orgId) fetchOrgDetails();
  }, [orgId]);

  useEffect(() => {
    if (orgDetails.id) populateForm();
  }, [orgDetails.id]);

  function getFormObject() {
    return {
      orgName: "",
      orgAddress: "",
      orgType: "",
      uiTheme: "default",

      startDate: "",
      endDate: "",
      coachingPlan: "fixed",
      amount: 0,
      hours: 0,
    };
  }

  const FormSchema = Yup.object().shape({
    orgName: Yup.string().required("Name is Required"),
    orgAddress: Yup.string().required("Address is Required"),
    orgType: Yup.string().required("Select a Type"),
    // uiTheme: Yup.string().required("Select a Theme"),

    startDate: Yup.date().required("Select Start Date"),
    endDate: Yup.date().required("Select End Date").min(Yup.ref("startDate"), "End date can not be before Start date"),
    coachingPlan: Yup.string().required("Select Plan"),
    amount: Yup.number().required("Amount is Required").positive("Enter Positive Value").integer("Enter Integer Value"),
    hours: Yup.number().when("coachingPlan", {
      is: "fixed",
      then: () =>
        Yup.number().required("Hours is Required").positive("Enter Positive Value").integer("Enter Integer Value"),
    }),
    // .when("startDate", (startDate, schema) => startDate && schema.min(startDate)),
  });

  const addCustomErrors = (errors) => {
    setCustomError((prevErrors) => ({
      ...prevErrors,
      ...errors,
    }));
  };

  async function fetchOrgTypes() {
    try {
      const resp = await trackPromise(OrgTypeService.getList());
      const { data } = resp;
      if (data.success) {
        set_orgTypeList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching org types");
      }
    } catch (err) {
      console.error("Fetch org types catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchStudentTypes() {
    try {
      const resp = await trackPromise(StudentTypeService.getList());
      const { data } = resp;
      if (data.success) {
        set_studentTypeList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching coachee types");
      }
    } catch (err) {
      console.error("Fetch org Coachee catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchOrgDetails() {
    try {
      const resp = await trackPromise(OrganisationService.getById(orgId));
      const { data } = resp;
      if (data.success) {
        set_orgDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching org details");
      }
    } catch (err) {
      console.error("Fetch org details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, orgDetails);
    formobj.orgType = orgDetails?.orgType?._id;
    formobj.uiTheme = "default";
    if (orgDetails?.startDate) formobj.startDate = moment(orgDetails.startDate).format("YYYY-MM-DD");
    if (orgDetails?.endDate) formobj.endDate = moment(orgDetails.endDate).format("YYYY-MM-DD");
    formobj.coachingPlan = orgDetails?.coachingPlan?.scheme;
    formobj.amount = orgDetails?.coachingPlan?.amount;
    formobj.hours = orgDetails?.coachingPlan?.hours;
    formobj = populateStudentTypeField(formobj, orgDetails?.coachingPlan?.distribution);
    set_formObject({ ...formobj });
  }

  function populateStudentTypeField(formValues, distribution = []) {
    distribution.forEach((level) => {
      formValues[`studentCount_${level?.studentType?._id}`] = level?.studentCount;
      formValues[`minSessions_${level?.studentType?._id}`] = level?.minSessions;
      formValues[`userUsedSessions_${level?.studentType?._id}`] = level?.usedSessions;
      formValues[`availableSessions_${level?.studentType?._id}`] = level?.availableSessions;
    });
    return formValues;
  }

  function checkCustomError(plan) {
    let error = true;
    if (plan == "fixed") return true;
    Object.keys(customError).forEach((key) => {
      if (customError[key]) error = false;
    });

    return error;
  }

  async function handleFormSubmit(values) {
    const submitext = orgId ? "Updated" : "Created";
    try {
      if (checkEmptyDistribution(values) && checkCustomError(values.coachingPlan)) {
        const resp = orgId
          ? await trackPromise(OrganisationService.update(orgId, validateForm(values)))
          : await trackPromise(OrganisationService.create(validateForm(values)));
        const { data } = resp;
        if (data.success) {
          toast.success(`Organisation ${submitext} successfully`);
          navigate(-1);
        } else {
          set_pageError(data.message || `Failed to ${submitext} organisation`);
        }
      }
    } catch (err) {
      console.error("Organisation error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Organisation error");
    }
    dispatch(getColorTheme(""));
  }
  function calculateHours(values) {
    let total = 0;
    studentTypeList.forEach((studentType) => {
      if (values[`studentCount_${studentType._id}`] && values[`minSessions_${studentType._id}`]) {
        total = values[`studentCount_${studentType._id}`] * values[`minSessions_${studentType._id}`] + total;
      }
    });
    return total;
  }

  function distributionMap(values) {
    return studentTypeList
      .map((studentType) => {
        return {
          studentType: studentType._id,
          studentCount: values[`studentCount_${studentType._id}`],
          minSessions: values[`minSessions_${studentType._id}`],
          usedSessions: values[`userUsedSessions_${studentType._id}`],
          availableSessions: values[`availableSessions_${studentType._id}`],
        };
      })
      .filter((studentField) => studentField.studentCount > 0);
  }

  function checkEmptyDistribution(values) {
    if (values.coachingPlan == "fixed") return true;

    const distribution = studentTypeList
      .map((studentType) => {
        return {
          studentType: studentType._id,
          studentCount: values[`studentCount_${studentType._id}`],
          minSessions: values[`minSessions_${studentType._id}`],
          usedSessions: values[`userUsedSessions_${studentType._id}`],
          availableSessions: values[`availableSessions_${studentType._id}`],
        };
      })
      .filter((studentField) => studentField?.studentCount > 0);
    if (distribution.length < 1) {
      setNoFieldError(true);
      return false;
    }
    return true;
  }

  function validateForm(values) {
    const formValues = { ...values };
    formValues.startDate = localDayStart(formValues.startDate).toDate();
    formValues.endDate = localDayEnd(formValues.endDate).toDate();
    if (values.coachingPlan == "fixed") {
      formValues.coachingPlan = { scheme: values.coachingPlan, hours: values.hours, amount: values.amount };
    } else {
      formValues.coachingPlan = {
        scheme: values.coachingPlan,
        amount: values.amount,
        distribution: distributionMap(values),
      };
      formValues.coachingPlan.hours = calculateHours(values);
      //formValues.coachingPlan.distribution.availableSessions = calculateTotalSession(values);
    }
    studentTypeList.forEach((studentType) => {
      delete formValues[`studentCount_${studentType._id}`];
      delete formValues[`minSessions_${studentType._id}`];
    });
    return formValues;
  }

  const themeData = [
    { id: 1, theme_code: "default", themeName: "Default" },
    { id: 2, theme_code: "themeCode01", themeName: "Blue" },
    { id: 3, theme_code: "themeCode02", themeName: "Green" },
    { id: 4, theme_code: "themeCode03", themeName: "Yellow" },
    // { id: 4, theme_code: "themeCode04", themeName: "Orange" },
  ];

  const onThemeClass = () => {
    dispatch(getColorTheme(formRef?.current?.value));
    const newThemeClass = THEME.filter((item) => item.theme_code === colorThemeName);
    dispatch(setColorThemeData(newThemeClass));
  };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{orgId ? "Edit" : "Add"} Organisation</h4>
      </div>

      <Row className="">
        <Col lg="8">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({
                handleSubmit,
                handleChange,
                values,
                errors,
                touched,
                isSubmitting,
                setFieldValue,

                setFieldTouched,
                setFieldError,
              }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Organisation Name"
                      name="orgName"
                      value={values.orgName}
                      onChange={handleChange}
                      isInvalid={touched.orgName && errors.orgName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.orgName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Organisation Address</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Organisation Address"
                      name="orgAddress"
                      value={values.orgAddress}
                      onChange={handleChange}
                      isInvalid={touched.orgAddress && errors.orgAddress}
                    />
                    <Form.Control.Feedback type="invalid">{errors.orgAddress}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Organisation Type</Form.Label>
                    <Form.Select
                      name="orgType"
                      value={values.orgType}
                      onChange={handleChange}
                      isInvalid={touched.orgType && errors.orgType}
                    >
                      <option>Select Type</option>
                      {orgTypeList?.length ? (
                        orgTypeList.map((itm, inx) => (
                          <option key={inx} value={itm._id}>
                            {itm.typeName}
                          </option>
                        ))
                      ) : (
                        <></>
                      )}
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.orgType}</Form.Control.Feedback>
                  </Form.Group>

                  {/* <div className=" align-items-center">
                    <Form.Group className="frm-group">
                      <Form.Label>Organisation Theme</Form.Label>
                      <Form.Select
                        ref={formRef}
                        name="uiTheme"
                        value={values.uiTheme}
                        onChange={handleChange}
                        isInvalid={touched.uiTheme && errors.uiTheme}
                      >
                        <option>Select Theme</option>
                        {themeData.map(({ theme_code, id, themeName }) => (
                          <option key={id} value={theme_code}>
                            {themeName}
                          </option>
                        ))}
                      </Form.Select>
                      <Form.Control.Feedback type="invalid">{errors.uiTheme}</Form.Control.Feedback>
                    </Form.Group>

                    <Button variant="clr-primary" className="me-2 mb-3" onClick={onThemeClass}>
                      Preview Theme
                    </Button>
                  </div> */}

                  <div className="row g-0">
                    <Form.Group className="col-lg me-lg-3 frm-group">
                      <Form.Label>Start Date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Select date"
                        name="startDate"
                        value={values.startDate}
                        onChange={handleChange}
                        isInvalid={touched.startDate && errors.startDate}
                        min={new Date().toISOString().split("T")[0]}
                      />
                      <Form.Control.Feedback type="invalid">{errors.startDate}</Form.Control.Feedback>
                    </Form.Group>

                    <Form.Group className="col-lg ms-lg-3 frm-group">
                      <Form.Label>End Date</Form.Label>
                      <Form.Control
                        type="date"
                        placeholder="Select date"
                        name="endDate"
                        value={values.endDate}
                        onChange={handleChange}
                        isInvalid={touched.endDate && errors.endDate}
                        min={new Date().toISOString().split("T")[0]}
                      />

                      <Form.Control.Feedback type="invalid">{errors.endDate}</Form.Control.Feedback>
                    </Form.Group>
                  </div>

                  <Form.Group className="frm-group mb-4">
                    <Form.Label className="mb-1">Assign Coaching Hours</Form.Label>
                    <div className="float-div py-3 px-3 mt-1">
                      <Form.Check
                        label="Fixed Hours & Fixed Amount"
                        name="planType"
                        type="radio"
                        id="fixed"
                        checked={values.coachingPlan == "fixed"}
                        disabled={orgId && values.coachingPlan === "distributed"}
                        onChange={(value) => {
                          setFieldValue("coachingPlan", "fixed");
                          setCustomError({});
                        }}
                      />
                      {values.coachingPlan == "fixed" ? (
                        <Row className="pt-3">
                          <Col>
                            <Form.Group className="frm-group">
                              <Form.Label>Hours</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Assign Hours"
                                name="hours"
                                min={orgDetails?.id ? orgDetails?.coachingPlan?.hours : 0}
                                value={values.hours || 0}
                                onChange={handleChange}
                                isInvalid={touched.hours && errors.hours}
                              />
                              <Form.Control.Feedback type="invalid">{errors.hours}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="frm-group">
                              <Form.Label>Sessions Total</Form.Label>
                              <Form.Control
                                className="col"
                                value={orgDetails?.coachingPlan?.availableSessions || 0}
                                disabled={true}
                              />

                              <Form.Control.Feedback type="invalid">{errors.hours}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="frm-group">
                              <Form.Label>Sessions Used</Form.Label>
                              <Form.Control
                                className="col "
                                value={orgDetails?.coachingPlan?.usedSessions || 0}
                                disabled={true}
                              />

                              <Form.Control.Feedback type="invalid">{errors.hours}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                          <Col>
                            <Form.Group className="frm-group">
                              <Form.Label>Total Amount</Form.Label>
                              <Form.Control
                                type="number"
                                placeholder="Total Amount"
                                name="amount"
                                min={0}
                                value={values.amount || 0}
                                onChange={handleChange}
                                isInvalid={touched.amount && errors.amount}
                              />
                              <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                            </Form.Group>
                          </Col>
                        </Row>
                      ) : null}
                    </div>
                    <div className="float-div py-3 px-3">
                      <Form.Check
                        label="User/Usage Based"
                        name="planType"
                        type="radio"
                        id="distributed"
                        checked={values.coachingPlan == "distributed"}
                        disabled={orgId && values.coachingPlan === "fixed"}
                        onChange={() => {
                          setFieldValue("coachingPlan", "distributed");
                        }}
                      />

                      {values.coachingPlan === "distributed" &&
                        studentTypeList?.map((studentType, inx) => (
                          <Row key={inx} className="py-3">
                            <Col lg={12}>
                              <Form.Label>{studentType.typeName}</Form.Label>
                            </Col>
                            <Col>
                              <Form.Group className="frm-group">
                                <Form.Label>User Count</Form.Label>
                                <Form.Control
                                  type="number"
                                  min={0}
                                  placeholder="Enter Count"
                                  name={`studentCount_${studentType._id}`}
                                  value={values[`studentCount_${studentType._id}`] || 0}
                                  onChange={(event) => {
                                    const userCountValue = event.target.value;
                                    setFieldValue(`studentCount_${studentType._id}`, userCountValue);

                                    const total = userCountValue * (values[`minSessions_${studentType._id}`] || 0);

                                    setFieldValue(`availableSessions_${studentType._id}`, total);

                                    if (userCountValue > 0) {
                                      if (!values[`minSessions_${studentType._id}`]) {
                                        addCustomErrors({
                                          [`minSessions_${studentType._id}`]: "Minimum Sessions is Required",
                                        });
                                      } else if (values[`minSessions_${studentType._id}`] < 1) {
                                        addCustomErrors({
                                          [`minSessions_${studentType._id}`]: "Minimum Sessions must be greater than 0",
                                        });
                                      } else {
                                        addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                        addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                      }
                                    } else if (values[`minSessions_${studentType._id}`] > 0 && userCountValue < 1) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "Count must be greater than 0",
                                      });
                                    } else if (values[`minSessions_${studentType._id}`] < 1 && userCountValue < 1) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else if (userCountValue < 1 && !values[`minSessions_${studentType._id}`]) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else {
                                      addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                      addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                    }
                                    setNoFieldError(false);
                                  }}
                                  onBlur={(event) => {
                                    const userCountValue = event.target.value;
                                    setFieldValue(`studentCount_${studentType._id}`, userCountValue);

                                    const total = userCountValue * (values[`minSessions_${studentType._id}`] || 0);
                                    // setTotalSessions(total);

                                    setFieldValue(`availableSessions_${studentType._id}`, total);

                                    if (userCountValue > 0) {
                                      if (!values[`minSessions_${studentType._id}`]) {
                                        addCustomErrors({
                                          [`minSessions_${studentType._id}`]: "Minimum Sessions is Required",
                                        });
                                      } else if (values[`minSessions_${studentType._id}`] < 1) {
                                        addCustomErrors({
                                          [`minSessions_${studentType._id}`]: "Minimum Sessions must be greater than 0",
                                        });
                                      } else {
                                        addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                        addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                      }
                                    } else if (values[`minSessions_${studentType._id}`] > 0 && userCountValue < 1) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "Count must be greater than 0",
                                      });
                                    } else if (values[`minSessions_${studentType._id}`] < 1 && userCountValue < 1) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else if (userCountValue < 1 && !values[`minSessions_${studentType._id}`]) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else {
                                      addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                      addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                    }
                                    setNoFieldError(false);
                                  }}
                                  isInvalid={customError[`studentCount_${studentType._id}`]}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {customError[`studentCount_${studentType._id}`]}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group className="frm-group">
                                <Form.Label>Minimum Sessions</Form.Label>
                                <Form.Control
                                  type="number"
                                  min={0}
                                  placeholder="Assign Hours"
                                  name={`minSessions_${studentType._id}`}
                                  value={values[`minSessions_${studentType._id}`] || 0}
                                  onChange={(event) => {
                                    const userHoursValue = event.target.value;
                                    setFieldValue(`minSessions_${studentType._id}`, userHoursValue);

                                    const total = (values[`studentCount_${studentType._id}`] || 0) * userHoursValue;

                                    setFieldValue(`availableSessions_${studentType._id}`, total);

                                    if (userHoursValue > 0) {
                                      if (!values[`studentCount_${studentType._id}`]) {
                                        addCustomErrors({ [`studentCount_${studentType._id}`]: "Count is Required" });
                                      } else if (values[`studentCount_${studentType._id}`] < 1) {
                                        addCustomErrors({
                                          [`studentCount_${studentType._id}`]: "Count must be greater than 0",
                                        });
                                      } else {
                                        addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                        addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                      }
                                    } else if (values[`studentCount_${studentType._id}`] > 0 && userHoursValue < 1) {
                                      addCustomErrors({
                                        [`minSessions_${studentType._id}`]: "Minimum Sessions must be greater than 0",
                                      });
                                    } else if (values[`studentCount_${studentType._id}`] < 1 && userHoursValue < 1) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else if (userHoursValue < 1 && !values[`studentCount_${studentType._id}`]) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else {
                                      addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                      addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                    }
                                  }}
                                  onBlur={(event) => {
                                    const userHoursValue = event.target.value;
                                    setFieldValue(`minSessions_${studentType._id}`, userHoursValue);

                                    const total = (values[`studentCount_${studentType._id}`] || 0) * userHoursValue;

                                    setFieldValue(`availableSessions_${studentType._id}`, total);

                                    if (userHoursValue > 0) {
                                      if (!values[`studentCount_${studentType._id}`]) {
                                        addCustomErrors({ [`studentCount_${studentType._id}`]: "Count is Required" });
                                      } else if (values[`studentCount_${studentType._id}`] < 1) {
                                        addCustomErrors({
                                          [`studentCount_${studentType._id}`]: "Count must be greater than 0",
                                        });
                                      } else {
                                        addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                        addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                      }
                                    } else if (values[`studentCount_${studentType._id}`] > 0 && userHoursValue < 1) {
                                      addCustomErrors({
                                        [`minSessions_${studentType._id}`]: "Minimum Sessions must be greater than 0",
                                      });
                                    } else if (values[`studentCount_${studentType._id}`] < 1 && userHoursValue < 1) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else if (userHoursValue < 1 && !values[`studentCount_${studentType._id}`]) {
                                      addCustomErrors({
                                        [`studentCount_${studentType._id}`]: "",
                                        [`minSessions_${studentType._id}`]: "",
                                      });
                                    } else {
                                      addCustomErrors({ [`minSessions_${studentType._id}`]: "" });
                                      addCustomErrors({ [`studentCount_${studentType._id}`]: "" });
                                    }
                                  }}
                                  isInvalid={customError[`minSessions_${studentType._id}`]}
                                />
                                <Form.Control.Feedback type="invalid">
                                  {customError[`minSessions_${studentType._id}`]}
                                </Form.Control.Feedback>
                              </Form.Group>
                            </Col>

                            <Col>
                              <Form.Group className="frm-group">
                                <Form.Label>Sessions Total</Form.Label>
                                <Form.Control
                                  className="col"
                                  name={`availableSessions_${studentType._id}`}
                                  value={values[`availableSessions_${studentType._id}`] || 0}
                                  disabled={true}
                                />
                              </Form.Group>
                            </Col>
                            <Col>
                              <Form.Group className="frm-group">
                                <Form.Label>Sessions Used</Form.Label>
                                <Form.Control
                                  className="col "
                                  value={
                                    orgDetails?.coachingPlan?.distribution.filter(
                                      (item) => item?.studentType?._id == studentType._id
                                    )[0]?.usedSessions || 0
                                  }
                                  disabled={true}
                                />

                                <Form.Control.Feedback type="invalid">{errors.hours}</Form.Control.Feedback>
                              </Form.Group>
                            </Col>
                          </Row>
                        ))}
                      {values.coachingPlan === "distributed" ? (
                        <Row>
                          <Form.Group className="frm-group">
                            <Col>
                              <Form.Label>Total Amount</Form.Label>
                            </Col>
                            <Col>
                              <Form.Control
                                type="number"
                                placeholder="Enter Count"
                                name="amount"
                                min={0}
                                value={values.amount || 0}
                                onChange={handleChange}
                                isInvalid={touched.amount && errors.amount}
                              />

                              <Form.Control.Feedback type="invalid">{errors.amount}</Form.Control.Feedback>
                            </Col>
                          </Form.Group>
                          {noFieldError ? (
                            <div id="errorObject" className="fieldError">
                              Please enter value for atleast one level
                            </div>
                          ) : null}
                        </Row>
                      ) : null}
                    </div>
                  </Form.Group>

                  <div className="row g-0 frm-btn-grp gap-2">
                    <Button
                      as={Link}
                      to="/organisation"
                      variant="clr-transparent"
                      className="col-lg me-lg-3"
                      onClick={() => dispatch(getColorTheme(""))}
                    >
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-lg-3">
                      {orgId ? "Edit" : "Add"} Organisation
                    </Button>
                  </div>

                  {pageError ? <div className="text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OrganisationCreate;

const RenderTooltip = (props) => (
  <Tooltip {...props}>Are the minimum number of coaching sessions allocated to a Coachee during creation</Tooltip>
);

const RenderTooltip02 = (props) => (
  <Tooltip {...props}>Are the minimum number of booking sessions required to create an appointment</Tooltip>
);

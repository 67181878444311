import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { copyObjectByKeys } from "../../utilities/functions";
import { TopicsService } from "../../services";

function TopicsCreate({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [topicId, set_topicId] = useState(params.id);
  const [topicDetails, set_topicDetails] = useState({});
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    if (topicId) fetchTopicDetails();
  }, [topicId]);

  useEffect(() => {
    if (topicDetails.id) populateForm();
  }, [topicDetails.id]);

  function getFormObject() {
    return {
      topicName: "",
      topicDescription: "",
    };
  }

  const FormSchema = Yup.object().shape({
    topicName: Yup.string().required("Topic Name is Required"),
    topicDescription: Yup.string().required("Topic Description is Required"),
  });

  async function fetchTopicDetails() {
    try {
      const resp = await trackPromise(TopicsService.getTopicById(topicId));
      const { data } = resp;
      if (data.success) {
        set_topicDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topic details");
      }
    } catch (err) {
      console.error("Fetch topic details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, topicDetails);
    set_formObject({ ...formobj });
  }

  async function handleFormSubmit(values) {
    const submitText = topicId ? "Updated" : "Created";
    try {
      const resp = topicId
        ? await trackPromise(TopicsService.updateTopic(topicId, values))
        : await trackPromise(TopicsService.createTopics(values));

      const { data } = resp;
      if (data.success) {
        toast.success(`Topic ${submitText} successfully.`);
        navigate(-1);
      } else {
        set_pageError(data.message || `Topic ${submitText} failed`);
      }
    } catch (err) {
      console.error("Topic error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || `Topic ${submitText} error`);
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{topicId ? "Edit" : "Add"} Topic</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Topic Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Topic Name"
                      name="topicName"
                      value={values.topicName}
                      onChange={handleChange}
                      isInvalid={touched.topicName && errors.topicName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.topicName}</Form.Control.Feedback>
                  </Form.Group>
                  {/* TODO Make topic Description instead of code */}
                  <Form.Group className="frm-group">
                    <Form.Label>Topic Description</Form.Label>
                    <Form.Control
                      as="textarea"
                      rows={3}
                      placeholder="Enter Topic Description"
                      name="topicDescription"
                      value={values.topicDescription}
                      onChange={handleChange}
                      isInvalid={touched.topicDescription && errors.topicDescription}
                    />
                    <Form.Control.Feedback type="invalid">{errors.topicDescription}</Form.Control.Feedback>
                  </Form.Group>
                  {/* <Form.Group className="frm-group">
                    <Form.Label>Topic Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Topic Code"
                      name="topicCode"
                      value={values.topicCode}
                      onChange={handleChange}
                      isInvalid={touched.topicCode && errors.topicCode}
                    />
                    <Form.Control.Feedback type="invalid">{errors.topicCode}</Form.Control.Feedback>
                  </Form.Group> */}
                  <div className="d-flex justify-content-center mb-3">
                    <Button as={Link} to="/topics" variant="clr-transparent" className="col-lg me-2">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-2">
                      {topicId ? "Edit" : "Add"}
                    </Button>
                  </div>
                  {pageError ? <div className="mt-3 text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default TopicsCreate;

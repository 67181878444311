import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useRouteLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faBuilding, faPeopleGroup, faUserShield } from "@fortawesome/free-solid-svg-icons";
import ReportAdmin from "./Component/ReportComponent";
import ReportBarChart from "../Dashboard/Component/ReportComponentBarChart";
// import ReportAdminLine from "./Component/ReportComponentBarChart";
import { trackPromise } from "react-promise-tracker";
import { StatsService, ReportService } from "../../services";
import moment from "moment";

function Dashboard() {
  const routeData = useRouteLoaderData("dashboard");
  const dispatch = useDispatch();
  const { authState, appState } = useSelector((state) => state);
  const [userStat, set_userStat] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [data, setData] = useState([]);
  const [coachData, setcoachData] = useState([]);
  const [coachEndDate, setCoachEndDate] = useState(new Date());
  const [dateError, setDateError] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ ...routeData }));
    fetchUserStat();
    OrganizationHours();
    CoachHours();
  }, []);

  const localFormatDate = (date) => {
    const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";
    const convertedDate = moment(date).format(outputFormat);
    return convertedDate;
  };

  const localFormatStartDate = (date) => {
    const givenDate = moment(date);
    if (givenDate) {
      const result = givenDate.set({
        hour: 0,
        minute: 0,
        second: 0,
      });
      return result.format();
    }
  };

  const endDateFormat = (date) => {
    const givenDate = moment(date);
    const result = givenDate.set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    return result.format();
  };

  

  



  const endDateOrgSet = endDateFormat(new Date());


  const date = new Date(endDateOrgSet);

// Subtract one year
date.setFullYear(date.getFullYear() - 1);

let startDateYearBack=(date.toISOString()); // Outputs the date in ISO format

const endDateSet = endDateFormat(coachEndDate);

  const localStartDate = localFormatDate(startDateYearBack);
  const localEndDate = localFormatDate(endDateSet);

  async function fetchUserStat() {
    try {
      const resp = await trackPromise(StatsService.getOverviewStats());
      const { data } = resp;
      if (data.success) {
        set_userStat(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function OrganizationHours() {
    try {
      const resp = await trackPromise(
        ReportService.getOrgHours({
          startDate: localStartDate,
          endDate: localEndDate,
          organisations: [],
        })
      );
      const { data } = resp;
      if (data.success) {
        setData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisationsHours catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  async function CoachHours() {
    try {
      const resp = await trackPromise(
        ReportService.getCoachHours({
          startDate: localStartDate,
          endDate: localEndDate,
          coaches: [],
        })
      );
      const { data } = resp;
      if (data.success) {
        setcoachData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisations catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <Row>
        <Col>
          <Row className="px-2">
            <Col>
              <Row className="px-4">
                <Col>
                  <Row className="mb-3">
                    <h4 className="hd-txt">Quick Actions loading</h4>
                  </Row>
                  <Row className="d-flex flex-wrap">
                    <Col xs={12} md={6} lg={4} xl={4} className="mb-3">
                      <Col className="inner-card">
                        <Row className="text-dash">
                          <Col xs={8} md={8}>
                            <h5 className="hd-txt">{userStat.organisations ? userStat.organisations : 0}</h5>
                            <h6>Organisations</h6>
                          </Col>
                          <Col xs={2} md={2} className="d-flex align-items-center justify-content-">
                            <FontAwesomeIcon icon={faBuilding} size="3x" color="#B18C93" />
                          </Col>
                        </Row>

                        <Row className="px-2 mt-2">
                          <Button variant="clr-primary" as={Link} to="/organisation" onClick={() => {}}>
                            Manage Organisations
                          </Button>
                        </Row>
                      </Col>
                    </Col>

                    <Col xs={12} md={6} lg={4} xl={4} className="mb-3">
                      <Col className="inner-card">
                        <Row className="text-dash">
                          <Col xs={8} md={8}>
                            <h5 className="hd-txt">{userStat.admins ? userStat.admins : 0}</h5>
                            <h6>Admins</h6>
                          </Col>
                          <Col xs={2} md={2} className="d-flex align-items-center justify-content-">
                            <FontAwesomeIcon icon={faUserShield} size="3x" color="#B18C93" />
                          </Col>
                        </Row>

                        <Row className="px-2 mt-2">
                          <Button variant="clr-primary" as={Link} to="/admin" onClick={() => {}}>
                            Manage Admins
                          </Button>
                        </Row>
                      </Col>
                    </Col>
                    <Col xs={12} md={6} lg={4} xl={4} className="mb-3">
                      <Col className="inner-card">
                        <Row className="text-dash">
                          <Col xs={8} md={8}>
                            <h5 className="hd-txt">{userStat.coaches ? userStat.coaches : 0}</h5>
                            <h6>Coaches</h6>
                          </Col>
                          <Col xs={2} md={2} className="d-flex align-items-center justify-content-">
                            <FontAwesomeIcon icon={faPeopleGroup} size="3x" color="#B18C93" />
                          </Col>
                        </Row>

                        <Row className="px-2 mt-2">
                          <Button variant="clr-primary" as={Link} to="/coach" onClick={() => {}}>
                            Manage Coaches
                          </Button>
                        </Row>
                      </Col>
                    </Col>
                  </Row>
                </Col>
              </Row>
            </Col>
          </Row>
          <Row className="px-4 my-4">
            <Row>
              <Col md={6} sm={12}>
                <h4 className="hd-txt">Reports</h4>
                <Row>
                  <h5>Coaching Session Distribution by Coachee Type</h5>


                  <p>
                  Monitor the allocation and utilisation of coaching sessions across different coachee types within an organisation, providing insights to identify trends and optimise coaching resources.
                   </p>
                </Row>
                <div>
                  <ReportAdmin data={data} />
                </div>
              </Col>
              <Col md={6} sm={12}>
                <Row
                  className="d-flex justify-content-end text-decoration-underline"
                  as={Link}
                  to={"/viewreports"}
                  style={{ color: "#B18C93" }}
                >
                  View More
                </Row>
                <Row style={{ marginTop: "10px" }}>
                  <h5>Coach Income Analysis</h5>
                  <p>
                  Track and analyse the total income earned by coaches.
                  </p>
                </Row>
                <div>
                  <ReportBarChart
                    data={coachData}
                    xAxisLabel="Amount"
                    yAxisLabel="Coaches"
                    xValueKey="totalAmount"
                    yValueKey="firstName"
                  />
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default Dashboard;

import React, { useEffect } from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";
import { useDispatch, useSelector } from "react-redux";
import { Container } from "react-bootstrap";
import { getColorThemeData } from "../../../stores/colorThemeSlice";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

const options = {
  responsive: true,
  maintainAspectRatio: false,
  plugins: {
    legend: {
      position: "top",
      align: "end",
    },
  },
  scales: {
    y: {
      display: true,
      title: {
        display: true,
        text: "Coaching Sessions",
      },
      beginAtZero: true,
      stacked: true,
    },
    x: {
      display: true, // Hide x-axis labels
      title: {
        display: true,
        text: "Organisations",
      },
      stacked: true,
      beginAtZero: true,
      ticks:{
        display:false
      }
    },
  },
};

const ReportAdmin = ({ data }) => {
  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);
  const dispatch = useDispatch();

  useEffect(() => {
    dispatch(getColorThemeData(colorTheme));
  }, [colorTheme, dispatch]);
  const colorThemeData = useSelector((state) => state.colorTheme.colorThemeData);

  if (!data || data?.length === 0 || !data[0]?.studentTypes) {
    return null;
  }

  const organizationNames = data?.map((item) => item?.orgName);

  const stackColors = ["#B18C93", "#dd8e9d", "#5a3d42", "#D6C5C8"];
  // Create datasets for each student type
  const datasets = data?.[0]?.studentTypes?.map((type, index) => {
    const backgroundColors = data?.map((org) => {
      const orgType = org?.studentTypes?.find((t) => t?.typeName === type?.typeName);
      return orgType ? stackColors[index % stackColors.length] : "rgba(0, 0, 0, 0)";
    });
    return {
      label: type?.typeCode,
      data: data?.map((org) => {
        const orgType = org?.studentTypes?.find((t) => t?.typeName === type?.typeName);
        return orgType ? orgType?.coachingSessions : 0;
      }),
      backgroundColor: backgroundColors,
      borderRadius: 20,
      barPercentage: 0.3,
      categoryPercentage: 0.5,
      barThickness: 8,
    };
  });

  // Create the data for the chart
  const chartData = {
    labels: organizationNames,
    datasets: datasets,
  };

  return (
    <Container>
      <div
        style={{
          borderWidth: 1,
          borderColor: colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93",
        }}
      >
        <Bar options={options} data={chartData} style={{ width: "100%", height: "80%" }} />
      </div>
    </Container>
  );
};

export default ReportAdmin;

import React from "react";
import { Chart as ChartJS, CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend } from "chart.js";
import { Bar } from "react-chartjs-2";

ChartJS.register(CategoryScale, LinearScale, BarElement, Title, Tooltip, Legend);

export default function ReportBarChart({ data, xAxisLabel, yAxisLabel, xValueKey, yValueKey }) {
  const chartData = {
    labels: data.map((item) => item[yValueKey]),
    datasets: [
      {
        label: xAxisLabel,
        data: data.map((item) => item[xValueKey]),
        backgroundColor: "#B18C93",
        borderRadius: 20,
        barThickness: 8,
      },
    ],
  };

  const options = {
    responsive: true,
    maintainAspectRatio: false,
    plugins: {
      legend: {
        position: "top",
        align: "end",
      },
    },
    scales: {
      x: {
        display: true,
        title: {
          display: true,
          text: xAxisLabel,
        },
      },
      y: {
        display: true,
        title: {
          display: true,
          text: yAxisLabel,
        },
        ticks: {
          display: false,
        },
      },
    },
  };

  options.indexAxis = "y"; // Set the orientation to horizontal

  return (
    <div
      style={{
        borderWidth: 1,
        borderColor: "black",
      }}
    >
      <Bar options={options} data={chartData} style={{ width: "100%", height: "80%" }} />
    </div>
  );
}

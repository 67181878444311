import { useState, useEffect } from "react";
import { Link, useNavigate, useParams } from "react-router-dom";
import { Container, Row, Col, Button, Form } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { copyObjectByKeys } from "../../utilities/functions";
import { OrgTypeService } from "../../services";

function OrgTypeCreate({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [orgTypeId, set_orgTypeId] = useState(params.id);
  const [orgTypeDetails, set_orgTypeDetails] = useState({});
  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    if (orgTypeId) fetchOrgTypeDetails();
  }, [orgTypeId]);

  useEffect(() => {
    if (orgTypeDetails.id) populateForm();
  }, [orgTypeDetails.id]);

  function getFormObject() {
    return {
      typeName: "",
      typeCode: "",
    };
  }

  const FormSchema = Yup.object().shape({
    typeName: Yup.string().required("Type Name is Required"),
    typeCode: Yup.string().required("Type Code is Required"),
  });

  async function fetchOrgTypeDetails() {
    try {
      const resp = await trackPromise(OrgTypeService.getOrgTypeById(orgTypeId));
      const { data } = resp;
      if (data.success) {
        set_orgTypeDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisation type details");
      }
    } catch (err) {
      console.error("Fetch organisation type details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  function populateForm() {
    let formobj = { ...formObject };
    copyObjectByKeys(formobj, orgTypeDetails);
    set_formObject({ ...formobj });
  }

  async function handleFormSubmit(values) {
    const submitText = orgTypeId ? "updated" : "created";
    try {
      const resp = orgTypeId
        ? await trackPromise(OrgTypeService.updateOrgType(orgTypeId, values))
        : await trackPromise(OrgTypeService.createOrgType(values));

      const { data } = resp;
      if (data.success) {
        toast.success(`Organisation Type ${submitText} successful!`);
        navigate(-1);
      } else {
        set_pageError(data.message || `Organisation Type Type ${submitText} failed`);
      }
    } catch (err) {
      console.error("Organisation Type Type error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || `Organisation Type Type ${submitText} error`);
    }
  }

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">{orgTypeId ? "Edit" : "Add"} Organisation Type</h4>
      </div>

      <Row className="">
        <Col lg="7">
          <div className="form-01-wrap px-4">
            <Formik
              initialValues={formObject}
              enableReinitialize="true"
              validationSchema={FormSchema}
              onSubmit={handleFormSubmit}
            >
              {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
                <Form noValidate onSubmit={handleSubmit}>
                  <Form.Group className="frm-group">
                    <Form.Label>Type Name</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Organistaion Type Name"
                      name="typeName"
                      value={values.typeName}
                      onChange={handleChange}
                      isInvalid={touched.typeName && errors.typeName}
                    />
                    <Form.Control.Feedback type="invalid">{errors.typeName}</Form.Control.Feedback>
                  </Form.Group>

                  <Form.Group className="frm-group">
                    <Form.Label>Type Code</Form.Label>
                    <Form.Control
                      type="text"
                      placeholder="Enter Type Code"
                      name="typeCode"
                      value={values.typeCode}
                      onChange={handleChange}
                      isInvalid={touched.typeCode && errors.typeCode}
                    />
                    <Form.Control.Feedback type="invalid">{errors.typeCode}</Form.Control.Feedback>
                  </Form.Group>
                  <div className="d-flex justify-content-center mb-3">
                    <Button as={Link} to="/org/type" variant="clr-transparent" className="col-lg me-2">
                      Cancel
                    </Button>
                    <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg ms-2">
                      {orgTypeId ? "Edit" : "Add"}
                    </Button>
                  </div>
                  {pageError ? <div className="mt-3 text-danger">{pageError}</div> : <></>}
                </Form>
              )}
            </Formik>
          </div>
        </Col>
      </Row>
    </Container>
  );
}

export default OrgTypeCreate;

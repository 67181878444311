import { useState, useEffect } from "react";
import { useParams, useNavigate } from "react-router-dom";
import { Container, Row, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import AgoraRTC from "agora-rtc-sdk-ng";
import { trackPromise } from "react-promise-tracker";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faArrowLeftLong } from "@fortawesome/free-solid-svg-icons";
import { setRouteData } from "../../stores/appSlice";
import { BookSlotServices } from "../../services";
import { THEME } from "../../utilities/theme";
import AgoraUIKit from "agora-react-uikit";
import "./Appointment.call.css";
import { yearDateFormat } from "../../utilities/functions";
import RatingPopUp from "./RatingPopUp";
import coachRatingService from "../../services/coachRating.service";
import { toast } from "react-toastify";

const RtcClient = AgoraRTC.createClient({ mode: "rtc", codec: "vp8" });

const styles = {
  container: { width: "80vw", height: "80vh", display: "flex", flex: 1 },
};

function AppointmentCall({ pageTitle }) {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const params = useParams();

  const [pageError, set_pageError] = useState("");
  const [isOpen, setIsopen] = useState(false);
  /* Video calling */
  const [rtcConfig, set_rtcConfig] = useState({});
  const [localUser, set_localUser] = useState(initUserObject());
  const [remoteUser, set_remoteUser] = useState(initUserObject());
  const [remoteMediaType, set_remoteMediaType] = useState("");
  // const [showVideoCall, setShowVideoCall] = useState(false);
  const [videocall, setVideocall] = useState(false);
  const { authState } = useSelector((state) => state);

  const studentId = authState?.user?.id;
  const rtcProps = {
    appId: "766564132c6a42e58ebfb4a840da61d4",
    // appId: rtcConfig?.appId,
    // channel: "test",
    channel: "coachingApp",
    token: null, // pass in channel token if the app is in secure mode
  };
  // const rtcProps = {
  //   appId: "766564132c6a42e58ebfb4a840da61d4",
  //   channel: "654c79f1bad843b2a52a0eb8",
  //   token:
  //     "007eJxTYGD0uXlrn4cfy5SWQ5Wbd67U6Hv8qNNjzduMomnCDc+NOC4rMJibmZmamRgaGyWbJZoYpZpapCalJZkkWpgYpCSaGaaY/KvySX1T75OaUXOHkZEBAkFAgsHM1CTZ3DLNMCkxxcLEOMko0dQo0SA1yQIkY2RikmZqYp5smZKSlGaWmmJubmBqagYAaVws1A==",
  // };
  // user= "65244f547c9ddbf6ed770556"
  const callbacks = {
    EndCall: () => setVideocall(false),
  };

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    getRtcConfiguration();
    return leaveChannel;
  }, []);

  useEffect(() => {
    (async () => {
      if (rtcConfig.appId) {
        console.log("Effect rtcConfig => ", rtcConfig);
        prepareClient();
      }
    })();
  }, [rtcConfig]);

  useEffect(() => {
    (async () => {
      if (localUser.uid) {
        console.log("Effect localUser => ", localUser);
        localUser.videoTrack.play("local-video-stream");
        await RtcClient.publish([localUser.audioTrack, localUser.videoTrack]);
      }
    })();
  }, [localUser]);

  useEffect(() => {
    console.log("remoteUser => ", remoteUser, remoteMediaType);
    if (remoteUser.uid) {
      if (remoteMediaType === "video" || remoteMediaType === "all") {
        remoteUser?.videoTrack.play(`${remoteUser.uid}`);
      }
      if (remoteMediaType === "audio" || remoteMediaType === "all") {
        remoteUser.audioTrack.play();
      }
    }
  }, [remoteUser, remoteMediaType]);

  function initUserObject() {
    return { uid: 0, videoTrack: null, audioTrack: null };
  }

  async function getRtcConfiguration() {
    try {
      const resp = await trackPromise(BookSlotServices.getRtcCofiguration(params?.slotId));
      const { data } = resp;

      if (data.success === false) {
        setVideocall(false);
      }
      if (data.success === true) {
        setVideocall(true);
        //set_rtcConfig(data.data);
      } else {
        set_pageError(data.message || "Error in fetching RTC configuration.");
      }
    } catch (err) {
      console.error("Fetch RTC configuration catch => ", err);
      if (err?.code !== "ERR_NETWORK") {
        const regexPattern = /^(?=.*[0-9])(?=.*[a-zA-Z])(?=.*[.,:])(?!.*\s).{8,}$/;
        const date = err?.response?.data?.message?.msgData;
        const letterRegex = /^[a-zA-Z]+$/;
        set_pageError(
          `${err?.response?.data?.message?.textMsg}.``${
            regexPattern.test(date) ? yearDateFormat(date) : letterRegex.test(date) ? date : ""
          }.`
        );
      } else {
        set_pageError(err?.message);
        console.log("error is printing")
      }
    }
  }

  async function prepareClient() {
    try {
      RtcClient.on("user-published", handlePublish);
      RtcClient.on("user-unpublished", handleUnpublish);
      const joinUid = await RtcClient.join(rtcConfig.appId, rtcConfig.channel, rtcConfig.token, rtcConfig.user);
      console.log("joinUid => ", joinUid);
      if (joinUid) {
        let localvideo = await AgoraRTC.createCameraVideoTrack({
          encoderConfig: "1080p",
        }).then((result)=>{
          console.log("result...........",result)
        }).catch((error)=>{
          console.log("error........" ,error)
        })
        let localaudio = await AgoraRTC.createMicrophoneAudioTrack();
        set_localUser({
          uid: joinUid,
          videoTrack: localvideo,
          audioTrack: localaudio,
        });
      }
    } catch (err) {
      console.error("Failed to prepare channel:", err);
      set_pageError(err.message || "Error in configuring the Video call");
    }
  }

  async function handlePublish(user, mediaType) {
    await RtcClient.subscribe(user, mediaType);
    console.log("subscribed to remote: user => ", user);
    set_remoteUser(user);
    set_remoteMediaType(mediaType);
  }

  function handleUnpublish() {
    set_remoteUser(initUserObject());
    set_remoteMediaType("");
  }

  function joinChannel(channelName, token) {
    try {
      // join channel
    } catch (error) {
      console.error("Failed to join channel:", error);
    }
  }

  const handleFormSubmit = async (values) => {
    const ratingData = { coach: "", topic: "", booking: params?.bookingId, rating: values?.rating };
    try {
      const resp = await trackPromise(coachRatingService.updateCoachRating(studentId, ratingData));
      const { data } = resp;
      if (data.success) {
        setIsopen(false);
      } else {
        set_pageError(data.message || "Registeration failed");
      }
    } catch (err) {
      console.error("Register error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err?.response?.data?.message || err?.message || "Register error");
    }
  };

  async function leaveChannel() {
    try {
      RtcClient.off("user-published", handlePublish);
      RtcClient.off("user-unpublished", handleUnpublish);
      const unpublishResp = await RtcClient.unpublish([localUser.audioTrack, localUser.videoTrack]);
      console.log("RtcClient.unpublish response => ", unpublishResp);
      await RtcClient.leave();
      clearTracks(localUser);
      set_localUser(initUserObject());
      set_remoteUser(initUserObject());
      set_remoteMediaType("");
      navigate(-1);
    } catch (err) {
      console.error("Error in leaving channel");
    }
  }

  function clearTracks(userObj) {
    userObj.videoTrack.stop();
    userObj.videoTrack.close();
    userObj.audioTrack.stop();
    userObj.audioTrack.close();
  }
console.log(RtcClient,"RtcClient........................");
  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Video Call</h4>
        <Button variant="clr-transparent" onClick={leaveChannel}>
          <FontAwesomeIcon icon={faArrowLeftLong} color={THEME.CLR_PRIMARY} title="Back" className="icon" />
        </Button>
      </div>

      {pageError ? <div className="error-msg">{pageError}</div> : <></>}

      <Row className="video-container">
        {/* <Col>
          <div id="local-video-stream" className="video-wrapper"></div>
        </Col>
        {remoteUser?.uid ? (
          <Col> 
            <div id={remoteUser.uid} className="video-wrapper"></div>
          </Col>
        ) : (
          <></>
        )} */}

        <div style={styles.container}>
          {videocall ? <AgoraUIKit rtcProps={rtcProps} callbacks={callbacks} /> : null}
        </div>
      </Row>

      <RatingPopUp
        isOpen={isOpen}
        pageError={pageError}
        set_pageError={set_pageError}
        handleFormSubmit={handleFormSubmit}
        setIsopen={setIsopen}
      />
      {/* <div>
        <Button variant="clr-primary" onClick={leaveChannel}>
          End Call
        </Button>
      </div> */}
    </Container>
  );
}

export default AppointmentCall;


// import AgoraRTC from "agora-rtc-sdk-ng";
// import { useEffect, useRef, useState } from "react";
// import { Button, Space, Typography, App } from "antd";
// import { showJoinedMessage, createMicrophoneAudioTrack, createCameraVideoTrack } from "./utils/utils";
// import { useUrlQuery, useUnMount } from "@/utils/hooks";
// import JoinForm from "./components/JoinForm";
// import AdvancedSetting from "./components/AdvancedSetting";
// import AgoraVideoPlayer from "./components/VideoPlayer";
// import Responsive from "./components/Responsive";
// const {
//   Title
// } = Typography;
// let client = null;
// let codec = 'vp8';
// function BasicVideoCall() {
//   const formRef = useRef();
//   useUrlQuery(formRef);
//   const [joined, setJoined] = useState(false);
//   const [videoTrack, setVideoTrack] = useState(null);
//   const [audioTrack, setAudioTrack] = useState(null);
//   const [remoteUsers, setRemoteUsers] = useState({});
//   const [localUid, setLocalUid] = useState("");
//   const {
//     message
//   } = App.useApp();
//   useEffect(() => {
//     initTracks();
//   }, []);
//   useUnMount(() => {
//     if (joined) {
//       leave();
//     }
//   });
//   const initTracks = async () => {
//     const tracks = await Promise.all([createMicrophoneAudioTrack(), createCameraVideoTrack()]);
//     setAudioTrack(tracks[0]);
//     setVideoTrack(tracks[1]);
//     return tracks;
//   };

//   /*
//    * Add the local use to a remote channel.
//    *
//    * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to add.
//    * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
//    */
//   const subscribe = async (user, mediaType) => {
//     await client.subscribe(user, mediaType);
//   };

//   /*
//    * Add a user who has subscribed to the live channel to the local interface.
//    *
//    * @param  {IAgoraRTCRemoteUser} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to add.
//    * @param {trackMediaType - The {@link https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/itrack.html#trackmediatype | media type} to add.
//    */
//   const handleUserPublished = async (user, mediaType) => {
//     const id = user.uid;
//     await subscribe(user, mediaType);
//     setRemoteUsers(prev => ({
//       ...prev,
//       [id]: user
//     }));
//   };

//   /*
//   * Remove the user specified from the channel in the local interface.
//   *
//   * @param  {string} user - The {@link  https://docs.agora.io/en/Voice/API%20Reference/web_ng/interfaces/iagorartcremoteuser.html| remote user} to remove.
//   */
//   const handleUserUnpublished = (user, mediaType) => {
//     if (mediaType === 'video') {
//       const id = user.uid;
//       setRemoteUsers(pre => {
//         delete pre[id];
//         return {
//           ...pre
//         };
//       });
//     }
//   };
//   const join = async () => {
//     try {
//       const options = formRef.current.getValue();
//       client = AgoraRTC.createClient({
//         mode: "rtc",
//         codec: codec
//       });
//       client.on("user-published", handleUserPublished);
//       client.on("user-unpublished", handleUserUnpublished);
//       let tracks = [audioTrack, videoTrack];
//       if (!audioTrack && !videoTrack) {
//         tracks = await initTracks();
//       }
//       // Join a channel
//       options.uid = await client.join(options.appId, options.channel, options.token || null, options.uid || null);
//       setLocalUid(options.uid);
//       await client.publish(tracks);
//       showJoinedMessage(message, options);
//       setJoined(true);
//     } catch (error) {
//       message.error(error.message);
//       console.error(error);
//     }
//   };
//   const leave = async () => {
//     audioTrack?.close();
//     setAudioTrack(null);
//     videoTrack?.close();
//     setVideoTrack(null);
//     setRemoteUsers({});
//     await client?.leave();
//     // leave the channel
//     setJoined(false);
//     const msg = "client leaves channel success!";
//     message.success(msg);
//   };
//   const onCodecChange = val => {
//     codec = val;
//   };
//   const onProfileChange = async val => {
//     await videoTrack.setEncoderConfiguration(val);
//   };
//   return <div className="padding-20">
//     <JoinForm ref={formRef}></JoinForm>
//     <Responsive style={{
//       marginTop: "10px"
//     }}>
//       <Space>
//         <Button type="primary" onClick={join} disabled={joined}>Join</Button>
//         <Button onClick={leave} disabled={!joined}>Leave</Button>
//       </Space>
//       <AdvancedSetting className="responsive-ml" audioTrack={audioTrack} videoTrack={videoTrack} onCodecChange={onCodecChange} onProfileChange={onProfileChange}></AdvancedSetting>
//     </Responsive>
//     {joined ? <div className="mt-10">
//       <Title level={4}>Local User</Title>
//       <div className="mt-10 mb-10">uid: {localUid}</div>
//       <AgoraVideoPlayer videoTrack={videoTrack}></AgoraVideoPlayer>
//     </div> : null}
//     {Object.keys(remoteUsers).length ? <div className="mt-10">
//         <Title level={4}>Remote Users</Title>
//         {Object.keys(remoteUsers).map(id => <AgoraVideoPlayer videoTrack={remoteUsers[id]?.videoTrack} audioTrack={remoteUsers[id]?.audioTrack} text={`uid: ${id}`} key={id}></AgoraVideoPlayer>)}
//       </div> : null}
//   </div>;
// }
// export default BasicVideoCall;


import React from "react";
import Modal from "react-bootstrap/Modal";
import { Button, Row } from "react-bootstrap";
import { formatDate, formatTime } from "../../utilities/functions";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faCalendar, faClock } from "@fortawesome/free-regular-svg-icons";

function EventModal({
  event,
  isOpen,
  onClose,
  deleteEvent,
  confirmEvent,
  setConfirmEvent,
  slotDetails,
  bookingId,
  loading,
}) {
  return (
    <Modal show={isOpen} onHide={onClose} centered>
      {confirmEvent === false && (
        <>
          <Modal.Header closeButton>
            <Modal.Title>Slot Details</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "110px" }}>
            {!bookingId ? (
              <>
                <Row className="mx-1 fw-bold">Unbooked Slot</Row>
                <div className="d-flex justify-content-start flex-row mx-2">
                  <div>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  <div className="mx-2">{formatDate(event?.fromDateTime)}</div>
                </div>
                <div className="d-flex justify-content-start flex-row mx-2">
                  <div>
                    <FontAwesomeIcon icon={faClock} />
                  </div>
                  <div className="mx-2">
                    {formatTime(event?.fromDateTime)} - {formatTime(event?.toDateTime)}
                  </div>
                </div>
              </>
            ) : loading && (slotDetails === undefined || slotDetails === null) ? (
              // <PageLoader />
              <div className="text-center">
                <h5>Loading...</h5>
              </div>
            ) : (
              <>
                <Row className="mx-1 fw-bold">
                  {`${slotDetails?.topic?.topicName} | ${slotDetails?.student?.firstName} ${slotDetails?.student?.lastName}`}
                </Row>
                <div className="d-flex justify-content-start flex-row mx-2">
                  <div>
                    <FontAwesomeIcon icon={faCalendar} />
                  </div>
                  <div className="mx-2">{formatDate(event?.fromDateTime)}</div>
                </div>
                <div className="d-flex justify-content-start flex-row mx-2">
                  <div>
                    <FontAwesomeIcon icon={faClock} />
                  </div>
                  <div className="mx-2">
                    {formatTime(event?.fromDateTime)} - {formatTime(event?.toDateTime)}
                  </div>
                </div>
              </>
            )}
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="clr-transparent">
              Cancel
            </Button>
            <Button variant="clr-primary" onClick={() => setConfirmEvent(true)} disabled={event.slotStatus}>
              Delete
            </Button>
          </Modal.Footer>
        </>
      )}

      {confirmEvent && (
        <>
          <Modal.Header closeButton>
            <Modal.Title> Delete Slot</Modal.Title>
          </Modal.Header>
          <Modal.Body style={{ height: "110px" }} className="d-flex flex-column justify-content-center text-center">
            <div>Are you sure you want to delete this slot?</div>
          </Modal.Body>
          <Modal.Footer>
            <Button onClick={onClose} variant="clr-transparent">
              Cancel
            </Button>
            <Button variant="clr-primary" onClick={deleteEvent} disabled={event.slotStatus}>
              Delete
            </Button>
          </Modal.Footer>
        </>
      )}
    </Modal>
  );
}

export default EventModal;

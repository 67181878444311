import { useState } from "react";
import { Link, useNavigate } from "react-router-dom";
import { Container, Row, Col, Button, Form, InputGroup } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEye, faEyeSlash } from "@fortawesome/free-solid-svg-icons";
import { setLocalData, clearAppData, getUserProfile } from "../../stores/store";
import { setToken } from "../../stores/authSlice";
import UserService from "../../services/user.service";
import "./Login.css";
import RegisterAcknowledge from "../Register/RegisterAcknowledge";
import TermsCondition from "../Register/TermsCondition";

function ForgotPassword() {
  const navigate = useNavigate();
  const dispatch = useDispatch();
  const [formObject, set_formObject] = useState(getFormObject());
  const [formError, set_formError] = useState("");
  const [showPassword, setShowPassword] = useState(false);

  const [showAcknowledge, setShowAcknowledge] = useState(1);

  const nextScreen = (screenNumber) => {
    setShowAcknowledge(screenNumber);
  };

  function getFormObject() {
    return {
      username: "",
    };
  }

  const FormSchema = Yup.object().shape({
    username: Yup.string().required("Username is Required"),
  });

  function handleInputChanged(event) {
    set_formObject({ ...formObject, [event.target.name]: event.target.value });
  }

  async function handleFormSubmit(values) {
    try {
      const resp = await trackPromise(UserService.forgotPassword(values));
      const { data } = resp;
      if (data.success) {
        toast.success(data.data);
        navigate(-1);
      } else {
        set_formError(data.message || "Forgot Password Failed");
      }
    } catch (err) {
      console.error("Forgot password error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_formError(err.response.data.message);
      } else {
        set_formError(err.message);
        toast.error(err.message || "Forgot Password error");
      }
    }
  }

  return (
    <Formik initialValues={formObject} validationSchema={FormSchema} onSubmit={handleFormSubmit}>
      {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
        <Container fluid={true} className="login-container">
          <Row className="">
            <Col lg={{ span: 6, offset: 3 }}>
              {showAcknowledge == 1 ? (
                <div className="login-wrap">
                  <div className="login-header">
                    <h3>Forgot Password</h3>
                    <h6>Coach & Grow App</h6>
                  </div>

                  <Form noValidate onSubmit={handleSubmit}>
                    <Form.Group className="frm-group" controlId="formUsername">
                      <Form.Label>Username</Form.Label>
                      <Form.Control
                        type="text"
                        placeholder="Enter Username"
                        name="username"
                        value={values.username}
                        onChange={handleChange}
                        isInvalid={touched.username && errors.username}
                      />
                      <Form.Control.Feedback type="invalid">{errors.username}</Form.Control.Feedback>
                    </Form.Group>

                    <div className="d-flex bottom-text">
                      <Link to="/login" className="mb-2 action-lnk">
                        Login?
                      </Link>
                    </div>

                    <Row>
                      <Col lg={{ span: 10, offset: 1 }}>
                        <div className="d-flex">
                          <Button
                            variant="clr-primary"
                            type="submit"
                            disabled={isSubmitting}
                            className="mt-2 mb-2 login-btn"
                          >
                            Reset Password
                          </Button>
                        </div>
                      </Col>
                    </Row>

                    <div className="bottom-text">
                      By clicking Register, you agree to our{" "}
                      <Link
                        onClick={() => {
                          nextScreen(2);
                        }}
                        className="action-lnk"
                      >
                        Terms & Conditions
                      </Link>
                      .
                    </div>
                    {formError ? <div className="text-danger">{formError}</div> : <></>}
                  </Form>
                </div>
              ) : null}
            </Col>
          </Row>
          {showAcknowledge == 2 ? <TermsCondition nextScreen={nextScreen} clickFlow={true} /> : null}
        </Container>
      )}
    </Formik>
  );
}

export default ForgotPassword;

import { useState, useEffect, useMemo, useRef, useCallback } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { MoodleCoursesService } from "../../services";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";

function MoodleCourseList({ pageTitle }) {
  const dispatch = useDispatch();
  const [courseList, set_CourseList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchMoodleCourses();
  }, []);

  async function fetchMoodleCourses() {
    try {
      const resp = await trackPromise(MoodleCoursesService.getAll());
      const { data } = resp;
      if (data.success) {
        set_CourseList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching topics");
      }
    } catch (err) {
      console.error("Fetch topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function syncMoodle() {
    try {
      const resp = await trackPromise(MoodleCoursesService.syncCouses());
      const { data } = resp;
      if (data.success) {
        toast.success("Data Synced");
        fetchMoodleCourses();
      } else {
        set_pageError(data.message || "Error in Syncing");
      }
    } catch (err) {
      console.error("Fetch topics catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  const columnDefs = [
    {
      headerName: "Display Name",
      field: "displayname",
      // width: 200,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Full Name",
      field: "fullname",
      // width: 200,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Short Name",
      field: "shortname",
      // width: 200,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Summary",
      field: "summary",
      // width: 700,
      suppressMovable: true,
      filter: true,
      tooltipField: "summary",
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
  ];
  const defaultColDef = useMemo(() => ({
    sortable: true,
    //flex: 1,
  }));
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading d-flex flex-lg-row flex-column">
        <h4 className="col hd-txt">Coaching Resource List</h4>
        <Button onClick={syncMoodle} variant="clr-primary" className="btn-add">
          Sync Resources
        </Button>
      </div>

      <Row className="mb-4">
        <Col lg="12">
          <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
            <AgGridReact
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={courseList}
              pagination={true}
              paginationPageSize={10}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
          {/* <div className="page-size-wrap">
            <div className="page-size">
              Records Per Page:
              <select onChange={onPageSizeChanged} id="page-size">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div> */}
        </Col>
      </Row>
    </Container>
  );
}

export default MoodleCourseList;

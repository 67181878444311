// Import the functions you need from the SDKs you need
import { initializeApp } from "firebase/app";
import { getMessaging, getToken } from "firebase/messaging";

const firebaseConfig = {
  apiKey: "AIzaSyAf34iIQkFQ-uTlgRQY-1J6q_GQy4--zz4",
  authDomain: "fir-coachandgrowandroid.firebaseapp.com",
  projectId: "fir-coachandgrowandroid",
  storageBucket: "fir-coachandgrowandroid.appspot.com",
  messagingSenderId: "1014128701024",
  appId: "1:1014128701024:web:6be8a7cd5b1f5c4e3a1d05",
  measurementId: "G-YMF1Z1WE10",
};

// Initialize Firebase
const app = initializeApp(firebaseConfig);
export const fcmMessaging = getMessaging(app);
const VAPID_KEY =
  "BOKqQugyb2Vsyh69HfiTA34ntWC1-UzUFX2R7svPm65p1AYJ7mFuJFaZhSNi3llGTnSqjFA_XEUY7mhZQSNWHI4";

export const requestNotificationPermission = async () => {
  try{
  const permission = await Notification.requestPermission();
  if (permission === "granted") {
    const token = await getToken(fcmMessaging, {
      vapidKey: VAPID_KEY,
    });
    console.log("token",token);
    return token;
  }
}
catch(error){
  window.location.reload();}
};

import { AgGridReact } from "ag-grid-react";
import React from "react";
import { useState } from "react";
import { useMemo } from "react";
import { useRef } from "react";
import { useDispatch } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { useCallback } from "react";
import { Button, Col, Container, Row } from "react-bootstrap";
import { trackPromise } from "react-promise-tracker";
import { Link } from "react-router-dom";
import { useEffect } from "react";
import { EmailTemplateService } from "../../services";
import { faPencil, faTrashCan, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { toast } from "react-toastify";
import DeleteListModal from "../CustomComponent/DeleteListModal";

const EmailTemplateList = ({ pageTitle }) => {
  const dispatch = useDispatch();
  const [templateLisData, set_TemplateListData] = useState([]);
  const [typeCode, set_typeCode] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchOrgListData();
  }, []);

  async function fetchOrgListData() {
    try {
      const resp = await trackPromise(EmailTemplateService.getOrgEmailTemplates());
      const { data } = resp;

      if (data.success) {
        set_TemplateListData(data.data);
        //set_typeCode(data.data.emailTypeCode);
      } else {
        set_pageError(data.message || "Error in fetching Email List data");
      }
    } catch (err) {
      console.error("Fetch Email List catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function deleteEmailTemplate(id) {
    try {
      const resp = await trackPromise(EmailTemplateService.deleteTemplate(id));
      const { data } = resp;

      if (data?.success === true) {
        toast.success("EmailTemplate Deleted Successfully.");
        fetchOrgListData();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  async function deleteOrg(id) {
    deleteEmailTemplate(id);
    closeDeleteModal();
  }

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
  };

  const columnDefs = [
    {
      headerName: "Email Type Code",
      field: "emailTypeCode",
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Subject",
      field: "emailSubject",
      // tooltipField: "email",
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Template For",
      field: "emailTriggerFor",
      tooltipField: "emailTriggerFor",
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      field: "action",
      suppressMovable: true,
      headerName: "Action",
      // width: 300,
      sortable: false,
      filter: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        return (
          <>
            <Link to={`../emailTemplateList/edit/${params?.data?.id}`}>
              <FontAwesomeIcon icon={faPencil} color="#3d3e45" title="Edit" />
            </Link>
            <Link className="grid_action" to="">
              <FontAwesomeIcon
                icon={faTrashCan}
                color="#3d3e45"
                title="Delete"
                // className={iconClass}
                onClick={() => {
                  openDeleteModal(params?.data?.id);
                }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    filter: true,
    sortable: true,
    // flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading email-telplate-lid-div d-flex flex-sm-row flex-column">
        <h4 className="col hd-txt">Email Template List</h4>
        <Button as={Link} to={"../emailTemplateList/create"} variant="clr-primary" className="btn-add">
          Update Email Template
        </Button>
      </div>

      <Row className="mb-4">
        <Col lg="12">
          {templateLisData?.length ? (
            <>
              <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
                <AgGridReact
                  id="myGrid"
                  className="myGrid"
                  ref={gridRef}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowData={templateLisData}
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                  onFirstDataRendered={onFirstDataRendered}
                />
              </div>
              <div className="page-size-wrap">
                <div className="page-size">
                  Records Per Page:
                  <select onChange={onPageSizeChanged} id="page-size">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </div>
              </div>
            </>
          ) : (
            <div>No Email Templates Found</div>
          )}
        </Col>
      </Row>
      {selectedItem && (
        <DeleteListModal
          id={selectedItem}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          deleteItem={deleteOrg}
          pageError={pageError}
        />
      )}
    </Container>
  );
};

export default EmailTemplateList;

import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link } from "react-router-dom";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { ChangeRequestService } from "../../services";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import DeleteListModal from "../CustomComponent/DeleteListModal";
import { Rating } from "react-simple-star-rating";

import { faPencil, faTrashCan } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";

function CoachChangeRequest({ pageTitle }) {
  const dispatch = useDispatch();

  const [pageError, set_pageError] = useState("");
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);
  const [setError, set_Error] = useState();
  const [changeRequest, set_changeRequest] = useState([]);
  const gridRef = useRef();

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchRequest();
  }, []);

  async function fetchRequest() {
    try {
      const resp = await trackPromise(ChangeRequestService.getChangeRequestByStudent());
      const { data } = resp;
      if (data.success) {
        //let coachList = data?.data?.filter((itm) => !itm?.isDeleted);
        set_changeRequest(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Request");
      }
    } catch (err) {
      console.error("Fetch Request catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function deleteCoachRequest(id) {
    try {
      const resp = await trackPromise(ChangeRequestService.deleteRequest(id));
      const { data } = resp;

      if (data?.success === true) {
        toast.success("Request For Coach Chnage Deleted Successfully.");
        fetchRequest();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  async function deleteOrg(id) {
    deleteCoachRequest(id);
    closeDeleteModal();
  }

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
  };

  const columnDefs = [
    {
      headerName: "Coach Name",
      field: "coach",

      suppressMovable: true,
      valueGetter: getFullName,
    },

    { headerName: "Reason", field: "requestReason", suppressMovable: true },

    {
      headerName: "Coach Rating",
      field: "coachRating",

      // width: 150,

      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const coachRating = params.data.coachRating;

        return (
          <div className="disabled-rating">
            <Rating initialValue={coachRating} size={25} fillColor="#B18C93" />
          </div>
        );
      },
    },
    {
      headerName: "Status",
      field: "requestStatus",
      suppressMovable: true,
      cellStyle: { textAlign: "center", textTransform: "capitalize" },
    },
    {
      field: "action",
      suppressMovable: true,
      headerName: "Action",
      sortable: false,
      filter: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const requestStatus = params.data.requestStatus;

        // Check if the request status is 'awaited' before rendering the buttons
        if (requestStatus === "awaited") {
          return (
            <>
              <Link to={`../coachChangeRequest/edit/${params?.data?.id}`}>
                <FontAwesomeIcon icon={faPencil} color="#3d3e45" title="Edit" />
              </Link>
              <Link className="grid_action" to="">
                <FontAwesomeIcon
                  icon={faTrashCan}
                  color="#3d3e45"
                  title="Delete"
                  onClick={() => {
                    openDeleteModal(params?.data?.id);
                  }}
                />
              </Link>
            </>
          );
        } else {
          return null;
        }
      },
    },
  ];

  function getFullName(params) {
    const { firstName, lastName } = params.data[params.colDef.field];
    return `${firstName} ${lastName}`;
  }

  const defaultColDef = useMemo(() => ({
    sortable: true,
    // cellStyle: () => ({
    //   display: "flex",
    //   alignItems: "center",
    //   justifyContent: "center",
    // }),
    flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);
  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Coach Change Request</h4>

        <Button as={Link} to="../coachChangeRequest/create" variant="clr-primary" className="btn-add">
          Add New Request
        </Button>
      </div>
      <Row className="mb-4">
        <Col>
          <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
            <AgGridReact
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={changeRequest}
              pagination={true}
              paginationPageSize={10}
              onFirstDataRendered={onFirstDataRendered}
              onPageSizeChanged={onPageSizeChanged}
              ref={gridRef}
            />
          </div>
        </Col>
      </Row>
      {selectedItem && (
        <DeleteListModal
          id={selectedItem}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          deleteItem={deleteOrg}
          pageError={setError}
        />
      )}
    </Container>
  );
}

export default CoachChangeRequest;

import httpClient from "../httpClient";
const baseSlug = "/reports";
const superSlug = baseSlug + "/super";
const adminSlug = baseSlug + "/admin";

class httpService {
  getOrgHours(data) {
    return httpClient.post(`${superSlug}/orgAnalysis`, data);
  }

  getCoachHours(data) {
    return httpClient.post(`${superSlug}/coachAnalysis`, data);
  }
  getTopicSessions(data) {
    return httpClient.post(`${superSlug}/topicAnalysis`, data);
  }

  getorgRenewalData() {
    return httpClient.get(`${superSlug}/orgRenewalData`);
  }

  getOrgStudentUsage(data) {
    return httpClient.post(`${adminSlug}/studentUsage`, data);
  }

  getTopicHours() {
    return httpClient.get(`${adminSlug}/topicHours`);
  }

  getStudentCount() {
    return httpClient.get(`${adminSlug}/studentCount`);
  }
  getOrgCoachHours(data) {
    return httpClient.post(`${adminSlug}/coachHours`, data);
  }
  getTopic() {
    return httpClient.get(`${adminSlug}/orgTopics`);
  }
  getOrgTopicHours(data) {
    return httpClient.post(`${adminSlug}/topicAnalysis`, data);
  }
  getorgCoach() {
    return httpClient.get(`${adminSlug}/orgCoaches`);
  }
}

export default new httpService();

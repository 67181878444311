import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Button } from "react-bootstrap";
import { useDispatch } from "react-redux";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { toast } from "react-toastify";
import { trackPromise } from "react-promise-tracker";
import { faPencil, faTrashCan, faTimes, faCheck, faEye } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { setRouteData } from "../../stores/appSlice";
import { OrgTypeService } from "../../services";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import DeleteListModal from "../CustomComponent/DeleteListModal";

function OrgTypeList({ pageTitle }) {
  const dispatch = useDispatch();
  const [orgTypeList, set_orgTypeList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchOrgTypes();
  }, []);

  async function fetchOrgTypes() {
    try {
      const resp = await trackPromise(OrgTypeService.getAll());
      const { data } = resp;
      let orgTypeList = data?.data?.filter((itm) => !itm?.isDeleted);

      set_orgTypeList(orgTypeList);
    } catch (err) {
      console.error("Fetch Organisation types catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function deleteOrgTypes(id) {
    try {
      const resp = await trackPromise(OrgTypeService.delete(id));
      const { data } = resp;

      if (data?.success === true) {
        toast.success("Organization Type deleted Successfully.");

        fetchOrgTypes();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  async function deleteOrg(id) {
    deleteOrgTypes(id);
    closeDeleteModal();
  }

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
  };

  const columnDefs = [
    {
      headerName: "Organisation Type Name",
      field: "typeName",
      // width: 300,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Organisation Type Code",
      field: "typeCode",
      // width: 400,
      suppressMovable: true,
      filter: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },

    {
      field: "action",
      suppressMovable: true,
      headerName: "Action",
      // width: 300,
      sortable: false,
      filter: false,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        return (
          <>
            <Link className="grid_action " to={`/org/type/view/${params.data.id}`}>
              <FontAwesomeIcon
                icon={faEye}
                color="#3d3e45"
                // className={iconClass}
                title="View"
              />
            </Link>

            <Link className="grid_action" to={`/org/type/edit/${params.data.id}`}>
              <FontAwesomeIcon
                icon={faPencil}
                color="#3d3e45"
                title="Edit"
                // className={iconClass}
              />
            </Link>
            <Link className="grid_action" to="">
              <FontAwesomeIcon
                icon={faTrashCan}
                color="#3d3e45"
                title="Delete"
                onClick={() => {
                  openDeleteModal(params?.data?.id);
                }}
              />
            </Link>
          </>
        );
      },
    },
  ];

  const defaultColDef = useMemo(() => ({
    filter: true,
    sortable: true,
    // flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Organisation Type List</h4>
        <Button as={Link} to="create" variant="clr-primary" className="btn-add">
          Add New
        </Button>
      </div>

      <Row className="">
        <Col lg="12">
          <div className="ag-theme-alpine" style={{ height: "480px", width: "100%" }}>
            <AgGridReact
              id="myGrid"
              className="myGrid"
              ref={gridRef}
              columnDefs={columnDefs}
              defaultColDef={defaultColDef}
              rowData={orgTypeList}
              pagination={true}
              paginationPageSize={10}
              paginationNumberFormatter={paginationNumberFormatter}
              onFirstDataRendered={onFirstDataRendered}
            />
          </div>
          <div className="page-size-wrap">
            <div className="page-size">
              Records Per Page:
              <select onChange={onPageSizeChanged} id="page-size">
                <option value="10">10</option>
                <option value="25">25</option>
                <option value="50">50</option>
              </select>
            </div>
          </div>
        </Col>
      </Row>
      {selectedItem && (
        <DeleteListModal
          id={selectedItem}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          deleteItem={deleteOrg}
          pageError={pageError}
        />
      )}
    </Container>
  );
}

export default OrgTypeList;

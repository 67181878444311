import React from "react";
import { Navigate } from "react-router-dom";

// Layouts
import AuthLayout from "./layouts/AuthLayout";
import ProtectedLayout from "./layouts/ProtectedLayout";

// Components
import Login from "./components/Login/Login";
import Register from "./components/Register/Register";
import CoachRegister from "./components/Register/CoachRegister";
import CreatePassword from "./components/Register/CreatePassword";
import Dashboard from "./components/Dashboard/Dashboard";
import OrganisationCreate from "./components/Organisation/Organisation.create";
import AdminCreate from "./components/Admin/Admin.create";
import AdminList from "./components/Admin/Admin.list";
import StudentCreate from "./components/Student/Student.create";
import StudentList from "./components/Student/Student.list";
import CoachList from "./components/Coach/Coach.list";
import CoachApprove from "./components/Coach/CoachApprove";
import OrganisationList from "./components/Organisation/Organisation.list";
import CoachEdit from "./components/Coach/Coach.edit";
import CoachCategoryList from "./components/Coach/CoachCategory.list";
import CoachCategroyCreate from "./components/Coach/CoachCategroy.create";
import TopicList from "./components/Topics/Topics.list";
import TopicsCreate from "./components/Topics/Topics.create";
import StudentTypeList from "./components/Student/StudentType.list";
import StudentTypeCreate from "./components/Student/StudentType.create";
import OrgTypeList from "./components/Organisation/OrgType.list";
import OrgTypeCreate from "./components/Organisation/OrgType.create";
import ProfileCreate from "./components/Profile/Profile.create";
import ChangePassword from "./components/ChangePassword/ChangePassword";
import ForgotPassword from "./components/Login/ForgotPassword";
import ProfileStudentCreate from "./components/Profile/ProfileStudent.create";
import CalenderList from "./components/Calender/Calender.list";
import SlotCreate from "./components/Calender/Slot.create";
import BulkUpload from "./components/Organisation/BulkUpload.create";
import CoachProfileUpdate from "./components/Coach/Coach.Profile.update";
import AppointmentWizard from "./components/AppointmentWizard/AppointmentWizard";
import MyCoursesList from "./components/ManageCourses/ManageCourses.list";
import AppointmentList from "./components/ManageCourses/Appointment.list";
import DashboardCoach from "./components/Dashboard/DashboardCoach";
import DashboardAdmin from "./components/Dashboard/DashboardAdmin";
import AppointmentDetails from "./components/ManageCourses/Appointment.detail";
import AppointmentCall from "./components/VideoCall/Appointment.call";
import AppointmentCall02 from "./components/VideoCall/Appointment.call.02";
import DashboardStudent from "./components/Dashboard/DashboardStudent";
import CoachAppointment from "./components/Coach/CoachAppointment.list";
import EmailTrigger from "./components/EmailTrigger/EmailTrigger";
import StudentProgress from "./components/Coach/Student.progress.list";
import StudentAppointmentList from "./components/Coach/StudentAppointment.list";
import StudentAppointmentDetails from "./components/Coach/StudentAppointment.detail";
import ViewMaterial from "./components/Material/ViewMaterial";
import ReviewAgreement from "./components/Agreement/ReviewAgreement";
import AssignMaterial from "./components/Material/AssignMaterial";
import SuperAdminReport from "./components/Reports/SuperAdminReport";
import AdminReport from "./components/Reports/AdminReport";
import MoodleCourseList from "./components/Moodle/MoodleCourse.list";
import ViewOrganisation from "./components/Organisation/ViewOrganisation";
import ViewAdmin from "./components/Admin/ViewAdmin";
import ViewCoaches from "./components/Coach/ViewCoaches";
import ViewTopics from "./components/Topics/ViewTopics";
import ViewStudent from "./components/Student/ViewStudent";
import OrgTypeView from "./components/Organisation/OrgTypeView";
import StudentTypeView from "./components/Student/StudentTypeView";
import EmailTemplateList from "./components/EmailTrigger/EmailTemplateList";
//import StudentAndCoachAgreement from "./components/Agreement/StudentAndCoachAgreement";
import Subscription from "./components/Subscription/subscription";
import AghoraVideo from "./components/VideoCall/AghoraVideo";
import StudentAgreement from "./components/Agreement/StudentAgreement";
import AnotherAppointmentWizard from "./components/AnotherAppointment/AnotherTopicSlotSelection";
import CoachChangeRequest from "./components/Coach Change Management/CoachChangeRequest";
import AddNewRequest from "./components/Coach Change Management/NewCoachRequest";
import ChangeRequestList from "./components/Coach Change Management/ManageRequest";
import RequestApprove from "./components/Coach Change Management/RequestApprove";
import { ViewCoachCategory } from "./components/Coach/ViewCoachCategory";
import ChatPage from "./components/Chat/Chat";
import ChatCoach from "./components/Chat/ChatCoach";
import Basics from "./components/VideoCall/testingVideo";
import StreamChatList from "./components/Chat/StreamChatList";

const USER_ROLES = { superadmin: "superadmin", admin: "admin", coach: "coach", student: "student" };

const AuthRoutes = [
  {
    path: "/",
    element: <AuthLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="/login" />,
      },
      {
        path: "/login",
        element: <Login />,
      },
      // {
      //   path: "/register",
      //   element: <Register />,
      // },
      {
        path: "/registerCoach",
        element: <CoachRegister />,
      },
      {
        path: "/createPassword/:token",
        element: <CreatePassword />,
      },
      {
        path: "/forgot-password",
        element: <ForgotPassword />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="/login" replace />,
  },
  {
    path: "*",
    element: <Navigate to="/login" replace />,
  },
];

const SuperadminRoutes = [
  {
    path: "/",
    element: <ProtectedLayout />,
    children: [
      {
        path: "/",
        element: <Navigate to="dashboard" />,
      },
      {
        path: "dashboard",
        element: <Dashboard />,
        id: "dashboard",
        loader: () => ({ pageTitle: "Dashboard" }),
      },
      {
        path: "organisation/edit/:id",
        element: <OrganisationCreate pageTitle="Manage Organisations" />,
      },
      { path: "organisation/view/:id", element: <ViewOrganisation pageTitle="Manage Organisations" /> },
      {
        path: "organisation/create",
        element: <OrganisationCreate pageTitle="Manage Organisations" />,
      },
      {
        path: "organisation",
        element: <OrganisationList pageTitle="Manage Organisations" />,
      },
      {
        path: "viewreports",
        element: <SuperAdminReport pageTitle="View Reports" />,
      },
      {
        path: "moodleResource",
        element: <MoodleCourseList pageTitle="Coaching Resources" />,
      },
      {
        path: "org/type",
        element: <OrgTypeList pageTitle="Organisation Type" />,
      },
      {
        path: "org/type/create",
        element: <OrgTypeCreate pageTitle="Organisation Type" />,
      },
      {
        path: "org/type/edit/:id",
        element: <OrgTypeCreate pageTitle="Organisation Type" />,
      },
      {
        path: "org/type/view/:id",
        element: <OrgTypeView pageTitle="Organisation Type" />,
      },
      {
        path: "admin/edit/:id",
        element: <AdminCreate pageTitle="Manage Admins" />,
      },
      { path: "admin/view/:id", element: <ViewAdmin pageTitle="Manage Admins" /> },
      {
        path: "admin/create",
        element: <AdminCreate pageTitle="Manage Admins" />,
      },
      {
        path: "admin",
        element: <AdminList pageTitle="Manage Admins" />,
      },
      {
        path: "coach",
        element: <CoachList pageTitle="Manage Coaches" />,
      },
      {
        path: "coachCategory",
        element: <CoachCategoryList pageTitle="Coach Category" />,
      },
      {
        path: "coachCategory/create",
        element: <CoachCategroyCreate pageTitle="Coach Category" />,
      },
      {
        path: "coachCategory/edit/:id",
        element: <CoachCategroyCreate pageTitle="Coach Category" />,
      },

      {
        path: "coachCategory/view/:id",
        element: <ViewCoachCategory pageTitle="Coach Category" />,
      },

      {
        path: "topics",
        element: <TopicList pageTitle="Manage Topics" />,
      },
      {
        path: "coachee/type",
        element: <StudentTypeList pageTitle="Coachee Type" />,
      },

      {
        path: "topics/create",
        element: <TopicsCreate pageTitle="Manage Topics" />,
      },
      {
        path: "topics/edit/:id",
        element: <TopicsCreate pageTitle="Manage Topics" />,
      },
      {
        path: "topics/view/:id",
        element: <ViewTopics pageTitle="Manage Topics" />,
      },
      {
        path: "coachee/type/create",
        element: <StudentTypeCreate pageTitle="Coachee Type" />,
      },
      {
        path: "coachee/type/edit/:id",
        element: <StudentTypeCreate pageTitle="Coachee Type" />,
      },
      {
        path: "coachee/type/view/:id",
        element: <StudentTypeView pageTitle="Coachee Type" />,
      },
      {
        path: "coach/edit/:id",
        element: <CoachEdit pageTitle="Manage Coaches" />,
      },
      {
        path: "coach/view/:id",
        element: <ViewCoaches pageTitle="Manage Coaches" />,
      },
      {
        path: "coach/approve/:id",
        element: <CoachApprove pageTitle="Manage Coaches" />,
      },
      {
        path: "profile/edit",
        element: <ProfileCreate pageTitle="Manage Profile" />,
      },
      {
        path: "changePassword",
        element: <ChangePassword pageTitle="Change Password" />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to="dashboard" replace />,
  },
  {
    path: "*",
    element: <Navigate to="dashboard" replace />,
  },
];

const getAdminRoutes = (slug) => [
  {
    path: `/${slug}`,
    element: <ProtectedLayout />,
    children: [
      {
        path: `/${slug}`,
        element: <Navigate to={`/${slug}/dashboard`} />,
      },
      {
        path: `/${slug}/dashboard`,
        element: <DashboardAdmin />,
        id: "dashboard",
        loader: () => ({ pageTitle: "Dashboard" }),
      },
      {
        path: "viewreports",
        element: <AdminReport pageTitle="View Reports" />,
      },
      {
        path: `/${slug}/profile/edit`,
        element: <ProfileCreate pageTitle="Manage Profile" />,
      },
      {
        path: `/${slug}/changePassword`,
        element: <ChangePassword pageTitle="Change Password" />,
      },
      {
        path: `/${slug}/coachee/create`,
        element: <StudentCreate pageTitle="Coachee Users" />,
      },
      {
        path: `/${slug}/coachee/edit/:id`,
        element: <StudentCreate pageTitle="Coachee Users" />,
      },
      {
        path: `/${slug}/coachee/view/:id`,
        element: <ViewStudent pageTitle="Coachee Users" />,
      },
      {
        path: `/${slug}/coachee`,
        element: <StudentList pageTitle="Coachee Users" />,
      },
      {
        path: `/${slug}/bulkupload`,
        element: <BulkUpload pageTitle="Bulk Upload" />,
      },
      {
        path: `/${slug}/emailTemplateList/create`,
        element: <EmailTrigger pageTitle="Email Templates" />,
      },

      {
        path: `/${slug}/emailTemplateList/edit/:id`,
        element: <EmailTrigger pageTitle="Email Trigger" />,
      },
      { path: `/${slug}/emailTemplateList`, element: <EmailTemplateList pageTitle="Email Templates" /> },
      {
        path: `/${slug}/subscription`,
        element: <Subscription pageTitle="Subscription" />,
      },

      {
        path: `/${slug}/manageRequest`,
        element: <ChangeRequestList pageTitle="Manage Request" />,
      },
      {
        path: "manageRequest/approve/:id",
        element: <RequestApprove pageTitle="Manage Request" />,
      },
    ],
  },
  {
    path: "/",
    element: <Navigate to={`/${slug}/dashboard`} replace />,
  },
  {
    path: "*",
    element: <Navigate to={`/${slug}/dashboard`} replace />,
  },
];

const getCoachRotes = (isTermsAccepted, agreementAccepted) =>
  // agreementAccepted === false
  //   ? [
  //       {
  //         path: "",
  //         element: <ProtectedLayout />,
  //         children: [
  //           {
  //             path: "",
  //             element: <Navigate to={"agreement"} />,
  //           },
  //           {
  //             path: "agreement",
  //             element: <StudentAndCoachAgreement />,
  //           },
  //         ],
  //       },
  //       {
  //         path: "/",
  //         element: <Navigate to={"agreement"} replace />,
  //       },
  //       {
  //         path: "*",
  //         element: <Navigate to={"agreement"} replace />,
  //       },
  //     ]
  [
    {
      path: "",
      element: <ProtectedLayout />,
      children: [
        {
          path: "",
          element: <Navigate to="/dashboard" />,
        },
        {
          path: "dashboard",
          element: <DashboardCoach />,
          id: "dashboard",
          loader: () => ({ pageTitle: "Dashboard" }),
        },
        {
          path: "testingvideo",
          element: <Basics />,
        },
        {
          path: "profile/edit",
          element: <CoachProfileUpdate pageTitle="Manage Profile" />,
        },
        {
          path: "changePassword",
          element: <ChangePassword pageTitle="Change Password" />,
        },
        {
          path: "calendarEvent",
          element: <CalenderList pageTitle="Coaching Slots" />,
        },

        {
          path: "calendarEvent/create",
          element: <SlotCreate pageTitle="Coaching Slots" />,
        },

        {
          path: "/assignresource",
          element: <AssignMaterial pageTitle="Assign Resource" />,
        },
        {
          path: "coachAppointment",
          element: <CoachAppointment pageTitle="Upcoming Appointments" />,
        },
        {
          path: "coachAppointment/:bookingId/:slotId/call",
          element: <AppointmentCall pageTitle="Manage Appointments" />,
        },
        {
          path: "coacheeProgress",
          element: <StudentProgress pageTitle="Coachee Progress" />,
        },
        {
          path: "coacheeProgress/appointment/:bookingId",
          element: <StudentAppointmentList pageTitle="Coachee Progress" />,
        },
        {
          path: "coacheeProgress/appointment/:bookingId/:slotId",
          element: <StudentAppointmentDetails pageTitle="Coachee Progress" />,
        },
        {
          path: "coacheeProgress/appointment/:bookingId/:slotId/call",
          element: <AppointmentCall pageTitle="Manage Appointments" />,
        },
        {
          path: "coacheeProgress/appointment/:bookingId/:slotId/vcall",
          element: <AppointmentCall02 pageTitle="Manage Appointments" />,
        },
        {
          path: "reviewagreement",
          element: <ReviewAgreement pageTitle="Review Agreement" />,
        },
        // {
        //   path: "chatCoach",
        //   element: <ChatCoach pageTitle="Chat Messaging" />,
        // },
         {
          path: "chatCoach",
          element: <StreamChatList pageTitle="Chat Messaging" />,
        },
      ],
    },
    {
      path: "coacheeProgress/appointment/:bookingId/:slotId/videoCall",
      element: <AghoraVideo />,
    },
   

    {
      path: "",
      element: <Navigate to="dashboard" replace />,
    },
    {
      path: "*",
      element: <Navigate to="dashboard" replace />,
    },
  ];

const getStudentRoutes = (slug, isAgreementAccpeted, isTermsAccepted, moodleId) =>
  !isAgreementAccpeted
    ? [
        {
          path: `/${slug}`,
          element: <ProtectedLayout />,
          children: [
            {
              path: `/${slug}`,
              element: <Navigate to={`/${slug}/agreement`} />,
            },
            {
              path: `/${slug}/agreement`,
              element: <StudentAgreement />,
            },
          ],
        },
        {
          path: "/",
          element: <Navigate to={`/${slug}/agreement`} replace />,
        },
        {
          path: "*",
          element: <Navigate to={`/${slug}/agreement`} replace />,
        },
      ]
    : // : isAgreementAccpeted && !isTermsAccepted
    // ? [
    //     {
    //       path: `/${slug}`,
    //       element: <ProtectedLayout />,
    //       children: [
    //         {
    //           path: `/${slug}`,
    //           element: <Navigate to={`/${slug}/term&condition`} />,
    //         },
    //         {
    //           path: `/${slug}/term&condition`,
    //           element: <StudentAndCoachTerms />,
    //         },
    //       ],
    //     },
    //     {
    //       path: "/",
    //       element: <Navigate to={`/${slug}/term&condition`} replace />,
    //     },
    //     {
    //       path: "*",
    //       element: <Navigate to={`/${slug}/term&condition`} replace />,
    //     },
    //   ]
    moodleId == null || moodleId === undefined || moodleId.length === 0
    ? [
        {
          path: `/${slug}`,
          element: <ProtectedLayout />,
          children: [
            {
              path: `/${slug}`,
              element: <Navigate to={`/${slug}/profile/edit`} />,
            },
            {
              path: `/${slug}/profile/edit`,
              element: <ProfileStudentCreate pageTitle="Manage Profile" />,
            },
          ],
        },
        {
          path: "/",
          element: <Navigate to={`/${slug}/profile/edit`} replace />,
        },
        {
          path: "*",
          element: <Navigate to={`/${slug}/profile/edit`} replace />,
        },
      ]
    : [
        {
          path: `/${slug}`,
          element: <ProtectedLayout />,
          children: [
            {
              path: `/${slug}`,
              element: <Navigate to={`/${slug}/dashboard`} />,
            },
            {
              path: `/${slug}/dashboard`,
              element: <DashboardStudent />,
              id: "dashboard",
              loader: () => ({ pageTitle: "Dashboard" }),
            },
            {
              path: `/${slug}/profile/edit`,
              element: <ProfileStudentCreate pageTitle="Manage Profile" />,
            },
            {
              path: `/${slug}/changePassword`,
              element: <ChangePassword pageTitle="Change Password" />,
            },

            {
              path: `/${slug}/bookslots`,
              element: <AppointmentWizard pageTitle="Browse Coaches" />,
            },

            {
              path: `/${slug}/manageCoaching/anotheBookslots`,
              element: <AnotherAppointmentWizard pageTitle="Browse Coaches" />,
            },
            {
              path: `/${slug}/manageCoaching`,
              element: <MyCoursesList pageTitle="Manage Sessions" />,
            },
            {
              path: `/${slug}/viewresources`,
              element: <ViewMaterial pageTitle=" View Resources" />,
            },
            {
              path: `/${slug}/reviewagreement`,
              element: <ReviewAgreement pageTitle="Review Agreement" />,
            },
            {
              path: `/${slug}/manageCoaching/coach/:coachId`,
              element: <AppointmentList pageTitle="Manage Sessions" />,
            },
            {
              path: `/${slug}/manageCoaching/coach/:coachId/:slotId`,
              element: <AppointmentDetails pageTitle="Manage Sessions" />,
            },
            {
              path: `/${slug}/manageCoaching/appointment/:coachId/:slotId/call`,
              element: <AppointmentCall pageTitle="Manage Sessions" />,
            },
            {
              path: `/${slug}/manageCoaching/appointment/:coachId/:slotId/vcall`,
              element: <AppointmentCall02 pageTitle="Manage Sessions" />,
            },
            {
              path: `/${slug}/coachChangeRequest`,
              element: <CoachChangeRequest pageTitle="Coach Change Management" />,
            },
            {
              path: `/${slug}/coachChangeRequest/create`,
              element: <AddNewRequest pageTitle="Coach Change Management" />,
            },
            {
              path: `/${slug}/coachChangeRequest/edit/:id`,
              element: <AddNewRequest pageTitle="Email Trigger" />,
            },
            // {
            //   path: `/${slug}/chatApp`,
            //   element: <ChatPage pageTitle="Chat Messaging" />,
            // },
            {
              path: `/${slug}/chatApp`,
              element: <StreamChatList pageTitle="Chat Messaging" />,
            },
          ],
        },
        {
          path: `/${slug}/manageCoaching/appointment/:bookingId/:slotId/videocall`,
          element: <AghoraVideo />,
        },

        {
          path: "/",
          element: <Navigate to={`/${slug}/dashboard`} replace />,
        },
        {
          path: "*",
          element: <Navigate to={`/${slug}/dashboard`} replace />,
        },
      ];

const GetRoutes = (isAuth = false, user = {}, organisation = {}) => {
  const { role, agreementAccepted, termsAccepted, moodleId } = user;

  if (isAuth) {
    switch (role?.roleCode) {
      case USER_ROLES?.superadmin:
        return SuperadminRoutes;
      case USER_ROLES?.admin:
        return getAdminRoutes(organisation?.orgSlug);
      case USER_ROLES?.coach:
        return getCoachRotes(termsAccepted, agreementAccepted);
      case USER_ROLES?.student:
        return getStudentRoutes(
          organisation?.orgSlug,

          agreementAccepted,
          termsAccepted,
          moodleId
        );
      default:
        return AuthRoutes;
    }
  } else {
    return AuthRoutes;
  }
};

export { USER_ROLES, GetRoutes };

import httpClient from "../httpClient";
const baseSlug = "/userRole";

class httpService {
  getAll() {
    return httpClient.get(`${baseSlug}`);
  }
}

export default new httpService();

import React, { useState, useEffect, useCallback, useRef, useMemo } from "react";
import { Link } from "react-router-dom";
import { Container, Row, Col, Form, Button } from "react-bootstrap";
import { useDispatch, useSelector } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { setRouteData } from "../../stores/appSlice";
import { OrganisationService, UserService } from "../../services";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faPencil, faTrashCan, faTimes, faCheck, faEye } from "@fortawesome/free-solid-svg-icons";
import { AgGridReact } from "ag-grid-react";
import "ag-grid-community/styles/ag-grid.css";
import "ag-grid-community/styles/ag-theme-alpine.css";
import { confirmAlert } from "react-confirm-alert";
import "react-confirm-alert/src/react-confirm-alert.css";
import { toast } from "react-toastify";
import { getColorThemeData } from "../../stores/colorThemeSlice";
import DeleteListModal from "../CustomComponent/DeleteListModal";
import DropdownList from "../CustomComponent/DropdownList";

function AdminList({ pageTitle }) {
  const dispatch = useDispatch();
  const [orgList, set_orgList] = useState([]);
  const [selectedOrg, set_selectedOrg] = useState(null);
  const [allAdmins, set_allAdmins] = useState([]);
  const [adminList, set_adminList] = useState([]);
  const [pageError, set_pageError] = useState("");
  const gridRef = useRef();
  const colorTheme = useSelector((state) => state.colorTheme.colorTheme);
  const [selectedItem, setselectedItem] = useState(null);
  const [isDeleteModalOpen, setIsDeleteModalOpen] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
    fetchOrganisations();
    fetchOrgAdmins();
    dispatch(getColorThemeData(colorTheme));
  }, []);

  const colorThemeData = useSelector((state) => state.colorTheme.colorThemeData);

  useEffect(() => {
    if (selectedOrg) {
      set_adminList(allAdmins.filter((itm) => itm.organisation?.id === selectedOrg._id));
    }
  }, [selectedOrg, allAdmins]);

  async function fetchOrganisations() {
    try {
      const resp = await trackPromise(OrganisationService.getList());
      const { data } = resp;

      if (data.success) {
        set_orgList(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisations catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchOrgAdmins() {
    try {
      const resp = await trackPromise(UserService.getAdmins());
      const { data } = resp;

      let adminList = data?.data?.filter((itm) => !itm?.isDeleted);
      set_allAdmins(adminList);
    } catch (err) {
      console.error("Fetch Admins catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function deleteOrgAdmins(id) {
    try {
      const resp = await trackPromise(UserService.delete(id));
      const { data } = resp;

      if (data?.success === true) {
        toast.success("Admin Deleted Successfully.");
        await fetchOrgAdmins();
      } else {
        toast.error(data?.message);
      }
    } catch (err) {
      console.error("Delete error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        toast.error(err.response.data.message || "Delete error");
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.response.data.message);
      }
    }
  }

  async function deleteOrg(id) {
    deleteOrgAdmins(id);
    closeDeleteModal();
  }

  const openDeleteModal = (id) => {
    setselectedItem(id);
    setIsDeleteModalOpen(true);
  };

  const closeDeleteModal = () => {
    setselectedItem(null);
    setIsDeleteModalOpen(false);
  };

  const handleSelect = (selectedList) => {
    set_selectedOrg(selectedList);
  };

  const getOptionLabelFunction = (option) => `${option.orgName}`;
  // const deleteOrg = (id) => {
  //   console.log("Delete id-----", id);
  //   confirmAlert({
  //     title: <div style={{ fontSize: "20px", color: colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93" }}>Delete Confirm</div>,
  //     className: "title-button",

  //     // message: "Are you sure to delete this?",
  //     childrenElement: () => (
  //       <div style={{ color: colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93" }}>Are you sure to delete this?</div>
  //     ),
  //     buttons: [
  //       {
  //         label: "Yes",
  //         className: "yes-button",
  //         style: { backgroundColor: colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93" },
  //         onClick: async () => {
  //           try {
  //             const resp = await trackPromise(UserService.delete(id));
  //             const { data } = resp;
  //             console.log(data, "delete data");

  //             if (data?.success === true) {
  //               await toast.success("Admin Deleted Successfully");
  //               console.log("Item deleted successfully");
  //               await fetchOrgAdmins();
  //             } else {
  //               toast.error(data?.message);
  //             }
  //           } catch (err) {
  //             console.error("Delete error catch => ", err);
  //             if (err.code !== "ERR_NETWORK") {
  //               toast.error(err.response.data.message || "Delete error");
  //               set_pageError(err.response.data.message);
  //             } else {
  //               set_pageError(err.response.data.message);
  //             }
  //           }
  //         },
  //       },
  //       {
  //         label: "No",
  //         className: "no-button",
  //         style: {
  //           border: `1px solid ${colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"}`,
  //           background: colorThemeData[0]?.CLR_TRANSPARENT ?? "transparent",
  //           color: colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93",
  //         },
  //         onClick: () => console.log("Click No"),
  //       },
  //     ],
  //   });
  // };

  const [columnDefs, setColumnDefs] = useState([
    {
      field: "username",
      headerName: "Username",
      // width: 200,
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },

      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Email",
      field: "email",
      tooltipField: "email",
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },
    {
      headerName: "Role",
      field: "role.roleName",
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "center" },
    },
    {
      headerName: "Organisation",
      field: "organisation.orgName",
      suppressMovable: true,
      filterParams: {
        buttons: ["reset"],
        closeOnApply: true,
      },
      cellStyle: { textAlign: "left" },
    },

    {
      field: "action",
      suppressMovable: true,
      headerName: "Action",
      sortable: false,
      suppressMenu: true,
      cellStyle: { textAlign: "center" },
      cellRenderer: function (params) {
        const isDelete = params.data.isDeleted;
        const iconClass = isDelete ? "disabled-icon" : "active-icon";
        return (
          <>
            <Link className={`grid_action ${isDelete ? "disabled-link" : ""}`} to={`/admin/view/${params?.data?.id}`}>
              <FontAwesomeIcon
                icon={faEye}
                className={iconClass}
                title="View"
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault(); // Prevent the edit action when isDelete is true
                  }
                }}
              />
            </Link>

            <Link className={`grid_action ${isDelete ? "disabled-link" : ""}`} to={`/admin/edit/${params?.data?.id}`}>
              <FontAwesomeIcon
                icon={faPencil}
                // color="#3d3e45"
                title="Edit"
                className={iconClass}
                onClick={(e) => {
                  if (isDelete) {
                    e.preventDefault(); // Prevent the edit action when isDelete is true
                  }
                }}
              />
            </Link>
            <Link className={`grid_action ${isDelete ? "disabled-link" : ""}`} to="">
              <FontAwesomeIcon
                icon={faTrashCan}
                // color="#3d3e45"
                title="Delete"
                className={iconClass}
                onClick={() => {
                  openDeleteModal(params?.data?.id);
                }}
              />
            </Link>
          </>
        );
      },
    },
  ]);
  const defaultColDef = useMemo(() => ({
    filter: true,
    sortable: true,
    flex: 1,
  }));

  const onFirstDataRendered = useCallback((params) => {
    gridRef.current.api.sizeColumnsToFit();
  }, []);

  //Pagination
  const paginationNumberFormatter = useCallback((params) => {
    return "[" + params.value.toLocaleString() + "]";
  }, []);

  const onPageSizeChanged = useCallback(() => {
    var value = document.getElementById("page-size").value;
    gridRef.current.api.paginationSetPageSize(Number(value));
  }, []);

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="col hd-txt">Organisation Admins</h4>
        <Button
          as={Link}
          to="create"
          variant="clr-primary"
          className="btn-add"
          state={{ selectedData: selectedOrg?._id }}
        >
          Add New
        </Button>
      </div>

      <Row className="mb-4">
        <Col lg="12">
          <Form.Group className="frm-group">
            <Form.Label>Organisation</Form.Label>
            <Col xs={12} md={4}>
              <DropdownList
                name="oraganisation"
                value={selectedOrg}
                allStudent={orgList}
                handleSelect={handleSelect}
                placeholder="Select Organisation"
                getOptionLabel={getOptionLabelFunction}
                isMulti={false}
              />
            </Col>
          </Form.Group>
        </Col>
      </Row>

      <Row className="mb-4">
        <Col lg="12">
          {adminList?.length ? (
            <>
              <div className="ag-theme-alpine" style={{ height: 480, width: "100%" }}>
                <AgGridReact
                  id="myGrid"
                  className="myGrid"
                  ref={gridRef}
                  columnDefs={columnDefs}
                  defaultColDef={defaultColDef}
                  rowData={adminList}
                  pagination={true}
                  paginationPageSize={10}
                  paginationNumberFormatter={paginationNumberFormatter}
                  onFirstDataRendered={onFirstDataRendered}
                />
              </div>
              <div className="page-size-wrap">
                <div className="page-size">
                  Records Per Page:
                  <select onChange={onPageSizeChanged} id="page-size">
                    <option value="10">10</option>
                    <option value="25">25</option>
                    <option value="50">50</option>
                  </select>
                </div>
              </div>
            </>
          ) : (
            <div>No Admin Found</div>
          )}
        </Col>
      </Row>
      {selectedItem && (
        <DeleteListModal
          id={selectedItem}
          isOpen={isDeleteModalOpen}
          onClose={closeDeleteModal}
          deleteItem={deleteOrg}
          pageError={pageError}
        />
      )}
    </Container>
  );
}

export default AdminList;

import { useState, useEffect } from "react";
import { Container, Row, Col, Button, Form, Image } from "react-bootstrap";
import { Formik } from "formik";
import * as Yup from "yup";
import { Link, useParams, useNavigate } from "react-router-dom";
import { useDispatch } from "react-redux";
import { trackPromise } from "react-promise-tracker";
import { toast } from "react-toastify";
import { setRouteData } from "../../stores/appSlice";
import { UserService, AccreditationService, ChangeRequestService } from "../../services";
import { replaceWithBr } from "../../utilities/functions";
import moment from "moment";
import Modal from "react-bootstrap/Modal";

function RequestApprove({ pageTitle }) {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const params = useParams();
  const [requestId, set_requestId] = useState(params.id);
  const [requestDetails, set_requestDetails] = useState({});
  const [confirmData, setconfirmData] = useState(false);

  const [formObject, set_formObject] = useState(getFormObject());
  const [pageError, set_pageError] = useState("");
  const [showErrorModal, setShowErrorModal] = useState(false);

  useEffect(() => {
    dispatch(setRouteData({ pageTitle }));
  }, []);

  useEffect(() => {
    if (requestId) fetchRequestDetails();
  }, [requestId]);

  function getFormObject() {
    return {
      requestStatus: "",
    };
  }

  const FormSchema = Yup.object().shape({
    requestStatus: Yup.string().required("Select an Request Status"),
  });

  async function fetchRequestDetails() {
    try {
      const resp = await trackPromise(ChangeRequestService.getRequestById(requestId));
      const { data } = resp;
      if (data.success) {
        set_requestDetails(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisation type details");
      }
    } catch (err) {
      console.error("Fetch organisation type details catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function handleFormSubmit(values) {
    const requestData = {
      confirmToProceed: confirmData,
      ...values,
    };
    set_formObject(values);

    try {
      const resp = await trackPromise(ChangeRequestService.updateRequestStatus(requestId, requestData));
      const { data } = resp;
      if (data.success) {
        if (data.data.serverConfirmation) {
          set_pageError(data.message);
          setShowErrorModal(true);

          // toast.error(data.message);

          setconfirmData(true);

          if (confirmData === true) {
            closeModal();
            set_pageError("");
            toast.success("Request status updated successfully.");
            navigate(-1);
          }

          // toast.success("Request status updated successfully.");
          // navigate(-1);
        } else {
          setconfirmData(false);
          set_pageError("");
          toast.success("Request status updated successfully.");
          navigate(-1);
        }
      } else {
        setconfirmData(false);
        set_pageError(data.message || "Request status update failed");
        toast.error(data.message || "Request status update failed");
      }
    } catch (err) {
      console.error("Request status updated error catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
      toast.error(err.response.data.message || err.message || "Request status updated error");
    }
  }

  const onConfirm = () => {
    handleFormSubmit(formObject);
  };
  const closeModal = () => {
    setShowErrorModal(false);
    setconfirmData(false);

    set_pageError("");
  };
  // const capitalizeFirstLetter = (str) => {
  //   return str.charAt(0).toUpperCase() + str.slice(1);
  // };

  return (
    <Container fluid={true} className="main-content">
      <div className="page-heading">
        <h4 className="hd-txt">Approve Request</h4>
      </div>

      <div className="details-wrap px-3">
        <DetailRow
          rwLabel="Coach Name"
          rwValue={`${requestDetails?.coach?.firstName || ""} ${requestDetails?.coach?.lastName || ""}`}
        />
        <DetailRow
          rwLabel="Coachee Name"
          rwValue={`${requestDetails?.student?.firstName || ""} ${requestDetails?.student?.lastName || ""}`}
        />

        {requestDetails.requestStatus && (
          <DetailRow
            className="text-capitalize"
            rwLabel="Request Status"
            rwValue={requestDetails.requestStatus
              .split(" ")
              .map((word) => word.charAt(0).toUpperCase() + word.slice(1))
              .join(" ")}
          />
        )}
        <DetailRow rwLabel="Request Reason" rwValue={requestDetails.requestReason} />
        <DetailRow rwLabel="Created On" rwValue={moment(requestDetails.createdAt).format("DD/MM/YYYY hh:mm A")} />
        <DetailRow rwLabel="Updated On" rwValue={moment(requestDetails.updatedAt).format("DD/MM/YYYY hh:mm A")} />
        {/* <DetailRow rwLabel="Email Address" rwValue={requestDetails.email} /> */}
        <div className="form-01-wrap">
          <Formik
            initialValues={formObject}
            enableReinitialize="true"
            validationSchema={FormSchema}
            onSubmit={handleFormSubmit}
          >
            {({ handleSubmit, handleChange, values, errors, touched, isSubmitting }) => (
              <Form noValidate onSubmit={handleSubmit}>
                <Form.Group as={Row} className="frm-group detail-row">
                  <Form.Label column lg={4} className="drw-label">
                    Status
                  </Form.Label>
                  <Col lg={3}>
                    <Form.Select
                      name="requestStatus"
                      value={values.requestStatus}
                      onChange={(e) => {
                        handleChange(e);
                      }}
                      // onChange={handleChange}
                      isInvalid={touched.requestStatus && errors.requestStatus}
                    >
                      <option value="">Select Status</option>
                      <option value="approved">Approved</option>
                      <option value="rejected">Rejected</option>
                    </Form.Select>
                    <Form.Control.Feedback type="invalid">{errors.requestStatus}</Form.Control.Feedback>
                  </Col>
                </Form.Group>

                <div className="row g-0 frm-btn-grp gap-2">
                  <Button as={Link} to="../manageRequest" variant="clr-transparent" className="col-lg-3 me-lg-3">
                    Cancel
                  </Button>
                  <Button variant="clr-primary" type="submit" disabled={isSubmitting} className="col-lg-3 ms-lg-3">
                    Submit
                  </Button>
                </div>

                {pageError ? <div className="text-danger">{pageError}</div> : <></>}
              </Form>
            )}
          </Formik>
        </div>
      </div>
      <ErrorModal show={showErrorModal} onHide={closeModal} errorMessage={pageError} onConfirm={onConfirm} />
    </Container>
  );
}

export default RequestApprove;

function DetailRow({ rwLabel = "", rwValue = "", arrayDataClassName }) {
  return (
    <Row className="detail-row">
      <Col lg={4} className="drw-label">
        {rwLabel}
      </Col>
      <Col lg={8} className={`drw-value ${arrayDataClassName}`}>
        {Array.isArray(rwValue) ? (
          rwValue?.map((itm, inx) => (
            // Modify this line to render the array values without using itm.topicName
            <span key={inx} className="drw-pill me-2">
              {itm}
            </span>
          ))
        ) : (
          <div dangerouslySetInnerHTML={{ __html: replaceWithBr(rwValue) }}></div>
        )}
      </Col>
    </Row>
  );
}

function ErrorModal({ show, onHide, errorMessage, onConfirm }) {
  return (
    <Modal show={show} onHide={onHide}>
      <Modal.Header closeButton>
        <Modal.Title>Important Message</Modal.Title>
      </Modal.Header>
      <Modal.Body>
        <p>{errorMessage}</p>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="clr-transparent" onClick={onHide}>
          Cancel
        </Button>
        <Button variant="clr-primary" onClick={onConfirm}>
          Confirm
        </Button>
      </Modal.Footer>
    </Modal>
  );
}

import { useEffect, useState } from "react";
import { Container, Row, Col, Button } from "react-bootstrap";
import { Link, useRouteLoaderData } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { setRouteData } from "../../stores/appSlice";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import { faEnvelope, faUsers } from "@fortawesome/free-solid-svg-icons";
import ReportBarChart from "../Dashboard/Component/ReportComponentBarChart";

import ReportAdminPie from "./Component/ReportComponentPie";
import { trackPromise } from "react-promise-tracker";
import { StatsService, ReportService } from "../../services";
import moment from "moment";

function DashboardAdmin() {
  const routeData = useRouteLoaderData("dashboard");
  const dispatch = useDispatch();
  const { authState, appState } = useSelector((state) => state);
  const [userStat, set_userStat] = useState([]);
  const [pageError, set_pageError] = useState("");
  const [StudentData, setData] = useState([]);
  const [userCount, set_userCount] = useState([]);

  useEffect(() => {
    dispatch(setRouteData({ ...routeData }));
    fetchUserStat();
    StudentusesHours();
    fetchStudentCount();
  }, []);

  const localFormatDate = (date) => {
    const outputFormat = "YYYY-MM-DDTHH:mm:ssZ";
    const convertedDate = moment(date).format(outputFormat);
    return convertedDate;
  };

  
  const endDateFormat = (date) => {
    const givenDate = moment(date);
    const result = givenDate.set({
      hour: 23,
      minute: 59,
      second: 59,
    });
    return result.format();
  };


  const endDateSet = endDateFormat(new Date());
  const date = new Date(endDateSet);

// Subtract one year
date.setFullYear(date.getFullYear() - 1);

let startDateYearBack=(date.toISOString()); // Outputs the date in ISO format
  const localStartDate = localFormatDate(startDateYearBack);
  const localEndDate = localFormatDate(endDateSet);



  console.log(localEndDate,localStartDate);
  async function fetchUserStat() {
    try {
      const resp = await trackPromise(StatsService.getOverviewStats());
      const { data } = resp;
      if (data.success) {
        set_userStat(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function StudentusesHours() {
    try {
      const resp = await trackPromise(
        ReportService.getOrgStudentUsage({
          startDate: localStartDate,
          endDate: localEndDate,
          orgUsers: [],
        })
      );
      const { data } = resp;
      if (data.success) {
        setData(data.data);
      } else {
        set_pageError(data.message || "Error in fetching organisations");
      }
    } catch (err) {
      console.error("Fetch organisationsHours catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }

  async function fetchStudentCount() {
    try {
      const resp = await trackPromise(ReportService.getStudentCount());
      const { data } = resp;
      if (data.success) {
        set_userCount(data.data);
      } else {
        set_pageError(data.message || "Error in fetching Coaches");
      }
    } catch (err) {
      console.error("Fetch Coaches catch => ", err);
      if (err.code !== "ERR_NETWORK") {
        set_pageError(err.response.data.message);
      } else {
        set_pageError(err.message);
      }
    }
  }
  const colorThemeData = useSelector((state) => state?.colorTheme?.colorThemeData);

  return (
    <Container fluid={true} className="main-content">
      <Row>
        <Col>
          <Row>
            <Col md={6}>
              <Row className="px-2 mx-1 ">
                <Row>
                  <Col className="inner-card mx-1  " md={6}>
                    <Row>
                      <Col>
                        <Row className="d-flex">
                          <Col md={10} xs={8}>
                            <Row className="text-dash">
                              <h5 className="hd-txt">{userStat.students ? userStat.students : 0}</h5>
                              {/* </Row>
                            <Row> */}
                              <h6>Users</h6>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="d-flex justify-content-end">
                              <FontAwesomeIcon
                                icon={faUsers}
                                size="3x"
                                color={colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"}
                              />
                            </Row>
                          </Col>
                        </Row>
                        <Row className="px-2">
                          <Button
                            variant="clr-primary"
                            as={Link}
                            to={`/${authState.user.organisation.orgSlug}/coachee`}
                          >
                            Coachee Users
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </Col>
            <Col md={6}>
              <Row className="px-2 mx-1 ">
                <Row>
                  <Col className="inner-card mx-1  " md={6}>
                    <Row>
                      <Col>
                        <Row className="d-flex">
                          <Col md={10} xs={8}>
                            <Row className="text-dash">
                              <h5 className="hd-txt">{userStat.emailTemplates ? userStat.emailTemplates : 0}</h5>
                              {/* </Row>
                            <Row> */}
                              <h6>Templates</h6>
                            </Row>
                          </Col>
                          <Col>
                            <Row className="d-flex justify-content-end">
                              <FontAwesomeIcon
                                icon={faEnvelope}
                                size="3x"
                                color={colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"}
                              />
                            </Row>
                          </Col>
                        </Row>
                        <Row className="px-2">
                          <Button
                            variant="clr-primary"
                            as={Link}
                            to={`/${authState.user.organisation.orgSlug}/emailTemplateList`}
                          >
                            Email Templates
                          </Button>
                        </Row>
                      </Col>
                    </Row>
                  </Col>
                </Row>
              </Row>
            </Col>
          </Row>
          <Row className="px-4 my-4">
            <Row>
              <Col>
                <h4 className="hd-txt">Reports</h4>
                <Row>
                  <h5>User  Session Utilisation</h5>
                  <p>
                  Track the allocation and utilisation of coaching sessions by individual users within the organisation.
                  </p>
                </Row>
                <div>
                  {/* <ReportAdminBar data={StudentData} /> */}
                  <ReportBarChart
                    data={StudentData}
                    xAxisLabel="Sessions"
                    yAxisLabel="User Name"
                    xValueKey="usedSessions"
                    yValueKey="firstName"
                  />
                </div>
              </Col>
              <Col>
                <Row
                  className="d-flex justify-content-end text-decoration-underline"
                  as={Link}
                  to={`/${authState.user.organisation.orgSlug}/viewreports`}
                  style={{ color: `${colorThemeData[0]?.CLR_PRIMARY ?? "#B18C93"}` }}
                >
                  View More
                </Row>
                <Row>
                  <h5>Active vs Inactive User Distribution</h5>
                  <p>
                  Track the distribution of active versus inactive users on the coaching platform.
                     </p>
                </Row>
                <div>
                  <ReportAdminPie legend={false} countData={userCount} />
                </div>
              </Col>
            </Row>
          </Row>
        </Col>
      </Row>
    </Container>
  );
}

export default DashboardAdmin;
